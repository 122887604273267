import { Formik, Form, Field, useFormikContext } from 'formik';
import _ from 'lodash';
import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Link } from 'react-router-dom';
import { useTranslation, Trans } from "react-i18next"

import ProtectionIcon from '../components/ProtectionIcon';
import { SortButton as DynamicSortButton } from '../patents/DataWizard';
import { downloadReport } from '../backend';
import { useBackend } from "../BackendProvider";
import { IconCopy, IconEdit } from "../components/icons";
import { ImageCaroussel, PlainImage } from '../components/Image';
import Modal from "../components/Modal";
import { patent_family } from "../data";
import './valuations.css';
import { useValuations } from "./ValuationsProvider";
import { DatePicker } from '../components/DatePicker';
import ToggleButton from '../components/ToggleButton';
import ToolTip from '../components/ToolTip';
import { useMessages } from '../Messages';
import { useRoles } from '../user/Auth';
import { familyUrl, memberUrl } from '../patents/utils';
import { useProductMapping } from '../products/products';
import { Score } from './scores';
import { Member } from '../patents/patents';
import { useFilteredPatents } from '../filter/FilteredPatents';
import { usePatents } from '../patents/PatentsProvider';


const technical_overall = "technical"
const commercial_overall = "commercial"
const legal_overall = "legal"
const financial_overall = "financial"

const byFamilyMember = 'by-family-member'
const byPatentFamily = 'by-patent-family'

const saveGroupBy = (groupBy) => window.localStorage.setItem('valuations-groupBy', groupBy)
const loadGroupBy = () => window.localStorage.getItem('valuations-groupBy') || byFamilyMember

const saveSortBy = (sortBy) => window.localStorage.setItem('valuations-sortBy', sortBy)
const loadSortBy = () => window.localStorage.getItem('valuations-sortBy') || 'internalReference'

const saveShowDetails = (showDetails) => window.localStorage.setItem('valuations-showDetails', showDetails)
const loadShowDetails = () => window.localStorage.getItem('valuations-showDetails') !== 'false'

const saveSortByOrder = (sortBy) => window.localStorage.setItem('valuations-sortByOrder', sortBy)
const loadSortByOrder = () => window.localStorage.getItem('valuations-sortByOrder') === '1' ? 1 : -1

const saveSortByRefOrder = (sortBy) => window.localStorage.setItem('valuations-sortByRefOrder', sortBy)
const loadSortByRefOrder = () => window.localStorage.getItem('valuations-sortByRefOrder') === '1' ? 1 : -1

const saveFromScore = (score) => window.localStorage.setItem('valuations-fromScore', score)
const loadFromScore = () => {
    const result = parseFloat(window.localStorage.getItem('valuations-fromScore'))
    return isNaN(result) ? 0.0 : result
}

const saveToScore = (score) => window.localStorage.setItem('valuations-toScore', score)
const loadToScore = () => {
    const result = parseFloat(window.localStorage.getItem('valuations-toScore'))
    return isNaN(result) ? 4.0 : result
}

const dateWidth = "w-24 text-sm text-slate-600 whitespace-nowrap text-center"
const scoreWidth = "md:w-16 w-8 text-sm text-slate-600 whitespace-nowrap text-center"
const editWitdh = "w-6 text-center"


export default function Valuations() {
    const {isEditUser} = useRoles()
    const {members} = useFilteredPatents()
    const {members: allMembers, families: allFamilies} = usePatents()
    const {valuationMeta, scoresLookup} = useValuations()
    const {setErrorMessage} = useMessages()
    const {t} = useTranslation()

    const valuationMetaById = _.keyBy(valuationMeta, m => m.familyMemberId)

    const [groupBy, setGroupBy] = useState(loadGroupBy())
    const groupByValues = [byFamilyMember, byPatentFamily] /* TODO: By Claim Scope */
    useEffect(() => saveGroupBy(groupBy), [groupBy])

    const [sortBy, setSortBy] = useState(loadSortBy())
    useEffect(() => saveSortBy(sortBy), [sortBy])

    const [showDetails, setShowDetails] = useState(loadShowDetails())
    useEffect(() => saveShowDetails(showDetails), [showDetails])

    const [sortOrder, setSortOrder] = useState(loadSortByOrder())
    useEffect(() => saveSortByOrder(sortOrder), [sortOrder])

    const [sortRefOrder, setSortRefOrder] = useState(loadSortByRefOrder())
    useEffect(() => saveSortByRefOrder(sortRefOrder), [sortRefOrder])

    const [fromScore, setFromScore] = useState<number>(loadFromScore())
    useEffect(() => saveFromScore(fromScore), [fromScore])

    const [toScore, setToScore] = useState<number>(loadToScore())
    useEffect(() => saveToScore(toScore), [toScore])

    const membersById = _.keyBy(allMembers, m => m.familyMemberId)
    const familiesById = _.keyBy(allFamilies, f => f.patentFamilyId)
    const scoresById = _.mapValues(scoresLookup, calcScore)
    
    const sortMembers = 
        sortBy === 'score'
            ? m => scoresById[m.familyMemberId] ?? 0.0
            : sortBy === 'date'
            ? m => valuationMetaById[m.familyMemberId]?.evaluationDate ?? '-'
            : m => membersById[m.familyMemberId].internalReference

    const transform = (items) =>
        _(items)
            .filter(m => {
                const score = scoresById[m.familyMemberId] ?? 0.0
                return ((fromScore ?? 0.0) <= score) && (score <= (toScore ?? 4.0))
            })
            .sortBy(sortMembers)
            //.tap(ms => (groupBy === patent_family ? sortOrder : (sortBy === 'internalReference' ? sortRefOrder : sortOrder)) > 0 ? ms : _.reverse(ms))
            .tap(ms => (sortBy === 'internalReference' ? sortRefOrder : sortOrder) > 0 ? ms : _.reverse(ms))
            .value()

    const groups = 
        groupBy === byPatentFamily
        ? _(members)
            .groupBy(m => membersById[m.familyMemberId].patentFamilyId).toPairs()
            .filter(([idString]) => idString in familiesById)
            .sortBy(([idString]) => familiesById[idString].internalReference)
            .tap(groups => (groupBy === byPatentFamily ? sortRefOrder : sortOrder) > 0 ? groups : _.reverse(groups))
            .map(([idString, items]) => ({items: transform(items), header: familiesById[+idString]})).value()
        : [{ 
            header: 'none',
            items: transform(members)
        }]

    const labelWidth = "w-32 sm:w-fit inline-block"

    return (
        <>
            {/* @ts-ignore */}
            <Helmet>
                <title>{t('evaluations')} | Patent Cockpit</title>
            </Helmet>

            <div className="header-row">
                <div className="flex flex-row gap-2 items-center w-visible md:max-w-4xl xl:max-w-5xl">
                    <h2 className="modern-h2 grow">{t('evaluations')}</h2>
                    <button 
                            className="btn-secondary font-normal text-base py-px"
                            onClick={() => downloadReport({ url: "/api/excel", report: "evaluation-report" }).catch((err) => setErrorMessage(err.message))}
                    >
                        {t('excel-export')}
                    </button>
                </div>
            </div>
            <div className="main-content">
                <div className="py-2 xl:max-w-5xl md:max-w-4xl  flex lg:flex-row flex-col lg:gap-6 gap-4 text-slate-600">
                    <div className="flex flex-col md:flex-row gap-4">
                        <div className="flex flex-row gap-2">
                            <label><span className={labelWidth}>{t('grouping')} &nbsp;</span>
                                <select className="form-select w-40 pr-4 h-8 py-1 text-sm dark:bg-pc-100" value={groupBy} onChange={(e) => setGroupBy(e.target.value)}>
                                    {groupByValues.map(v => <option key={v} value={v}>{t(v) ?? v}</option>)}
                                </select>
                            </label>
                        </div>
                    </div>

                    <div className="flex md:flex-row flex-col gap-4">
                        <label>
                            {t('from-score')} &nbsp;
                            <input type="number" className="form-input bg-transparent h-8 py-1 w-14" value={fromScore} placeholder="0" onChange={e => setFromScore(parseFloat(e.target.value))} />
                        </label>

                        <label>
                            {t('to-score')} &nbsp;
                            <input type="number" className="form-input bg-transparent h-8 py-1 w-14" value={toScore} placeholder="4" onChange={e => setToScore(parseFloat(e.target.value))} />
                        </label>
                    </div>

                    <div className="grow" />

                    <label className="inline-flex items-center gap-2">
                        <span className={groupBy === 'none' ? 'text-slate-300' : ''}>{t('show-details')}</span> <ToggleButton {...{checked: showDetails, setChecked: setShowDetails, disabled: groupBy === 'none'}}/>
                    </label>
                </div>

                <div className="flex flex-col">
                    <div className="xl:max-w-5xl md:max-w-4xl max-w-xl py-2 pl-1 pr-4 bg-white dark:bg-pc-100 border-b-2 border-pc-300 flex flex-row items-center justify-end sm:gap-4 gap-2 -4 sticky -top-4 z-10">
                        <div title={t("sort-by-ref")} className={scoreWidth + ' inline-flex items-center'}><span className="md:hidden inline">{t('ref')}</span><span className="hidden md:inline">{t('reference')}</span>&nbsp;<DynamicSortButton {...{searchField: 'internalReference', sortField: sortBy, sortOrder: sortRefOrder, setSortOrder: setSortRefOrder, setSortField: setSortBy}} /></div>
                        <div className="grow" />
                        <div title={t("sort-by-eval-date")} className={dateWidth + ' inline-flex items-center justify-center'}><span className="inline">{t('eval-date')}</span>&nbsp;<DynamicSortButton {...{searchField: 'date', sortField: sortBy, sortOrder, setSortOrder, setSortField: setSortBy}} /></div>
                        <div className={scoreWidth}><span className="md:hidden inline">{t('tec')}</span><span className="hidden md:inline">{t('technical')}</span></div>
                        <div className={scoreWidth}><span className="md:hidden inline">{t('com')}</span><span className="hidden md:inline">{t('commercial')}</span></div>
                        <div className={scoreWidth}><span className="md:hidden inline">{t('leg')}</span><span className="hidden md:inline">{t('legal')}</span></div>
                        <div className={scoreWidth}><span className="md:hidden inline">{t('fin')}</span><span className="hidden md:inline">{t('financial')}</span></div>
                        <div title="Sort by Total Score" className={scoreWidth + ' inline-flex items-center'}><span className="md:hidden inline">{t('tot')}</span><span className="hidden md:inline">{t('total')}</span>&nbsp;<DynamicSortButton {...{searchField: 'score', sortField: sortBy, sortOrder, setSortOrder, setSortField: setSortBy}} /></div>

                        <div className={editWitdh} >
                            <ToolTip className="tooltip-left" >
                                <Trans i18nKey="evaluation-score-help">
                                    The Total Score is calculated as (T · C · L · F)<sup>¼</sup>,
                                    where T, C, L and F are the technical, commercial, legal and financial score, respectively.
                                </Trans>
                            </ToolTip>
                        </div>
                        {isEditUser && <div className={editWitdh} />}
                    </div>


                    {groups.filter(g => g.items.length > 0).map(({ header, items }, gindex) =>
                        <ValuationGroup key={`header-${groupBy}-${gindex}`} {...{ header, showDetails }} >
                            {items.map((m) =>
                                <ValuationRow key={m.familyMemberId} {...{
                                    membersById,
                                    member: m,
                                    scores: scoresLookup[m.familyMemberId] ?? [],
                                    meta: valuationMetaById[m.familyMemberId],
                                    items,
                                }} />)}
                        </ValuationGroup>
                    )}
                </div>
            </div>
        </>
    )
}

function ValuationGroup({header, showDetails, children}) {
    const groupStyle = "xl:max-w-5xl md:max-w-4xl max-w-xl flex flex-col overflow-hidden pr-2"
    const headerStyle = "flex flex-row gap-1 overflow-hidden"
    if (header.patentFamilyId !== undefined) {
        return (
            <div className={groupStyle + ' pl-1 pt-1 border-b-2 border-pc-300'}>
                <div className={headerStyle}>
                    <Link className="hover:underline decoration-slate-700" to={familyUrl(header)}>
                        <h3 className="grow overflow-hidden text-ellipsis whitespace-nowrap text-slate-700" >
                            {header.internalReference}: {header.familyName}
                        </h3>
                    </Link>
                </div>
                <ValuationGroupDetails {...{patentFamilyId: header.patentFamilyId, showDetails}} />
                {children}
            </div>
        )
    } else { // none or something unknonw
        return (
            <div className={groupStyle} >
                {children}
            </div>
        )
    }
}

type ValuationGroupDetailsProps = {
    patentFamilyId: number,
    showDetails: boolean,
    familyMemberId?: number,
}

const detailStyle = "max-w-sm text-slate-500" 
const detailHeaderStyle = "pb-2 text-slate-600"
function ValuationGroupDetails({patentFamilyId, showDetails, familyMemberId}: ValuationGroupDetailsProps) {
    const { claims, claimScopes, images} = useBackend()
    const {families, members} = useFilteredPatents()
    const {hasClaimScopes} = useRoles()
    const {t} = useTranslation()
    const {commoditiesByFamilyId, claimScopeCommoditiesByPatentFamilyId} = useProductMapping()

    const family = families.find(f => f.patentFamilyId === patentFamilyId)

    if (!showDetails || family === undefined) return null
    else {
        const familyMemberIds = (familyMemberId && hasClaimScopes) 
            ? new Set([familyMemberId]) 
            : new Set(members.filter(m => m.patentFamilyId === patentFamilyId).map(m => m.familyMemberId))

        const products = _.uniqBy([
            ...(commoditiesByFamilyId[patentFamilyId] ?? []), 
            ...(claimScopeCommoditiesByPatentFamilyId[patentFamilyId] ?? [])
        ], 'commodityId')

        const claimScopeIds = new Set(claims.filter(c => familyMemberIds.has(c.familyMemberId) && c.claimScopeId).map(c => c.claimScopeId))
        const claimScopeSummaries = _(claimScopes).filter(c => claimScopeIds.has(c.claimScopeId)).map(c => [c.claimScopeId, c.claimScopeSummary]).fromPairs().value()
        const claimScopeImages = _(images).filter(i => i.entity === 'claim-scope' && claimScopeIds.has(i.entityId)).keyBy(i => i.entityId).value()
        const carouselImages = _(claimScopeSummaries).toPairs().map(([id, caption]) => ({image: claimScopeImages[id], caption})).value()
        const familyImage = images.find(i => i.entity === patent_family && i.entityId === patentFamilyId)

        let image = hasClaimScopes
            ? <div className="w-44"><ImageCaroussel {...{images: familyImage ? [{image: familyImage, caption: family.summary}, ...carouselImages] : carouselImages}} /></div> 
            : <div className="h-32 w-44 p-1">
                <PlainImage {...{ entity: patent_family, entityId: patentFamilyId, clickable: false }} />
            </div>

        return (
            <div className="flex sm:flex-row flex-col gap-4 text-sm pl-2 sm:pl-0">
                    {image}
                <div className={detailStyle + ' w-[50%]'}>
                    <h5 className={detailHeaderStyle}>{t('summary')}</h5>
                    <p>{family.summary ?? '-'}</p>
                </div>
                <div className={detailStyle}>
                    <h5 className={detailHeaderStyle}>{t('products')}</h5>
                    {products.filter(p => p).map(p => 
                        <div key={p.commodityClass + '/' + p.commodityReference} className="whitespace-nowrap flex flex-row items-center">
                            <ProtectionIcon isThirdParty={p.isThirdParty} /> {p.commodityClass}: {p.commodityReference}
                        </div>)}
                </div>
            </div>
        )
    }
}


const subCategories = {
    technical: [
        'feasability',
        'scalability',
        'applications',
        'life_cycle',
        'substitutions',
    ],
    commercial: [
        'market',
        'complementary',
        'business_model',
        'interactions',
    ],
    legal: [
        'protectability',
        'coverage',
        'protection_extent',
        'fto',
        'enforceability',
        'lifetime',
        //status: "Legal Status",
    ],
    financial: [
        'positive',
        'negative',
    ]
}

// TODO: use useScores/useFamilyScores
export function background(overall) {
    return overall < 1.0 || overall === '-'
        ? "bg-red-200"
        : overall < 2.0
        ? "bg-yellow-200/80"
        : overall < 3.0
        ? "bg-lime-200"
        : "bg-green-600/50"
}

function OverallScore({overall}) {
    const notValid = overall === '-' || isNaN(overall)
    const fixedScore = notValid ? '-' : overall.toFixed(1)
    return (
        <div title="Total Score =" className={`${scoreWidth} pt-1 ${background(fixedScore)} shrink-0 h-fit pb-1`}>
            {fixedScore}
        </div>
    )
}

function _calcScore(scores) {
    return (scores.length === 0) 
        ? 0.0
        : scores.indexOf('-') >= 0 || scores.indexOf('') >= 0
        ? '-'
        : Math.pow(scores.reduceRight((a, b) => a * b, 1), 1.0 / scores.length)
}

export function calcScore(scores) {
    return scores[0]?.type !== undefined
        // Only use top level scores to calculate overall score
        ? _calcScore(scores.filter(s => !s.type.includes(".")).map(s => s.score))
        : _calcScore(scores)
}

const asDbScore = (newScores, familyMemberId) => 
    _(newScores).toPairs()
        .filter(([type, score]) => _.property(type)(subCategories) && !isNaN(parseFloat(score)))
        .map(([type, score]) => ({score, type, familyMemberId}))
        .value()

const dateField = "grow-0 text-center w-24 text-sm whitespace-nowrap tabular-nums"
const scoreField = "grow-0 text-center md:w-16 w-8 mx-auto p-1 text-sm"

function ValuationRow({member, scores, items, meta}) {
    const {isEditUser} = useRoles()
    const {t} = useTranslation()

    const [showEditModal, setShowEditModal] = useState(false)
    const [showCopyModal, setShowCopyModal] = useState(false)

    const evaluationDate = meta?.evaluationDate ?? '-'

    const technical = scores.find(s => s.type === technical_overall)?.score ?? '-'
    const commercial = scores.find(s => s.type === commercial_overall)?.score ?? '-' 
    const legal = scores.find(s => s.type === legal_overall)?.score ?? '-'
    const financial = scores.find(s => s.type === financial_overall)?.score ?? '-'
    
    const overall = calcScore([technical, commercial, legal, financial])

    const onlyMember = items?.length === 1

    return (
        <div 
            className="border-t-2 border-pc-200 py-1.5 px-2 flex flex-row gap-2 w-full"
        >
            <div className="grow flex flex-col lg:flex-row min-w-0 overflow-hidden">
                <h4 className="min-w-0 overflow-hidden truncate text-ellipsis whitespace-nowrap grow text-slate-600">
                    <Link to={memberUrl(member)}>
                        {member.internalReference}
                    </Link>
                </h4>
                <div className="min-w-fit shrink-0 pt-px">
                    <div className="flex flex-row sm:gap-4 gap-2 w-full justify-end items-center">
                        <div className="grow" />
                        <div className={dateField}>{evaluationDate}</div>
                        <div className={scoreField}>{technical}</div>
                        <div className={scoreField}>{commercial}</div>
                        <div className={scoreField}>{legal}</div>
                        <div className={scoreField}>{financial}</div>
                        <OverallScore {...{ overall }} />
                        {isEditUser 
                            ? <div className={editWitdh}><button title={t("edit-evaluation")} onClick={() => setShowEditModal(true)} className="btn-secondary p-0.5 w-6 h-6"><IconEdit /></button></div>
                            : <div className={editWitdh}/>
                        }
                        {isEditUser && <div className={editWitdh}><button title={t("copy-evaluation")} onClick={() => !onlyMember && setShowCopyModal(true)} className={`${onlyMember ? "btn-disabled" : "btn-secondary"} p-0.5 w-6 h-6`}><IconCopy /></button></div>}
                    </div>
                </div>
                {showEditModal && <ValuationRowEdit {...{member, scores, setShowModal: setShowEditModal}} />}
                {showCopyModal && <ValuationCopy {...{...member, copyScores: {technical, commercial, legal, financial}, setShowModal: setShowCopyModal, items}} />}
            </div>
        </div>
    )
}

function ValuationCopy({setShowModal, copyScores: {technical, commercial, legal, financial}, familyMemberId, internalReference, items}) {

    const {scores, addScores, deleteScores, valuationMeta, addValuationMetas, deleteValuationMetas, loadAll} = useValuations()
    const {t} = useTranslation()

    const copyFromMeta = valuationMeta.find(m => m.familyMemberId === familyMemberId)

    const _overall = calcScore([technical ?? 0.0, commercial ?? 0.0, legal ?? 0.0, financial ?? 0.0])
    const overall = _overall === '-' ? '-' : _overall.toFixed(1)

    const others = _(items)
        .filter(i => i.internalReference !== internalReference)
        .sortBy(i => i.internalReference)
        .value()

    const initialValues = _(others).map(o => [o.internalReference, false]).fromPairs().value()
    const scoreField = "w-16 text-center"
    // TODO: discuss with Bas how to behave if scores are getting overwritten
    return (
        <Modal escAction={() => setShowModal(false)}>
            <Formik 
                initialValues={{...initialValues, technical: true, commercial: true, legal: true, financial: true}}
                onSubmit={(values) => {
                    const copyType = ['technical', 'commercial', 'legal', 'financial'].filter(t => values[t])
                    const toCopyScores = scores.filter(s => s.familyMemberId === familyMemberId && copyType.find(t => s.type.includes(t)))
                    const toCopyIds = others.filter(o => values[o.internalReference]).map(o => o.familyMemberId)
                    const toDelete = scores.filter(s => toCopyIds.indexOf(s.familyMemberId) >= 0)
                    const toAdd = toCopyIds.flatMap(id => toCopyScores.map(s => ({...s, familyMemberId: id})))

                    const toDelMeta = valuationMeta.filter(({familyMemberId}) => toCopyIds.indexOf(familyMemberId) >= 0)
                    const toCopyMeta = toCopyScores.length === 0 ? [] : toCopyIds.map(familyMemberId => ({...copyFromMeta, familyMemberId}))
                    //setShowModal(false)
                    deleteScores(toDelete)
                        .then(() => addScores(toAdd))
                        .then(() => deleteValuationMetas(toDelMeta))
                        .then(() => addValuationMetas(toCopyMeta))
                        .then(() => loadAll())
                        .finally(() => setShowModal(false))
                }}
            >{({values, setFieldValue}) => {
                const allSelected = _(initialValues).toPairs().reduce((acc, [key]) => acc && (values[key] ?? false), true)
                return <Form className="sm:max-w-[80vw]">
                    <div className="p-4">
                        <h4><Trans t={t} i18nKey="scores-of-ref" values={{internalReference}}/></h4>
                    </div>
                    <div className="flex flex-col px-4 pb-4 gap-1">
                        <label className="inline-flex items-center gap-2">
                            <Field type="checkbox" name="technical" className="form-checkbox" />
                            <div className="w-24">{t('technical')}</div>
                            <div className={scoreField}>{technical}</div>
                        </label>
                        <label className="inline-flex items-center gap-2">
                            <Field type="checkbox" name="commercial" className="form-checkbox" />
                            <div className="w-24">{t('commercial')}</div>
                            <div className={scoreField}>{commercial}</div>
                        </label>
                        <label className="inline-flex items-center gap-2">
                            <Field type="checkbox" name="legal" className="form-checkbox" />
                            <div className="w-24">{t('legal')}</div>
                            <div className={scoreField}>{legal}</div>
                        </label>
                        <label className="inline-flex items-center gap-2">
                            <Field type="checkbox" name="financial" className="form-checkbox" />
                            <div className="w-24">{t('financial')}</div>
                            <div className={scoreField}>{financial}</div>
                        </label>
                        <div className="inline-flex items-center gap-2 ml-6 border-t-2 border-slate-400 pt-1 w-fit">
                            <div className="w-24">{t('total')}</div>
                            <div className={scoreField + ' ' + background(overall)}>{overall}</div>
                        </div>
                    </div>
                    <h5 className="px-4 py-2">{t('copy-to')}</h5>
                    <div className="px-4">
                        <label className="inline-flex gap-3 items-center w-full border-b-2 border-pc-200">
                            <input 
                                type="checkbox" checked={allSelected} className="form-checkbox" 
                                onChange={(e) => _.forEach(initialValues, (v, key) => setFieldValue(key, e.target.checked))}/>
                            <div className="label py-1">{t('all')}</div>
                        </label>
                    </div>
                    <div className="columns-[14rem] px-4 pb-4 pt-1">
                        {others.map(item =>
                            <label key={item.internalReference} className="inline-flex items-center gap-3 w-full">
                                <Field name={item.internalReference} type="checkbox" className="form-checkbox" />
                                <div className="label py-1">{item.internalReference}</div>
                            </label>)}
                    </div>
                    <div className="p-4 bg-pc-200 flex flex-row-reverse gap-4">
                        <input type="submit" value={t('copy')} className="btn-primary" />
                        <div className="btn-secondary" onClick={() => setShowModal(false)}>{t('cancel')}</div>
                    </div>
                </Form>
            }}</Formik>
        </Modal>
    )
}

const saveShowWizzard = (show) => window.localStorage.setItem('valuations-showWizzard', show)
const loadShowWizzard = () => window.localStorage.getItem('valuations-showWizzard') === 'true' ?? false

function ValuationRowEdit({member, scores, setShowModal}: {member: Member, scores: Score[], setShowModal: (show: boolean) => void}) {
    const {t} = useTranslation()
    const family = useFilteredPatents().families.find(f => member.patentFamilyId === f.patentFamilyId)
    const {addScores, deleteScores, addValuationMetas, deleteValuationMetas, valuationMeta, loadAll} = useValuations()
    
    const [showWizzard, setShowWizzard] = useState(undefined)
    const familyMemberId = member.familyMemberId
    const meta = valuationMeta.find(m => m.familyMemberId === familyMemberId)

    const initialDetails = _(scores).filter(s => s.familyMemberId === familyMemberId && s.type.includes('.')).value()
    const [details, setDetails] = useState(initialDetails)

    const [showDetails, setShowDetails] = useState(loadShowWizzard())
    useEffect(() => saveShowWizzard(showDetails), [showDetails])

    const setDetail = ({type, score}) => {
        const index = _.findIndex(details, d => d.type === type)
        if (index < 0) setDetails([...details, {type, score, familyMemberId}])
        else setDetails([...details.slice(0, index), {type, score, familyMemberId}, ...details.slice(index +1)])
    }

    return (
        <Modal escAction={() => setShowModal(false)}>
            <div>
                <div className="p-4 pb-1 flex flex-col gap-2">
                    <h3 className="lg:max-w-4xl md:max-w-2xl sm:max-w-lg max-w-xs whitespace-nowrap truncate text-ellipsis">
                        <Trans i18nKey="edit-scores-for" values={{name: `${member.internalReference} (${member.countryCode}): ${family.familyName}`}} />
                    </h3>
                    <ValuationGroupDetails {...{ patentFamilyId: member.patentFamilyId, showDetails: true, familyMemberId }} />
                </div>
                <Formik
                    initialValues={{ 
                        ...{ technical: '', commercial: '', legal: '', financial: '', evaluationDate: meta?.evaluationDate ?? (new Date().toISOString().substring(0, 10)) }, 
                        ..._(scores).map(s => [s.type, s.score]).fromPairs().value() 
                    }}
                    onSubmit={(newScores) => {
                        //setShowModal(false)
                        return deleteScores(scores)
                            .then(() => addScores(asDbScore(newScores, familyMemberId)))
                            .then(() => {
                                const toDelete = _.difference(initialDetails, details)
                                const toAdd = _.difference(details, initialDetails)
                                return deleteScores(toDelete)
                                    .then(() => addScores(toAdd))
                            })
                            .then(() => (meta ? deleteValuationMetas([meta]) : Promise.resolve({})))
                            .then(() => addValuationMetas([{ familyMemberId, evaluationDate: newScores.evaluationDate }]))
                            .then(() => loadAll())
                            .finally(() => setShowModal(false))
                    }}
                    validate={(values) => {
                        const errors = {}
                        const isWithinLimits = (name) => {
                            if (values[name] !== undefined && (+values[name] < 0.0 || 4.0 < +values[name])) 
                                errors[name] = t('score-range-error', {name: t(name)})
                            else if (values[name] !== '' && isNaN(parseFloat(values[name])))
                                errors[name] = t('score-number-error')
                        }
                        isWithinLimits('technical')
                        isWithinLimits('commercial')
                        isWithinLimits('legal')
                        isWithinLimits('financial')
                        if (typeof values.evaluationDate !== 'string' || values.evaluationDate === '')
                            errors['evaluationDate'] = t('no-evaluation-date')
                        //console.log(errors)
                        //console.log(values)
                        return errors
                    }}
                >
                    <Form>
                        <div className="p-4 flex flex-col gap-2">
                            <div className="sm:flex sm:flex-row grid grid-cols-2 sm:gap-4 md:gap-8">
                                <ScoreField {...{ setShowWizzard, name: "technical" }} autoFocus />
                                <ScoreField {...{ setShowWizzard, name: "commercial" }} />
                                <ScoreField {...{ setShowWizzard, name: "legal" }} />
                                <ScoreField {...{ setShowWizzard, name: "financial" }} />
                                <TotalScore />

                                <label>
                                    <div className="label">{t('evaluation-date')}</div>
                                    <Field name="evaluationDate" className="form-input" as={DatePicker} />
                                </label>

                            </div>

                            <FieldError />

                        </div>

                        <div className="flex flex-row-reverse items-center gap-4 p-4 pl-4 bg-pc-200">
                            <input type="submit" value={t("save")} className="btn-primary" />
                            <div className="btn-secondary" onClick={() => setShowModal(false)}>{t('cancel')}</div>
                            <div className="grow" />
                            <div className="inline-flex gap-2 items-center text-slate-500">
                                <span className="text-sm sm:text-base">{t('detailed-evaluation')}</span>
                                <ToggleButton checked={showDetails} setChecked={setShowDetails} />
                                <ToolTip className="tooltip-center sm:tooltip-right">
                                    <p>{t('create-scores-as-means')}</p>
                                </ToolTip>
                            </div>
                        </div>

                        {showWizzard !== undefined && showDetails && <>
                            <WizzardContext.Provider value={{ details, setDetail }}>
                                <div className="bg-pc-200 px-4 pb-4">
                                    <div className="flex lg:flex-row flex-wrap gap-4 bg-white rounded-md p-2">
                                        <EvaluationWizzard {...{ detailFor: 'technical', familyMemberId }} />
                                        <EvaluationWizzard {...{ detailFor: 'commercial', familyMemberId }} />
                                        <EvaluationWizzard {...{ detailFor: 'legal', familyMemberId }} />
                                        <EvaluationWizzard {...{ detailFor: 'financial', familyMemberId }} />
                                    </div>
                                </div>
                            </WizzardContext.Provider>
                        </>}
                    </Form>
                </Formik>
            </div>
        </Modal>
    )
}

function ScoreField({name, autoFocus = false, setShowWizzard}) {
    const {errors, touched} = useFormikContext()
    const {t} = useTranslation()
    const invalid = errors[name] && touched[name]
    return (
        <div className="break-inside-avoid-column">
            <label className="label">{t(name)}</label>
            <Field 
                name={name} 
                autoFocus={autoFocus} 
                className={`select-all w-24 form-input h-8 py-1 text-center ${invalid && "border-red-600"}`} 
                onFocus={() => setShowWizzard(name)} />
        </div>
    )
}

function FieldError() {
    const {errors, touched} = useFormikContext()
    const err = _(touched).toPairs().filter(([name, t]) => t && errors[name]).map(([name]) => errors[name]).head()
    return err
        ?<div className="text-red-700">{err}</div>
        : null
}

type ScoreParts = {
    technical?: number,
    commercial?: number,
    legal?: number,
    financial?: number,
}

function TotalScore() {
    const {values} = useFormikContext<ScoreParts>()
    const {t} = useTranslation()
    const _overall = calcScore([values.technical ?? '-', values.commercial ?? '-', values.legal ?? '-', values.financial ?? '-']) as number
    const overall = _overall === undefined || isNaN(_overall) ? '-' : _overall.toFixed(1)
    //console.log({overall})
    return (
        <div className="">
            <div className="label w-24">{t('total')}</div>
            <div className={`w-24 py-1 ${background(parseFloat(overall))} text-center h-8`}>{overall}</div>
        </div>
    )
}

interface WizzardContextProps {
    details: Score[],
    setDetail: ({type, score}) => void,
}

let WizzardContext = React.createContext<WizzardContextProps>({
    details: [],
    setDetail: ({type, score}) => {},
})

const useWizzard = () => useContext(WizzardContext)

function EvaluationWizzard({detailFor, familyMemberId}: {detailFor: string, familyMemberId: number}) {
    const {t} = useTranslation()
    const {setFieldValue} = useFormikContext()
    const _labels = subCategories[detailFor]
    const {setDetail, details} = useWizzard()
    const detailedScores: Record<string, Score> = _(details)
        .filter(s => s.familyMemberId === familyMemberId && s.type.startsWith(`${detailFor}.`))
        .keyBy(s => s.type.split(".")[1] ?? '')
        .value()
    
    const currentMean = _(_labels).map(key => +(detailedScores[key]?.score ?? 0.0)).mean().toFixed(1)
    const useValue = () => setFieldValue(detailFor, currentMean)

    return (
        <div className="lg:pb-0 pb-4">
            <h4 className="pb-2">{t(detailFor)}</h4>
            <div className="btn-secondary text-sm mb-2 py-3 text-center" onClick={useValue}>
                <Trans i18nKey="copy-what-where" values={{what: currentMean, where: t(detailFor)}} components={{capitalize: <span className="capitalize"/>}}/>
            </div>
            {_(_labels).map(key =>
                <label key={key} className="break-inside-avoid-column block grow-0">
                    <div className="label py-1 text-sm">{t(`score-labels.${detailFor}.${key}.label`)}</div>
                    <select className="form-select text-slate-700 grow-0 w-52 text-sm py-1 pl-1 pr-8 text-ellipsis truncate" value={detailedScores[key]?.score ?? 0} onChange={e => {
                        setDetail({ type: `${detailFor}.${key}`, score: e.target.value })
                    }}>
                        {[0, 1, 2, 3, 4].map(ci => 
                            <option key={detailFor + ci} value={ci}>({ci}) {t(`score-labels.${detailFor}.${key}.categories.${ci}`)}</option>
                        )}
                    </select>
                </label>
            ).value()}
        </div>
    )
}
