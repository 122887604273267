import { Formik, Form, Field } from "formik"
import _ from 'lodash'

import { useAuth } from "../../user/Auth"
import { useBills } from "./BillsProvider"
import { countryCodes, display_names } from "../../data"
import { TeamManagementProvider, useTeamManagementContext } from "../../Management"

export default function Billing() {
    const {team} = useAuth()
    const { billingInformation, bills, hasLoaded } = useBills()
    return (
        <div className="">
            <div className="header-row">
                <h2>Billing</h2>
            </div>
            <div className="main-content flex flex-col md:flex-row gap-10 md:gap-20">
                {(hasLoaded && billingInformation?.companyName !== undefined) && 
                    <div className="flex flex-col gap-10">
                        <TeamManagementProvider team={team}><BillingPlan /></TeamManagementProvider>
                        {bills.length > 0 && <Bills />}
                    </div>
                }
                <BillingInformation />
            </div>
        </div>
    )
}

function BillingPlan() {
    const { users, hasLoaded: hasUsersLoaded } = useTeamManagementContext()
    const numUsers = users.length
    const { billingPlan, updatePlan, prices, hasLoaded } = useBills()
    const notSetYet = billingPlan?.frequency === undefined
    //console.log(notSetYet)
    //console.log(billingPlan)

    return (
        <div className="form-card">
            <h3 className="pb-2">Price Plan</h3>
            {hasUsersLoaded && hasLoaded &&
                <Formik
                    initialValues={{
                        frequency: billingPlan?.frequency ?? "1Y",
                        currency: billingPlan?.currency ?? "CHF",
                    }}
                    onSubmit={(plan, {resetForm}) => {
                        updatePlan(plan)
                        resetForm({values: plan})
                    }}
                >{({ values: { frequency, currency }, dirty }) => {
                    const price = prices.find(p => p.frequency === frequency && p.currency === currency)
                    const monthlyPrice = (price?.basicPrice ?? 1) + Math.max(numUsers - 2, 0) * (price?.addedPrice ?? 1)
                    const finalPrice = monthlyPrice * (frequency === "1Y" ? 12 : 1)
                    return (
                        <Form className="flex flex-col gap-4 min-w-xs">
                            <Field name="frequency" className="form-select" as="select">
                                <option value="1Y">Billed Yearly</option>
                                <option value="1M">Billed Monthly</option>
                            </Field>
                            <div className="flex flex-row gap-4 items-center">
                                <div>For <span className="text-2xl text-slate-600">{numUsers}</span> Users: </div>
                                <div className="grow text-2xl text-slate-700 w-16 text-right">{finalPrice}</div>
                                <Field name="currency" className="form-select" as="select">
                                    <option value="CHF">CHF</option>
                                    <option value="EUR">EUR</option>
                                </Field>
                            </div>
                            <div className="text-slate-400 text-right">
                                {monthlyPrice} {currency} per month
                            </div>
                            <input 
                                disabled={!notSetYet && !dirty} 
                                type="submit" 
                                value={notSetYet ? "Save" : "Update"}
                                className="btn-primary disabled:btn-disabled" 
                            />
                        </Form>
                    )
                }}</Formik>
            }
        </div>
    )
}

export function BillingInformationForm({billingInformation, updateBillingInformation}) {
    return (
        <Formik
            initialValues={billingInformation}
            onSubmit={(updated, { resetForm }) => {
                updateBillingInformation(updated)
                resetForm({ values: updated })
            }}
        >{({ dirty }) =>
            <Form className="flex flex-col min-w-xs">
                <label>
                    <div className="label">(Company) Name*</div>
                    <Field name="companyName" className="form-input w-full" />
                </label>
                <label>
                    <div className="label">Address Line 1*</div>
                    <Field name="address1" className="form-input w-full" required />
                </label>
                <label>
                    <div className="label">Address Line 2</div>
                    <Field name="address2" className="form-input w-full" />
                </label>
                <label>
                    <div className="label">ZIP*</div>
                    <Field name="zip" className="form-input w-full" required />
                </label>
                <label>
                    <div className="label">City*</div>
                    <Field name="city" className="form-input w-full" required />
                </label>
                <label>
                    <div className="label">Country*</div>
                    <Field name="countryCode" className="form-select" required as="select">
                        {countryCodes.map(value => <option key={value} value={value}>{display_names[value] ?? value}</option>)}
                    </Field>
                </label>
                <div className="flex flex-row-reverse items-end">
                    <input disabled={!dirty} type="submit" value="Update" className="disabled:btn-disabled btn-primary" />
                    <div className="grow text-slate-500">*Required</div>
                </div>
            </Form>
            }</Formik>
    )
}

function BillingInformation() {
    const { billingInformation, updateBillingInformation, hasLoaded } = useBills()

    return (
        <div className="form-card">
            <h3>Billing Information</h3>
            {hasLoaded && <BillingInformationForm {...{billingInformation, updateBillingInformation}} /> }
        </div>
    )
}

function BillStatus({ paid, due }) {
    const today = (new Date()).toISOString().split('T')[0]

    const overdue = !paid && due < today

    let text = 
        paid ? "Paid" 
        : overdue ? "Overdue" 
        : "Unpaid"
    let style = 
        paid ? "bg-lime-300/50 text-lime-700" 
        : overdue ? "bg-red-300/50 text-red-700"
        : "bg-amber-300/50 text-amber-700"

    return (
        <div 
            className={`p-2 font-semibold text-sm w-20 text-center rounded-full ${style}`}>
            {text}
        </div>
    )
}

export function Invoice({reference, start, end, due, paid, amount, currency}) {
    return (
        <div className="p-3 border border-pc-250 bg-pc-100/50 rounded-md flex flex-col gap-2 tabular-nums" >
            <div className="flex flex-row justify-between">
                <h5 className="text-xl text-slate-500">{reference}</h5>
                <BillStatus {...{ paid, due }} />
            </div>
            <div className="flex flex-row justify-between items-end">
                <table>
                    <tbody>
                        <tr>
                            <td className="pr-1 text-slate-500 text-right">From:</td>
                            <td>{start}</td>
                        </tr>
                        <tr>
                            <td className="pr-1 text-slate-500 text-right">To:</td>
                            <td>{end}</td>
                        </tr>
                        <tr>
                            <td className="pr-1 text-slate-500 text-right">Due:</td>
                            <td>{due}</td>
                        </tr>
                    </tbody>
                </table>
                <div className="pt-2">
                    <span className="text-3xl">{amount}</span> <span className="text-slate-500">{currency}</span>
                </div>
            </div>
        </div>
    )
}

function Bills() {
    const { bills } = useBills()
    return (
        <div className="form-card">
            <h3 className="pb-2">Invoices</h3>
            <div className="flex flex-col gap-2 min-w-xs">
                {_(bills)
                    .sortBy(b => b.start)
                    .reverse()
                    .map(bill => <Invoice key={bill.reference} {...bill} />)
                    .value()
                }
            </div>
        </div>
    )
}