import { CostItem, CostLink } from "./CostsProvider"
import { FxConverter } from "../forecast/fx";
import { Member } from "../patents/patents";
import { createExcelFile } from "../backend";
import _ from "lodash";

export type AccumulationConfig = {
    fxConverter: FxConverter;
    currency: string;
    inclVat: boolean;
}

export function accumulateCosts(costs: CostItem[], config: AccumulationConfig) {
    let total = 0.0
    const byYear: Record<number, number> = {}
    const costIds = {}

    const { fxConverter, currency, inclVat } = config

    costs.forEach(c => {
        const year = new Date(c.date).getFullYear()
        const amount = fxConverter.convert(inclVat ? (c.amount + c.vat) : c.amount, c.currency, currency)
        total += amount
        byYear[year] = (byYear[year] ?? 0.0) + amount
        costIds['total'] = [...(costIds['total'] ?? []), c.costId]
        costIds[year] = [...(costIds[year] ?? []), c.costId]
    })

    return { total, byYear, costIds }
}

export const importFields = [
    { name: 'date', required: true },
    { name: 'amount', required: true },
    { name: 'vat', required: false }, // default should be 0
    { name: 'currency', required: true },
    { name: 'invoiceNumber', required: true },
    { name: 'costCenter', required: false },
    { name: 'comment', required: false },
    { name: 'familyMemberReference', required: true },
]

// TODO: also have sheet with the grouped, filtered view
export function exportCosts(costs: CostItem[], linkByCostId: Record<number, CostLink>, memberById: Record<number, Member>, team: string) {
    const header = importFields.map(f => f.name)
    const excel = {
        sheets: [
            {
                name: 'Costs',
                header,
                rows: _(costs)
                    .map(cost => {
                        const familyMemberReference = memberById[linkByCostId[cost.costId]?.entityId]?.internalReference
                        return { ...cost, familyMemberReference }
                    })
                    .filter(cost => cost.familyMemberReference !== undefined)
                    .sortBy(cost => `${cost.familyMemberReference}-${cost.date}`)
                    .map(cost => header.map(f => cost[f]))
                    .value()
            }
        ]
    }
    createExcelFile(excel, `costs-${team}-${new Date().toISOString().slice(0, 10)}.xlsx`)
}