import { useQuery } from "@tanstack/react-query";
import { parseBackend } from "../../backend";

export interface RealmStatistics {
    patents: IpRightsStatistics;
    trademarks: IpRightsStatistics;
    products: EntityStatistics;
    inventions: EntityStatistics;
    users: UserStatistics;
    flags: string[];
    realm: {name: string, active: boolean, plan?: string}
}

export interface IpRightsStatistics {
    activeFamilies: number;
    inactiveFamilies: number;
    activeMembers: number;
    inactiveMembers: number;
}

export interface EntityStatistics {
    active: number;
    inactive: number;
}

export interface UserStatistics {
    view: number;
    edit: number;
    admin: number;
}

export function getRealmStatistics(onlyActive: boolean): Promise<RealmStatistics[]> {
    return parseBackend(fetch(`/api/statistics?onlyActive=${onlyActive}`))
}

export function useStatistics(onlyActive: boolean) {
    return useQuery<RealmStatistics[]>(['admin', 'statistics', onlyActive], () => getRealmStatistics(onlyActive)) // TODO
}