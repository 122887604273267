import { useState } from "react"
import { useLocation, useNavigate } from "react-router"
import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next"

import { useAuth } from "./Auth"

export default function Login() { 
    const [user, setUser] = useState("")
    const [password, setPassword] = useState("")

    const [showPassword, setShowPassword] = useState(false)

    const [error, setError] = useState(null)

    let navigate = useNavigate();
    let location = useLocation()

    let {signin} = useAuth()

    const { t } = useTranslation()

    function handleLogin(e) {
        e.preventDefault()
        setError(null)
        signin(user, password)
            .then(() => { 
                const from = location?.state?.from
                navigate("/teams", { state: {from}, replace: true }) 
            })
            .catch(err => { console.log(err); setError(err.message) })
    }

    const labelStyle = "text-gray-700 text-sm block"
    const labelDistStyle = "mb-1 " +labelStyle

    return (
        <div>
            <h2 className="pb-4 text-center">Login</h2>
            <form className="flex flex-col gap-4 text-xl" onSubmit={handleLogin}>
                <div className="flex flex-col">
                    <label htmlFor="user" className={labelDistStyle}>{t("user")}</label>
                    <input
                        className="px-1 border border-1 border-gray-200 font-light"
                        type="text"
                        autoFocus
                        id="user"
                        pattern="[\x00-\x7F]+"
                        value={user}
                        onChange={e => setUser(e.target.value)} />
                </div>

                <div className="flex flex-col">
                    <div className="flex flex-row justify-between items-end">
                        <label htmlFor="password" className={labelDistStyle}>{t("password")}</label>
                        <label className="text-sm text-gray-400 hover:text-gray-500 underline cursor-pointer mb-1">
                            <input type="checkbox" className="hidden" checked={showPassword} onChange={e => setShowPassword(e.target.checked)} />
                            {t("show-password")}
                        </label>
                    </div>
                    <input
                        className="px-1 border border-1 border-gray-200 font-light"
                        type={showPassword ? "text" : "password"}
                        id="password"
                        value={password}
                        onChange={e => setPassword(e.target.value)} />
                </div>

                <div className="h-0" />

                <input
                    disabled={password.length === 0 || user.length === 0}
                    className={(password.length !== 0 && user.length !== 0) ? "btn-primary" : "btn-disabled"}
                    type="submit"
                    value={t("login")}
                />
            </form>

            {(typeof (error) === 'string') &&
                <div className="border-l-4 border-red-700 p-2 py-4 mt-4 text-sm bg-red-50">
                    <div className="text-red-800 capitalize mb-1">
                        {error}
                    </div>
                    <div>
                        {t('please-contact')} <a className="underline" href="mailto:support@patent-cockpit.com">support@patent-cockpit.com</a>.
                    </div>
                </div>
            }
            <div className="pt-4 text-sm text-slate-500">
                <p>
                <Link className="underline" to="/signup">{t('no-login-sign-up')}</Link>.
                </p>
                <p>
                <Link className="underline" to="/login/team">{t('create-a-new-team')}</Link>.
                </p>
            </div>
        </div>
    )
}