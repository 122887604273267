import { useState } from "react"
import { Helmet } from "react-helmet-async"
import { Link, useSearchParams } from "react-router-dom"
import { Outlet, useNavigate, useParams } from "react-router"
import { Form, Formik, Field, ErrorMessage } from 'formik'
import { useTranslation } from "react-i18next"
import _ from 'lodash'

import { useInventions } from "./InventionsProvider"
import { DeleteButton } from "../components/edit-table"
import { DatePicker } from "../components/DatePicker"
import { IconEdit } from "../components/icons"
import { deadlineColor, inventionUrl } from "./utils"
import Modal from "../components/Modal"
import { useRoles } from "../user/Auth"

export function PaymentRow({payment, paymentUrl}) {
    const {t} = useTranslation()

    const {delPayment, updatePayment, inventorById, inventionById} = useInventions()
    const {isEditUser} = useRoles()

    const invention = inventionById[payment.inventionId]
    const inventor = inventorById[payment.agentId]

    function setPaid(paid) {
        updatePayment({...payment, paid})
    }

    const name = `${inventor.lastName}, ${inventor.firstName}`
    return (
        <div className="border-2 rounded-md border-pc-200 p-4 flex flex-col max-w-2xl">
            <div className="flex justify-between flex-row gap-2">
                <h3 className="whitespace-nowrap overflow-hidden text-ellipsis">
                    <span className="hidden sm:inline-flex">{t('payment-for')}</span> {name}
                </h3>
                {isEditUser && <div className="sm:self-auto self-end flex flex-row gap-2">
                    <Link title="Edit Payment" className="btn-primary p-1 w-6 h-6" to={paymentUrl(payment)}><IconEdit /></Link>
                    <DeleteButton title="Delete Payment" del={() => delPayment(payment.paymentId)} small/>
                </div>}
            </div>
            <div className="flex sm:flex-row flex-col gap-1 justify-between pt-2">
                <div>
                    {t('invention')}: <Link to={inventionUrl(invention)} className="text-pc-600 hover:underline">{invention.reference}</Link>
                </div>
                <div className="self-end sm:self-auto">
                    <span className={`inline-flex items-center gap-2 ${payment.paid || deadlineColor(payment.dueDate)}`}>
                        {payment.amount} {payment.currency} @ {payment.dueDate} 
                        <input 
                            className="form-checkbox"
                            type="checkbox" 
                            disabled={!isEditUser}
                            checked={payment.paid} 
                            onChange={(e) => setPaid(e.target.checked)} />
                    </span>
                </div>
            </div>
        </div>
    )
}

// TODO: more like in the data wizzard
export default function InventionPayments() {
    const {t} = useTranslation()
    const {isEditUser} = useRoles()
    const {payments, inventorById, inventionById} = useInventions()

    const [_filterInput, setFilterInput] = useState("")
    const filterInput = _filterInput.trim().toLocaleLowerCase()

    function contains(str) {
        return str.toLocaleLowerCase().includes(filterInput)
    }

    const filteredPaymentsAll = 
        (filterInput === "" || filterInput === undefined)
            ? payments
            : payments.filter(payment => {
                const invention = inventionById[payment.inventionId]
                const inventor = inventorById[payment.agentId]
                if (contains(inventor.lastName) || contains(inventor.firstName))
                    return true
                else if (contains(invention.reference) || contains(invention.title))
                    return true
                else if (contains(payment.dueDate) || contains(payment.dueDate))
                    return true
                else
                    return false
            })

    const [onlyUnpaid, setOnlyUnpaid] = useState(false)

    const filteredPayments = onlyUnpaid ? filteredPaymentsAll.filter(p => !p.paid) : filteredPaymentsAll

    const today = new Date()
    const todayStr = today.toISOString().split('T')[0]

    return (
        <>
            {/* @ts-ignore */}
            <Helmet>
                <title>{t('payments')} | Patent Cockpit</title>
            </Helmet>

            <div className="header-row">
                <div className="flex flex-row gap-2 max-w-2xl">
                    <h2 className="modern-h2">{t('payments')}</h2>
                    <div className="grow" />
                    {isEditUser && <Link className="btn-primary pt-1 pb-0 px-2 text-sm" to="add">{t('add-payment')}</Link>}
                </div>
            </div>
            <div className="main-content flex flex-col gap-2">
                <div className="flex sm:flex-row flex-wrap flex-col sm:justify-between gap-2 max-w-2xl px-1">
                    <input
                        className="border border-pc-200 pl-2"
                        value={_filterInput}
                        placeholder={t("filter")}
                        type="search"
                        onChange={(e) => setFilterInput(e.target.value)}
                    />
                    <label>
                        <input
                            className="form-checkbox inline-flex items-baseline"
                            checked={onlyUnpaid}
                            type="checkbox"
                            onChange={(e) => setOnlyUnpaid(e.target.checked)}
                        />
                        <span className="pl-1">{t('only-unpaid')}</span>
                    </label>
                    <div className="self-end">{t('today')}: {todayStr}</div>
                </div>
                {filteredPayments
                    .sort((a, b) => b.dueDate.localeCompare(a.dueDate))
                    .map(payment => <PaymentRow key={payment.paymentId} {...{payment, paymentUrl}} />)
                }
            </div>

            <Outlet />
        </>
    )
}

const labelStyle = "text-gray-800"

function AddEditPaymentMask({paymentId}) {
    const {t} = useTranslation()
    const { inventions, payments, updatePayment, addPayment, inventorsByInventionId} = useInventions()

    let navigate = useNavigate()
    let [searchParams] = useSearchParams()

    function getId(name) {
        const parsed = parseInt(searchParams.get(name))
        return isNaN(parsed) ? 0 : parsed
    }

    const sortedInventions = _.sortBy(inventions, i => i.reference)

    const existingPayment = payments.find(p => p.paymentId === paymentId)

    const submitFunction = existingPayment === undefined ? addPayment : updatePayment

    const inventionId = existingPayment?.inventionId ?? getId("inventionId")
    const sortedInventors = _(inventorsByInventionId[inventionId] ?? [])
        .map(i => ({ ...i, name: `${i.lastName}, ${i.firstName}` }))
        .sortBy(i => i.name)
        .value()

    const initialValues = {
        dueDate: existingPayment?.dueDate ?? "",
        paid: existingPayment?.paid ?? false,
        agentId: (existingPayment?.agentId ?? getId("agentId")),
        inventionId,
        amount: existingPayment?.amount ?? 0,
        currency: existingPayment?.currency ?? "EUR",
    }
    
    return (
        <Formik
            initialValues={initialValues}
            onSubmit={(payment, { resetForm }) => {
                submitFunction({...payment, agentId: +payment.agentId, inventionId: +payment.inventionId, paymentId})
                resetForm()
                navigate(-1)
            }}
            validate={({inventionId, agentId, dueDate}) => {
                const errors = {}

                if (inventionId <= 0)
                    errors['inventionId'] = t('select-invention')

                if (agentId <= 0)
                    errors['agentId'] = t('select-inventor')

                if (dueDate === "" || dueDate === undefined)
                    errors['dueDate'] = t('select-due-date')

                return errors
            }}
        >{({ dirty, errors, touched, values: {inventionId} }) => {
            const borderColor = (name) => (touched[name] && errors[name]) ? "border-red-600" : ""
            const inventionMissing = inventionId <= 0
            const noInventorsDefined = (!inventionMissing) && sortedInventors.length === 0
            return (
                <Form className="flex flex-col gap-2 sm:max-w-sm">
                    <label className={labelStyle} htmlFor="inventionId">{t('invention')}</label>
                    <Field invalid={errors.inventionId} className={`form-select ${borderColor("inventionId")}`} id="inventionId" name="inventionId" as="select">
                        <option value={0}>{t('select-invention')}</option>
                        {sortedInventions.map(i => <option key={i.inventionId} value={+i.inventionId}>{i.reference}: {i.title}</option>)}
                    </Field>
                    <RedErrorMessage name="inventionId" />

                    <label className={labelStyle} htmlFor="agentId">{t('inventor')}</label>
                    <Field invalid={errors.agentId} className={`form-select ${borderColor("agentId")}`} id="agentId" name="agentId" as="select">
                        <option value={0}>{t('select-inventor')}</option>
                        {inventionMissing && <option value={0}>{t('first-select-invention')}</option>}
                        {noInventorsDefined && <option value={0}>{t('first-define-inventors')}</option>}
                        {sortedInventors.map(i => <option key={i.agentId} value={+i.agentId}>{i.name}</option>)}
                    </Field>
                    <RedErrorMessage name="agentId" />

                    <label className={labelStyle} htmlFor="dueDate">{t('dueDate')}</label>
                    <Field className={`form-input h-10 ${borderColor("dueDate")}`} id="dueDate" name="dueDate" as={DatePicker} />
                    <RedErrorMessage name="dueDate" />

                    <label className={labelStyle} htmlFor="amount">{t('amount')}</label>
                    <div className="flex flex-row gap-1">
                        <Field className="form-input" id="amount" name="amount" type="number" />
                        <Field className="form-select" id="currency" name="currency" as="select">
                            <option value="EUR">EUR</option>
                            <option value="CHF">CHF</option>
                            <option value="USD">USD</option>
                            <option value="GBP">GBP</option>
                        </Field>
                    </div>


                    <label className={labelStyle} htmlFor="paid">
                        <Field className="form-checkbox mr-2" id="paid" name="paid" type="checkbox" />
                        {t('paid')}
                    </label>


                    <div className="flex flex-row-reverse gap-2">
                        <input
                            disabled={!dirty}
                            className={dirty ? "btn-primary" : "btn-disabled"}
                            type="submit"
                            value={existingPayment ? t("update") : t("add")}
                        />
                        <div onClick={() => navigate(-1)} className={"btn-secondary"}>{t('cancel')}</div>
                    </div>

                </Form>
            )
        }}</Formik>
    )
}

export function RedErrorMessage({name}) {
    return <ErrorMessage name={name}>{msg => <div className="text-red-600 pb-2">{msg}</div>}</ErrorMessage>
}

export function EditPaymentModal() {
    const {paymentId} = useParams()
    return (
        <Modal>
            <div className="p-4 w-screen sm:w-auto">
                <AddEditPaymentMask {...{paymentId: +paymentId}}/>
            </div>
        </Modal>
    )
}

function paymentUrl({paymentId}) {
    return `/inventions/payments/${encodeURIComponent(paymentId)}`
}