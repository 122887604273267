import { useState } from 'react'
import { Helmet } from "react-helmet-async"
import { createSearchParams, Link } from "react-router-dom"
import { Outlet, useNavigate, useParams } from "react-router"
import { Field, Form, Formik, useFormikContext } from "formik"
import Fuse from 'fuse.js'
import { useTranslation, Trans } from "react-i18next"
import _ from 'lodash'

import Image from "../components/Image"
import { Invention, useInventions } from "./InventionsProvider"
import { PaymentRow, RedErrorMessage } from "./InventionPayments"
import { IconCheckSmall, IconEdit, IconPlus, IconTrash, IconX } from "../components/icons"
import { DeleteButton } from "../components/edit-table"
import { DatePicker } from "../components/DatePicker"
import { memberUrl } from "../patents/utils"
import { plusPeriod } from "../utils/dates"
import { linkDifference, saveLinks, useBackend } from "../BackendProvider"
import { countryCodes, invention_country_link, invention_inventor_link } from "../data"
import Modal from "../components/Modal"
import { useRoles } from '../user/Auth'
import TagList, { TagListField } from '../components/TagList'
import { usePatents } from '../patents/PatentsProvider'
import { deadlineColor, inventionUrl } from './utils'
import { truncate } from '../utils/strings'
import { FocusedBrowser } from '../documents/FocusedBrowser'
import { BreadCrumbs } from '../components/BreadCrumbs'

export default function InventionView() {
    const {t} = useTranslation()
    let { reference } = useParams()
    let navigate = useNavigate()
    const {isEditUser, hasNewInnovations, hasDocuments} = useRoles()

    const {members, families, isLoading} = usePatents()
    const {inventionByReference, delInvention, paymentsByInventionId, inventorById, inventorsByInventionId} = useInventions()

    const invention = inventionByReference[reference]

    // Don't fail for the short moment when we have just updated the reference
    if (invention === undefined)
        return (
            <div className="main-content">
                {isLoading 
                    ? <div />
                    : <div>{t("page-not-found")} <Link className="btn-primary" to="/inventions/portfolio">{t('please-return-short')}</Link> </div>}
                <Outlet />
            </div>
        )

    const {title, referenceDate, claimOnInvention, familyMemberId, inventionId} = invention

    const inventionInventors = _(inventorsByInventionId[inventionId] ?? []).sortBy(i => `${inventorById[i.agentId].lastName} ${inventorById[i.agentId].firstName}`).value()

    const importantPayments = (paymentsByInventionId[inventionId] ?? []).sort((a, b) => b.dueDate.localeCompare(a.dueDate))

    const now = new Date()
    const nowStr = now.toISOString().split('T')[0]

    const receiptDeadline = referenceDate
    const objectionToDisclosureDeadline = plusPeriod(referenceDate, {months: 2})
    const objectionToFreeInventionDeadline = plusPeriod(referenceDate, {months: 3})
    const inventionClaimDeadline = plusPeriod(referenceDate, {months: 4})

    const confirmation_ms_reached = invention.receiptSent
    const disclosure_ms_reached = invention.objectionToDisclosure === "none"
    const free_invention_ms_reached = !invention.freeInvention || invention.objectionToFreeInvention === "none"
    const invention_claim_ms_reached = claimOnInvention !== "undecided"

    const free_invention_claim_accepted = invention.freeInvention && invention.objectionToFreeInvention === "none"

    const ready_for_application_ms_reached = (
        claimOnInvention === "claimed" && 
        (!free_invention_claim_accepted))

    const application = members.find(m => m.familyMemberId === familyMemberId)
    const applicationDate = application?.applicationDate

    const application_ms_reached = ready_for_application_ms_reached && applicationDate !== undefined

    const family = families.find(f => f.patentFamilyId === application?.patentFamilyId)
    const priorityDate = family?.priorityDate ?? applicationDate
    //const isPct = application?.pctRouteFiling === true
    //const foreignClaimsPeriod = isPct ? {months: 15} : {months: 9}  // Either 18M - 3M or 12M - 3M
    const foreignClaimsDeadline = plusPeriod(priorityDate ?? referenceDate, {months: 9})

    const foreign_claims_ms_reached = application_ms_reached && invention.foreignClaims !== "undecided"

    if (invention === undefined) return null

    return (
        <>
            {/* @ts-ignore */}
            <Helmet>
                <title>{t('invention')} {reference} | Patent Cockpit</title>
            </Helmet>

            <BreadCrumbs parts={[
                { to: "..", label: t('inventions') },
                { to: ".", label: <span>{reference}{title && <span className="hidden sm:inline ml-3 text-pcx-500">{truncate(title)}</span>}</span> },
            ]} />

            <div className="main-content h-fit">
                <div className="sm:min-w-sm max-w-3xl">
                    <div className='flex flex-col-reverse sm:grid sm:grid-cols-[10rem_1fr_auto] gap-4 pb-6'>
                        <div className="max-h-full min-h-[8rem] w-32 sm:w-40 shrink-0">
                            <Image {...{entity: "invention", isEditable: isEditUser, entityId: inventionId, title: `${reference}: ${title}`, text: invention?.summary}}/>
                        </div>
                        <div className="flex flex-col gap-2">
                            <div className='grow'>
                                {invention?.summary ?? null}
                            </div>
                            <a href="#documents" className="block mb-2 underline">{t('documents')}</a> {/* TODO: Scroll to Documents */}
                        </div>
                        <div className='flex flex-row gap-1 max-sm:items-center'>
                            {isEditUser && <>
                                <h3 className='grow sm:hidden'>{title}</h3>
                                <Link title="Edit Invention" to="edit" className="shrink-0 btn-primary p-1 w-6 h-6"><IconEdit /></Link>
                                <DeleteButton title="Delete Invention" {...{ small: true, del: () => { delInvention(inventionId); navigate("..") } }} />
                            </>}
                        </div>
                    </div>

                    <div className="flex flex-col gap-2">
                        <div className="flex sm:flex-row flex-col items-end justify-between text-gray-600">
                            <div>{t('received')}: {referenceDate}</div>
                            <div>{t('today')}: {nowStr}</div>
                        </div>

                        <CollapsableCard {...{
                            title: t("confirmation-of-receipt"),
                            isReached: confirmation_ms_reached,
                            deadline: receiptDeadline,
                        }}>
                            <ReceiptSent {...{ invention }} />
                        </CollapsableCard>

                        <CollapsableCard {...{
                            title: t("disclosures"),
                            isReached: disclosure_ms_reached,
                            deadline: objectionToDisclosureDeadline,
                        }}>
                            <ObjectionToDisclosure {...{ invention }} />
                        </CollapsableCard>

                        <CollapsableCard {...{
                            title: t("free-invention"),
                            isReached: free_invention_ms_reached,
                            deadline: objectionToFreeInventionDeadline,
                        }}>
                            <ObjectionToFreeInvention {...{ invention }} />
                        </CollapsableCard>

                        <CollapsableCard {...{
                            title: (t('claim-on-invention') + (
                                claimOnInvention === "claimed" && !free_invention_claim_accepted
                                    ? (": " + t('yes'))
                                    : claimOnInvention === "unclaimed"
                                    ? (": " + t('no'))
                                    : "")),
                            isReached: invention_claim_ms_reached,
                            deadline: inventionClaimDeadline,
                            disabled: free_invention_claim_accepted
                        }}>
                            <ClaimOnInvention {...{ invention }} />
                        </CollapsableCard>

                        <CollapsableCard {...{
                            title: <Trans i18nKey="application-reference" values={{reference: ready_for_application_ms_reached ? (application?.internalReference ?? "") : ""}} />,
                            isReached: application_ms_reached,
                            deadline: undefined,
                            disabled: !ready_for_application_ms_reached,
                        }}>
                            <ConnectToPatent {...{ invention, application }} />
                        </CollapsableCard>

                        {/* Foreign Claims */}
                        <CollapsableCard {...{
                            title: t("claim-on-foreign"),
                            isReached: foreign_claims_ms_reached,
                            deadline: foreignClaimsDeadline,
                            disabled: !application_ms_reached
                        }}>
                            <ForeignClaims {...{ invention }} />
                        </CollapsableCard>

                        {hasNewInnovations &&
                            <CollapsableCard {...{
                                title: "Claim on Foreign Patents (NEW)",
                                isReached: foreign_claims_ms_reached,
                                deadline: foreignClaimsDeadline,
                                disabled: !application_ms_reached
                            }}>
                                <ForeignClaimsNew {...{ invention, patentFamilyId: family?.patentFamilyId }} />
                            </CollapsableCard>}
                    </div>

                    <div className="flex flex-col gap-2 mt-6 pt-4 border-t-2 border-pc-250">
                        <div className="flex flex-row justify-between ">
                            <h4>{t('inventors')}</h4>
                            {isEditUser && <>
                                <Link title={t("edit-invention")} to="edit" className="shrink-0 btn-primary p-1 w-6 h-6"><IconEdit /></Link>
                            </>}
                        </div>
                        {inventionInventors.map(inv => {
                            const agentId = inv.agentId
                            const inventor = inventorById[agentId]
                            return (
                                <div key={agentId} className="flex flex-row whitespace-nowrap items-center">
                                    <div className="grow overflow-hidden text-ellipsis">{inventor.lastName}, {inventor.firstName}</div>
                                    {isEditUser &&
                                        <Link
                                            to={{
                                                pathname: "payments/add",
                                                search: createSearchParams({ agentId: agentId?.toString(), inventionId: inventionId?.toString() }).toString()
                                            }}
                                            className="btn-secondary px-2 py-0.5"
                                        >
                                            {t('add-payment')}
                                        </Link>}
                                </div>
                            )
                        })}
                    </div>

                    <div className="flex flex-col gap-2 pt-6">
                        <div className="flex flex-row gap-2 justify-between pr-1 pt-6 border-t-2 border-pc-250">
                            <h4>{t('payments')}</h4>
                            {isEditUser &&
                                <Link
                                    to={{
                                        pathname: "payments/add",
                                        search: createSearchParams({ inventionId: inventionId?.toString() }).toString()
                                    }}
                                    className="btn-secondary"
                                >
                                    {t('add-payment')}
                                </Link>}
                        </div>
                        {importantPayments.map(payment =>
                            <PaymentRow key={payment.paymentId} {...{ payment, paymentUrl: ({ paymentId }) => `payments/${paymentId}/edit` }} />)}
                    </div>

                    {hasDocuments && <div className='mt-4 border-pc-250 border-t-2 pt-2' id="documents">
                        <FocusedBrowser {...{entity: "invention", internalReference: reference}} />
                    </div>}

                    <Outlet />
                </div>
            </div>
        </>
    )
}


const labelStyle = "text-gray-800"


export function AddEditInventionModal() {
    const {t} = useTranslation()
    let navigate = useNavigate()
    let { reference } = useParams()

    const {
        inventions, 
        updateInvention,
        addInvention, 
        inventors, 
        inventionInventors: _invetionInventors, 
        addInventionInventors, 
        delInventionInventors
    } = useInventions()
    const { linkOperation } = useBackend()

    const invention = inventions.find(i => i.reference === reference)

    const isAdding = invention === undefined

    const initialValue = !isAdding ? invention : {
        reference: "",
        title: "",
        summary: "",
        referenceDate: "",
        freeInvention: false,
    } as Invention

    const inventionInventors = _invetionInventors
        .filter(i => i.inventionId === initialValue.inventionId)
        .map(ii => `${ii.agentId}`) // TagList only understands Strings

    const inventorsById = _.keyBy(inventors, i => i.agentId)
    const investorNamesById = _.mapValues(inventorsById, i => `${i.lastName}, ${i.firstName}`)

    const otherFields = {
        receiptSent: false, 
        objectionToDisclosure: "undecided",
        objectionToFreeInvention: "undecided",
        claimOnInvention: "undecided",
        familyMemberId: undefined,
        foreignClaims: "undecided",
    }

    const otherReferences = new Set(inventions.map(i => i.reference).filter(r => r !== reference))

    const asInventionInventor = (agentId, inventionId) => ({agentId, inventionId})

    async function saveInventionInventors(newInventionsInventors, inventionId) {
        const [toAdd, toDel] = linkDifference(inventionInventors, newInventionsInventors)
        return Promise.all([
            ...toDel.map(id => delInventionInventors(asInventionInventor(id, inventionId))),
            ...toAdd.map(id => addInventionInventors(asInventionInventor(id, inventionId)))]
        ).then(() => linkOperation(invention_inventor_link, "get"))
    }

    async function addSubmit(invention) { 
        return addInvention({...invention, ...otherFields, inventionInventors: undefined})
            .then(({inventionId}) => saveInventionInventors(invention.inventionInventors, inventionId))
            .then(() => navigate(inventionUrl(invention)))
    }
    function editSubmit(invention) { 
        saveInventionInventors(invention.inventionInventors, invention.inventionId)
        updateInvention({...invention, inventionInventors: undefined})
        //console.log(invention)
        navigate(inventionUrl(invention)) 
    }

    const onSubmit = isAdding ? addSubmit : editSubmit

    let addButton = <AddInventorButton />

    let title = isAdding ? t('add-invention') : t("edit-invention")

    return (
        <Modal>
            {/* @ts-ignore */}
            <Helmet>
                <title>{title} | Patent Cockpit</title>
            </Helmet>

            <div className="sm:min-w-sm max-w-lg">
                <Formik
                    initialValues={{...initialValue, inventionInventors}}
                    onSubmit={onSubmit}
                    validate={({reference, title, referenceDate}) => {
                        const errors = {}
                        if (reference === undefined || reference === "")
                            errors['reference'] = t('reference-required')
                        else if (otherReferences.has(reference))
                            errors['reference'] = t('reference-used')
                        else if (title === undefined || title === "")
                            errors['title'] = t('title-required')
                        else if (referenceDate === undefined || referenceDate === "")
                            errors['referenceDate'] = t('reference-date-required')
                            
                        return errors
                    }}
                >{({ errors, touched }) => {
                    const borderColor = (name) => (touched[name] && errors[name]) ? "border-red-600" : ""
                    return <>
                        <Form>
                            <div className='flex flex-col gap-2 p-4'>
                            <h4 className="pb-2">{title}</h4>

                            <label className={labelStyle} htmlFor="reference">{t('reference')}</label>
                            <Field className={`form-input ${borderColor("reference")}`} id="reference" name="reference" />
                            <RedErrorMessage name="reference" />

                            <label className={labelStyle} htmlFor="title">{t('title')}</label>
                            <Field className={`form-input ${borderColor("title")}`} id="title" name="title" />
                            <RedErrorMessage name="title" />

                            <label className={labelStyle} htmlFor="referenceDate">{t('reference-date')}</label>
                            <Field className={`form-input h-10 ${borderColor("referenceDate")}`} id="referenceDate" name="referenceDate" as={DatePicker} />
                            <RedErrorMessage name="referenceDate" />

                            <label className={labelStyle} htmlFor="freeInvention">
                                <Field className="form-checkbox mr-2" id="freeInvention" name="freeInvention" type="checkbox" />
                                {t('claimed-by-inventors-as-free')}
                            </label>

                            <label className={labelStyle} htmlFor="inventionInventors">{t('inventors')}</label>
                            <TagListField {...{
                                name: "inventionInventors",
                                tagDisplays: investorNamesById,
                                placeholder: t("add"),
                                availableTags: _.sortBy(Object.keys(inventorsById), id => investorNamesById[id]),
                                addButton,
                            }} />

                            <label className={labelStyle} htmlFor="summary">{t('summary')}</label>
                            <Field className="form-textarea h-24" id="summary" name="summary" as="textarea" />
                            </div>

                            <div className="flex flex-row-reverse gap-4 p-4 bg-pcx-200">
                                <input
                                    type="submit"
                                    className="btn-primary"
                                    value={isAdding ? t("add") : t("update")}
                                />
                                <Link to=".." className="btn-secondary">{t('cancel')}</Link>
                            </div>
                        </Form>
                        {/* Outlet for AddInventorButton; has to be within the Formik Context */}
                        <Outlet /> 
                    </>
                }}</Formik>
            </div>
        </Modal>
    )
}

function AddInventorButton() {
    return <>
        <Link to="add-inventor" className="btn-secondary w-6 h-6 p-px"><IconPlus /></Link>
    </>
}

export function AddInventorModal() {
    // Use Formik hooks to add agent
    let navigate = useNavigate()
    const {t} = useTranslation()
    const {entityOperation} = useBackend()
    const {setValues, values} = useFormikContext<{inventionInventors: number[]}>()

    function postAction(agentId) {
        return Promise.resolve(setValues({...values, inventionInventors: [...values.inventionInventors, agentId]}))
    }

    const {inventors} = useInventions()
    const asLabel = (i) => `${i.lastName}; ${i.firstName}`

    const inventorsList = new Fuse(
        inventors.map(asLabel), 
        { threshold: 0.1, })

    return (
        <Modal>
            <div className="">
                <Formik
                    initialValues={{firstName: "", lastName: ""}}
                    onSubmit={({firstName, lastName}) =>
                        entityOperation("agent", "add", {firstName, lastName, agentType: "person"})
                            .then(({agentId}: {agentId: number}) => postAction(agentId))
                            .then(() => navigate(".."))
                        // Add Inventor
                        // Link it up (Post-Action)
                        // Navigate Back up
                    }
                >{
                    ({dirty, values: {firstName, lastName}}) => {
                        const possibleOverlaps = firstName !== "" && lastName !== ""
                            ? inventorsList.search(asLabel({firstName, lastName})).map(o => o.item)
                            : []
                        return (
                            <Form>
                                <div className="flex flex-col gap-2 p-4">
                                    <h5>{t('add-inventor')}</h5>
                                    <label htmlFor="firstName">{t('firstName')}</label>
                                    <Field id="firstName" name="firstName" className="form-input" />

                                    <label htmlFor="lastName">{t('lastName')}</label>
                                    <Field id="lastName" name="lastName" className="form-input" />
                                </div>

                                {possibleOverlaps.length > 0 && 
                                    <div className="px-4 pb-4">
                                        <h6 className="font-medium">{t('possible-overlaps')}</h6>
                                        <ul>
                                            {possibleOverlaps.map(o => <li>{o}</li>)}
                                        </ul>
                                    </div>}

                                <div className="flex flex-row-reverse gap-2 p-4 bg-pc-200">
                                    <input 
                                        type="submit" 
                                        disabled={!dirty} 
                                        className={dirty ? "btn-primary" : "btn-disabled"} 
                                        value={t("add-inventor")} />
                                    <Link to=".." className="btn-secondary">{t('cancel')}</Link>
                                </div>
                            </Form>
                        )
                    }
                }</Formik>
            </div>
        </Modal>
    )
}


function StateIndicator({isReached, deadline}) {
    const {t} = useTranslation()
    return (!isReached && deadline) 
        ?  <span className={`inline-flex gap-2 ${deadlineColor(deadline)}`}>{t('due')}: {deadline}</span>
        : null
}


const collapseHeader = "collapse-title pl-3 flex sm:flex-row flex-col-reverse justify-between items-center"
const stateIndicatorStyle = "float-right self-end"

function CollapsableCard({title, isReached, deadline, children, disabled = false}) {
    return (
        <div className={`collapse collapse-arrow ${disabled && "collapse-close bg-slate-100"} rounded border-2 border-pc-200`}>
            <input type="checkbox" />
            <div className={collapseHeader}>
                <div className="grow inline-flex gap-2 items-center self-start">
                    {isReached
                        ? <span className="text-gray-600"><IconCheckSmall /></span>
                        : <span className="text-gray-600"><IconX /></span>}
                    <h4 className={disabled ? "text-slate-500" : ""}>{title}</h4>
                </div>
                {!disabled && 
                    <div className={stateIndicatorStyle}>
                        <StateIndicator {...{isReached, deadline}} />
                    </div>}
            </div>
            <div className="collapse-content">
                {children}
            </div>
        </div>
    )
}


//receiptSent
function ReceiptSent({invention}) {
    const {t} = useTranslation()
    const { updateInvention } = useInventions()
    const {isEditUser} = useRoles()

    function setNextState(state) {
        updateInvention({...invention, receiptSent: state})
    }

    const { receiptSent } = invention

    return (
        <div className="flex flex-row justify-end gap-2">{
            receiptSent 
                ? <button disabled={!isEditUser} className="btn-secondary disabled:btn-disabled" onClick={() => setNextState(false)}>{t('reverse')}</button>
                : <button disabled={!isEditUser} className="btn-primary disabled:btn-disabled" onClick={() => setNextState(true)}>{t('confirmation-sent')}</button>
        }</div>
    )
}

// objectionToDisclosure is [none, objected, undecided]
function ObjectionToDisclosure({invention}) {
    const {t} = useTranslation()
    const { updateInvention } = useInventions()
    const {isEditUser} = useRoles()

    function setNextState(state) {
        updateInvention({...invention, objectionToDisclosure: state})
    }

    return (
        <div className="flex flex-row justify-end gap-2">{
            invention.objectionToDisclosure === "undecided"
                ? <>
                    <button disabled={!isEditUser} className="btn-primary disabled:btn-disabled" onClick={() => setNextState("none")}>{t('accept')}</button>
                    <button disabled={!isEditUser} className="btn-warn disabled:btn-disabled" onClick={() => setNextState("objected")}>{t('object')}</button>
                </>
                : invention.objectionToDisclosure === "none"
                ? <button disabled={!isEditUser} className="btn-secondary disabled:btn-disabled" onClick={() => setNextState("undecided")}>{t('reverse')}</button>
                : invention.objectionToDisclosure === "objected"
                ? <button disabled={!isEditUser} className="btn-primary disabled:btn-disabled" onClick={() => setNextState("undecided")}>{t('feedback-received')}</button>
                : <div>Unknown Objection State '{invention.objectionToDisclosure}'. <a className="underline" href="mailto:support@patent-cockpit.com">Contact Support</a></div>
        }</div>
    )
}

// objectionToFreeInvention is [none, objected, undecided]
function ObjectionToFreeInvention({invention}) {
    const {t} = useTranslation()
    const { updateInvention } = useInventions()
    const {isEditUser} = useRoles()

    function setNextState(state) {
        updateInvention({...invention, objectionToFreeInvention: state})
    }

    // TODO: add freeInvention to add/edit (and unify it)
    const { freeInvention, objectionToFreeInvention } = invention

    return (
        <div className="flex flex-col gap-4">
            <label className="inline-flex items-center gap-1">
                <input
                    className="form-checkbox"
                    type="checkbox"
                    disabled={!isEditUser}
                    checked={freeInvention}
                    onChange={(e) => updateInvention({ ...invention, freeInvention: e.target.checked })} />
                {t("claimed-by-inventors-as-free")}
            </label>
            {freeInvention &&
                <div className="flex flex-row justify-end gap-2">{
                    objectionToFreeInvention === "undecided"
                        ? <>
                            <button disabled={!isEditUser} className="btn-primary disabled:btn-disabled" onClick={() => setNextState("none")}>{t('accept')}</button>
                            <button disabled={!isEditUser} className="btn-warn disabled:btn-disabled" onClick={() => setNextState("objected")}>{t('object')}</button>
                        </>
                        : objectionToFreeInvention === "none"
                        ? <button disabled={!isEditUser} className="btn-secondary disabled:btn-disabled" onClick={() => setNextState("undecided")}>{t('reverse')}</button>
                        : objectionToFreeInvention === "objected"
                        ? <button disabled={!isEditUser} className="btn-primary disabled:btn-disabled" onClick={() => setNextState("undecided")}>{t('feedback-received')}</button>
                        : <div>Unknown Objection State '{objectionToFreeInvention}'. <a className="underline" href="mailto:support@patent-cockpit.com">Contact Support</a></div>
                }</div>}
        </div>
    )
}

// claimOnInvention is ["undecided", "unclaimed", "claimed"]
function ClaimOnInvention({invention}) {
    const {t} = useTranslation()
    const { updateInvention } = useInventions()
    const {isEditUser} = useRoles()

    function setNextState(state) {
        updateInvention({...invention, claimOnInvention: state})
    }

    const { claimOnInvention } = invention

    return (
        <div className="flex flex-row justify-end gap-2">{
            claimOnInvention === "undecided"
                ? <>
                    <button disabled={!isEditUser} className="btn-secondary disabled:btn-disabled" onClick={() => setNextState("unclaimed")}>{t('dont-claim')}</button>
                    <button disabled={!isEditUser} className="btn-primary disabled:btn-disabled" onClick={() => setNextState("claimed")}>{t('claim')}</button>
                </>
                : claimOnInvention === "unclaimed" || claimOnInvention === "claimed"
                ? <button disabled={!isEditUser} className="btn-secondary disabled:btn-disabled" onClick={() => setNextState("undecided")}>{t('reverse')}</button>
                : <div>Unknown Claim State '{claimOnInvention}'. <a className="underline" href="mailto:support@patent-cockpit.com">Contact Support</a></div>
        }</div>
    )
}

// TODO: "connect to application" step
function ConnectToPatent({invention, application}) {
    const {t} = useTranslation()
    const {updateInvention} = useInventions()
    const {isEditUser} = useRoles()
    const {members, families} = usePatents()

    const {familyMemberId} = invention

    // TODO: move this up to do search only once?
    const applicationDate = application?.applicationDate

    const idByReference = Object.fromEntries(members.map(m => [m.internalReference, m.familyMemberId]))
    const patentReference = members.find(m => m.familyMemberId === familyMemberId)?.internalReference ?? ""

    return (
        <div className="flex flex-col items-end sm:flex-row sm:justify-end gap-2">
            {
                (application && applicationDate)
                    ? <>
                        <div className="grow w-full sm:w-auto">
                            <Link className="underline" to={memberUrl(application)}><Trans i18nKey="check-application" values={{reference: application.internalReference}} /></Link>
                            <br/>
                            {t('priorityDate')}: {families.find(f => f.patentFamilyId === application.patentFamilyId)?.priorityDate ?? "-"}
                        </div>
                        <button
                            className="btn-warn h-fit disabled:btn-disabled"
                            disabled={!isEditUser}
                            onClick={() => updateInvention({ ...invention, familyMemberId: undefined })}
                        >
                            <Trans i18nKey="unlink-from" values={{reference: application.internalReference}} />
                        </button>
                    </>
                : (application) ? (
                        <Link className="btn-primary" to={memberUrl(application)}>{t('set-application-date')}</Link>
                    )
                : (
                    <Formik 
                        initialValues={{patentReference}}
                        onSubmit={({patentReference}, {resetForm}) => {
                            const familyMemberId = idByReference[patentReference]
                            //console.log(patentReference, familyMemberId)
                            updateInvention({...invention, familyMemberId})
                            resetForm()
                        }}
                    >{({dirty}) =>
                        <Form className="w-full flex sm:flex-row flex-col justify-end sm:items-center items-start gap-2">
                            <label htmlFor="patentReference">{t('application-filed-under')}</label>
                            <Field className="form-input" name="patentReference" list="patentReferences" />
                            <datalist id="patentReferences">{
                                Object.keys(idByReference)
                                    .sort()
                                    .map(r => <option key={r} value={r}/>)
                            }</datalist>

                            <input 
                                disabled={!dirty || !isEditUser}
                                className={"btn-primary disabled:btn-disabled"} 
                                type="submit" 
                                value={t("link")}
                            />
                        </Form>
                    }</Formik>
                )
            }
        </div>
    )
}

const clearedState = 'cleared'
const stoppedState = 'stopped'
const toBeFiledState = 'to-be-filed'
const filedState = 'filed'

// TODO Into data.js
const state_display = {
    [toBeFiledState]: 'To Be Filed',
    [filedState]: 'Filed',
    [stoppedState]: 'Stopped',
    [clearedState]: 'Redeemed',
}

const reminder_display = {
    "prio": "Before end of Priority Year",
    "pct": "Before end of PCT International Phase",
    "granted": "When EP granted",
    "custom": "Custom Date",
}

function onlyDate(s) {
    if (isNaN(Date.parse(s)))
        return undefined
    else return s
}

function calcualteDueDate({reminder, familyMemberId, members}) {
    const customDate = onlyDate(reminder) && reminder
    if (customDate)
        return customDate
    else {
        const [isRightMember, calc] = reminder === "prio" 
            ? [m => m.familyMemberId === +familyMemberId, m => plusPeriod(m.applicationDate, {months: 9})]
            : reminder === "pct"
            ? [m => m.familyMemberId === +familyMemberId && m.countryCode === "WO", m => plusPeriod(m.applicationDate, {months: 15})]
            : reminder === "granted"
            ? [m => m.familyMemberId === familyMemberId && m.countryCode === "EP", m => m.patentDate ?? (new Date().toISOString().slice(0, 10))]
            : [() => false, () => {}]
        const member = members.find(isRightMember)
        //console.log({reminder, member, familyMemberId, delay})
        if (member?.applicationDate === undefined)
            return undefined
        else {
            return calc(member)
        }
    }
}

const legalStates = [toBeFiledState, filedState, stoppedState, clearedState]
function legalState(state) {
    if (legalStates.indexOf(state) >= 0)
        return state
    else
        return undefined
}

// TODO: Translate
function EditClaimedCountry({inventionId, countryCode, countryCodes = [], setIsEditing, state, reminder, familyMemberId, patentFamilyId}) {
    const {members} = usePatents()
    const {linkOperation} = useBackend()
    const {inventionCountries} = useBackend()
    const familyMembers = _(members).filter(m => m.patentFamilyId === patentFamilyId).sortBy(m => m.internalReference.toLowerCase()).value()
    const isAdding = countryCode === undefined

    const initialValues = {
        inventionId,
        countryCode: countryCode ?? "EP",
        state: legalState(state) ?? toBeFiledState,
        reminder: (onlyDate(reminder) ? "custom" : reminder) ?? "prio",
        familyMemberId: familyMemberId ?? familyMembers[0]?.familyMemberId,
        date: onlyDate(reminder) ?? '',
    }
    //console.log(reminder)
    //console.log(initialValues)

    function update(values) {
        const ic_link = values.reminder === 'custom' ? { ...values, reminder: values.date } : values
        // We are looking for the old values to make sure they are deleted from the local copy
        return Promise.resolve(inventionCountries.find(ic => ic.inventionId === inventionId && ic.countryCode === countryCode))
            .then(oldValues => linkOperation(invention_country_link, "delete", oldValues))
            .then(() => linkOperation(invention_country_link, "add", ic_link))
            .then(() => linkOperation(invention_country_link, "get"))
    }

    return (
        <Modal escAction={() => setIsEditing(false)}>
            <Formik 
                initialValues={initialValues}
                onSubmit={(_values) => {
                    const values = {..._values, familyMemberId: +_values.familyMemberId}
                    let apiCall = isAdding 
                        ? linkOperation(invention_country_link, "add", values)
                        : update(values)
                    apiCall.then(() => setIsEditing(false))
                }}
                validate={values => {
                    const errors = {}
                    const dueDate = calcualteDueDate({...values, members})
                    if ((values.reminder !== "custom" && values.reminder !== "granted") && dueDate === undefined) {
                        const member = members.find(m => m.familyMemberId === values.familyMemberId)
                        if (member?.internalReference === undefined)
                            errors['reminder'] = `Cannot calculate due date.\nThe reference is not set properly.`
                        if (member?.applicationDate === undefined)
                            errors['reminder'] = `Cannot calculate due date.\n${member?.internalReference ?? "<Reference not set>"} has no application date.`
                        if (values.reminder === "pct") {
                            errors['reminder'] = `Cannot calculate due date.\nIs ${member?.internalReference ?? "<Reference not set>"} really a PCT application (country is 'WO')?`
                        } else {
                            errors['reminder'] = `Cannot calculate due date.\nPlease contact support.`
                        }
                    }
                    if (values.reminder === "custom" && onlyDate(values.date) === undefined)
                        errors['reminder'] = `Explicit due date must be set.`

                    if (values.reminder === "granted" && values.countryCode !== "EP")
                        errors['reminder'] = `Due date based on granted EP requires EP as country.`

                    if (values.familyMemberId === undefined || values.familyMemberId === "")
                        errors['familyMemberId'] = `Reference must be set.`

                    return errors
                }}
            >{({values, errors, touched, isSubmitting}) =>  {

                //console.log(values)
                const isCustom = values.reminder === "custom"
                const dueDate = calcualteDueDate({...initialValues, ...values, members})

                return <Form>
                    <div className="p-4 flex flex-col gap-1">
                        {isAdding
                            ? <h5>Add Claimed Country</h5>
                            : <h5>Edit Claim State of {countryCode}</h5>
                        }
                        {isAdding && <label className="w-fit"><div className="label">State</div>
                            <Field name="countryCode" className="form-select py-1 pl-2" as="select">
                                {countryCodes.map(c => <option key={c} value={c}>{c}</option>)}
                            </Field></label>}

                        <div className="flex flex-col sm:flex-row gap-4">

                            <label className="w-fit"><div className="label">State</div>
                                <Field name="state" className="form-select py-1 pl-2" as="select">
                                    {legalStates.map(s =>
                                        <option key={s} value={s}>{state_display[s] ?? s}</option>
                                    )}
                                </Field></label>

                            <label className="w-fit"><div className="label">Reference</div>
                                <Field name="familyMemberId" className="form-select py-1 pl-2" as="select">
                                    {familyMembers.map(m =>
                                        <option key={m.familyMemberId} value={m.familyMemberId}>{m.internalReference}</option>
                                    )}
                                </Field></label>
                        </div>


                        <label className="w-fit"><div className="label">Due Date based on</div>
                            <Field name="reminder" className="form-select py-1 pl-2" as="select">
                                {["prio", "pct", "granted", "custom"].filter(s => s !== "granted" || values.countryCode === "EP").map(s =>
                                    <option  key={s}value={s}>{reminder_display[s] ?? s}</option>
                                )}
                            </Field></label>

                        <label className="w-fit"><div className="label">Due Date</div>
                            <Field name="date" className={`form-input py-1 pl-2 ${isCustom ? "block" : "hidden"}`} as={DatePicker} />
                            {!isCustom && (dueDate ? dueDate : "-") }
                        </label>

                        {_(touched).toPairs().filter(([key]) => errors[key] !== undefined).map(([key]) => {
                            const error = errors[key]
                            return <div className="px-2 p-1 mt-4 text-red-700 border border-red-700 max-w-sm whitespace-pre-line" key={key}>{error}</div>
                        }).value()}

                    </div>
                    <div className="bg-pc-200 p-4 flex flex-row-reverse gap-4">
                        <input type="submit" disabled={isSubmitting} value="Save" className="btn-primary disabled:btn-disabled" />
                        <div className="btn-secondary" onClick={() => setIsEditing(false)}>Cancel</div>
                    </div>
                </Form>
            }}</Formik>
        </Modal>
    )
}

function ForeignClaimsNew({invention, patentFamilyId}) {
    const { isEditUser } = useRoles()

    const { updateInvention, inventionCountries } = useInventions()

    const { foreignClaims, inventionId } = invention

    const foreignCountries = _(inventionCountries)
        .filter(i => i.inventionId === inventionId && i.state !== stoppedState)
        .sortBy(i => i.countryCode)
        .value()

    //console.log(foreignCountries)
    function setNextState(state) {
        updateInvention({...invention, foreignClaims: state})
    }

    const [isEditing, setIsEditing] = useState(false)

    return (
        <div>
            <label className="text-slate-700 inline-flex gap-2 items-center">
                <input 
                    type="checkbox" className="form-checkbox" 
                    disabled={!isEditUser} 
                    checked={foreignClaims === "decided"} 
                    onChange={e => setNextState(e.target.checked ? "decided" : "undecided")} 
                />
                Final Decision has been reached.
            </label>
            {isEditUser &&
                <div className="flex flex-row-reverse">
                    <button className="btn-secondary text-sm" onClick={() => setIsEditing(true)}>Add Country</button>
                </div>}
            {isEditing && 
                // @ts-ignore TODO: missing props
                <EditClaimedCountry {...{
                    inventionId,
                    setIsEditing, 
                    countryCodes: countryCodes.filter(cc => cc !== "WO" && foreignCountries.find(fc => fc.countryCode === cc) === undefined), 
                    patentFamilyId,
                }} />}
            <div className="flex flex-col pt-2 empty:pt-0">
                {_(foreignCountries)
                    .sortBy(fc => fc.countryCode)
                    .map(fc =>
                        <div key={`${fc.countryCode}-${fc.state}`} className="border-t-2 last:border-b-2 border-pc-200">
                            <ClaimedCountry {...{...fc, patentFamilyId}} />
                        </div>)
                    .value()}
            </div>
        </div>
    )
}

function ClaimedCountry({inventionId, countryCode, state, patentFamilyId, familyMemberId, reminder}) {
    const { isEditUser } = useRoles()
    const { members } = usePatents()
    const [isEditing, setIsEditing] = useState(false)
    const dueDate = calcualteDueDate({reminder, familyMemberId, members})

    const [isDeleting, setIsDeleting] = useState(false)
    const member = members.find(m => m.familyMemberId === familyMemberId)

    return (
        <div className="flex flex-wrap sm:flex-row gap-x-2 p-1">
            <Link to={member && memberUrl(member)}><h6 className="w-6 hover:underline">{countryCode}</h6></Link>
            <div className="px-2 text-slate-500">{state_display[state]}</div>
            <div className="basis-full h-0 sm:hidden"/>
            <div className="grow text-right text-slate-700 tabular-nums">
                <StateIndicator {...{ deadline: dueDate, isReached: state === filedState }} />
            </div>
            {isEditUser && <>
                <button className="btn-primary h-6 w-6 p-0.5" onClick={() => setIsEditing(true)}><IconEdit /></button>
                <button className="btn-warn disabled:btn-disabled h-6 w-6 p-0.5 disabled:p-0.5" onClick={() => setIsDeleting(true)}><IconTrash /></button>
            </>}
            {isEditing && <EditClaimedCountry {...{inventionId, countryCode, state, setIsEditing, patentFamilyId, reminder, familyMemberId}} />}
            {isDeleting && <DeleteClaimedCountry {...{setIsDeleting, inventionId, countryCode}} />}
        </div>
    )
}

function DeleteClaimedCountry({setIsDeleting, countryCode, inventionId}) {
    const {inventionCountries, linkOperation} = useBackend()
    const country = inventionCountries.find(ic => ic.inventionId === inventionId && ic.countryCode === countryCode)
    function remove() {
        linkOperation(invention_country_link, "delete", country)
            .finally(() => setIsDeleting(false))
    }
    return (
        <Modal escAction={() => setIsDeleting(false)}>
            <div className="p-4">
                <h5>Remove claimed country {countryCode}?</h5>
                <p className="pt-2">
                    Be sure to inform the inventors about your decision.
                </p>
            </div>
            <div className="p-4 bg-pc-200 flex flex-row-reverse gap-4">
                <button className="btn-warn" onClick={() => remove()}>Remove</button>
                <button className="btn-secondary" onClick={() => setIsDeleting(false)}>Cancel</button>
            </div>
        </Modal>
    )
}


// values for foreignClaims are [undecided, decided]
function ForeignClaims({invention}) {
    const {t} = useTranslation()
    const { linkOperation } = useBackend()
    const { isEditUser } = useRoles()
    const { updateInvention, inventionCountries } = useInventions()

    const { foreignClaims, inventionId } = invention

    const claimed = inventionCountries.filter(i => i.inventionId === inventionId && i.state === "claimed").map(i => i.countryCode)
    const cleared = inventionCountries.filter(i => i.inventionId === inventionId && i.state === "cleared").map(i => i.countryCode)

    const [claimedCountries, setClaimedCountries] = useState(claimed)
    const [clearedCountries, setClearedCountries] = useState(cleared)

    const isDirty = (!_.isEqual(claimedCountries, claimed)) || (!_.isEqual(clearedCountries, cleared))

    function asLink(countryCode, state) {
        return {countryCode, inventionId, state}
    }

    function setNextState(state) {
        updateInvention({...invention, foreignClaims: state})
    }

    function cancelAction() {
        setClaimedCountries(claimed)
        setClearedCountries(cleared)
    }

    function saveAction() {
        saveLinks(
            claimed.map(c => asLink(c, "claimed")),
            claimedCountries.map(c => asLink(c, "claimed")),
            linkOperation, invention_country_link
        ).then(() =>
            saveLinks(
                cleared.map(c => asLink(c, "cleared")),
                clearedCountries.map(c => asLink(c, "cleared")),
                linkOperation, invention_country_link
            ))
    }

    return (
        <div className="flex flex-col gap-3">
            <div>
                <h5 className="pb-1">{t('claimed-countries')}</h5>
                <TagList {...{
                    availableTags: countryCodes.filter(c => clearedCountries.indexOf(c) < 0),
                    tags: claimedCountries,
                    setTags: setClaimedCountries,
                    disabled: !isEditUser,
                    title: "claimed-countries",
                    placeholder: "Country",
                }} />
            </div>
            <div>
                <h5 className="pb-1">{t('cleared-countries')}</h5>
                <TagList {...{
                    availableTags: countryCodes.filter(c => claimedCountries.indexOf(c) < 0),
                    tags: clearedCountries,
                    setTags: setClearedCountries,
                    select: true,
                    disabled: !isEditUser,
                    title: "cleared-countries",
                    placeholder: "Country",
                }} />
                <span>{t('any-other-countries')}</span>
            </div>
            <div className="flex flex-row gap-2 justify-end">
                <button disabled={!isDirty || !isEditUser} onClick={cancelAction} className="btn-secondary disabled:btn-disabled">{t('reset')}</button>
                <button disabled={!isDirty || !isEditUser} onClick={saveAction} className="btn-primary disabled:btn-disabled">{t('save')}</button>
            </div>
            <div className="flex flex-row-reverse pt-1">
                {foreignClaims === "undecided"
                    ?  <button disabled={!isEditUser} onClick={() => setNextState("decided")} className="btn-primary disabled:btn-disabled">{t('decision-communicated')}</button>
                    :  <button disabled={!isEditUser} onClick={() => setNextState("undecided")} className="btn-secondary disabled:btn-disabled">{t('reverse-decision')}</button>}
            </div>
        </div>
    )
}