import { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import _ from 'lodash'

import Modal from '../components/Modal'
import { useBackend } from "../BackendProvider"
import { EditFormikMask, EditFormik } from '../components/edit-table'
import { formikPersonFields, formikCompanyFields, personValidation, companyValidation, agent_link, invention_inventor_link } from "../data"
import { useMessages } from '../Messages'
import { Formik, Form, Field, useFormikContext } from 'formik'
import { useRoles } from '../user/Auth'
import { splitCompanyName, nameOf } from './utils'
import KanjiTip from '../components/KanjiTip'


export default function Agents() {
    const {t} = useTranslation()
    const {agents, entityOperation} = useBackend()

    return (
        <>
            <h2 className={"header-row modern-h2"}>{t('agents')}</h2>
            <div className="main-content flex flex-wrap gap-6">
                <div className="pt-2 pb-4 break-inside-avoid-column">
                    <AgentTable {...{
                        title: t('persons'), type: 'person', 
                        displayFields: ['firstName', 'lastName'], fields: formikPersonFields, validationsFct: personValidation, 
                        sortBy: a => `${a.lastName} ${a.firstName}`.toLowerCase(), 
                        agents, entityOperation
                    }} />
                </div>

                <div className="py-3 break-inside-avoid-column">
                    <AgentTable {...{
                        title: t('companies'), type: 'company', 
                        displayFields: ['name'], fields: formikCompanyFields, validationsFct: companyValidation, 
                        sortBy: a => a.name.toLowerCase(), 
                        agents, entityOperation
                    }} />
                </div>
            </div>
        </>
    )
}

const cellStyle = "pr-4"

function AgentTable({title, type, displayFields, fields, validationsFct, sortBy, agents, entityOperation}) {
    const {t} = useTranslation()
    const {isEditUser} = useRoles()

    const filtered = _(agents).filter(a => a.agentType === type).sortBy(sortBy).value()
    const validations = validationsFct(filtered)

    const [isSelected, setIsSelected] = useState({})

    useEffect(() => {
      if (filtered.length > 0 && _.size(isSelected) !== _.size(filtered)) {
        setIsSelected(_(filtered).map(f => [f.agentId, false]).fromPairs().value())
      }
    }, [filtered, isSelected])
    

    const numSelected = _.reduceRight(isSelected, (a, b) => a + (b ? 1 : 0), 0)

    const [showDelete, setShowDelete] = useState(false)
    const [showMerge, setShowMerge] = useState(false)
    const [showAdd, setShowAdd] = useState(false)
    const [showMove, setShowMove] = useState(false)

    function handleAction(event) {
        if (event.target.value === 'add')
            setShowAdd(true)
        else if (event.target.value === 'delete')
            setShowDelete(true)
        else if (event.target.value === 'move')
            setShowMove(true)
        else if (event.target.value === 'merge')
            setShowMerge(true)
        
        event.target.value = 'action'
    }

    let showDeleteModal = showDelete ? <DeleteModal {...{selected: filtered.filter(f => isSelected['' + f.agentId]), displayFields, setShowModal: setShowDelete}}/> : null
    let showMergeModal = showMerge ? <MergeModal {...{selected: filtered.filter(f => isSelected['' + f.agentId]), displayFields, setShowModal: setShowMerge}}/> : null
    let showAddModal = showAdd ? <AddModal {...{fields: (fields instanceof Function) ? fields({}) : fields, displayFields, setShowModal: setShowAdd, title: t(`add-${type}`)}} /> : null
    let showMoveModal = showMove ? <MoveModal {...{agent: filtered.find(f => isSelected['' + f.agentId]), setShowModal: setShowMove}} /> : null

    return (
        <div>
            <div className="flex flex-row justify-between gap-4 pb-2">
                <h3>{title}</h3>
                {isEditUser &&
                    <select className="form-select py-1" onChange={e => handleAction(e)}>
                        <option value="action">{t('select-action')}</option>
                        <option value="add">{t('add-' + type)}</option>
                        <option disabled={numSelected !== 1} value="move">{t('move-selected')}</option>
                        <option disabled={numSelected < 1} value="delete">{t('delete-selected')}</option>
                        <option disabled={numSelected < 2} value="merge">{t('merge-selected')}</option>
                    </select>}
            </div>
            <table >
                <thead>
                    <tr className=" border-b-2 border-pc-250">
                        {displayFields.map(name =>
                            <th className="text-left" key={name}>
                                <div className={cellStyle}>{t(name)}</div>
                            </th>
                        )}
                        {isEditUser && <>
                            <th className="pb-1">
                                <label htmlFor="all-select" className="font-normal pt-1 inline-flex items-center cursor-pointer">
                                    {t('all')}
                                </label>
                            </th>
                            <th className="pb-1">
                                <input
                                    id="all-select"
                                    className="form-checkbox" type="checkbox"
                                    checked={_.size(isSelected) > 0 && _(isSelected).values().reduceRight((a, b) => a && b, true)}
                                    onChange={(e) => setIsSelected(_(filtered).map(f => [f.agentId, e.target.checked]).fromPairs().value())}
                                />
                            </th>
                        </>}
                    </tr>
                </thead>
                <tbody>
                    {filtered.map((item, i) => {
                        return (
                            <tr className="border-b-2 border-pc-200 last:border-pc-250 hover:bg-pcx-200" key={i}>
                                {displayFields.map(name => {
                                    const display = item[name]
                                    return (
                                    <td key={name}>
                                        <div className={cellStyle}>{<KanjiTip kanjiOpt={display} >{display}</KanjiTip>}</div>
                                    </td>
                                    )
                                })}
                                {isEditUser && <>
                                    <td className="text-right">
                                        <EditFormik {...{
                                            original: item,
                                            fields: (fields instanceof Function) ? fields(item) : fields,
                                            onSubmit: (row) => entityOperation('agent', "update", row),
                                            small: true,
                                            validations,
                                        }} />
                                    </td>
                                    <td>
                                        {/*<DeleteButton {...{
                                        del: () => entityOperation(type, "delete", item.agentId),
                                        small: true
                                    }} />*/}
                                        <label className="px-1 inline-flex items-center cursor-pointer">
                                            <input
                                                className="form-checkbox" type="checkbox"
                                                checked={isSelected['' + item.agentId] ?? false}
                                                onChange={e => setIsSelected(ss => ({ ...ss, [item.agentId]: e.target.checked }))}
                                            />
                                        </label>
                                    </td>
                                </>}
                            </tr>
                        )
                    })}
                </tbody>
            </table>
            {showDeleteModal}
            {showMergeModal}
            {showAddModal}
            {showMoveModal}
        </div>
    )
}
function MergeModal({selected, displayFields, setShowModal}) {
    const {t} = useTranslation()
    const {agentLinks, inventionInventors, entityOperation, linkOperation} = useBackend()
    const [target, setTarget] = useState(selected[0]?.agentId)
    function doMerge () {
        const toMerge = _(selected).filter(a => a.agentId !== +target).map(a => a.agentId).value()

        const agentLinksToMerge = _(agentLinks).filter(link => toMerge.find(agentId => agentId === link.agentId)).value()
        const existingAgentLinks = agentLinks.filter(link => link.agentId === +target)
        const newAgentLinks = _(agentLinksToMerge).map(link => ({...link, agentId: +target})).differenceWith(existingAgentLinks, _.isEqual).uniqWith(_.isEqual).value()

        const inventionLinksToMerge = _(inventionInventors).filter(link => toMerge.find(agentId => agentId === link.agentId)).value()
        const existingInventionLinks = inventionInventors.filter(link => link.agentId === +target)
        const newInventionLinks = _(inventionLinksToMerge).map(link => ({...link, agentId: +target})).differenceWith(existingInventionLinks, _.isEqual).uniqWith(_.isEqual).value()

        linkOperation(agent_link, 'bulk-delete', agentLinksToMerge)
            .then(() => linkOperation(agent_link, 'bulk-add', newAgentLinks))
            .then(() => linkOperation(invention_inventor_link, 'bulk-delete', inventionLinksToMerge))
            .then(() => linkOperation(invention_inventor_link, 'bulk-add', newInventionLinks))
            .then(() => Promise.all(toMerge.map(m => entityOperation('agent', 'delete', m))))
            .then(() => entityOperation('agent', 'get'))
            .then(() => linkOperation(agent_link, 'get'))
            .then(() => linkOperation(invention_inventor_link, 'get'))
            .finally(() => setShowModal(false))
    }
    return (
        <Modal escAction={() => setShowModal(false)}>
            <div>
                <div className="p-4 flex flex-col gap-2">
                    <h4 className="pb-2">{t('merge-question')}</h4>
                    <table className="mx-auto w-fit">
                        <thead>
                            <tr className="border-b-2 border-pc-250">
                                {displayFields.map(name =>
                                    <th className="text-left" key={name}>
                                        <div className={cellStyle}>{t(name)}</div>
                                    </th>
                                )}
                            </tr>
                        </thead>
                        <tbody>
                            {selected.map((item, i) =>
                                <tr className="border-b-2 border-pc-200 last:border-pc-250" key={i}>
                                    {displayFields.map(name =>
                                        <td key={name}>
                                            <div className={cellStyle}>{item[name]}</div>
                                        </td>
                                    )}
                                </tr>
                            )}
                        </tbody>
                    </table>
                    <div className="pt-2 max-w-[20rem]">{t('after-merge-description')}</div>
                    <select className="form-select mx-auto" value={target} onChange={e => setTarget(e.target.value)}>
                        {selected.map(agent => <option key={agent.agentId} value={agent.agentId}>{displayFields.map(f => agent[f]).join(' ')}</option>)}
                    </select>
                    <div className="px-4 py-2 text-red-700 font-semibold text-center">
                        {t('action-irreversible')}
                    </div>
                </div>
                <div className="p-4 bg-pc-200 flex flex-row-reverse gap-4">
                    <button className="btn-warn" onClick={doMerge}>{t('merge')}</button>
                    <button className="btn-secondary" onClick={() => setShowModal(false)}>{t('cancel')}</button>
                </div>
            </div>
        </Modal>
    )
}

function DeleteModal({selected, displayFields, setShowModal}) {
    const {t} = useTranslation()
    const {entityOperation, linkOperation} = useBackend()
    const {setErrorMessage} = useMessages()

    function doDelete() {
        Promise.all(selected.map(agent => entityOperation('agent', 'delete', agent.agentId)))
            .then(() => entityOperation('agent', 'get'))
            .then(() => linkOperation(agent_link, 'get'))
            .then(() => linkOperation(invention_inventor_link, 'get'))
            .catch(err => setErrorMessage(err.message))
            .finally(() => setShowModal(false))
    }
    return (
        <Modal escAction={() => setShowModal(false)}>
            <div>
                <div className="p-4">
                    <h4 className="pb-2">{t('delete-question')}</h4>
                    <table className="mx-auto">
                        <thead>
                            <tr className="border-b-2 border-pc-250">
                                {displayFields.map(name =>
                                    <th className="text-left" key={name}>
                                        <div className={cellStyle}>{t(name)}</div>
                                    </th>
                                )}
                            </tr>
                        </thead>
                        <tbody>
                            {selected.map((item, i) =>
                                <tr className="border-b-2 border-pc-200 last:border-pc-250" key={i}>
                                    {displayFields.map(name =>
                                        <td key={name}>
                                            <div className={cellStyle}>{item[name]}</div>
                                        </td>
                                    )}
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
                <div className="p-4 bg-pc-200 flex flex-row-reverse gap-4">
                    <button className="btn-warn" onClick={doDelete}>{t('delete')}</button>
                    <button className="btn-secondary" onClick={() => setShowModal(false)}>{t('cancel')}</button>
                </div>
            </div>
        </Modal>
    )
}

function AddModal({fields, validations = undefined, setShowModal, title}) {
    const { entityOperation } = useBackend()
    return (
        <Modal id="add-modal" escAction={() => setShowModal(false)}>
            <EditFormikMask {...{ 
                original: {}, 
                fields, 
                onSubmit: (agent) => { entityOperation('agent', "add", agent); setShowModal(false) }, 
                validations, 
                cancelAction: () => setShowModal(false),
                title,
            }} />
        </Modal>
    )
}

const nameStyle = "font-light text-xl tracking-wide"

function isEmpty(string) {
    return (typeof string !== 'string' || string.trim() === '')
}

function ErrorMessageLine({name}) {
    const {errors, touched} = useFormikContext()
    //console.log(errors, touched)

    return (errors[name] && touched[name])
        ? <span className="text-red-600 normal-case">&nbsp; {errors[name]}</span>
        : null
}

function MoveModal({agent, setShowModal}) {

    const {t} = useTranslation()
    const {entityOperation} = useBackend()

    if (agent === undefined) {
        setShowModal(false)
        return null
    }

    //console.log(agent)
    const isCompany = agent.agentType === 'company'
    const targetType = isCompany ? 'person' : 'company'
    //console.log(targetType)
    const initialValues = isCompany ? splitCompanyName(agent) : {name: nameOf(agent)}

    return (
        <Modal escAction={() => setShowModal(false)}>
            <Formik 
                initialValues={initialValues}
                onSubmit={(newAgent) =>
                    entityOperation('agent', 'update', {...newAgent, agentType: targetType, agentId: agent.agentId})
                        .then(() => setShowModal(false))
                }
                validate={(values) => {
                    const errors = {}
                    if (isCompany) {
                        if (isEmpty(values['firstName']) && isEmpty(values['lastName']))
                            errors['firstName'] = t('required')
                    } else {
                        if (isEmpty(values))
                            errors['name'] = t('required')
                    }
                    return errors
                }}
            >{({errors, touched}) =>
                <Form>
                    <div className="form-fields form-col ">
                        <h4>
                            <Trans 
                                i18nKey="move-from-to" 
                                values={{name: nameOf(agent), from: t(agent.agentType), to: t(targetType)}} 
                                components={{namespan: <span className={nameStyle}/>}}
                            />
                        </h4>

                        {isCompany ? <>
                            <label>
                                <div className="label w-fit">{t('firstName')} <ErrorMessageLine name="firstName" /></div>
                                <Field name="firstName" className="form-input" />
                            </label>

                            <label>
                                <div className="label">{t('lastName')}</div>
                                <Field name="lastName" className="form-input" />
                            </label>
                        </> : 
                            <label>
                                <div className="label w-fit">{t('name')} <ErrorMessageLine name="name" /></div>
                                <Field name="name" className="form-input" />
                            </label>
                        }
                    </div>

                    <div className="form-buttons">
                        <input type="submit" className="btn-primary" value={t("move")} />
                        <div onClick={() => setShowModal(false)} className="btn-secondary">{t('cancel')}</div>
                    </div>
                </Form>
            }</Formik>
        </Modal>
    )
}