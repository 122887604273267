import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { createSearchParams } from "react-router-dom";

import { useMessages, ErrorMessage } from "../Messages"
import { AgorumObject } from "./DocumentsProvider"
import { family_member, invention, patent_family, trade_mark, trademark_family } from "../data";
import { emptyStringAsUndefined } from "../utils/strings";
import { useAuth } from "../user/Auth";
import _ from "lodash";
import { familyUrl } from "../patents/utils";
import { trademarkFamilyUrl } from "../trademarks/utils";
import { inventionUrl } from "../inventions/utils";

const identifier = 'client-areas'
const folderLookup = {
    [patent_family]: 'Patents',
    [family_member]: 'Patents',
    [trademark_family]: 'Trademarks',
    [trade_mark]: 'Trademarks',
    [invention]: 'Inventions',
}

const reverseLookup = {
    'Patents': { type: patent_family, baseurl: '/patents', url: (internalReference: string) => familyUrl({internalReference}) },
    'Trademarks': { type: trademark_family, baseurl: '/trademarks', url: (reference: string) => trademarkFamilyUrl({reference}) },
    'Inventions': { type: invention, baseurl: '/inventions', url: (reference: string) => inventionUrl({reference}) },
}

export function analysePath(path: string) {
    const parts = path.split(identifier)?.[1]?.split('/')?.slice(5) ?? []
    if (parts.length === 0) return undefined

    const logic = reverseLookup[parts[0]]
    if (!logic) return undefined

    if (parts.length === 1) return {url: logic.baseurl, parts}
    return {
        parts,
        url: logic.url(parts[1]),
    }
}
// 1: see if you find family 
// if not create family folder
// return family folder
// 
// see if you find member
// if not, go to 1
// create member folder

export async function createFamilyFolder({number, entity, internalReference, description}: {number: string, entity: string, internalReference: string, description?: string}) {
    // Objects in trash are not in path 9999
    // ci: case insensitive; "" because of spaces
    const query = `infolder:\${ID:${getClientRoot(number)}} name_ci:"${folderLookup[entity]}"`
    const searchResult = await folderSearch(encodeURIComponent(query))
    const uuid = searchResult?.[0]?.uuid
    if (!uuid)
        throw new Error(`Could not find folder for ${entity}`)
    const result = await addFolder({uuid, foldername: internalReference, description})
    // TODO: This is currently only the id. Can we change that?
    //console.log({result})
    return result
}

export function createSearchKey(entity: string, internalReference: string) {
    return ['search-uuid', entity, internalReference]
}

export async function findUuid(number: string, entity: string, internalReference: string, parent?: string) {
    const inpath = parent 
        ? `${folderLookup[entity]}/${parent}`
        : folderLookup[entity]
    const query = `infolder:\${ID:${getClientRoot(number)}/${inpath}} name_ci:"${internalReference}"`
    const searchResult = await folderSearch(encodeURIComponent(query))
    return searchResult?.[0]?.uuid
}

// https://agorumdocproxy.agorum.com/roiwebui/acds_module/overview2/index.html#/7a345050-e913-11e9-a3bc-005056aa0ecc/ecba9c30-e7f1-11ec-bc0b-005056aa0ecc
// infolder:${ID:/agorum/roi/files/client-areas/10/00/35/client_100035/Patents/P-003}
// classname:(noteobject OR folderobject)
export function useFindUuid(number: string, entity: string, internalReference: string, parent?: string) {
    const {data, isLoading} = useQuery(
        createSearchKey(entity, internalReference),
        //() => search(encodeURIComponent(query), ['inTrash']),
        () => findUuid(number, entity, internalReference, parent).then(r => r ? r : ''),
        {enabled: !!internalReference && !!number && !!entity})
    //console.log(data)
    return {uuid: emptyStringAsUndefined(data), isLoading}
}

type DynamicProperties<T extends string> = {
    [P in T]: any
}
async function folderSearch<K extends string>(query: string, properties: K[] = []): Promise<(AgorumObject & DynamicProperties<K>)[]> {
    const params = createSearchParams({query, provider: 'search'})
    //console .log(params)
    return parseResponse(fetch(`/api/dms/pass-through/api/rest/object?${params}&${makeQueryParams(properties)}`, { method: 'GET', }))
}

export async function parseLight(response: Promise<Response>) {
    return response
        .then(res => res.ok ? res.json() : Promise.reject({ status: 'error', message: res.status }))
}

/// TODO: status: 'unauthorized' is not handled
export async function parseResponse(response: Promise<Response>) {
    return response
        .then(res => res.ok ? res.json() : Promise.reject({ status: 'error', message: res.status }))
        .then(res => {
            if (res.success) {
                if (res.data === undefined) {
                    console.warn('No data in response')
                    return {}
                } else {
                    return res.data
                }
            } else {
                return Promise.reject({ status: 'error', message: res.message })
            }
    })
}


function makeQueryParams(properties: string[]) {
    return ['uuid', 'name', 'isFolder', 'formatObject.mimeType', 'description', ...properties].map(p => `properties=${p}`).join('&')
}

function getSource(uuid?: string, root?: string) {
    const source = uuid 
        ? `uuid:${uuid}` 
        : encodeURIComponent(root ?? 'agorum/roi/Files')
        /*
        Pfad mit %2F codieren (aber lieber uuid von root speichern) oder root mit “9999” finden (immer fixe ID).
        */
    return source
}

function getFilePath(source: string, properties: string[] = []) {
    const params = makeQueryParams(properties)
    const path = `/api/dms/pass-through/api/rest/object/${source}?${params}`
    const basicKey = ['dms', source]
    const queryKey: string[] = [...basicKey, params]
    return {basicKey, queryKey, path}
}

export function getClientRoot(number: string) {
    if (!number) return undefined

    const groups = _.words(number, /.{2}/g)
    const root = `agorum/roi/Files/client-areas/${groups.join('/')}/client_${number}`
    return root
}

type AugmentedAgorumObject<T extends string> = AgorumObject & Record<T, any>

export function useAgorumObject<T extends string>({uuid, root, properties = []}: {uuid?: string, root?: string, properties?: T[]}) {
    const {setErrorMessage} = useMessages()
    const {setStrike} = useAuth() // TODO: necessary for others as well?
    const queryClient = useQueryClient()
    
    const source = getSource(uuid, root)

    const {path, queryKey, basicKey} = getFilePath(source, properties)

    const queryFn = async () => parseResponse(fetch(path, { method: 'GET', }))
        .then(res => { setStrike(0); return res })
        .catch(err => {
            setErrorMessage(err.message)
            if (err.status === 'unauthorized') {
                setStrike(s => s + 1)
            }
        })

    const {data: object, error, isLoading} = useQuery<AgorumObject, ErrorMessage, AgorumObject>(
        queryKey,
        queryFn,
    )
    if (error) {
        console.error(error)
        setErrorMessage(error.message)
    }

    const postMutation = useMutation<AgorumObject, ErrorMessage, Record<string, any>>(
        (payload: Record<string, any>) => updateObject(uuid, payload), {
        onMutate: async (payload) => {
            await queryClient.cancelQueries(queryKey)
            const formerValue = queryClient.getQueryData<AgorumObject>(queryKey)
            queryClient.setQueryData(queryKey, (old: AgorumObject) => ({...old, ...payload}))
            return { formerValue }
        },
        onError: (err, payload, context: {formerValue: AgorumObject}) => {
            queryClient.setQueryData(queryKey, context?.formerValue)
            setErrorMessage(err.message)
        },
        onSettled: () => queryClient.invalidateQueries(basicKey),
    })

    async function deleteObj() {
        await queryClient.cancelQueries(queryKey)
        const resp = await deleteObject(uuid)
        queryClient.invalidateQueries(basicKey)
        return resp
    }

    async function revert() {
        const resp = await revertObject(uuid)
        queryClient.invalidateQueries(basicKey)
        return resp
    }

    //console.log({object})

    return {
        object: object ? {...object as AugmentedAgorumObject<T>, 'contentType': object['formatObject.mimeType']} : undefined,
        revert,
        update: postMutation.mutate,
        deleteObject: deleteObj, //deleteMutation.mutate,
        error, isLoading
    }
}

function getFolderPath(object?: {uuid: string}) {
    const path = `/api/dms/pass-through/api/rest/object?${makeQueryParams([])}&source=uuid:${object?.uuid}&provider=folder`
    const queryKey: string[] = ['dms', 'folder', object?.uuid ?? '']
    return  {queryKey, path}
}

export function useAgorumFolder(object?: AgorumObject) {
    const {setErrorMessage} = useMessages()
    
    const {path, queryKey} = getFolderPath(object)

    //const queryKey: string[] = [path]
    const queryFn = async () => parseResponse(fetch(path, { method: 'GET', }))

    const {data: folder, error, isLoading} = useQuery<AgorumObject[], ErrorMessage, AgorumObject[]>(
        queryKey,
        queryFn,
        {enabled: object?.isFolder === true}
    )

    if (error) {
        console.error(error)
        setErrorMessage(error.message)
    }

    return { 
        folder,
        error, 
        isLoading: (object?.isFolder && isLoading),
    }
}

export function useFolderReload(object?: {uuid: string}) {
    const client = useQueryClient()
    const {queryKey} = getFolderPath(object)

    return {
        reload: (uuid?: string) => 
            uuid === undefined
                ? client.invalidateQueries(queryKey)
                : client.invalidateQueries(getFolderPath({uuid}).queryKey),
    }
}

export function useFileReload() {
    const client = useQueryClient()
    return {
        reload: (uuid: string) => {
            const source = getSource(uuid)
            const {queryKey} = getFilePath(source, [])
            client.invalidateQueries(queryKey.slice(0, 2))
        },
    }

}

export async function addFolder({uuid, foldername, description}: {uuid: string, foldername: string, description?: string}): Promise<{id: number}> {
    const formdata = new FormData()
    formdata.append('source', uuid)
    formdata.append('handler', 'folder')
    formdata.append('data', JSON.stringify({name: foldername, description}))

    return parseResponse(fetch(`/api/dms/pass-through/api/rest/object`, {
        method: 'POST',
        body: formdata,
        headers: { 'Accept': 'application/json' },
    }))
}

export async function searchTrashcan({query = '', start}: {query: string, start?: string}) {
    return parseLight(fetch(`/api/dms/pass-through/api/rest/custom/aipm.patent.cockpit.api.trashcan?query=${query}`))
}

export async function deleteObject(uuid: string) {
    // This should only move it to the trash
    const params = encodeURIComponent(JSON.stringify({trash: true}))
    return parseResponse(fetch(`/api/dms/pass-through/api/rest/object/${uuid}?parameters=${params}`, { method: 'DELETE', }))
}

function revertObject(uuid: string) {

    return parseResponse(fetch(`/api/dms/pass-through/api/rest/custom/aipm.patent.cockpit.api.revert?source=${uuid}`))
}

export async function createPreviewTicket(uuid: string) {
    const params = {source: `uuid:${uuid}`, provider: 'preview'}
    return parseResponse(fetch(`/api/dms/pass-through/api/rest/object/?${createSearchParams(params)}`, { method: 'GET', }))
}

export async function renameObject(uuid: string, name: string) {
    return updateObject(uuid, {name})
}

async function updateObject(uuid: string, data: Record<string, any>) {
    const formdata = new FormData()
    formdata.append('data', JSON.stringify(data))
    formdata.append('updatehandler', 'object')

    return parseResponse(fetch(`/api/dms/pass-through/api/rest/object/${uuid}`, {
        method: 'PUT',
        body: formdata,
        headers: { 'Accept': 'application/json' },
    }))
}

export async function moveObject(uuid: string, target: string, parent: string) {
    const formdata = new FormData()
    formdata.append('handler', 'object')
    formdata.append('target', 'uuid:' + target)
    formdata.append('parameters', JSON.stringify({parent: [parent]}))

    return parseResponse(fetch(`/api/dms/pass-through/api/rest/object/move/uuid:${uuid}`, {
        method: 'POST',
        body: formdata,
        headers: { 'Accept': 'application/json' },
    }))
}

export async function search({query, limit = 10, start}: {query: string, limit?: number, start?: string}) {
    const params = createSearchParams({
        query: encodeURIComponent(query),
        //includeTrash: 'true', // TODO
        ...(limit ? {limit: limit.toString()} : {}),
        ...(start ? {start} : {}),
    })
    
    return parseLight(fetch(`/api/dms/pass-through/api/rest/custom/aipm.patent.cockpit.api.search?${params.toString()}`))
}

export async function uploadFile(file: File, uuid: string, sessionId: string, baseurl: string) {
    const formData = new FormData()
    formData.append('filename', file)

    const params = {
        name: file.name,
        target: 'uuid:' + uuid,
        sessionId,
    }
    const path = `https://${baseurl}/api/rest/custom/aipm.patent.cockpit.api.upload?${createSearchParams(params)}`

    return fetch(path, {
        method: "POST",
        body: formData,
    })
}
/*
// Example response
{
  "pathParts" : [
    "Patents",
    "P-002",
    "P-002US"
  ],
  "uuid" : "4472f590-fe1f-11ee-b1bd-00000a6d680a",
  "parents" : [
    {
      "name" : "P-002US",
      "uuid" : "384b6310-fe1f-11ee-b1bd-00000a6d680a"
    },
    {
      "name" : "P-002",
      "uuid" : "37e638a0-fe1f-11ee-b1bd-00000a6d680a"
    },
    {
      "name" : "Patents",
      "uuid" : "b88d1720-fc0e-11ee-b1bd-00000a6d680a"
    }
  ]
}
*/

export async function parentPaths(uuid: string, pathParts: string[]): Promise<{parents: {name: string, uuid: string}[]}> {
    const path = `/api/dms/pass-through/api/rest/custom/aipm.patent.cockpit.api.pathParts?uuid=${uuid}&pathParts=${encodeURIComponent(JSON.stringify(pathParts))}`
    return parseLight(fetch(path))
}

// {{base_url}}/object?sessionId={{sessionId}}&properties=uuid&properties=displayName&properties=name&properties=firstParent&provider=trashcan
// export async function listTrash() {
//     const params = {
//         provider: 'trashcan',
//         properties: ['uuid', 'displayName', 'name', 'firstParent']
//     }
//     const path = `/api/dms/pass-through/api/rest/object?${createSearchParams(params)}`

//     const uuids = await parseResponse(fetch(path))
//     return Promise.all(uuids.map(({uuid}) => {
//         const { path } = getFilePath(uuid, ['name', 'uuid'])
//         parseResponse(fetch(path))
//     }))
// }