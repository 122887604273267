import { Dialog, Transition } from '@headlessui/react'

import { Link, createSearchParams, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";
import { StopIcon, ExclamationTriangleIcon } from "@heroicons/react/20/solid";
import { ExclamationTriangleIcon as OutlineExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { Formik, Form, Field, useField, useFormikContext } from "formik";
import clsx from 'clsx';
import _ from "lodash";

import { PcIpRight, PcCostCenter, PcCostCenterLink, useDennemeyer, useIpRight, useSynchronize, IpRight, MaintenanceAction, useTimeline } from "./DennemeyerProvider";
import { IconSpinner } from "../components/icons";
import Modal from "../components/Modal";
import { calculateHardInstructionCuttoff, deriveInitialIpRight, deriveStatus, extractDiscrepancy, extractState, isUndecided } from "./utils";
import { linkDifference } from "../BackendProvider";
import { ipSubTypes as allIpSubTypes, origins } from '.'
import { CostCenterSelection, } from './IpImport'
import { firstUpperCase } from "../utils/strings";
import { Fragment, useState } from "react";
import { Member } from '../patents/patents';
import { missingInformation } from './check';
import { usePatents } from '../patents/PatentsProvider';
import { DatePicker } from '../components/DatePicker';


// familyMemberId can be undefined for a short period. Just after adding new FM
export function MemberRenewalBibliographic({familyMemberId}: {familyMemberId?: number}) {
    const {t} = useTranslation()
    const location = useLocation()

    const {memberById} = usePatents()
    const member = memberById[familyMemberId]
    const {ipRightByMemberId} = useDennemeyer()

    const pcIpRight = ipRightByMemberId[familyMemberId]

    const {ipRight: ipRightResp} = useIpRight(pcIpRight?.dennemeyerId)
    const ipRight = ipRightResp?.Data

    if (member === undefined || familyMemberId === undefined) return null

    const params =  _.pickBy({
        familyMemberId: familyMemberId.toString(),
        ipRightId: pcIpRight?.ipRightId?.toString(),
        dennemeyerId: pcIpRight?.dennemeyerId?.toString(),
    }, v => v !== undefined)


    const hasBeenStopped = location?.state?.hasBeenStopped === true && 
        (ipRight?.Status.includes('Granted') || ipRight?.Status === 'Pending')

    const fields = [
        hasBeenStopped && { key: '', value: <HasBeenStoppedModal {...{ params }} /> },
        { key: t('renewals'), value: <RenewalStateDisplay {...{ params, member, pcIpRight, ipRight }} /> },
        ipRight !== undefined && { key: t('history'), value: <HistoryLink {...{ params, maintenanceActions: ipRight.MaintenanceActions }} /> },
    ].filter(Boolean)

    return <>{
        fields.map(({ key, value }) =>
            <Fragment key={key}>
                <div className="sm:text-right text-gray-500">{key}</div>
                <div>{value}</div>
            </Fragment>)
    }</>
}

function calculateNextDay(maintenanceActions: MaintenanceAction[] | undefined, calculateDueDates: (dueDate: string) => {instructionDueDate: string} | undefined) {
    if (!maintenanceActions) return undefined

    const cutoff = calculateHardInstructionCuttoff()
    const today = new Date().toISOString().split('T')[0]
    const openActions = maintenanceActions?.filter(ma => isUndecided(ma, undefined, cutoff) && ma.DueDate >= today) ?? []
    const openAction = _(openActions)
        .map(ma => ({...ma, ...calculateDueDates(ma.DueDate)}))
        .minBy(ma => ma.instructionDueDate)
    return openAction
}

function HistoryLink({params, maintenanceActions}: {params: Record<string, string>, maintenanceActions?: MaintenanceAction[]}) {
    const {t} = useTranslation()
    const { calculateDueDates } = useTimeline()
    const count = maintenanceActions?.length ?? 0
    //console.log({maintenanceActions})

    const action = calculateNextDay(maintenanceActions, calculateDueDates)

    return (
        <Link
            to={{pathname: "renewals/history", search: createSearchParams(params).toString()}}
            className="underline-link pl-0 sm:pl-0"
        >
            {t('instructions-count', {count})} {action?.instructionDueDate && `(${firstUpperCase(t("next-instruction-due", {duedate: action.instructionDueDate}))}${action.Annuity ? `; ${t('annuity')}: ${action.Annuity}` : ''})`}
        </Link>
    )
}

function HasBeenStoppedModal({params}: {params: Record<string, string>}) {
    const {t} = useTranslation()
    const {ipRightById, instructionsByIpRightId} = useDennemeyer()

    const pcIpRight = ipRightById[params.ipRightId]
    const {ipRight: ipRightResp} = useIpRight(pcIpRight?.dennemeyerId)
    const ipRight = ipRightResp?.Data

    const hardInstructionCuttoff = calculateHardInstructionCuttoff()
    const pcInstruction = instructionsByIpRightId[params.ipRightById]
    const openMaintenanceActions = (ipRight?.MaintenanceActions ?? [])
        .filter(ma => isUndecided(ma, pcInstruction, hardInstructionCuttoff))

    return (
        <Modal>
            <div className='p-4 space-y-2'>
                <h2 className='mb-4'>{t('stop-file-short')}</h2>
                <p>{t('file-set-stopped')}</p>
                <p>{t('stop-file-long')}</p>
            </div>
            {openMaintenanceActions.length > 0 && 
                <div className='px-4 pb-4'>
                    <div className='bg-warn-200 rounded px-4 py-2 text-warn-900 flex flex-row gap-4 items-center'>
                        <ExclamationTriangleIcon className="w-8 h-8 inline-block text-warn-500" />
                        <div>
                            <p>{t('open-instructions-warning')}</p>
                            <p>{t('open-instructions-advice')}</p>
                        </div>
                    </div>
                </div>}
            <div className='p-4 flex flex-col sm:flex-row-reverse gap-4 bg-pcx-200'>
                {/* <StopWithDmButton pcIpRight={pcIpRight} postAction={() => navigate('.')} /> */}
                <Link to="." className='btn-primary capitalize text-center'>{t('renewals-desc.dont-stop')}</Link>
                <Link to="edit" className='btn-secondary capitalize text-center'>{t('back')}</Link>
            </div>
        </Modal>
    )
}

function RenewalStateDisplay({ipRight, params, member, pcIpRight}: {ipRight?: IpRight, params: Record<string, string>, member: Member, pcIpRight?: PcIpRight}) {
    const {t} = useTranslation()
    const {validationsByIpRightId} = useDennemeyer()

    const status = extractState(member, pcIpRight, ipRight, validationsByIpRightId[pcIpRight?.ipRightId] ?? [])

    const isAtProvider = 
        status === "ready-at-payment-provider" ||
        status === "sent" ||
        status === "validation-errors"

    return (
        <div className='flex flex-row gap-1 whitespace-nowrap'>
            <div className='grow'>
                <span onClick={() => console.log({ipRight})}>{t(`renewals-desc.${status}`)}</span>
            </div>
            {status !== 'pct-not-importable' &&
                <div className='flex flex-row gap-1'>
                    <Link to={{ pathname: "renewals", search: createSearchParams(params).toString() }} className="btn-secondary text-sm py-px">
                        {t(isAtProvider ? 'edit' : 'import')}
                    </Link>
                    <StopDmRightButton {...{ disabled: !isAtProvider, member, ipRightId: pcIpRight?.ipRightId }} />
                </div>}
        </div>
    )
}

type FormPcIpRight = PcIpRight & {linked?: number, costCenters: PcCostCenterLink[], ownerId: number}

export function MemberRenwalsEdit() {
    const [searchParams] = useSearchParams()

    const {memberById } = usePatents()
    //console.log({parents}) // TODO

    const { ipRightById, owners, } = useDennemeyer()

    const ipRightId = parseNumber(searchParams.get('ipRightId'))

    const familyMemberId = parseNumber(searchParams.get('familyMemberId'))
    const member = memberById[familyMemberId]

    const dennemeyerId = searchParams.get('dennemeyerId') ?? undefined

    const pcIpRight = ipRightById[ipRightId]

    if (!member) {
        console.warn("Member not found")
        return null
    }

    const fallbackOwnerId = _(owners).minBy(o => o.name)?.ownerId

    if (fallbackOwnerId === undefined)
        return <NoOwnerModal />

    const missing = missingInformation(member)

    if (missing.length > 0) 
        return <NotEnoughDataModal {...{missingInformation: missing}} />

    return <MemberPostRenewal {...{member, pcIpRight, dennemeyerId, fallbackOwnerId}} />
}

function NoOwnerModal() {
    const {t} = useTranslation()
    return (
        <Modal>
            <div className="p-4">
                <h3 className="mb-2">{t('no-owner-defined')}</h3>
                <p>
                    <Trans
                        i18nKey='define-owner'
                        components={{ here: <Link className="underline decoration-pcx-500 hover:decoration-pcx-400" to="/renewals/owners/add" /> }}
                    />
                </p>
            </div>
            <div className="p-4 flex flex-row-reverse gap-4 bg-pcx-200">
                <Link className="btn-primary" to="/renewals/owners/add">{t('add-owner-details')}</Link>
                <Link className="btn-secondary" to="..">{t('cancel')}</Link>
            </div>
        </Modal>
    )
}

function NotEnoughDataModal({missingInformation}: {missingInformation: string[]}) {
    const {t} = useTranslation()

    const translated = _(missingInformation).map(missing => t(missing)).sortBy().value()

    return (
        <Modal>
            <div className='p-4'>
                <h2 className='mb-4'>{t("missing-information")}</h2>
                <p className='sm:text-lg text-gray-800'>{t("missing-information-no-registration")}</p>
                <div className='flex flex-row gap-4 items-center'>
                    <div className='max-sm:hidden px-4 text-warn-600'><OutlineExclamationTriangleIcon className='h-12 w-12' /></div>
                    <ul className='leading-relaxed text-lg lg:columns-2xs lg:max-h-32'>
                        {translated.map(missing => <li key={missing}>{t(missing)}</li>)}
                    </ul>
                </div>
                <p className='mt-4 sm:text-lg text-gray-800'>{t("edit-case-complete-info")}</p>
            </div>
            <div className='p-4 bg-pcx-200 flex flex-row-reverse gap-4'>
                <Link className='btn-primary' to={"../edit"}>{t('edit')}</Link>
                <Link className='btn-secondary' to={".."}>{t('back')}</Link>
            </div>
        </Modal>
    )
}

function MemberPostRenewal({member, dennemeyerId, pcIpRight, fallbackOwnerId}: {member: Member, dennemeyerId?: string, pcIpRight?: PcIpRight, fallbackOwnerId: number}) {
    const {t} = useTranslation()

    const navigate = useNavigate()

    const {ipRight: ipRightResp} = useIpRight(dennemeyerId)
    const ipRight = ipRightResp?.Data

    const {parents, deleteOriginationLink, postOriginationLink} = usePatents()
    //console.log({parents}) // TODO
    const familyMemberId = member.familyMemberId
    const linked = parents[familyMemberId]?.[0]?.familyMemberId

    const {
        postIpRight, 
        costCentersByIpRightId,  postCostCenterLink, deleteCostCenterLink,
        validationsByIpRightId, 
        ownershipsByIpRight, owners, postOwnershipLink, deleteOwnershipLink,
    } = useDennemeyer()

    const ipRightId = pcIpRight?.ipRightId
    const status = extractState(member, pcIpRight, ipRight, validationsByIpRightId[ipRightId] ?? [])
    const isAtProvider = 
        status === "ready-at-payment-provider" ||
        status === "sent" ||
        status === "validation-errors"


    const {triggerSynchronize, synchronizing} = useSynchronize()

    // TODO: remove
    const discrepancy = ipRight && extractDiscrepancy(member, ipRight)
    const validations = validationsByIpRightId[ipRightId] ?? []

    //console.log({ipRight, pcIpRight})

    const initialValues: FormPcIpRight = pcIpRight ?
        {
            ...pcIpRight,
            linked,
            costCenters: costCentersByIpRightId[pcIpRight.ipRightId] ?? [],
            ownerId: ownershipsByIpRight[pcIpRight.ipRightId]?.[0]?.ownerId ?? fallbackOwnerId,
        } : {
            ...deriveInitialIpRight(member),
            costCenters: [],
            ownerId: fallbackOwnerId,
        }
    return (
        <Modal>
            <Formik
                initialValues={initialValues}
                onSubmit={async (ipRight: FormPcIpRight) => {
                    const { status } = deriveStatus(member)
                    const { ipRightId } = (await postIpRight({...ipRight, status})) as PcIpRight
                    const existing = ownershipsByIpRight[ipRightId]?.[0]
                    if (existing) {
                        await deleteOwnershipLink(existing)
                    }
                    await postOwnershipLink({ ipRightId, ownerId: ipRight.ownerId, ownerType: 'registered' })

                    const [toAdd, toDelete] = linkDifference(
                        costCentersByIpRightId[ipRightId] ?? [],
                        ipRight.costCenters.map(cc => ({...cc, ipRightId})),
                        (a: PcCostCenterLink, b: PcCostCenterLink) => a.ipRightId === b.ipRightId && a.costCenterId === b.costCenterId && a.percentage === b.percentage)

                    if (toDelete.length > 0) 
                        await deleteCostCenterLink(toDelete)
                    if (toAdd.length > 0)
                        await postCostCenterLink(toAdd)

                    if (linked !== ipRight.linked) {
                        if (linked)
                            await deleteOriginationLink({from: familyMemberId, to: linked})
                        if (ipRight.linked)
                            await postOriginationLink({from: familyMemberId, to: ipRight.linked})
                    }

                    await triggerSynchronize([ipRightId])
                    navigate("..")
                }}
                enableReinitialize
            >{({values: {status}, isSubmitting}) => 
                <Form>
                    <div className="p-4 space-y-4">
                        <h2>{member.internalReference}: {t('renewals-desc.send')}</h2>
                        {discrepancy && 
                            <div>
                                <ExclamationTriangleIcon className="w-5 h-5 inline-block text-warn-500 mr-1" />
                                Patent Cockpit: {discrepancy.pc} vs. Dennemeyer: {discrepancy.dm}
                            </div>}
                        {validations.length > 0 &&
                            <ul className="list-disc space-y-2 pl-4">
                                {validations.map(v =>
                                    <li key={v.validationId} className="text-red-700 list-outside" title={v.message}>
                                        {t("dm_messages." + v.errorCode)}
                                    </li>
                                )}
                            </ul>}
                        <div className="grid grid-cols-1 sm:grid-cols-[auto_auto] gap-x-3 gap-y-2 items-center">

                            <SubTypeSelector {...member} />

                            <label htmlFor="origin" className="label" title="Origin">{t('forecast.filing-procedure')}</label>
                            <Field id="origin" name="origin" as="select" className="form-select">
                                {origins.map(origin => <option key={origin.name} value={origin.name}>{t(origin.description)}</option>)}
                            </Field>

                            <label htmlFor="ownerId" className="label">{t('owner')}</label>
                            <Field id="ownerId" name="ownerId" as="select" className="form-select">
                                {owners.map(owner => <option key={owner.ownerId} value={owner.ownerId}>{owner.name}</option>)}
                            </Field>

                            <label htmlFor="costCenters" className="label">{t('cost-centers')}</label>
                            <CostCenterSelectionField name="costCenters" ipRightId={ipRightId} />

                            <label htmlFor="firstDueDate" title={t('nextDueDate')} className="label">{t('take-over-from')}</label>
                            <Field name="firstDueDate" className="form-input" as={DatePicker} />
                        </div>
                    </div>
                    <div className="flex flex-col gap-4 bg-pcx-200 p-4">
                        <div className="flex flex-col sm:flex-row-reverse gap-4">
                            <button type="submit" className={clsx(status === 'Inactive' ? "btn-warn" : "btn-primary", "inline-block whitespace-nowrap")} disabled={synchronizing}>
                                {status === 'Inactive'
                                    ? t('renewals-desc.stop')
                                    : isAtProvider
                                    ? t('save')
                                    : t('instruct-payment-provider-to-handle-case')
                                } {(synchronizing || isSubmitting) && <IconSpinner className="inline ml-1 h-5 w-5 animate-spin" />}
                            </button>
                            <Link className="btn-secondary text-center" to="..">{t('back')}</Link>
                        </div>
                    </div>
                </Form>
            }</Formik>
        </Modal>
    )
}


function StopDmRightButton({member, ipRightId, disabled}: {member: Member, ipRightId?: number, disabled?: boolean}) {
    const {t} = useTranslation()

    const {postMember} = usePatents()
    const { postIpRight, ipRightById } = useDennemeyer()
    const pcIpRight = ipRightById[ipRightId]
    const {triggerSynchronize} = useSynchronize()
    
    const [isOpen , setIsOpen ] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [alsoStopWithPc, setAlsoStopWithPc] = useState(true)

    const closeModal = () => setIsOpen(false)

    async function handleStop() {
        if (!isSubmitting) {
            setIsSubmitting(true)
            if (alsoStopWithPc) {
                await postMember({ ...member, familyMemberStatus: 'stopped' })
            }
            await postIpRight({ ...pcIpRight, status: 'Inactive' })
            await triggerSynchronize([ipRightId])
            setIsSubmitting(false)
            setIsOpen(false)
        }
    }


    return (
        <>
            <button {...{disabled}} onClick={() => setIsOpen(!isOpen)} className='shrink-0 btn-warn text-red-800/70 disabled:btn-disabled p-px disabled:p-px w-6 h-6'>
                <StopIcon className="w-5 h-5" />
            </button>
            <Transition appear show={isOpen} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={closeModal}>
                    {/* Background */}
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 backdrop-filter backdrop-blur-sm bg-black/25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-sm bg-white text-left align-middle shadow-lg transition-all">
                                    <Dialog.Title as="h3" className="p-4" >
                                        {member.internalReference}: {t('renewals-desc.stop')}
                                    </Dialog.Title>
                                    <div className="p-4 pt-0">
                                        <p className='font-base text-gray-500'>
                                            {t('renewals-desc.really-stop')}
                                        </p>
                                    </div>
                                    <label className='flex flex-row gap-2 px-4'>
                                        <input type="checkbox" checked={alsoStopWithPc} onChange={() => setAlsoStopWithPc(!alsoStopWithPc)} />
                                        <span className='text-gray-500'>{t('stop-pc-case')}?</span>
                                    </label>

                                    <div className="mt-4 p-4 bg-pcx-200 flex flex-row-reverse gap-2">
                                        <button type="button" className='btn-warn' onClick={() => handleStop()}>
                                            {t('stop')} {isSubmitting && <IconSpinner className="inline w-5 h-5 animate-spin" />}
                                        </button>
                                        <button
                                            type="button"
                                            className="btn-secondary"
                                            onClick={closeModal}
                                        >
                                            {t('back')}
                                        </button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    )
}

function SubTypeSelector({familyMemberId, patentFamilyId, ipType}: Member) {
    const {t} = useTranslation()
    // At first we assume that there will be only one parent (for divisionals and continuations)
    const {membersByFamilyId} = usePatents()

    const {values, setFieldValue} = useFormikContext<FormPcIpRight>()

    const ipSubTypes = allIpSubTypes[ipType] ?? []

    const ipSubType = values.ipSubType
    const members = (membersByFamilyId[patentFamilyId] ?? []).filter((m: Member) => m.familyMemberId !== familyMemberId)
    const linkedEnable = ipSubTypes.find(t => t.name === ipSubType)?.withLink && members.length > 0 

    const name = 'ipSubType'
    return <>
        <label htmlFor={name} className="label" title="ipSubType">{t('specialIpType')}</label>
        <Field id={name} name={name} as="select" className="form-select">
            {ipSubTypes.map(type => <option key={type.name} value={type.name}>{t(type.label ?? type.name)}</option>)}
        </Field>
        <label htmlFor='linked' className={clsx('label', !linkedEnable && 'opacity-40')} title="Former">{t('linked-ip-right')}</label>
        <select
            name="linked" id="linked" className='form-select disabled:opacity-40' disabled={!linkedEnable}
            value={(linkedEnable && values.linked) ?? '-1'} onChange={e => setFieldValue('linked', e.target.value)}
        >
            <option value={-1}>{t('select')}...</option>
            {_(members)
                .sortBy(m => m.internalReference.toLowerCase())
                .map((m: Member) =>
                    <option key={m.familyMemberId} value={m.familyMemberId}>
                        {m.internalReference}
                    </option>)
                .value()}
        </select>
    </>
}

function CostCenterSelectionField({name, ipRightId}: {name: string, ipRightId?: number}) {
    const [, meta, helpers] = useField<(PcCostCenter & PcCostCenterLink)[]>(name)
    const { setValue } = helpers
    const { value } = meta

    return <CostCenterSelection {...{
        value, ipRightId, onChange: ({target: {value}}) => {
            console.log({value, x: 'x'})
            setValue(value)
        }
    }} />
}


function parseNumber(value: string | null) {
    if (value === null)
        return undefined
    const num = parseInt(value)
    if (isNaN(num))
        return undefined
    return num
}
