import { useState } from "react";
import { Helmet } from "react-helmet-async"
import { useTranslation } from "react-i18next";
import { NavLink, Link, Outlet, useNavigate, useParams } from "react-router-dom";
import { PencilSquareIcon, PlusCircleIcon } from "@heroicons/react/24/outline";
import { Formik, Form, Field } from 'formik'
import _ from 'lodash'

import { countryCodes } from "../../data";
import { IconX } from "../../components/icons";
import { useDennemeyer, PcOwner, PcAddress } from "../DennemeyerProvider";
import { mark_as_dirty } from "../dennemeyer_backend";
import Modal from "../../components/Modal";
import { useMessages } from "../../Messages";
import clsx from "clsx";

export default function Owners() {
    const {t} = useTranslation()
    const { owners } = useDennemeyer()

    const linkStyle = ({ isActive }) => clsx(
        "rounded-md bg-white p-2 hover:text-pcx-400 group shadow hover:shadow-md inline-flex items-center gap-2 ",
        isActive ? "text-pcx-500" : "text-slate-700",
    )

    return <>
        {/* @ts-ignore */}
        <Helmet>
            <title>{t('owners')} | {t('renewal-fees')} | Patent Cockpit</title>
        </Helmet>

        <div className="main-content bg-pcx-100">
            <div className="flex flex-row gap-4">
                <div className="hidden only:flex lg:flex flex-col gap-2 w-full sm:w-80">
                    {_(owners)
                        .sortBy(o => o.name)
                        .map(owner =>
                            <NavLink
                                key={owner.ownerId} to={`edit/${owner.ownerId}`}
                                className={linkStyle}
                            ><PencilSquareIcon className="h-6 w-6 " />{owner.name}</NavLink>
                        ).value()}
                    <Link
                        to='add'
                        className="rounded-md bg-white p-2 hover:text-pcx-500 shadow hover:shadow-md inline-flex items-center gap-2 text-pcx-700"
                    ><PlusCircleIcon className="h-6 w-6" />{t('add')}</Link>
                </div>
                <Outlet />
            </div>
        </div>
    </>
}


const valid_countries = countryCodes.slice(2)

export function OwnerEdit({isAdding = false}) {
    const {t} = useTranslation()
    let navigate = useNavigate()

    const {
        owners,
        postOwner, postAddress, postOwnerAddress,
        deleteOwner, deleteAddress,
        addressById, addressesByOwner,
        invalidateIpRights,
    } = useDennemeyer()
    const { setErrorMessage } = useMessages()

    let { id } = useParams()

    const [showDeleteModal, setShowDeleteModal] = useState(false)

    function from_path() {
        const ownerId = parseInt(id)
        const owner = owners.find(o => o.ownerId === ownerId)
        //console.log({ownerAddresses, addressesByOwner, addressById})
        const link = addressesByOwner[ownerId]?.[0] // For the moment we assume that there is only one type of address (LegalAddress)
        const address = addressById[link?.addressId]
        if (address === undefined)
            console.warn(`Address not found for owner ${owner?.name} (${ownerId})`)
        return {...owner, ...address}
    }

    const initialValues = isAdding
        ? {name: '', ipEmail: '', address1: '', address2: '', city: '', state: '', postCode: '', countryCode: 'DE'}
        : from_path()
    return (
        <div className="rounded-lg bg-white shadow w-full sm:w-fit">
            <Formik 
                initialValues={initialValues}
                enableReinitialize
                onSubmit={
                    async (values, {resetForm}) => {
                        const owner = await postOwner(values) as PcOwner
                        const address = await postAddress(values) as PcAddress
                        if (isAdding) {
                            await postOwnerAddress({ownerId: owner.ownerId, addressId: address.addressId, addressType: 'LegalAddress'})
                            navigate(`../edit/${owner.ownerId}`)
                        } else {
                            await mark_as_dirty('owners', owner.ownerId)
                            await mark_as_dirty('addresses', owner.ownerId)
                            invalidateIpRights()
                            resetForm({values})
                        }
                    }
                }
            >{({dirty}) =>  
                <Form>
                    <div className="flex flex-row justify-between p-4">
                        <h3>{isAdding ? t('add-owner-details') : t('edit-owner-details')}</h3>
                        <Link to=".."><IconX/></Link>
                    </div>
                    {!isAdding && <div className="mx-4 mb-4 info py-2">
                        {t('changes-affect-sync')}
                    </div>}
                    <div className="grid sm:grid-cols-2 gap-4 pt-0 p-4">

                        <label className="sm:col-span-2">
                            <div className="label mb-1">{t('company-name')}</div>
                            <Field name="name" className="form-input w-full" required autoFocus />
                        </label>
                        <label className="sm:col-span-2">
                            <div className="label mb-1">{t('ipEmail')}</div>
                            <Field name="ipEmail" className="form-input w-full" required type="email" />
                        </label>
                        <label className="sm:col-span-2">
                            <div className="label mb-1">{t('address1')}</div>
                            <Field name="address1" className="form-input w-full" required />
                        </label>
                        <label className="sm:col-span-2">
                            <div className="label mb-1">{t('address2')}</div>
                            <Field name="address2" className="form-input w-full" />
                        </label>
                        <label className="">
                            <div className="label mb-1">{t('post-code')}</div>
                            <Field name="postCode" className="form-input w-full" required />
                        </label>
                        <label className="">
                            <div className="label mb-1">{t('city')}</div>
                            <Field name="city" className="form-input w-full" required />
                        </label>
                        <label className="">
                            <div className="label mb-1">{t('state')}</div>
                            <Field name="state" className="form-input w-full" />
                        </label>
                        <label className="">
                            <div className="label mb-1">{t('country')}</div>
                            <Field name="countryCode" className="form-select" as="select">
                                {valid_countries.map(cc => <option key={cc} value={cc}>{cc}</option>)}
                            </Field>
                        </label>
                    </div>
                    <div className="p-4 xbg-pcx-200 flex flex-row-reverse gap-4">
                        <input type="submit" disabled={!dirty} className="btn-primary disabled:btn-disabled" value={isAdding ? t('add') : t('save')} />
                        <Link to=".." className="btn-secondary">{t('cancel')}</Link>

                        {!isAdding && <button type="button" className="btn-tertiary mr-auto" onClick={() => setShowDeleteModal(true)}>{t('delete')}</button>}
                    </div>
                </Form>
            }</Formik>
            {showDeleteModal &&
                <OwnerDelete
                    name={initialValues.name}
                    cancelAction={() => setShowDeleteModal(false)}
                    deleteAction={() => 
                        mark_as_dirty('owners', initialValues.ownerId)
                            .then(() => deleteAddress(initialValues))
                            .then(() => deleteOwner(initialValues))
                            .catch(err => setErrorMessage(err.message))
                            .finally(() => navigate('..'))}
                />}
        </div>
    )
}

function OwnerDelete({name, cancelAction = () => {}, deleteAction = () => {}}) {
    const {t} = useTranslation()
    return (
        <Modal escAction={cancelAction} blurClick={cancelAction}>
            <div className="p-4 font-semibold">
                {t('delete-owner', {name})}

            </div>
            <div className="p-4 bg-pcx-200 flex flex-row-reverse gap-4">
                <button type="button" className="btn-warn" onClick={deleteAction}>{t('delete')}</button>
                <button type="button" className="btn-secondary" onClick={cancelAction}>{t('cancel')}</button>
            </div>
        </Modal>
    )
}