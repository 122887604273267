import { useEffect, useState } from "react";
import clsx from "clsx";
import { Link, useNavigate, useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { QuestionMarkCircleIcon } from "@heroicons/react/24/outline";
import { Button, Popover, DialogTrigger, RadioGroup, Radio, Label } from 'react-aria-components';
import { CheckCircleIcon, CheckIcon, ExclamationTriangleIcon, PauseIcon, PlayIcon, StopIcon } from "@heroicons/react/24/solid";
import _ from 'lodash'

import { noHtml } from "../utils/strings";
import { useAuth } from "../user/Auth"
import { MaintenanceAction, useDennemeyer, useMaintenance, useInvalidateMaintenances, MaintenanceActionPhase, PcInstruction, SpecialInstruction, useSynchronize, useTimeline } from "./DennemeyerProvider";
import { DueDateStatus } from "./settings/instruction_timeline";
import { familyUrl, memberUrl } from "../patents/utils";
import { emptyStringAsUndefined } from "../utils/strings";
import { Member, Family } from "../patents/patents";
import { FamilyDetails } from "./components";
import Modal from "../components/Modal";
import { post_instructions } from "./dennemeyer_backend";
import { useClaimScopes } from "../products/products";
import { PlainImage } from "../components/Image";
import { usePatents } from "../patents/PatentsProvider";
import { useComments } from "../comments/CommentsProvider";
import { family_member } from "../data";

/**
 * Time Line
 * 
 * T - 5 months: renewal notice is being sent; now client can instruct
 * 
 * T - 3 months: last day for possible instruction
 * T - 2 months: invoice is being sent
 * 
 * T: Due Date
 * 
 */


export function InstructionDueDate({instructionDueDate, status, maintenanceAction}: {instructionDueDate: string, status: DueDateStatus, maintenanceAction?: MaintenanceAction}) {
    const {t} = useTranslation()

    if (instructionDueDate === undefined) return null

    const isDueDateClose = status === 'critical' && !maintenanceAction.PermanentOrder
    const isPastDueDate = (status === 'manual-instruction' || status === 'too-late') && !maintenanceAction.PermanentOrder

    //console.log({status, isDueDateClose, isPastDueDate, isNeededToContactDm})

    const statusBubble = <div className={clsx(
        isPastDueDate
        ? "bg-warn-400/20 text-warn-400" 
        : isDueDateClose ? "bg-yellow-400/20 text-yellow-400"
        : "bg-pcx-400/20 text-pcx-400",
        "p-0 rounded-full block")}
    >
        <div className="w-2 h-2 bg-current rounded-full" />
    </div>

    return (
        <>
            <div className="my-auto">{statusBubble}</div>
            <div
                title={t('due')}
                className={clsx(
                    "tabular-nums font-semibold md:text-lg inline-flex items-center gap-2",
                    isPastDueDate ? "text-warn-700" :
                        isDueDateClose ? "text-yellow-700" :
                            "text-pcx-900",
                )}>
                {instructionDueDate}
            </div>
            <div className={
                clsx(
                    isPastDueDate ? "text-warn-900" : "text-slate-600",
                    "text-sm align-middle whitespace-nowrap")}>
                {isPastDueDate
                    ? t('past-instruction-due-date')
                    : t('instruction-due')}
            </div>
        </>
    )
}

const dm_email = 'taxes@dennemeyer.com'

function emailSubject(maintenance: MaintenanceAction) {
    const uck = maintenance.IpRightInfo?.UniqueCaseKey
    const reference = maintenance.IpRightInfo?.CustomerReference
    const s = [uck, reference].filter(s => s !== undefined).join(' / ')

    const subject = `Late Fee${s.length > 0 ? ':' : ''} ${s}`
    return `Subject=${encodeURIComponent(subject)}`
}

function ManualEmailLink({maintenanceAction, className = undefined}: {maintenanceAction: MaintenanceAction, className?: string}) {
    return (
        <a 
            href={`mailto:${dm_email}?${emailSubject(maintenanceAction)}`}
            className={className}
        >
            {dm_email}
        </a>
    )
}

export function ActionView({status, maintenanceAction}: {status: DueDateStatus, maintenanceAction?: MaintenanceAction}) {
    const {t} = useTranslation()
    if (maintenanceAction === undefined || status === 'too-late' || maintenanceAction.DennemeyerId === undefined) {
        return null
    } else {
        return (
            <Link className="btn-secondary text-sm w-fit h-fit" to={`/renewals/maintenances/instruct/${maintenanceAction.DennemeyerId}`}>
                {maintenanceAction?.PermanentOrder ? t('edit') : t('instruct')}
            </Link>
        )
    }
}

export function FeeAmount({amount, currency}: {amount: number | string, currency: string}) {
    return (
        <div className='md:text-lg font-semibold text-pcx-900 tabular-nums whitespace-nowrap self-end'>
            {amount} <span className="text-slate-500 font-normal text-sm">{currency}</span>
        </div>
    )
}

const autoPayPhases: Partial<Record<MaintenanceActionPhase, string>> = {
    "Auto-Pay in Progress": "fee-will-be-paid-automatically",
    "Instructed / In Progress": "fee-will-be-paid-automatically",
    "Closed": "fee-has-been-paid-automatically",
    "Waiting For Instruction": "fee-will-not-be-paid-automatically-instruct-with-pay-to-pay",
    "Inactive": "fee-has-not-been-paid",
}

const manualPhases: Partial<Record<MaintenanceActionPhase, string>> = {
    "Waiting For Instruction": "instruct-or-the-fee-will-not-be-paid",
    "Instructed / In Progress": "instructed-this-fee-will-be-paid",
    "Closed": "fee-has-been-paid",
    "Inactive": "fee-will-not-be-paid",
}

const icons = {
    "Auto-Pay in Progress": <PlayIcon className="w-5 h-5 text-pcx-500" />,
    "Instructed / In Progress": <PlayIcon className="w-5 h-5 text-pcx-500" />,
    "Closed": <CheckIcon className="w-5 h-5 text-pcx-500" />,
    "Waiting For Instruction": <PauseIcon className="w-5 h-5 text-pcx-300" />,
    "Inactive": <StopIcon className="w-5 h-5 text-warn-300" />,
    "Unknown": <QuestionMarkCircleIcon className="w-5 h-5 text-slate-500" />,
}

const instructionLabels = {
    'Pay': 'ip-right-pay',
    'Cancel': 'ip-right-cancel',
    'Hold': 'ip-right-hold',
    'Skip': 'ip-right-skip',
}

export function extractStatusDescription(
    {maintenanceAction, status, instruction, t}: 
    {maintenanceAction: MaintenanceAction, status: DueDateStatus, instruction?: PcInstruction, t: (s: string, options?: object) => string}
) {
    let phase = (maintenanceAction.PermanentOrder ? autoPayPhases[maintenanceAction.Phase] : manualPhases[maintenanceAction.Phase]) ?? maintenanceAction.Phase
    let icon = icons[maintenanceAction.Phase] ?? null

    if (!maintenanceAction.PermanentOrder && status === 'too-late' && maintenanceAction.Phase === 'Waiting For Instruction') {
        phase = 'fee-will-not-be-paid'
        icon = icons['Inactive']
    }

    if (instruction !== undefined) {
        phase = t('instructed-on', {date: instruction.created.slice(0, 10)}) + ': ' + t(instructionLabels[instruction.instruction])
        if (instruction.instruction === 'Pay') {
            icon = icons['Closed']
        } else if (instruction.instruction === 'Skip' || instruction.instruction === 'Cancel') {
            icon = icons['Inactive']
        } else if (instruction.instruction === 'Hold') {
            icon = icons['Waiting For Instruction']
        }
    } else {
        phase = t(phase)
    }

    return [phase, icon]
}

export function StatusView({maintenanceAction, status, instruction}: {maintenanceAction: MaintenanceAction, status: DueDateStatus, instruction?: PcInstruction}) {
    const {t} = useTranslation()

    const [phase, icon] = extractStatusDescription({maintenanceAction, status, instruction, t})

    return <div className="relative pl-6 text-left text-sm text-pcx-800" title={'Payment Provider: ' + maintenanceAction?.Phase}>
        <div className="absolute left-0">
            {icon ?? <div className="w-5 h-5" />}
        </div>
        {phase}
    </div>
}


export function ExpiryRibbon({member}: {member: Member}) {
    const {t} = useTranslation()
    return member.expiryDate ? <div className="text-xs ribbon rounded-sm w-fit"><span className="text-slate-500">{t('expires')}:</span> {member.expiryDate}</div> : null
}


export function FamilyGroupRow({family}: {family: Family}) {
    return (
        <tr className="first:border-t-0 border-t-2 border-pcx-300">
            <td className="pt-2 md:px-2 " colSpan={4}>
                <h3 className="text-pcx-600 hover:text-pcx-500 pb-1">
                    <Link to={familyUrl(family)}>{family.internalReference}: {family.familyName}</Link>
                </h3>

                <FamilyDetails family={family} />
            </td>
        </tr>
    )
}

export function ClaimScopeGroupRow({claimScopeIds, family, claimScopeGroupIndex = undefined}: {claimScopeIds: number[], family: Family, claimScopeGroupIndex?: number}) {
    const {t} = useTranslation()
    const {claimscopeById, membersByClaimScopeId} = useClaimScopes()

    //console.log({family})

    if (claimScopeIds === undefined || claimScopeIds.length === 0) 
        return null

    return (
        <tr className="border-t-2 border-pcx-300">
            <td className="pt-2 px-5 md:pl-10" colSpan={4}>
                <h4 className="text-pcx-600 hover:text-pcx-500">
                    <Link to={familyUrl(family)}>{family.internalReference} - {t('claimScope')} {claimScopeGroupIndex + 1}</Link>
                </h4>

                <div className="flex flex-row gap-4 max-w-[80rem] overflow-x-auto">
                {claimScopeIds.map(id =>  {
                    const claimScope = claimscopeById[id]
                    const members = membersByClaimScopeId[id] ?? []
                    return claimScope && members.length > 0 && (
                        <div key={id} className="flex flex-row gap-2 md:gap-4 items-start" title={noHtml(claimScope.claimScopeSummary)}>
                            <div className="w-44 h-32 p-1">
                                <PlainImage entity={"claim-scope"} entityId={id} title={undefined} text={undefined} />
                            </div>
                            <div className="w-80 text-slate-500 text-sm pt-1">
                                <h5 className="pb-1 text-slate-600 text-base">{t('claim-noun')} {members[0]?.claim?.claimNumber ?? ''}</h5>
                                <div className="" dangerouslySetInnerHTML={{ __html: claimScope.claimScopeSummary }}></div>
                            </div>
                        </div>
                    )
                })}
                </div>
            </td>
        </tr>
    )
}


// Permanent Payment
//   - Auto Pay in Progress (without instruction)
//     ->  [~Pay] -> 'Will be Paid' (= Auto Pay in Progress | Instructed / In Progress)
//     ->  [Hold] -> Waiting for Instructions
//     ->  [Cancel] -> Inactive
//   - Waiting for Instruction 
//     -> [Pay] -> Instructed / In Progress
//     -> [Cancel] -> Inactive
//   - 'Will be Paid' (Auto Pay in Progress with custom Pay instruction)
//      -> [Undecide] -> (remove instruction) -> Auto Pay in Progress 
//      -> [Hold] -> Waiting for Instructions
//      -> [Cancel] -> Inactive
//   - Instructed / In Progress
//     (no actions)
//   - Inactive
//     (no actions)

// Manual Instructions
//   - Waiting for Instruction (without instruction)
//     -> [Pay] -> Instructed / In Progress
//     -> [Cancel] -> Inactive
//   - 'Will not be paid' (Waiting for Instruction with custom Skip instruction)
//     -> [Pay] -> Instructed / In Progress
//     -> [Cancel] -> Inactive
//   - Inactive
//     (no actions)
//   - Instruction / In Progress
//     (no actions)

interface MaintenanceActionInstruction {
    label: string,
    value: SpecialInstruction, // -> instruction
    comment?: string,
    pcOnly?: boolean,
    indication?: 'warn' | 'green',
    warn?: boolean,
}

function createActions(
    isPermanentOrder: boolean,
    phase: MaintenanceActionPhase,
    instruction: PcInstruction | undefined,
    t: (word: string) => string
): MaintenanceActionInstruction[] {

    if (isPermanentOrder) {
        if (phase === 'Instructed / In Progress' || phase === 'Inactive') {
            // Let's still alos setting explicit PAY instruction on PC side
            return [
                { label: t('ip-right-pay'), value: 'Pay', comment: t('ip-right-pay-comment'), pcOnly: true, indication: 'green' },
                { label: t('ip-right-cancel'), value: 'Cancel', comment: t('ip-right-cancel-comment'), indication: 'warn' },
            ]
        } else if (phase === 'Auto-Pay in Progress' && instruction?.instruction === 'Pay') {
            return [
                { label: t('ip-right-undecide'), value: 'Undecide', comment: t('ip-right-undecide-comment'), pcOnly: true },
                //{ label: t('ip-right-hold'), value: 'Hold', comment: t('ip-right-hold-comment') },
                { label: t('ip-right-cancel'), value: 'Cancel', comment: t('ip-right-cancel-comment'), indication: 'warn' },
            ]
        } else if (phase === 'Waiting For Instruction') {
            return [
                { label: t('ip-right-pay'), value: 'Pay', comment: t('ip-right-pay-comment'), indication: 'green' },
                { label: t('ip-right-cancel'), value: 'Cancel', comment: t('ip-right-cancel-comment'), indication: 'warn' },
            ]
        } else { // Assume 'Auto Pay in Progress' without instruction
            return [
                { label: t('ip-right-pay'), value: 'Pay', comment: t('ip-right-pay-comment'), pcOnly: true, indication: 'green' },
                //{ label: t('ip-right-hold'), value: 'Hold', comment: t('ip-right-hold-comment') },
                { label: t('ip-right-cancel'), value: 'Cancel', comment: t('ip-right-cancel-comment'), indication: 'warn' },
            ]
        }
    } else { // manual
        if (phase === 'Instructed / In Progress' || phase === 'Inactive') {
            // No action means nothing we can do
            return []
        } else if (phase === 'Waiting For Instruction' && instruction?.instruction === 'Skip') {
            return [
                //{ label: t('ip-right-undecide'), value: 'Undecide', comment: t('ip-right-undecide-comment'), pcOnly: true },
                { label: t('ip-right-pay'), value: 'Pay', comment: t('ip-right-pay-comment'), indication: 'green' },
                { label: t('ip-right-cancel'), value: 'Cancel', comment: t('ip-right-cancel-comment'), indication: 'warn' },
            ]
        }
        else { // (phase === 'Waiting For Instruction') 
            return [
                //{ label: t('ip-right-skip'), value: 'Skip', comment: t('ip-right-skip-comment'), indication: 'warn', pcOnly: true },
                { label: t('ip-right-pay'), value: 'Pay', comment: t('ip-right-pay-comment'), indication: 'green' },
                { label: t('ip-right-cancel'), value: 'Cancel', comment: t('ip-right-cancel-comment'), indication: 'warn' },
            ]
        }
    }
}


export function MaintenanceModal() {
    const { t } = useTranslation()
    const {user: {name: user}} = useAuth()
    const {memberById, familyById, postMember} = usePatents()
    const {ipRightByDennemeyerId, postIpRight, invalidateIpRights, instructionByDennemeyerId, postInstruction, deleteInstruction} = useDennemeyer()
    const { calculateDueDates } = useTimeline()
    const {triggerSynchronize} = useSynchronize()
    const {invalidateMaintenances} = useInvalidateMaintenances()
    const {addComment} = useComments()

    const [comment, setComment] = useState('')

    let navigate = useNavigate()
    let { id } = useParams()

    const {maintenance} = useMaintenance(id) // maintenance can be undefined
    const isPermanentOrder = maintenance?.PermanentOrder ?? false
    //console.log({maintenance})

    const ipRight = ipRightByDennemeyerId[maintenance?.IpRightInfo?.DennemeyerId]
    const member = memberById[ipRight?.familyMemberId]
    const family = familyById[member?.patentFamilyId]

    const instruction = instructionByDennemeyerId[maintenance?.DennemeyerId]

    const actions = createActions(isPermanentOrder, maintenance?.Phase, instruction, t)
    const [value, setValue] = useState(undefined as MaintenanceActionInstruction | undefined)
    useEffect(() => {
        if (instruction !== undefined && value === undefined) {
            const action = actions.find(a => a.value === instruction.instruction)
            if (action !== undefined)
                setValue(actions.find(a => a.value === instruction.instruction) ?? undefined)
        }
    }, [actions, instruction, value])

    const [doStopPcCase, setDoStopPcCase] = useState(true)
    const [doStopDmCase, setDoStopDmCase] = useState(true)
    const isNotPayingFee = value?.value === 'Cancel' || value?.value === 'Skip'

    if (ipRight === undefined || member === undefined || maintenance === undefined) return null
    //console.log({ipRight})

    //const {fees} = calcFees(maintenance)

    async function stoppingCases() {
        if (isNotPayingFee) {
            if (doStopPcCase && member !== undefined) {
                await postMember({...member, familyMemberStatus: "stopped"})
            }
            if (doStopDmCase && ipRight !== undefined) {
                await postIpRight({...ipRight, status: "Inactive"})
                await triggerSynchronize([ipRight.ipRightId])
            }
        }
    }

    async function markInstruction() {
        if (value !== undefined) {
            if (instruction !== undefined) {
                await deleteInstruction(instruction)
            }
            if (value.value !== 'Undecide') {
                await postInstruction({dennemeyerId: maintenance.DennemeyerId, instruction: value.value, ipRightId: ipRight.ipRightId})
            }

            if (emptyStringAsUndefined(comment) !== undefined) {
                await addComment({entity: family_member, entityId: ipRight.familyMemberId, comment: comment, user, created: new Date().toISOString()})
            }

            await stoppingCases()
            navigate(-1)
        }
    }

    async function handleInstruction() {
        if (value !== undefined) {
            if (value.pcOnly !== true && value.value !== 'Undecide') {
                await post_instructions([
                    { DennemeyerId: maintenance.DennemeyerId, Instruction: value.value }
                ])
            }
            if (instruction !== undefined) {
                await deleteInstruction(instruction)
            }
            if (value.value !== 'Undecide') {
                await postInstruction({dennemeyerId: maintenance.DennemeyerId, instruction: value.value, ipRightId: ipRight.ipRightId})
            }

            if (emptyStringAsUndefined(comment) !== undefined) {
                await addComment({entity: family_member, entityId: ipRight.familyMemberId, comment: comment, user, created: new Date().toISOString()})
            }

            await stoppingCases()

            invalidateIpRights()
            invalidateMaintenances()

            navigate(-1)
        }
    }

    const {status, /*instructionDueDate*/} = calculateDueDates(maintenance.DueDate)

    const needManualInstruction = (
        status === 'manual-instruction'
        || maintenance.Phase === 'Instructed / In Progress'
    ) && value !== undefined && value.pcOnly !== true

    return (
        <Modal escAction={() => navigate('..')}>
            <div className="p-4 pb-0 max-md:max-w-[95vw] max-w-[80vw] space-y-4">
                <h3 className="grow min-w-0 whitespace-nowrap text-ellipsis overflow-hidden max-w-2xl">
                    <Link to={memberUrl(member)}>{t('instruct-name', {
                        name: emptyStringAsUndefined(family.familyName)
                            ? `${member.internalReference} - ${family.familyName}`
                            : member.internalReference
                    })}</Link>
                </h3>
            </div>
            <div className="p-4 flex flex-col md:flex-row gap-4">
                <SelectDecision {...{ instruction, actions, value, setValue, maintenanceAction: maintenance, label: t('take-decision'), contactWarning: t('no-instruction-possible-contact') }} />
                <div className="flex flex-col gap-2">
                    <h4 className="">{t('comment')} <span className="text-slate-400">({t('optional')})</span></h4>
                    <textarea className="w-full form-textarea border-gray-300 grow" value={comment} onChange={e => setComment(e.target.value)} />
                </div>
            </div>
            <div className={clsx(
                "px-4 pb-2 grid grid-cols-[min-content_1fr] items-center gap-2 text-sm",
                isNotPayingFee ? "text-slate-600" : "text-slate-400"
            )}>
                <input type="checkbox" className="form-checkbox" disabled={!isNotPayingFee} id="stop-pc-case" checked={isNotPayingFee && doStopPcCase} onChange={e => setDoStopPcCase(e.target.checked)} />
                <label htmlFor="stop-pc-case" className="xlabel">{t('stop-pc-case')}</label>

                <input type="checkbox" className="form-checkbox" disabled={!isNotPayingFee} id="stop-dm-case" checked={isNotPayingFee && doStopDmCase} onChange={e => setDoStopDmCase(e.target.checked)} />
                <label htmlFor="stop-dm-case" className="xlabel">{t('stop-dm-case')}</label>
            </div>
            {needManualInstruction &&
                <div className="py-2 px-4 w-full">
                    <div className="p-2 px-4 bg-red-200 rounded shadow mx-auto flex flex-row gap-6 items-center w-full">
                        <ExclamationTriangleIcon className="h-8 w-8 inline-block text-warn-700" />
                        <div>
                            <p>
                                No instruction possible anymore. Please contact Dennemeyer by Email.
                            </p>
                            <p>
                                Email: <ManualEmailLink className="btn-tertiary px-0" {...{maintenanceAction: maintenance}} />
                            </p>
                        </div>
                    </div>
                </div>}
            <div className="p-4 bg-pcx-200 flex flex-col sm:flex-row-reverse gap-4">
                {needManualInstruction
                    ? OnlyMarkDecision({onClick: markInstruction, t})
                    : <button type="submit" className="btn-primary disabled:btn-disabled" onClick={handleInstruction} disabled={value === undefined}>
                        {t('instruct')}
                    </button>
                }
                {/* @ts-ignore */}
                <Link to={-1} className="btn-tertiary mx-auto sm:mx-0">{t('back')}</Link>
            </div>
        </Modal>
    )
}

function OnlyMarkDecision({onClick, t}) {
    //const { t } = useTranslation()
    return (
        <DialogTrigger>
            <Button className='btn-tertiary px-0'>
                {t('only-mark-decision')}
            </Button>
            <Popover>
                <div className="p-4 border border-pcx-600 shadow rounded bg-white h-fit">
                    <p className='font-medium pb-2 text-slate-700'>
                        {t('are-you-sure')}
                    </p>
                    <button type="submit" className="btn-secondary" {...{ onClick }}>
                        {t('only-mark-decision')}
                    </button>
                </div>
            </Popover>
        </DialogTrigger>
    )
}

function selectionColors(isSelected: boolean, isHovered: boolean, indication?: 'warn' | 'green') {
    //console.log({indication})
    if (!isSelected && !isHovered)
        return ['bg-white text-slate-700']
    switch (indication) {
        case 'warn':
            return [
                isHovered && 'text-slate-100 bg-warn-500 border-warn-500',
                isSelected && !isHovered && 'text-slate-100 bg-warn-600 border-warn-600',
            ]
        case 'green':
            return [
                isHovered && 'text-slate-100 bg-green-500 border-green-500',
                isSelected && !isHovered && 'text-slate-100 bg-green-600 border-green-600',
            ]
        default:
            return [
                isHovered && 'text-slate-100 bg-pcx-500 border-pcx-500',
                isSelected && !isHovered && 'text-slate-100 bg-pcx-600 border-pcx-600',
            ]
    }
}

function SelectDecision(
    {actions, value, setValue, maintenanceAction, label, contactWarning}: 
    {actions: MaintenanceActionInstruction[], value: MaintenanceActionInstruction, setValue: (action: MaintenanceActionInstruction) => void, maintenanceAction: MaintenanceAction, label: string, contactWarning: string}) {
    const actionsByValue = _.keyBy(actions, 'value')
    return (
        <RadioGroup onChange={v => setValue(actionsByValue[v])} value={value?.value} className='flex flex-col gap-2'>
            <Label className="text-pcx-800 font-semibold text-xl">{label}</Label>
            <div className="hidden last:block md:w-96">
                <p> 
                    {contactWarning}: <ManualEmailLink className="btn-tertiary px-0" {...{maintenanceAction}} />.
                </p>
            </div>
            {actions.map((action) => 
                <Radio key={action.value} value={action.value} >{({ isSelected, isHovered }) => {
                    const { label, comment, indication } = action
                    return <div className={clsx(
                        'py-2 px-4 rounded shadow-md md:w-96 cursor-pointer flex flex-row gap-6 items-center border border-slate-200',
                        ...selectionColors(isSelected, isHovered, indication),
                    )}>
                        <div className={clsx(!isSelected && !isHovered && "text-slate-300")}>
                            {indication === 'warn' ? <ExclamationTriangleIcon className="h-8 w-8" /> : <CheckCircleIcon className="h-8 w-8" />}
                        </div>
                        <div>
                            <p className={clsx("font-semibold mb-px")}>{label}</p>
                            <p className={clsx("text-sm pt-0 opacity-80 mb-0")}>{comment}</p>
                        </div>
                    </div>
                }}</Radio>
            )}
        </RadioGroup>
    )
}

// Test cases
// 1. Permanent Order
//  1.1 Auto Pay in Progress
/*
        {
          "DennemeyerId" : "ce8ae042-cf4c-4387-bf47-c642fe4c8b6c",
          "IpRightInfo" : {
            "DennemeyerId" : "860d9bc7-6981-4509-80fa-06aef820bf2c",
            "UniqueCaseKey" : "214",
            "Type" : "Patent",
            "SubType" : "patent",
            "CountryCode" : "DE",
            "ApplicationNumber" : "15721532-8",
            "GrantNumber" : "602015014742-1",
            "CustomerReference" : "P3999 EP-DE",
            "Status" : "Granted/Registered"
          },
          "ActionType" : "Annuity",
          "DueDate" : "2024-05-04",
          "Annuity" : 10,
          "Status" : "Auto-Pay in Progress",
          "Phase" : "Auto-Pay in Progress",
          "PermanentOrder" : true,
          "FeesEstimated" : [
            {
              "RNNumber" : 1599461,
              "RNDennemeyerId" : "c3f9d93a-e82b-44d8-bfdc-f826967c221e",
              "IssueDate" : "2023-11-24",
              "Currency" : "EUR",
              "TotalFee" : 445.00
            }
          ],
          "FeesActual" : [
          ]
        },

        // INACTIVE
                {
          "DennemeyerId" : "40923b09-6c80-46a7-b747-90212fdfb9f2",
          "ActionType" : "Annuity",
          "DueDate" : "2023-07-16",
          "Annuity" : 3,
          "Status" : "Inactive",
          "Phase" : "Inactive",
          "PermanentOrder" : true,
          "FeesEstimated" : [
            {
              "RNNumber" : 1594789,
              "RNDennemeyerId" : "1eb6c6ad-3cf1-4e43-a8c8-19669f7fb176",
              "IssueDate" : "2023-11-15",
              "Currency" : "EUR",
              "TotalFee" : 545.00
            }
          ],
          "FeesActual" : [
          ]
        }

        // INSTRUCTED
                {
          "DennemeyerId" : "ca193c11-0995-4579-8636-72e05c773a17",
          "ActionType" : "Annuity",
          "DueDate" : "2024-04-07",
          "Annuity" : 4,
          "Status" : "Instructed / In Progress",
          "Phase" : "Instructed / In Progress",
          "PermanentOrder" : true,
          "FeesEstimated" : [
            {
              "RNNumber" : 1594805,
              "RNDennemeyerId" : "d6c5a51f-7323-41e9-891f-0af2f6ff2e9c",
              "IssueDate" : "2023-11-15",
              "Currency" : "EUR",
              "TotalFee" : 675.00
            }
          ],
          "FeesActual" : [
          ]
        }
*/
//  1.2 Waiting for Instruction