import {useState} from "react";
import { NavLink, Link, Outlet, useNavigate, useParams } from "react-router-dom";
import { PencilSquareIcon, PlusCircleIcon } from "@heroicons/react/24/outline";
import { Helmet } from "react-helmet-async"
import { useTranslation } from "react-i18next"
import {Formik, Form, Field} from "formik"
import clsx from "clsx";
import _ from 'lodash'

import { IconX } from "../../components/icons";
import Modal from '../../components/Modal'
import { PcCostCenter, useDennemeyer } from "../DennemeyerProvider";
import { useMessages } from "../../Messages";
import { mark_as_dirty } from "../dennemeyer_backend";

export default function CostCenters() {

    const {t} = useTranslation()

    const {costCenters} = useDennemeyer()

    const linkStyle = ({ isActive }) => clsx(
        "rounded-md bg-white p-2 hover:text-pcx-400 group shadow hover:shadow-md inline-flex items-center gap-2 ",
        isActive ? "text-pcx-500" : "text-slate-700",
    )

    return <>
        {/* @ts-ignore */}
        <Helmet>
            <title>{t('credentials')} | {t('renewal-fees')} | Patent Cockpit</title>
        </Helmet>
        <div className="main-content bg-pcx-100">
            <div className="flex flex-row gap-4">
                <div className="hidden only:flex lg:flex flex-col gap-2 w-full sm:w-80">
                    {_(costCenters)
                        .sortBy(o => o.name)
                        .map(owner =>
                            <NavLink
                                key={owner.costCenterId} to={`edit/${owner.costCenterId}`}
                                className={linkStyle}
                            ><PencilSquareIcon className="h-6 w-6" />{owner.name}</NavLink>
                        ).value()}
                    <Link
                        to='add'
                        className="rounded-md bg-white p-2 hover:text-pcx-500 shadow hover:shadow-md inline-flex items-center gap-2 text-pcx-700"
                    ><PlusCircleIcon className="h-6 w-6" />{t('add')}</Link>
                </div>
                <Outlet />
            </div>
        </div>
    </>
}

export function CostCenterEdit({isAdding = false}) {
    const {t} = useTranslation()
    let navigate = useNavigate()

    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const {costCenters, postCostCenter, deleteCostCenter, invalidateIpRights} = useDennemeyer()
    const { setErrorMessage } = useMessages()

    let { id } = useParams()

    function from_path() {
        const costCenterId = parseInt(id ?? '0')
        return costCenters.find(o => o.costCenterId === costCenterId)
    }

    const initialValues = isAdding ? { name: '' } : from_path()

    if (initialValues === undefined) return null

    return (
        <div className="rounded-lg bg-white shadow w-full sm:max-w-md">
            <Formik
                initialValues={initialValues}
                enableReinitialize
                onSubmit={async (values, { resetForm }) => {
                    const costCenter = await postCostCenter(values) as PcCostCenter
                    if (isAdding) {
                        navigate(`../edit/${costCenter.costCenterId}`)
                    } else {
                        await mark_as_dirty('cost-centers', costCenter.costCenterId)
                        invalidateIpRights()
                        resetForm({values})
                    }
                }}
            >{({ dirty }) =>
                <Form>
                    <div className="flex flex-row justify-between p-4">
                        <h3>{isAdding ? t('add-cost-center') : t('edit-cost-center')}</h3>
                        <Link to=".."><IconX /></Link>
                    </div>
                    {!isAdding && <div className="mx-4 mb-4 info py-2">
                        {t('changes-affect-sync')}
                    </div>}
                    <div className="grid sm:grid-cols-2 gap-4 pt-0 p-4">
                        <label className="sm:col-span-2">
                            <div className="label mb-1">{t('name')}</div>
                            <Field name="name" className="form-input w-full" required autoFocus />
                        </label>
                    </div>
                    <div className="p-4 xbg-pcx-200 flex flex-row-reverse gap-4">
                        <input type="submit" disabled={!dirty} className="btn-primary disabled:btn-disabled" value={(isAdding ? t('add') : t('save')) as string} />
                        <Link to=".." className="btn-secondary">{t('cancel')}</Link>

                        {!isAdding && <button type="button" className="btn-tertiary mr-auto" onClick={() => setShowDeleteModal(true)}>{t('delete')}</button>}
                    </div>
                </Form>
            }</Formik>
            {showDeleteModal && 
                <CostCenterDelete 
                    name={initialValues.name}
                    cancelAction={() => setShowDeleteModal(false)}
                    deleteAction={() =>
                        mark_as_dirty('cost-centers', initialValues.costCenterId)
                            .then(() => deleteCostCenter(initialValues))
                            .catch(err => setErrorMessage(err.message))
                            .finally(() => navigate('..'))
                            }
                    />}
        </div>
    )
}

export function CostCenterDelete({name, cancelAction = () => {}, deleteAction = () => {}}) {
    const {t} = useTranslation()
    return (
        <Modal escAction={cancelAction} blurClick={cancelAction}>
            <div className="p-4 font-semibold">
                {t('delete-cost-center', {name})}

            </div>
            <div className="p-4 bg-pcx-200 flex flex-row-reverse gap-4">
                <button type="button" className="btn-warn" onClick={deleteAction}>{t('delete')}</button>
                <button type="button" className="btn-secondary" onClick={cancelAction}>{t('cancel')}</button>
            </div>
            </Modal>
    )
}