import React, { createRef, useState } from "react"
import { FieldHookConfig, Formik, Form, useField } from "formik"
import { useTranslation } from "react-i18next"
import { Combobox } from '@headlessui/react'
import { ChevronUpDownIcon, ArrowsPointingInIcon, ArrowsPointingOutIcon, CloudArrowUpIcon, CloudArrowDownIcon, PhotoIcon, PencilSquareIcon, StopIcon } from '@heroicons/react/24/solid'
import { FaArrowRotateLeft, FaArrowRotateRight } from "react-icons/fa6";
import AvatarEditor from 'react-avatar-editor'
// TODO: Replace Dropzone by https://react-dnd.github.io/react-dnd/examples/other/native-files
// and remove react-dropzone from deps
import Dropzone from 'react-dropzone'
import clsx from "clsx"
import _ from 'lodash'

import { sortImages } from "./image-utils"
import { scrapeEpoImage, uploadImage } from "../backend"
import { image_link } from "../data"
import { IconX, IconChevronLeft, IconChevronRight, IconExpand } from "./icons"
import Modal from "./Modal"
import { useMessages } from "../Messages"
import { DeleteButton } from "./edit-table"
import { useBackend } from "../BackendProvider"

export interface ImageEntry {
  realm: string,
  imageId: number,
  entity: string,
  originalFilename: string,
}

export interface ImageProperties {
  originalImage: string | File;
  name: string;
  type: string;
  croppedImage: string | undefined;
  position: { x: number; y: number };
  scale: number;
  rotate: number;
}

function EditorImageForm({ currentImage, isEdited, closeForm, entity, entityId, callback = (imageEntry: ImageEntry) => Promise.resolve() }) {
  const { t } = useTranslation()

  const editorRef: React.RefObject<AvatarEditor> = createRef()
  const [imageProperties, setImageProperties] = useState<ImageProperties>({
    originalImage: "",
    name: isEdited ? currentImage.originalFilename : "",
    type: "",
    croppedImage: undefined,
    position: { x: 0.5, y: 0.5 },
    scale: 1,
    rotate: 0
  })
  //console.log({ imageProperties, currentImage }) 

  const { originalImage, position, scale, rotate } = imageProperties;
  const currentFile = ({ currentImage }) => {

    function FileWithId(file, id) {
      this.file = file;
      this.id = id;
    }

    if (editorRef?.current) {
      const canvas = editorRef.current.getImageScaledToCanvas()
      canvas.toBlob(async (blob: Blob) => {
        let file: File

        if (isEdited) {
          const fileBlob = new File([blob], imageProperties.name, {
            type: currentImage ? "image/" + currentImage.originalFilename.split(".")[1] : "",
            lastModified: Date.now(),
          })
          file = new FileWithId(fileBlob, currentImage.imageId)
        } else {
          const fileBlob = new File([blob], imageProperties.name, {
            type: imageProperties.type,
            lastModified: Date.now(),
          })
          file = new FileWithId(fileBlob, null)
        }

        return uploadImage(file).then(imageEntry => callback(imageEntry))
      })
    }
  }

  const [editorFormFactor, setEditorFormFactor] = useState(1.4)

  const handleDrop = (dropped: File[]): void => {
    setImageProperties((prevState) => ({
      ...prevState,
      name: dropped[0].name,
      type: dropped[0].type,
      originalImage: dropped[0]
    }))
  }
  const handleRotate = (direccion: "left" | "right"): void => {
    setImageProperties((prevState) => ({
      ...prevState,
      rotate:
        direccion === "left"
          ? (prevState.rotate - 90) % 360
          : (prevState.rotate + 90) % 360
    }))
  }

  const handleZoom = (event: React.ChangeEvent<any>): void => {
    const scale = +event.target.value
    setImageProperties((prevState) => ({
      ...prevState,
      scale
    }))
  }
  const handleUpload = (event: React.ChangeEvent<any>): void => {
    setImageProperties((prevState) => ({
      ...prevState,
      name: event.target.files[0].name,
      type: event.target.files[0].type,
      originalImage: event.target.files[0]
    }))
  }

  const handlePositionChange = (position: ImageProperties["position"]) => {
    setImageProperties((prevState) => ({
      ...prevState,
      position
    }))
  }

  const handleSubmit = () => {
    currentFile({ currentImage })
  }

  return (
    <Modal>
      <div className="flex flex-col">
        <div className="p-4">
          <div className="flex flex-row">
            <h4>{t('select-image')}</h4>
            <div className="grow ml-2" />
            <button onClick={() => closeForm()} className="text-pc-600 border-2 rounded-full border-pc-600 text-center h-6 w-6"><IconX /></button>
          </div>
        </div>
        <div className="lg:flex lg:flex-row items-center p-4 gap-4">
          <div className="flex flex-col items-center justify-center lg:h-[calc(1.4*300px+50px)] lg:w-[calc(2.0*300px+40px)] relative">
            {(originalImage === "" && !isEdited) &&
              <div className="absolute inset-x-0 text-center align-middle text-lg font-semibold text-pcx-500 pointer-events-none uppercase">
                {t('drop-image-here')}
              </div>}
            <Dropzone onDrop={handleDrop} noClick noKeyboard>
              {({ getRootProps, getInputProps }) => (
                <div {...getRootProps()}>
                  <AvatarEditor
                    ref={editorRef}
                    color={[200, 200, 200, 0.6]}
                    scale={scale}
                    border={20}
                    width={300 * editorFormFactor}
                    height={300 / editorFormFactor}
                    crossOrigin="anonymous"
                    image={!isEdited ? originalImage : currentImage ? currentImage.url : currentImage}
                    rotate={rotate}
                    position={position}
                    onPositionChange={handlePositionChange}
                  />
                  <input {...getInputProps()} />
                </div>
              )}
            </Dropzone>
          </div>
          <div className="grid grid-cols-[5rem_1fr] gap-4 m-2">
            {!isEdited && (
              <label className="btn-secondary text-center py-2 text-xl col-span-2">{t('select-file')}
                <input
                  className="col-span-2 hidden"
                  type="file"
                  id={"file-upload-" + entity + "-" + entityId}
                  name={"file-upload-" + entity + "-" + entityId}
                  accept="image/*" onChange={handleUpload}
                />
              </label>)}
            <div className="label">{t('zoom')}:</div>
            <div className="grid grid-cols-[1rem_1fr_1rem] gap-1 items-center">
              <ArrowsPointingOutIcon className="w-4 h-4 text-pcx-600" />
              <input
                name="scale"
                type="range"
                onChange={handleZoom}
                min={0.5}
                max={2}
                step={0.01}
                defaultValue={scale}
              />
              <ArrowsPointingInIcon className="w-4 h-4 text-pcx-600" />
            </div>

            <div className="label">{t('form')}:</div>
            <div className="grid grid-cols-[1rem_1fr_1rem] gap-1 items-center">
              <div> <div className="border-2 border-pcx-600 h-4 w-2 mx-auto" /></div>
              <input
                name="editorFormFactor"
                type="range"
                onChange={e => setEditorFormFactor(+e.target.value)}
                min={0.7}
                max={2.0}
                step={0.1}
                value={editorFormFactor}
              />
              <div> <div className="border-2 border-pcx-600 h-2 w-4" /></div>
            </div>

            <div className="label">{t('rotate')}:</div>
            <div className="flex space-x-2">
              <button className="btn-secondary px-2 py-1 text-lg" onClick={() => handleRotate("left")} ><FaArrowRotateLeft  className="w-5 h-5" /></button>
              <button className="btn-secondary px-2 py-1 text-lg" onClick={() => handleRotate("right")}><FaArrowRotateRight className="w-5 h-5"/></button>
            </div>
            <label htmlFor="filename">{t('filename')}:</label>
            <input 
              className="form-input text-sm py-1" type="text" name="filename" 
              value={imageProperties.name} 
              onChange={(e) => setImageProperties((prevState) => ({ ...prevState, name: e.target.value }))} 
            />
          </div>
        </div>
        <div className="flex flex-row-reverse gap-4 p-4 bg-pcx-200">
          <input type="button" className="btn-primary" value={t('save')} onClick={handleSubmit} />
          <button type="button" className="btn-secondary" onClick={() => closeForm()}>{t('cancel')}</button>
        </div>
      </div>
    </Modal >
  )
}

function SelectImageForm({ selectableImages, closeForm, relink }) {
  const { t } = useTranslation()
  const {entityOperation} = useBackend()

  return (
    <Modal>
      <div className="flex flex-col p-4">
        <button className="btn-tertiary w-fit text-lg mb-2" onClick={() => closeForm()}>
          &lt; {t('back')}
        </button>
        <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-6 xl:grid-cols-8 gap-2">
          {selectableImages.map((image) => {
            return (
              <div key={image.imageId} className="relative h-36 w-36 px-px">
                <button className="border-2 border-pc-200 hover:border-pc-400 w-full" onClick={() => relink(image.imageId)} title={image.originalFilename}>
                  <div className="h-28 flex flex-row items-center">
                    <img className="mx-auto max-h-full p-1" src={image.url} alt={"Patent " + image.imageId} />
                  </div>
                  <div className="h-8 max-h-full truncate max-w-full">{image.originalFilename}</div>
                </button>
                {!image.entity &&
                  <div className="absolute top-0 right-0">
                    <DeleteButton {...{ del: () => entityOperation("image", "delete", image.imageId) }} />
                  </div>
                }
              </div>
            )
          }
          )}
        </div>
      </div>
      <div className="p-4 bg-pcx-200 flex flex-row-reverse">
        <button onClick={() => closeForm()} className="btn-secondary">{t('cancel')}</button>
      </div>
    </Modal>
  )
}

function EpoNumberField({ publicationNumbers = [], ...props }) {
  const [query, setQuery] = useState("")
  const [field, meta, helpers] = useField(props as FieldHookConfig<string>);

  //console.log({publicationNumbers, field, meta})

  const options = _(publicationNumbers)
    .filter(n => n.toUpperCase().includes(query))
    .uniq()
    .sortBy()
    .value()

  const showQueryAsOption = (!options.includes(query)) && (query.length > 0)

  return (
    <Combobox value={props.value ?? meta.value} onChange={helpers.setValue} name={field.name} as="div" className="relative">
      <Combobox.Input className="form-input" onChange={(event) => setQuery(event.target.value?.toUpperCase())} />
      <Combobox.Button className="absolute inset-y-0 right-0 flex items-center px-2 text-pcx-500">
        <ChevronUpDownIcon className="w-5 h-5" aria-hidden={true} />
      </Combobox.Button>
      <Combobox.Options
        className="absolute bg-white mt-2 border-2 border-pcx-300 rounded overflow-hidden list-none min-w-full shadow max-h-80 overflow-y-auto">
        {(showQueryAsOption ? [...options, query] : options)
          .map((number) => (
            <Combobox.Option key={number} value={number}>{({ active }) =>
              <div className={clsx("px-3 py-1 hover:bg-pcx-200", active && "bg-pcx-200")}>
                {number}
              </div>
            }</Combobox.Option>
          ))}
      </Combobox.Options>
    </Combobox>
  )
}

// relink: id => IO[delete?, add]
function EpoLoadForm({ relink, closeAction = () => { }, publicationNumbers = [] }) {
  const { t } = useTranslation()
  const { setErrorMessage } = useMessages()

  return (
    <Modal blurClick={closeAction}>
      <Formik
        initialValues={{ publicationNumber: "" }}
        onSubmit={({ publicationNumber }) => {
          scrapeEpoImage(publicationNumber)
            .then(({ imageId }) => relink(imageId))
            .then(() => closeAction)
            .catch(err => { setErrorMessage(err.message); closeAction() })
        }}
      >{({ isSubmitting }) =>
        <Form>
          <div className="flex flex-col gap-2 p-4">
            <h4 className="pb-2">{t('load-epo-image')}</h4>
            <label className="w-full">
              <div className="mb-2">{t('publicationNumber')}</div>
              <EpoNumberField className="form-input" name="publicationNumber" {...{ publicationNumbers }} />
            </label>
          </div>
          <div className="flex flex-row-reverse gap-4 p-4 sm:px-6 bg-pc-200">
            <input disabled={isSubmitting} className="btn-primary cursor-pointer" type="submit" value={t("load")} />
            <button disabled={isSubmitting} className="btn-secondary cursor-pointer" onClick={closeAction}>{t('cancel')}</button>
          </div>
        </Form>
        }</Formik>
    </Modal>
  )
}

function EditImageModal({ currentImage = undefined, entity, entityId, setShowPopup, epoLoad }) {
  const { images, entityOperation, linkOperation } = useBackend()
  //console.log(images)

  const loadImages = () => entityOperation("image", "get")

  const selectableImages = _.uniqBy(images, i => i.imageId).sort((a, b) => sortImages(a, b, entity))

  async function unlink() {
    return linkOperation(image_link, "delete", { imageId: currentImage.imageId, entity, entityId })
      .then(() => loadImages())
      .then(() => linkOperation(image_link, "get"))
      .then(() => setShowPopup(false))
  }

  async function relink(id: number) {
    if (currentImage !== undefined)
      return linkOperation(image_link, "delete", { imageId: currentImage.imageId, entity, entityId })
        .then(() => linkOperation(image_link, "add", { imageId: id, entity, entityId }))
        .then(() => loadImages())
        .then(() => setShowPopup(false))
    else
      return linkOperation(image_link, "add", { imageId: id, entity, entityId })
        .then(() => loadImages())
        .then(() => setShowPopup(false))
  }

  async function uploadCallback(imageEntry: ImageEntry) {

    if (currentImage?.imageId)
      return linkOperation(image_link, "delete", { imageId: currentImage.imageId, entity, entityId })
        .then(() => linkOperation(image_link, "add", { imageId: imageEntry.imageId, entity, entityId, realm: imageEntry.realm }))
        .then(() => { entityOperation("image", "get"); setShowPopup(false) })
    else
      return linkOperation(image_link, "add", { imageId: imageEntry.imageId, entity, entityId, realm: imageEntry.realm })
        .then(() => { entityOperation("image", "get"); setShowPopup(false) })
  }

  return (
    <ImageEditModalView {...{
      setShowEditModal: setShowPopup,
      currentImage,
      entity,
      entityId,
      unlink,
      relink,
      selectableImages,
      epoLoad,
      callback: uploadCallback,
    }} />
  )
}


interface ImageOperation {
  background: string;
  title: string,
  click: () => void,
  icon: any,
}

type ModalType = 'edit' | 'upload' | 'epo' | 'select' | undefined

function ImageEditModalView({
  setShowEditModal,
  currentImage,
  entity,
  entityId,
  unlink,
  relink,
  selectableImages,
  epoLoad,
  callback = (imageEntry: ImageEntry) => Promise.resolve(),
}) {
  const { t } = useTranslation()

  const [modalType, setModalTyp] = useState(undefined as ModalType)

  //console.log({epoLoad})
  // TODO: when updating make sure old images are deleted

  const items: ImageOperation[] = [
    {
      title: t("upload-image"),
      icon: CloudArrowUpIcon,
      background: 'bg-pcx-700',
      click: () => setModalTyp('upload'),
    },
    epoLoad !== undefined && {
      title: t("epo-load"),
      icon: CloudArrowDownIcon,
      background: 'bg-pcx-500',
      click: () => setModalTyp('epo'),
    },
    {
      title: t("select-image"),
      icon: PhotoIcon,
      background: 'bg-pcx-400',
      click: () => setModalTyp('select'),
    },
    currentImage !== undefined && {
      title: t("edit-image"),
      icon: PencilSquareIcon,
      background: 'bg-pcx-300',
      click: () => setModalTyp('edit'),
    },
    {
      title: t("no-image"),
      icon: StopIcon,
      background: 'bg-warn-300',
      click: () => unlink(true),
    },
  ].filter(Boolean)

  let modal = null
  if (modalType === 'upload' || modalType === 'edit') {
      modal = <EditorImageForm {...{
        currentImage,
        isEdited: modalType === 'edit',
        closeForm: () => setModalTyp(undefined),
        entity,
        entityId,
        callback: callback
    }} />
  } else if (modalType === 'epo') {
    modal = <EpoLoadForm {...{
      relink,
      closeAction: () => setModalTyp(undefined),
      publicationNumbers: epoLoad ?? [],
    }} />
  } else if (modalType === 'select') {
    modal = <SelectImageForm {...{
      selectableImages,
      closeForm: () => setModalTyp(undefined),
      relink,
    }} />
  }

  return (
    <>
      <Modal>
        <div className="p-4">
          <h4 className="mb-6">{t('edit-image')}</h4>
          <ul className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {items.map((item: ImageOperation, itemIdx) => (
              <li key={itemIdx} className="flow-root w-64">
                <div className="relative -m-2 flex items-center space-x-4 rounded-xl p-2 focus-within:ring-2 hover:bg-pcx-100 group">
                  <div
                    className={clsx(
                      item.background,
                      'flex-shrink-0 flex items-center justify-center h-16 w-16 rounded-lg'
                    )}
                  >
                    <item.icon className="h-8 w-8 text-white" aria-hidden="true" />
                  </div>
                  <div>
                    <h4 className="text-sm">
                      <button onClick={item.click} className="focus:outline-none">
                        <span className="absolute inset-0" aria-hidden="true" />
                        <h6 className="group-hover:text-gray-900">{item.title}</h6>
                      </button>
                    </h4>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
        <div className="p-4 bg-pcx-200 flex flex-row-reverse">
            <button onClick={() => setShowEditModal(false)} className="btn-secondary">{t('cancel')}</button>
        </div>
      </Modal>
      {modal}
    </>
  )
}

function ImageModal({ imageUrl, setShowImageModal, title = undefined, text }:
  { imageUrl: string, setShowImageModal: (t: boolean) => void, title?: string, text?: string }) {
  return (
    <Modal blurClick={() => setShowImageModal(false)}>
      <div className=" bg-white max-w-screen flex flex-col gap-3 p-4">
        <div className="flex flex-row-reverse justify-between items-center">
          <button className="btn-neutral rounded-full p-2" onClick={() => setShowImageModal(false)}><IconX /></button>
          {title && <h3 className="truncate max-w-[80vw]">{title}</h3>}
        </div>
        <div className="flex flex-col gap-2">
          <div>
            <img className="max-h-[80vh] mx-auto" src={imageUrl} alt={"Image for " + imageUrl} />
          </div>
          {text && <div className="py-2 max-w-prose" dangerouslySetInnerHTML={{ __html: text }} />}
        </div>
      </div>
    </Modal>
  )
}

function getBackgroundColor(stringInput) {
  let stringUniqueHash = [...stringInput].reduce((acc, char) => {
    return char.charCodeAt(0) + ((acc << 5) - acc);
  }, 0);
  return `hsl(${stringUniqueHash % 360},45%,75%)`;
}

export function ProfileImage({ userId, user }) {
  const { imagesLookup } = useBackend()

  const image = imagesLookup['user']?.[+userId]
  const imageUrl = image?.url

  return typeof (imageUrl) === 'string'
    ? <div className="w-full h-full flex flex-row items-center">
      <img className="max-h-full mx-auto" src={imageUrl} alt={"Image for " + user} loading="lazy" />
    </div>
    : <div className="w-full h-full" style={{ background: getBackgroundColor(user) }} />
}

export function PlainImage({ entity, entityId, title = undefined, text = undefined, fallback = undefined, clickable = true, hint = undefined }) {
  const { t } = useTranslation()
  const { imagesLookup, hasLoaded } = useBackend()

  const [showImageModal, setShowImageModal] = useState(false)
  var image = imagesLookup[entity]?.[entityId]
  if (image === undefined && fallback !== undefined) {
    let fb = imagesLookup[fallback.entity]?.[fallback.entityId]
    image = fb
  }
  const imageUrl = image?.url

  if (typeof (imageUrl) === 'string')
    return <>
      <div className={`w-full h-full ${clickable && "cursor-pointer"} flex flex-row items-center`} onClick={() => setShowImageModal(clickable && !showImageModal)}>
        <img className="max-h-full mx-auto" src={imageUrl} alt={"Image for " + imageUrl} loading="lazy" />
      </div>
      {showImageModal && <ImageModal {...{ imageUrl, setShowImageModal, title, text }} />}
    </>
  else
    return hasLoaded
      ? <>
        <svg className="font-bold w-full h-full stroke-none fill-pc-500/50" viewBox="0 0 80 18">
          <text x="3" y="15">{t('no-image')}</text>
          {hint && <text className="font-normal fill-pc-500/60 text-[4px]" x="3" y="24">{hint}</text>}
        </svg>
      </>
      : <div className="w-full h-full bg-slate-100" />

}

// pass all the info in here (images, entity, entityId, linkOperation)
export default function Image({ entity, entityId, isEditable = true, title = undefined, text = undefined, epoLoad = undefined }) {

  const { t } = useTranslation()
  const { imagesLookup } = useBackend()

  const image = imagesLookup[entity]?.[entityId]
  const imageUrl = image?.url

  const [hovering, setHovering] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false)
  const [showImageModal, setShowImageModal] = useState(false)

  if (typeof (imageUrl) === 'string')
    return (
      <div
        className={(hovering || showEditModal) ? "relative" : ""}
        onMouseEnter={() => setHovering(true)}
        onMouseLeave={() => setHovering(false)}
      >
        <div className="h-36 flex flex-row items-center">
          <img className="mx-auto max-h-full" src={imageUrl} alt={`${entity} ${entityId}: ${imageUrl}`} />
        </div>
        {(hovering || showEditModal) &&
          <div className={"flex flex-col gap-2 place-content-center bg-white/30 backdrop-blur-sm absolute min-h-full min-w-full bottom-0 left-0"}>
            <button onClick={() => setShowImageModal(true)} className="btn-secondary self-center "><IconExpand /></button>
            {isEditable &&
              <button onClick={() => setShowEditModal(true)} className="btn-secondary self-center ">{t('edit-image')}</button>}
          </div>}
        {showEditModal && <EditImageModal {...{
          currentImage: image,
          entity,
          entityId,
          setShowPopup: (t) => { setShowEditModal(t); setHovering(t) },
          epoLoad,
        }} />}
        {showImageModal &&
          <ImageModal {...{ imageUrl, setShowImageModal: (t) => { setShowImageModal(t); setHovering(t) }, title, text }} />
        }
      </div>
    )
  else if (!isEditable)
    return (
      <div className="h-36 text-center font-black text-3xl text-pc-500/50 align-middle pt-12">{t('no-image')}</div>
    )
  else
    return (
      <div className="grid h-36 bg-gray-100 rounded-sm border border-1 border-pc-200">
        <div className="self-center place-self-center flex flex-col space-y-2">
          <button onClick={() => setShowEditModal(true)} className="btn-secondary sm:p-2m">{t('edit-image')}</button>
          {showEditModal && <EditImageModal {...{
            entity,
            entityId,
            setShowPopup: (t) => { setShowEditModal(t); setHovering(t) },
            epoLoad,
            publicationNumbers: epoLoad,
          }} />}
        </div>
      </div>
    )
}


// images: [{image: {url}, number, caption}]
export function ImageCaroussel({ images, children = null }) {
  const { t } = useTranslation()
  const [image, setImage] = useState(0)

  function nextImage() {
    setImage((image + 1) % images.length)
  }

  function prevImage() {
    setImage((image - 1 + images.length) % images.length)
  }

  const [expandedImage, setExpandedImage] = useState(undefined)

  if (images.length === 0)
    return (
      <div className="h-36">
        {children}
      </div>
    )
  else
    return (
      <div className="relative group max-h-[12.5rem] overflow-hidden">
        {images[image].image !== undefined
          ? <div className="h-[9rem] flex flex-row items-center">
            <img className="max-h-full mx-auto" src={images[image].image.url} alt={`claim scope ${image}`} />
          </div>
          : <div className="h-36 text-center font-black text-3xl text-pc-500/50 align-middle pt-16">{t('no-image')}</div>
        }
        <div className="pt-2 text-xs w-full">
          <div className="mx-auto max-w-full">
            <div className="after:content-['.'] float-left pr-1">{images[image].number ?? (image + 1)}</div>
            <div className="max-w-full line-clamp-3" dangerouslySetInnerHTML={{ __html: images[image].caption }} />
          </div>
        </div>
        <div className="hidden group-hover:flex place-content-center absolute min-h-full min-w-full bottom-0 left-0">
          <div className="flex flex-row justify-between w-full h-full self-center">
            <button className="btn-secondary disabled:btn-disabled disabled:pb-2 bg-white/80 h-fit text-pc-400 pb-2" disabled={images.length <= 1} onClick={() => prevImage()}><IconChevronLeft /></button>
            <button className="btn-secondary bg-white/80 text-pc-400" onClick={() => setExpandedImage(images[image])}><IconExpand /></button>
            <button className="btn-secondary disabled:btn-disabled disabled:pb-2 bg-white/80 h-fit text-pc-400 pb-2" disabled={images.length <= 1} onClick={() => nextImage()}><IconChevronRight /></button>
          </div>
        </div>
        {expandedImage && <ImageModal {...{ imageUrl: expandedImage?.image?.url, setShowImageModal: () => setExpandedImage(undefined), text: expandedImage.caption }} />}
      </div>
    )
}