import { family_member, patent_family, trade_mark, trademark_family } from "../data"
import { Invention } from "../inventions/InventionsProvider"
import { inventionUrl } from "../inventions/utils"
import { Family, Member } from "../patents/patents"
import { familyUrl, memberUrl } from "../patents/utils"
import { Commodity, commodityUrl } from "../products/products"
import { AugmentedTaskProps, TaskProps } from "../tasks/utils"
import { Trademark, TrademarkFamily } from "../trademarks/TrademarksProvider"
import { trademarkFamilyUrl, trademarkUrl } from "../trademarks/utils"
import Fuse from "fuse.js";

export function extractLink(entity: string, data: Family | Member | Invention | Commodity | Trademark | TrademarkFamily | (TaskProps & AugmentedTaskProps)): {to: string, reference: string} | undefined {
    let to = undefined
    let reference = undefined
    switch (entity) {
        case (patent_family):
            const family = data as Family
            to = familyUrl(family)
            reference = family.internalReference
            break
        case (family_member):
            const member = data as Member
            to = memberUrl(member)
            reference = member.internalReference
            break
        case ('invention'):
            const invention = data as Invention
            to = inventionUrl(invention)
            reference = invention.reference
            break
        case (trade_mark):
            const trademark = data as Trademark
            to = trademarkUrl(trademark)
            reference = trademark.reference
            break
        case (trademark_family):
            const trademarkFamily = data as TrademarkFamily
            to = trademarkFamilyUrl(trademarkFamily)
            reference = trademarkFamily.reference
            break
        case ('commodity'):
            const commodity = data as Commodity
            to = commodityUrl(commodity)
            reference = commodity.commodityReference
            break
        case ('task'):
            const task = data as (TaskProps & AugmentedTaskProps)
            if (task.internalReference && task.to) {
                to = task.to
                reference = task.internalReference
            } else {
                to = `/tasks/post?taskId=${task.taskId}`
                reference = task.title
            }
            break
    }
    //console.log({to, reference, entity, data})
    if (to && reference) 
        return {to, reference}
    else
        return undefined
}

export function decorateMatches({value, indices}: Fuse.FuseResultMatch, startTag = '<span class="search-highlight">', endTag = "</span>") {
    if (!value || !indices) return ""

    const result = []
    let m = 0
    let [start, end] = indices.at(m)
    for (let i = 0; i < value.length; i++) {
        if (i === start) {
            result.push(startTag)
        }
        result.push(value[i])
        if (i === end) {
            result.push(endTag)
            if (indices.length > 1) { // last indices is just the end of the string
                m = m + 1;
                let tuple = indices.at(m);
                if (!tuple) {
                    [start, end] = tuple
                }
            }
        }
    }
    return result.join("")
}