import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from "react-i18next"
import { ExclamationTriangleIcon } from '@heroicons/react/20/solid'
import _ from 'lodash'

import { Member, Family, Tag, Comment } from './patents'
import { Agent, AgentLink} from '../agents/utils'
import Modal, { LoadingModal } from '../components/Modal'
import { agent_link } from '../data'
import { downloadImportExample, extractExcel, importExcelPortfolio, uploadEpoImport } from '../backend'
import { useBackend } from '../BackendProvider'
import { IconLoad } from '../components/icons'
import { useMessages } from '../Messages'
import { useAuth } from '../user/Auth'
import clsx from 'clsx'
import { usePatents } from './PatentsProvider'

export function ExcelExtractPortfolio() {
  const { t } = useTranslation()
  const {setErrorMessage} = useMessages()
  
  let navigate = useNavigate()

  const [isLoading, setIsLoading] = useState(false)

  const [portfolio, setPortfolio] = useState(undefined)

  const maxFamilies = 100

  return <>
    {/* @ts-ignore */}
      <Helmet>
        <title>{t('excel-import')} | Patent Cockpit</title>
      </Helmet>
      <div className="header-row">
        <h2>{t('excel-import')}</h2>
      </div>
      <div className="main-content">
        {(portfolio?.families?.length ?? 0) === 0 && 
          <>
            <div className="flex sm:flex-row flex-col gap-2">
              <Link to=".." className="btn-secondary">{t('cancel')}</Link>

              <button className="btn-secondary" onClick={() => downloadImportExample()} >{t('example-file')}</button>

              <form className="w-fit">
                <label className="w-fit" htmlFor="excel-file-upload">
                  <div className="btn-primary cursor-pointer">
                    {t('upload-excel-patent-portfolio')}
                  </div>
                </label>
                <input
                  type="file"
                  id="excel-file-upload"
                  name="excel-file-upload"
                  accept=".xlsx"
                  onInputCapture={(e) => {
                    //console.log('Uploading file...', e.target.files); 
                    setIsLoading(true)
                    e.preventDefault();
                    // @ts-ignore
                    extractExcel(e.target.files[0])
                      .then(setPortfolio)
                      .catch(err => { 
                        console.warn(err); 
                        setErrorMessage(err.message); 
                      })
                      .finally(() => {
                        setIsLoading(false)
                        // @ts-ignore
                        e.target.value = null
                      })
                  }}
                  style={{
                    clip: "rect(0 0 0 0)",
                    clipPath: "inset(50%)",
                    height: "1px",
                    overflow: "hidden",
                    position: "absolute",
                    whiteSpace: "nowrap",
                    width: "1px",
                  }}
                />
              </form>
            </div>
            <div className="py-4">
              <div className="info">
                <p>{t('upload-tip-size')}</p>
                <p>{t('upload-tip-formulas')}</p>
                <ol>
                  <li>{t('upload-tip-formulas-remedy1')}</li>
                  <li>{t('upload-tip-formulas-remedy2')}</li>
                </ol>
              </div>
            </div>
          </>
        }
        {isLoading && <LoadingModal /> }
        {portfolio?.families?.length > 0 &&
          <ImportPortfolioSetup {...{portfolio, afterImportAction: () => navigate(".."), maxFamilies, chunkSize: 50}}  />
        }
    </div>
  </>
}


export type ImportPortolio = {
  families: Family[]
  members: Member[]
  agents: Agent[]
  agentLinks: AgentLink[]
  existing?: string[]
  comments: Comment[]
  tags: Tag[]
}


export type ImportPortfolioSetupProps = {
  portfolio: ImportPortolio,
  maxFamilies: number
  chunkSize?: number
  afterImportAction: () => void
  //importPortfolios: (portfolios: ImportPortolio[]) => (Promise<void> | void)
  updateByDefault?: boolean
}


export function ImportPortfolioSetup({ portfolio, maxFamilies, afterImportAction, chunkSize = 50, updateByDefault = false }: ImportPortfolioSetupProps) {
  const { t } = useTranslation()
  const {team} = useAuth()
  const {setErrorMessage} = useMessages()
  const {members: _existingMembers, reload} = usePatents()
  const {agents: existingAgents, agentLinks: existingAgentLinks, entityOperation, linkOperation} = useBackend()

  const existingAgentById = _.keyBy(existingAgents, 'agentId')
  const existingMembers = _existingMembers.map(m => {
    const ofMember = existingAgentLinks.filter(al => al.familyMemberId === m.familyMemberId)
    const inventors = ofMember.filter(l => l.linkType === 'inventor')
    const applicants = ofMember.filter(l => l.linkType === 'applicant')
    const owners = ofMember.filter(l => l.linkType === 'owner')
    return {...m, inventors, applicants, owners}
  })

  const { families, members, agents, agentLinks: agentLinks_new, existing = [], comments, tags } = portfolio
  const agentLinks = _.uniqBy([...agentLinks_new, ...existingAgentLinks], l => `${l.agentId}-${l.familyMemberId}-${l.linkType}`)
  const [toBeUpdated, setToBeUpdated] = useState((updateByDefault ? _.fromPairs(existingMembers.map((m: Member) => [m.internalReference, true])) : {}) as Record<string, boolean>)

  const agentsById = _.keyBy(agents, a => a.agentId)
  const agentLinksByMember = _.groupBy(agentLinks, al => al.familyMemberId)
  const familiesById = _.keyBy(families, f => f.patentFamilyId)
  const groupedMembers = _(members)
    .map(m => {
      const links = agentLinksByMember[m.familyMemberId] ?? []
      const inventors = links.filter(l => l.linkType === 'inventor')
      const owners = links.filter(l => l.linkType === 'owner')
      const applicants = links.filter(l => l.linkType === 'applicant')
      return {...m, inventors, owners, applicants}
    })
    .groupBy(m => m.patentFamilyId)
    .value()

  const tagsById = _.groupBy(tags, t => t.entityId)
  const commentsById = _.groupBy(comments, c => c.entityId)

  const [isLoading, setIsLoading] = useState(false)
  const [loadingPercentage, setLoadingPercentage] = useState(-1)

  const duplicateMemberReferences = _(members).groupBy(m => m.internalReference).toPairs().filter(([_, ms]) => ms.length > 1).map(([k, _]) => k).sort().value()

  function importPortfolios(portfolios: ImportPortolio[]) {
    setIsLoading(true)
    // Use this instead of Promise.all, so that the requests are sent one after the other
    function goThroughPortfolios(last) {
      return last.then(index => {
        setLoadingPercentage(100.0 * index / portfolios.length)
        return index < portfolios.length
          ? goThroughPortfolios(importExcelPortfolio(portfolios[index]).then(() => index + 1))
          : Promise.resolve({})
      })
    }

    return goThroughPortfolios(Promise.resolve(0))
      .then(() => Promise.all([
        reload(),
        entityOperation('agent', 'get'),
        linkOperation(agent_link, 'get'),
        linkOperation('tag', 'get')
      ]))
      .then(() => afterImportAction())
      .catch(err => setErrorMessage(err.message))
      .finally(() => { setIsLoading(false); setLoadingPercentage(-1) })
  }

  function createPortfolios() {
    const portfolios = _(members)
      .filter(m => !existing.includes(m.internalReference) || (toBeUpdated[m.internalReference] ?? false))
      .groupBy(m => m.patentFamilyId)
      .toPairs()
      .chunk(chunkSize)
      .map(groups => {
        const families = groups.map(([pid]) => familiesById["" + pid])
        const familyIds = new Set(groups.map(([pid]) => +pid))
        const members = groups.flatMap(([pid, ms]) => ms)
        const familyMemberIds = new Set(members.map(m => m.familyMemberId))
        const group_agentLinks = agentLinks.filter(l => familyMemberIds.has(l.familyMemberId))
        const group_agents = _(group_agentLinks).map(l => l.agentId).uniq().map(id => agentsById[id] ?? existingAgentById[id]).filter(Boolean).value()

        const portfolio = {
          realm: team,
          families, members, agents: group_agents,
          agentLinks: group_agentLinks,
          tags: tags.filter(t => familyIds.has(t.entityId)),
          comments: comments.filter(c => familyIds.has(c.entityId))
        }
        //console.log(portfolio)
        return portfolio
      }).value()
    return portfolios
  }

  return (
    <div className="py-4">
      <div className="flex flex-row py-2 gap-2 max-w-xl">
        <h3>{t('portfolio-import-h3')}</h3>
        <div className="grow" />
        <Link to=".." className="btn-secondary">{t('cancel')}</Link>
        <button onClick={() => importPortfolios(createPortfolios())} className="btn-primary">{t('import')}</button>
      </div>
      <div className="text-slate-600 pt-1 pb-2 text-sm">
        <p>{t('see-below-skipped')}</p>
        {_.size(groupedMembers) > maxFamilies && <p>{t('only-showing-100-families')}</p>}
      </div>
      <DuplicateReferencesWarning duplicateReferences={duplicateMemberReferences} />
      <ImportPortfolioTable {...{ groupedMembers, maxFamilies, existing, familiesById, commentsById, tagsById, agentsById: {...agentsById, ...existingAgentById}, existingMembers, toBeUpdated, setToBeUpdated }} />
      <div className="pt-4 text-sm">
        <h4 className='only:hidden'>{t('skipped-ip-rights')}</h4>
        {existing.filter(e => !(toBeUpdated[e] || false)).map(s => <span key={s} className="after:content-[',_'] last:after:content-['']">{s}</span>)}
      </div>
      {isLoading && loadingPercentage < 0 && <LoadingModal />}
      {isLoading && loadingPercentage >= 0 &&
        <Modal>
          <div className="p-3 flex flex-row gap-2">{t('loading')}... <IconLoad /></div>
          <div className="p-3">{t('completed')} {loadingPercentage.toFixed(2)}%</div>
        </Modal>
      }
    </div>
  )
}

function DuplicateReferencesWarning({ duplicateReferences }: {duplicateReferences: string[]}) {
  const { t } = useTranslation()
  return duplicateReferences?.length > 0
    ? <div className="text-sm text-yellow-800 py-4 pt-3 mb-4 bg-yellow-100 border-l-2 border-yellow-600 pl-4 pr-4 w-fit">
      <h4 className='text-yellow-900'>
        <ExclamationTriangleIcon className='w-5 h-5 text-yellow-700 inline mr-2 mb-0.5' />
        {t('duplicate-member-references')}
      </h4>
      <p className='max-w-prose'>{t('duplicate-member-references-text')}</p>
      <ul className="flex flex-row gap-1 list-none text-yellow-800 font-semibold">
        {duplicateReferences.map(r => <li className='after:content-[","] last:after:content-[""]' key={r}>{r}</li>)}
      </ul>
    </div>
    : null
}

export type ImportPortfolioTableProps = {
  groupedMembers: Record<string, Member[]>
  maxFamilies: number
  existing: string[]
  familiesById: Record<string, Family>
  commentsById: Record<string, Comment[]>
  tagsById: Record<string, Tag[]>
  agentsById: Record<string, Agent>
  existingMembers: Member[]
  toBeUpdated: Record<string, boolean>
  setToBeUpdated: (arg: ((us: {[key: string]: boolean}) => {[key: string]: boolean})) => void
}

export function ImportPortfolioTable({groupedMembers, maxFamilies, existing, familiesById, commentsById, tagsById, agentsById, existingMembers, toBeUpdated, setToBeUpdated}: ImportPortfolioTableProps) {
  const { t } = useTranslation()

  let yes = t('yes')
  let no = t('no')

  function renderAgents(agentLinks: AgentLink[]) {
    if (!agentLinks) return null
    // agent links is sometimes just the array of agent ids (probably for current)
    //console.log({agentLinks, agentsById})
    return _(agentLinks)
      .map(a => agentsById[a.agentId])
      .map(a => a.agentType === 'person' ? `${a.firstName} ${a.lastName}` : a.name)
      .join("; ")
  }

  return (
    <table className="whitespace-nowrap text-xs">
      <thead className="text-left">
        <tr>
          <th className="border border-pcx-200">{t('existing')}?</th>
          <th className="border border-pcx-200">{t('update')}?</th>
          <th className="border border-pcx-200">{t('family')} {t('internalReference')}</th>
          <th className="border border-pcx-200">{t('familyName')}</th>
          <th className="border border-pcx-200">{t('summary')}</th>
          <th className="border border-pcx-200">{t('comments')}</th>
          <th className="border border-pcx-200">{t('priorityDate')}</th>
          <th className="border border-pcx-200">{t('ext-family-ref')}</th>
          <th className="border border-pcx-200">{t('tags')}</th>

          <th className="border border-pcx-200">{t('internalReference')}</th>
          <th className="border border-pcx-200">{t('externalReference')}</th>
          <th className="border border-pcx-200">{t('countryCode')}</th>
          <th className="border border-pcx-200">{t('title')}</th>

          <th className="border border-pcx-200">{t('applicationNumber')}</th>
          <th className="border border-pcx-200">{t('applicationDate')}</th>

          <th className="border border-pcx-200">{t('publicationNumber')}</th>
          <th className="border border-pcx-200">{t('publicationDate')}</th>

          <th className="border border-pcx-200">{t('patentNumber')}</th>
          <th className="border border-pcx-200">{t('patentDate')}</th>

          <th className="border border-pcx-200">{t('expiryDate')}</th>
          <th className="border border-pcx-200">{t('patentOfficeLink')}</th>
          <th className="border border-pcx-200">{t('numberClaims')}</th>

          <th className="border border-pcx-200">{t('validated')}</th>
          <th className="border border-pcx-200">{t('pctRouteFiling')}</th>
          <th className="border border-pcx-200">{t('firstFiling')}</th>
          <th className="border border-pcx-200">{t('unitaryPatent')}</th>
          <th className="border border-pcx-200">{t('optOut')}</th>

          <th className="border border-pcx-200">{t('ipType')}</th>
          <th className="border border-pcx-200">{t('familyMemberStatus')}</th>

          <th className="border border-pcx-200">{t('inventors')}</th>
          <th className="border border-pcx-200">{t('applicants')}</th>
          <th className="border border-pcx-200">{t('owners')}</th>
        </tr>
      </thead>
      <tbody>
        {_(groupedMembers).toPairs()
          .slice(0, maxFamilies)
          .map(([pid, ms]) => [familiesById["" + pid], ms])
          .sortBy(([family]: Family[]) => family.internalReference)
          .flatMap(([family, ms] : [Family, Member[]]) =>
            _(ms).sortBy(m => m.internalReference).map(m => {
              const exists = existing.find(s => m.internalReference === s) !== undefined
              const tbu = toBeUpdated[m.internalReference] ?? false
              const before = exists && existingMembers.find(s => m.internalReference === s.internalReference)
              // TODO: add inventors, applicants, owners to before (as objects)
              //console.log({before})
              const skipped = exists && !tbu
              return <tr key={m.internalReference} className={clsx(skipped && "text-slate-400")}>
                <td className="border border-pcx-200">{exists ? yes : no}</td>
                <td className='border border-pcx-200'><input
                  type="checkbox"
                  disabled={!exists}
                  checked={!exists || tbu}
                  onChange={e => setToBeUpdated(us => ({ ...us, [m.internalReference]: e.target.checked }))} />
                </td>
                <td className="border border-pcx-200">{family.internalReference}</td>
                <td className="border border-pcx-200">{family.familyName}</td>
                <td className="border border-pcx-200">{family.summary}</td>
                <FamilyDataCell {...{ exists, skipped, value: (commentsById[family.patentFamilyId] ?? []).map(c => c.comment).join(", ") }} />
                <td className="border border-pcx-200">{family.priorityDate as string}</td>
                <td className="border border-pcx-200">{family.externalReference}</td>
                <FamilyDataCell {...{ exists, skipped, value: (tagsById[family.patentFamilyId] ?? []).map(t => t.tag).join(", ") }} />

                <DataCell {...{ m, before, skipped }} name="internalReference" />
                <DataCell {...{ m, before, skipped }} name="externalReference" />
                <DataCell {...{ m, before, skipped }} name="countryCode" />
                <DataCell {...{ m, before, skipped }} name="title" />

                <DataCell {...{ m, before, skipped }} name="applicationNumber" />
                <DataCell {...{ m, before, skipped }} name="applicationDate" />

                <DataCell {...{ m, before, skipped }} name="publicationNumber" />
                <DataCell {...{ m, before, skipped }} name="publicationDate" />

                <DataCell {...{ m, before, skipped }} name="patentNumber" />
                <DataCell {...{ m, before, skipped }} name="patentDate" />

                <DataCell {...{ m, before, skipped }} name="expiryDate" />
                <DataCell {...{ m, before, skipped }} name="patentOfficeLink" transform={v =>
                  typeof v === 'string' && v !== '' &&
                  <a href={v}
                    className="underline"
                    target="_blank"
                    rel="noreferrer"
                  >{v}</a>
                } />
                <DataCell {...{ m, before, skipped }} name="numberClaims" />

                <DataCell {...{ m, before, skipped }} name="validated" transform={v => v ? yes : no} />
                <DataCell {...{ m, before, skipped }} name="pctRouteFiling" transform={v => v ? yes : no} />
                <DataCell {...{ m, before, skipped }} name="firstFiling" transform={v => v ? yes : no} />
                <DataCell {...{ m, before, skipped }} name="unitartyPatent" transform={v => v ? yes : no} />
                <DataCell {...{ m, before, skipped }} name="optOut" transform={v => v ? yes : no} />

                <DataCell {...{ m, before, skipped }} name="ipType" transform={t} />
                <DataCell {...{ m, before, skipped }} name="familyMemberStatus" transform={t} />

                <DataCell {...{ m, before, skipped }} name="inventors" transform={renderAgents} />
                <DataCell {...{ m, before, skipped }} name="applicants" transform={renderAgents} />
                <DataCell {...{ m, before, skipped }} name="owners" transform={renderAgents} />
              </tr>
            }).value()
          ).value()}
      </tbody>
    </table>

  )
}

function FamilyDataCell({value, exists, skipped}: {value: any, exists: boolean, skipped: boolean}) {
    return <td className={clsx(
        "border border-pcx-200",
        exists && value !== undefined && value !== '' && (
            skipped 
                ? "bg-slate-100 text-slate-500" 
                : "bg-pcx-200 text-pcx-900"),
    )}>{value}</td>
}

function DataCell({m, name, transform = (v) => v, before, skipped}) {
    const mValue = m?.[name] !== undefined ? transform(m[name]) : undefined
    const bValue = before?.[name] !== undefined ? transform(before[name]) : undefined
    const updated = before !== undefined && mValue !== bValue
    //if (before)
    //    console.log({m, before, name, updated})
    return <td className={clsx("border border-pcx-200", updated && (skipped ? "bg-slate-100 text-slate-500" : "bg-pcx-200 text-pcx-900"))}>{mValue ?? ''}</td>
}


function ExcelImportModal({ result, closeAction = () => { } }) {
  const {reload} = usePatents()
  const { t } = useTranslation()

  const failed = (result ?? []).filter(r => r.status === "fail")
  const good = (result ?? []).filter(r => r.status === "ok")

  return (result
    ?  <Modal key="result">
        <div className="flex flex-col gap-2">
          <div className="flex flex-col gap-1 p-4 sm:px-6">
            <h3>Import Results</h3>
            <div>Imported {good.length} families of {result.length}</div>
            {failed.length > 0 &&
              <div className="py-2">
                <h4>Errors ({failed.length})</h4>
                <table className="text-sm">
                  <thead className="text-left">
                    <tr className="border-b-2 border-pcx-300">
                      <th>Identifier</th>
                      <th>Message</th>
                    </tr>
                  </thead>
                  <tbody>
                    {failed.map(r =>
                      <tr key={r.id} className="border-b-2 border-pcx-200">
                        <td className="pr-2">{r.id}</td>
                        <td>{r.message}</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            }
          </div>
          <div className="flex flex-row justify-end p-4 sm:px-6 px-4 bg-pcx-200">
            <button
              onClick={() => {
                // TODO: add agents/links here as well!
                reload()
                closeAction()
              }}
              className="btn-primary"
            >Close</button>
          </div>
        </div>
    </Modal>
    : <Modal key="loading">
      <div className="p-6">
        <h3 className="pb-2 inline-flex gap-2">{t('loading')}... <IconLoad /></h3>
        <div>{t('please-be-patient')} &#x1F64F;</div>
      </div>
    </Modal>
  )
}

export function ExcelEpoImportButton() {
  const {setErrorMessage} = useMessages()

  const [showModal, setShowModal] = useState(false)
  const [result, setResult] = useState(undefined)

  return (
    <>
      <form className="w-full">
        <label className="w-full" htmlFor="excel-file-upload">
          <div className=" btn-secondary whitespace-nowrap cursor-pointer">
            Excel Epo Import
          </div>
        </label>
        <input
          type="file"
          id="excel-file-upload"
          name="excel-file-upload"
          accept=".xlsx"
          onInputCapture={(e) => {
            //console.log('Uploading file...', e.target.files); 
            setShowModal(true)
            e.preventDefault();
            // @ts-ignore
            uploadEpoImport(e.target.files[0])
              // @ts-ignore
              .then(res => { setResult(_.sortBy(res, r => r.status)); e.target.value = null })
              // @ts-ignore
              .catch(err => {console.warn(err); setErrorMessage(err.message); setShowModal(false); e.target.value = null})
          }}
          style={{
            clip: "rect(0 0 0 0)",
            clipPath: "inset(50%)",
            height: "1px",
            overflow: "hidden",
            position: "absolute",
            whiteSpace: "nowrap",
            width: "1px",
          }}
        />
      </form>
      {showModal && <ExcelImportModal {...{result, closeAction: () => { setShowModal(false); setResult(undefined) }}} /> }
    </>
  )
}