import { Member } from "../patents/patents";
import { isEmptyDate, isEmptyString } from "../utils/strings";

/*
Required values for all
  application date 
  application number
  publication date
  publication number
  patent date
  patent number

  status == 'granted'

Required values for JP, KR, ID, PH and VN
  number of claims
*/
const countriesWithNumberOfClaims = new Set(['JP', 'KR', 'ID', 'PH', 'VN'])

// translate with useTranslation
export function missingInformation(member: Member) {
    const missinngInformation = [
        isEmptyDate(member.applicationDate) && 'applicationDate',
        isEmptyDate(member.publicationDate) && 'publicationDate',
        isEmptyDate(member.patentDate) && 'patentDate',
        isEmptyString(member.applicationNumber) && 'applicationNumber',
        isEmptyString(member.publicationNumber) && 'publicationNumber',
        isEmptyString(member.patentNumber) && 'patentNumber',
        member.familyMemberStatus !== 'granted' && 'status-not-granted',
        countriesWithNumberOfClaims.has(member.countryCode) && (member.numberClaims === undefined || member.numberClaims === 0) && 'numberClaims',
    ]

    return missinngInformation.filter(Boolean)
}