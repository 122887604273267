import { useState } from "react"
import { Link } from "react-router-dom"
import { Helmet } from 'react-helmet-async'
import _ from 'lodash'

import { PlainImage } from "../components/Image"
import { IconX } from "../components/icons"
import { useBackend } from "../BackendProvider"
import { familyUrl, groupByEp, memberUrl } from "../patents/utils"
import ToolTip from "../components/ToolTip"
import { Member } from "../patents/patents"
import { Claim } from "./ClaimsProvider"
import { ClaimScope } from "./ClaimScope"
import { useAllOrFilteredPatents, useFilteredPatents } from "../filter/FilteredPatents"


function relevantClaimScopeIds(selectedVersion, claimScape) {
    const scope_id_claim_number = _(selectedVersion)
        .flatMap(({ member, version }) =>
            _(claimScape[`${member}-${version}`]).toPairs()
                .map(([scopeId, claim]) => [scopeId, claim.claimNumber])
                .value())
        .reduce((acc, [scopeId, claimNumber]: number[]) =>
            ({ ...acc, [scopeId]: Math.min(claimNumber, acc[scopeId] ?? claimNumber) })
            , {})

    const result = _(scope_id_claim_number).toPairs()
        .sort(([id1, cn1]: [string, number], [id2, cn2]: [string, number]) => cn1 - cn2)
        .map(([id, cn]) => id).value()

    return result
}

function Hull({family, children}) {
    return (
        <div className="pt-2 pb-6 w-full overflow-y-auto">
            <h3 className="pb-2 ">
                Claim Scape for &nbsp;
                <Link className="underline-link truncate" to={familyUrl(family)}>
                    {family.internalReference}: {family.familyName}
                </Link>
            </h3>
            {children}
        </div>
    )
}


// - Create the mapping (memberId, version, claimScopeId) -> claim
// - Create selected versions
// - relevant scope ids <- all (memberId, version, *) for which (memberId, version) in selected versions
export function FamilyClaimScape({ members, claims, imagesById, claimScopesById }: {
        members: Member[], 
        claims: Claim[], 
        imagesById: {[key: string]: {url: string}}, 
        claimScopesById: {[key: string]: ClaimScope}
    }) {

    const claimScape = claims.reduce((acc, claim) => {
        if (claim.claimScopeId !== undefined) {
            const famVerKey = `${claim.familyMemberId}-${claim.version}`
            if (!(famVerKey in acc))
                acc[famVerKey] = {}
            if (!(claim.claimScopeId in acc[famVerKey])
                || acc[famVerKey][claim.claimScopeId].claimNumber > claim.claimNumber) // claim scope already in but make sure that claim number is smaller
                acc[famVerKey][claim.claimScopeId] = claim
        }
        return acc
    }, {})

    const membersById = _.keyBy(members, 'familyMemberId')

    // {familyMemberId: [version]}
    const versionsByMember = Object.values(claimScape).reduce((acc: object, byFamVer) => {
        const relClaims = Object.values(byFamVer)
        const familyMemberId = relClaims[0].familyMemberId
        return { ...acc, [familyMemberId]: _.uniq([...(acc[familyMemberId] ?? []), ...relClaims.map(c => c.version)]).sort((a, b) => b - a) }
    }, {})

    const [selectedVersion, setSelectedVersion] = useState(Object.entries(versionsByMember).map(([member, versions]) => ({ member, version: versions[0] })))
    //console.log(selectedVersion)

    function AddToSelectedVersion() {
        return (
            <select
                className="form-select py-px pl-2 w-24"
                value="Add"
                onChange={(e) => {
                    const parts = e.target.value.split('-')
                    if (parts.length === 2) {
                        const [member, version] = parts
                        setSelectedVersion([...selectedVersion, { member, version: +version }])
                    }
                }}
            >
                <option>Add</option>
                {
                    _.flatMap(Object.entries(versionsByMember)
                        .map(([member, versions]) => versions
                            .filter(version => selectedVersion.find(v => v.member === member && v.version === version) === undefined)
                            .map(version => ({ member, version })))
                    ).map(({ member, version }) => {
                        const key = `${member}-${version}`
                        //console.log("Creating option for " + key)
                        return (
                            <option key={key} value={key} >
                                {membersById[member].countryCode}: Version {version}
                            </option>
                        )
                    })
                }
            </select>
        )
    }

    const scopeCell = "align-top lg:min-w-[12rem] min-w-[6rem] flex flex-col py-2"
    const textCell = "p-2 align-top sm:min-w-[10rem]"
    return (
        <table className="text-sm pt-1">
            <thead>
                <tr className="border-b-2 border-pc-300">
                    <th className="text-right pb-0.5 pr-1">
                        <AddToSelectedVersion />
                    </th>
                    {selectedVersion.map(({ member, version }, index) => {
                        const mem = membersById[member]
                        return (
                            <th className="text-left px-2" key={"th-" + member + '-' + version}>
                                <div className="flex flex-row justify-between">
                                    <div>
                                        <Link className="underline-link" to={memberUrl(mem)}>
                                            {mem.countryCode}: <span className="hidden sm:inline">Version</span> {version}
                                        </Link>
                                    </div>
                                    <div className="pt-0.5">
                                        <button onClick={() => setSelectedVersion(selectedVersion.filter((v, i) => i !== index))}><IconX /></button>
                                    </div>
                                </div>
                            </th>
                        )
                    })}
                </tr>
            </thead>
            <tbody className="">
                {relevantClaimScopeIds(selectedVersion, claimScape).map(claimScopeId => {
                    const summary = claimScopesById['' + claimScopeId]?.claimScopeSummary ?? ''
                    return (
                        <tr key={"claim-" + claimScopeId}
                            className="border-b-2 border-pc-250"
                        >
                            <td className={scopeCell}>
                                <div className="w-36 text-center py-px">
                                    {imagesById[claimScopeId]?.url === undefined
                                        ? <div className="h-24 text-center font-black text-xl text-pc-500/50 align-middle pt-8 bg-pc-100">
                                            No Image
                                        </div>
                                        : <img
                                            className="h-24 mx-auto"
                                            src={imagesById[claimScopeId]?.url}
                                            alt={"Image of claim scope " + summary} />
                                    }
                                </div>
                                <div className="pl-2 lg:pt-2 max-w-[12rem]" dangerouslySetInnerHTML={{ __html: summary }} />
                            </td>
                            {selectedVersion.map(({ member, version }) => {
                                const claim = claimScape[`${member}-${version}`]['' + claimScopeId]
                                return (claim === undefined)
                                    ? <td className={textCell} key={`entry-${claimScopeId}-${member}-${version}`} />
                                    : <td className={textCell} key={`entry-${claimScopeId}-${member}-${version}`}>
                                        <div>
                                            <span className="float-left pr-1 font-bold">{claim.claimNumber}.</span>
                                            <div dangerouslySetInnerHTML={{__html: claim.claimText}} />
                                        </div>
                                    </td>
                            })}
                        </tr>
                    )
                })}
            </tbody>
        </table>
    )
}

export default function ClaimScape() {
  
    const {families, members} = useFilteredPatents()
    const { claims, images, claimScopes } = useBackend()

    const familyById = _.keyBy(families, 'patentFamilyId')
    const membersByFamily = _.groupBy(members, 'patentFamilyId')
    const memberIdsByFamily = _(members)
        .groupBy('patentFamilyId')
        .mapValues(members => new Set(members.map(m => m.familyMemberId)))
        .value()
    //Object.fromEntries(Object.entries(membersByFamily).map(([pId, members]) => [pId, new Set(members.map(m => m.familyMemberId))]))

    const claimScopesById = _.keyBy(claimScopes, 'claimScopeId')
    const imagesById = _.keyBy(images.filter(i => i.entity === 'claim-scope'), 'entityId')
    return (
        <>
            {/* @ts-ignore */}
            <Helmet>
                <title>ClaimScape | Patent Cockpit</title>
            </Helmet>
            <div className="header-row">
                <h2>ClaimScape</h2>
            </div>
            <div className="main-content">
                {families.length === 0
                    ? <div className="text-center">
                        Quickly assess the claim differences within you patent families.
                        <br />
                        Add some patents <Link className="text-pc-300 underline-link" to="/patents/portfolio">in the portfolio page</Link> to get started!
                    </div>
                    : Object.entries(memberIdsByFamily).map(([pId, mIds]) => {
                        const family = familyById[pId]
                        if (family === undefined)
                            return <div key={pId} />

                        const relevantClaims = claims.filter(c => mIds.has(c.familyMemberId))
                        if (relevantClaims.length === 0)
                            return <Hull key={pId} {...{ family }}><div>No claim scopes defined yet.</div></Hull>
                        else
                            return <Hull key={pId} {...{ family }}>
                                <FamilyClaimScape {...{
                                    family,
                                    members: membersByFamily[pId],
                                    imagesById,
                                    claims: relevantClaims,
                                    claimScopesById
                                }} />
                            </Hull>
                    })
                }
            </div>
        </>
    )
}

function ClaimScopeDescription({claimScopeId, claimScopeSummary}) {
    return (
        <div className="p-4 max-w-md bg-pc-100 dark:bg-pc-200 rounded-lg text-sm leading-relaxed h-full">
            <div className="w-64 h-48 pb-2 mx-auto">
                <PlainImage {...{ entity: "claim-scope", entityId: claimScopeId, text: claimScopeSummary }} />
            </div>
            <div dangerouslySetInnerHTML={{__html: claimScopeSummary}} />
        </div>
    )
}

// TODO: delete the rest
export function ClaimScape2({patentFamilyId, applyGlobalFilter = false, onlyIndepentClaims = false}) {
    const {membersByFamilyId} = useAllOrFilteredPatents(applyGlobalFilter)
    
    const {claimScopes, claims} = useBackend()
    const members = membersByFamilyId[patentFamilyId] ?? []
    const familyMembers = groupByEp(members)

    const memberIds = new Set(familyMembers.map(m => m.familyMemberId))
    const memberById = _.keyBy(familyMembers, m => m.familyMemberId)

    // [familyMemberId]
    const familyClaims = _(claims)
        .filter(c => !onlyIndepentClaims || c.claimType === 'independent-claim')
        .filter(c => memberIds.has(c.familyMemberId))
        .groupBy(c => c.familyMemberId)
        .mapValues(cs => {
            const maxDate = _(cs).map(c => c.versionDate).max()
            const maxVersion = _(cs).filter(c => c.versionDate === maxDate).map(c => c.version).max()
            return cs.filter(c => c.version === maxVersion)
        })
        .value()

    // [claimScopeId][familyMemberId]
    const claimByScopeId = _(familyClaims).toPairs()
        .flatMap(([id, cs]) => cs.filter(c => c.claimScopeId))
        .groupBy(c => c.claimScopeId)
        .mapValues(cs => _.keyBy(cs, c => c.familyMemberId))
        .value()

    const claimScopeIds = new Set(
        _(familyClaims).toPairs()
            .flatMap(([id, cs]) => cs.map(c => c.claimScopeId).filter(c => c !== undefined))
            .value())
    //console.log(claimByScopeId)

    const familyScopes = _(claimScopes)
        .filter(c => claimScopeIds.has(c.claimScopeId))
        .sortBy(c => -_.size(claimByScopeId[c.claimScopeId]))
        .value()

    return (
        <table>
            <tbody>
                {familyMembers.map(m =>
                    <tr key={m.internalReference}>
                        <td className="whitespace-nowrap py-1 pr-1">
                            <Link className="text-pc-600 underline" to={memberUrl(m)}>{m.internalReference}</Link>
                        </td>
                        {familyScopes.map(({ claimScopeId }, scopeIndex) => {
                            const claim = claimByScopeId[claimScopeId]?.[m.familyMemberId]
                            const internalReference = memberById[claim?.familyMemberId]?.internalReference
                            const className = scopeIndex === (familyScopes.length - 1) && scopeIndex !== 0 ? "last:tooltip-left" : ''
                            return <td key={`${m.internalReference}--${claimScopeId}`} className="p-px">
                                <ClaimNumberTooltip {...{ ...claim, internalReference, className }} />
                            </td>
                        })}
                    </tr>)}
                <tr>
                    <td></td>
                    {familyScopes.map(({ claimScopeId, claimScopeSummary }) =>
                        <td
                            className="p-px align-top"
                            key={'claim-scope-' + claimScopeId}
                        >
                            <ClaimScopeDescription {...{
                                claimScopeId,
                                claimScopeSummary,
                            }} />
                        </td>
                    )}
                </tr>
            </tbody>
        </table>
    )
}

function ClaimNumberTooltip({claimNumber, claimText, internalReference, className}) {
    if (claimNumber)
        return <ToolTip className={className} anchor={
            <Link className="ribbon ribbon-clickable block" to={memberUrl({ internalReference })}>Claim {claimNumber}</Link>
        }>
            <Link className="text-sm block" to={memberUrl({ internalReference })}>
                <h5 className="pb-1 pt-1 text-white">Claim {claimNumber} of {internalReference}</h5>
                <div dangerouslySetInnerHTML={{ __html: claimText }} />
            </Link>
        </ToolTip>
    else
        return null
}
