import _ from 'lodash'
import { DateOrString } from "./dates";

export function firstUpperCase(str: string) {
    return str[0].toUpperCase() + str.slice(1);
}

export function noHtml(str: string) {
    return str.replace(/<[^>]*>/g, '');
}

export function emptyStringAsUndefined<T>(s: T | ""): T | undefined {
    return s === "" ? undefined : s
}

export function nonEmptyString(s?: string): boolean {
    return s !== undefined && s !== ""
}

export function isEmptyString(s?: string): boolean {
    return s === undefined || s === ""
}

export function objWithoutEmptyStrings<T>(obj: T): T {
    return _(obj).toPairs().filter(([_, v]) => v !== undefined && v !== '').fromPairs().value() as T
}

export function nonEmptyDate(s?: DateOrString) {
    return s !== undefined && s !== ""
}

export function isEmptyDate(s: Date | string) {
    return s === undefined || s === ""
}

export function capitalize(str?: string) {
    if (!str) return undefined

    const words = str.split(' ')
    return _(words).map(w => // if word starts with uppercase, leave it alone; otherwise capitalize it
        w[0] === w[0].toUpperCase() ? w : w[0].toUpperCase() + w.substring(1))
        .join(' ')
}

export function truncate(s: string, maxLength: number = 30) {
    return s.length > maxLength ? s.slice(0, maxLength) + '...' : s
}