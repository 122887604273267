import { useEffect, useState } from "react"
import { Combobox } from '@headlessui/react'
import { FolderIcon, /*TrashIcon*/ } from '@heroicons/react/24/outline'
import { Link, useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import clsx from "clsx"
import _ from "lodash"

import { useBrowser } from "./DocumentsProvider"
import { collectFirst } from "../collection"
import { analysePath, getClientRoot, search, searchTrashcan, useAgorumObject } from "./backend"
import { useDocumentSettings } from "./DocumentsSettings"


const _search = async (
  {query, start, inTrash, setSearchResult}: 
  {query: string, start?: string, inTrash?: boolean, setSearchResult: any}
) => (inTrash ? searchTrashcan({query, start}) : search({query, start})).then(setSearchResult)

const doQuery = _.debounce(_search, 300)


type ResultProps = {
  name: string
  uuid: string
  highlights: string[]
  isFolder: boolean
  folder: string[]
  paths?: string[]
}

function SearchResult({ result }: { result: ResultProps & { to: string } }) {
  // const {previewLink} = useBrowser()
  const context = collectFirst(result.paths ?? [], analysePath)

  return (
    <Combobox.Option className='list-none' value={result}>{({ active }) =>
      <div
        className={clsx(
          "text-sm text-gray-700 px-3 py-2 block hover:xbg-pcx-100 rounded-md hover:bg-gray-900 hover:bg-opacity-5 hover:text-gray-900",
          active && "bg-gray-900 bg-opacity-5 text-gray-900"
        )}
      >
        <div className="text-inherit mb-1 flex flex-row gap-4 items-baseline">
          <Link to={result.to}><h4 className="text-base shrink-0">{result.name}</h4></Link>
          {context && <Link
            to={`${context.url}?tab=Documents&preview=${result.uuid}`}
            className="text-pcx-500 hover:underline underline-offset-2 min-w-0 truncate">{context.parts.slice(context.parts.length > 1 ? 1 : 0, -1).join(' / ')}</Link>}
        </div>
        <Link to={result.to}>
          {result.highlights.slice(0, 2).map((h, i) =>
            <div key={i}
              // highligh spans with class search-highlight  (KEEP comment to make sure rule is incorporated in the final code)
              className="whitespace-pre-wrap line-clamp-2 mb-1 pb-1 border-b last:mb-0 last:pb-0 last:border-b-0"
              dangerouslySetInnerHTML={{ __html: h.replace(/\n+/g, '\n').split('\n').slice(0, 2).join('\n') }}
            />)}
        </Link>
      </div>
    }</Combobox.Option>
  )
}

export function useHandleDocumentResults() {
  let navigate = useNavigate()

  return {
    handleDocumentResult: (result: { to: string }) =>
      navigate(result.to)
  }
}

export function DocumentResults({ query }: { query: string }) {
  const {t} = useTranslation()

  const [inTrash, setInTrash] = useState(false)
  const [searchResult, setSearchResult] = useState(undefined) // TODO
  const { previewLink } = useBrowser()

  const { number } = useDocumentSettings()
  const root = getClientRoot(number)
  const { object } = useAgorumObject({ root })
  const start = object?.uuid

  useEffect(() => {
    if ((query !== '' || inTrash) && doQuery) {
      doQuery({ query, start, inTrash, setSearchResult })
    } else if (query === '' && !inTrash) {
      setSearchResult(undefined)
    }
  }, [query, start, inTrash])

  //console.log({searchResult, start})

  if (!start) return null

  return <>
    <div className="px-5 py-2 text-sm text-gray-500 flex flex-row gap-x-6">
      <label className="block">
        <input className="form-checkbox mr-2" type="checkbox" checked={inTrash} onChange={() => setInTrash(!inTrash)} />
        <span>{t('search-in-trash')}</span>
      </label>
    </div>

    {searchResult?.rows?.length > 0 &&
      <Combobox.Options className="max-h-[80vh] scroll-py-2 overflow-y-auto p-2 text-sm" static >
        {searchResult.rows.map((result, i) => 
          <SearchResult key={i} {...{ result: { ...result, to: previewLink(result) } }} />)}
      </Combobox.Options>
    }

    {query !== '' && searchResult?.rows?.length === 0 && (
      <div className="px-6 py-14 text-center sm:px-14">
        <FolderIcon className="mx-auto h-6 w-6 text-gray-900 text-opacity-40" aria-hidden="true" />
        <p className="mt-4 text-sm text-gray-900">
          {t('no-results-try-again')}
        </p>
      </div>
    )}

  </>
}