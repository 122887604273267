import React, { useState, useEffect} from 'react'

import Package from '../../package.json'
import { useMessages } from '../Messages'
import { Trans } from 'react-i18next'

const packageVersion = Package.version

function useServerVersion() {
    const [serverVersion, setVersion] = useState(undefined)

    useEffect(() => {
        if (serverVersion === undefined)
            fetch('/version.json')
                .then(r => r.json())
                .then(({version}) => setVersion(version))
    }, [serverVersion])

    return {serverVersion}
}

export function ModernVersion() {
    const { serverVersion } = useServerVersion()
    const { setInfoMessage, infoMessage } = useMessages()

    // If there is another infoMessage, the reload won't be shown. But we can live with that.
    if (serverVersion && (packageVersion !== serverVersion) && infoMessage === undefined) {
        setInfoMessage(<span><Trans i18nKey="version-reload" components={{
            reload: <button className='underline' onClick={() => window.location.reload()}></button>
        }} /></span>)
    }

    return <div className=" text-gray-400 text-xs font-thin">{packageVersion}</div>
}

export default function Version({smallMenu}: {smallMenu: boolean}) {
    const { serverVersion } = useServerVersion()
    const { setInfoMessage, infoMessage } = useMessages()

    // If there is another infoMessage, the reload won't be shown. But we can live with that.
    if (serverVersion && (packageVersion !== serverVersion) && infoMessage === undefined) {
        setInfoMessage(<span><Trans i18nKey="version-reload" components={{
            reload: <button className='underline' onClick={() => window.location.reload()}></button>
        }} /></span>)
    }

    return (
        smallMenu
            ? <div className="h-4" />
            : <div className="pl-10 pt-0 text-gray-400 text-xs font-thin">{packageVersion}</div>
    )
}