import React, { ReactElement, createContext, useContext, useEffect, useState } from "react"
import { Outlet, useLocation, useNavigate, useParams } from "react-router"
import { Link, createSearchParams, useSearchParams } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import { useTranslation, Trans } from "react-i18next"
import { Formik, Form, Field, useField, useFormikContext } from "formik"
import { ArrowDownTrayIcon } from "@heroicons/react/24/outline"
import _ from "lodash"

import { AddAgentMask } from "../agents/AddAgent"
import KanjiTip from "../components/KanjiTip"
import { useRoles } from "../user/Auth"
import { useComments } from "../comments/CommentsProvider"
import { Comment } from "../comments/Comments"
import { epoLoad } from "../backend"
import { extractAgents, splitAgents, familyUrl, memberUrl } from "./utils"
import { asUrl } from '../components/edit-table'
import Trinary from "../components/input/Trinary"
import { DatePicker } from '../components/DatePicker'
import { IconEdit, IconExternalLink, IconPlus, IconSpinner } from '../components/icons'
import Modal from "../components/Modal"
import { epCountries, euCountries, family_member, agent_link, patent_family, countryCodes, ipTypes, familyMemberStates, unitaryPatentCountries } from '../data'
import { ImageCaroussel, PlainImage } from "../components/Image"
import Claims from "../claims/Claims"
import { saveLinks, useBackend } from "../BackendProvider"
import { FamilyBreadCrumb, KeyValues } from "./Family"
import { useLocationHistory } from "../cockpit/Location"
import { TagListField } from "../components/TagList"
import { background } from "../valuations/Valuations"
import clsx from "clsx"
import { useMemberScores } from "../valuations/scores"
import { Family, Member } from "./patents"
import { useTasks } from "../tasks/TasksProvider"
import Task from "../tasks/Task"
import { deriveDate } from "../tasks/utils"
import DeleteButton from "../components/DeleteButton"
import { MemberRenewalBibliographic } from "../renewal/MemberOverview"
import { usePatents } from "./PatentsProvider"
import { LinkedTabCard } from "../components/TabCard"
import { FocusedBrowser } from "../documents/FocusedBrowser"
import { BreadCrumbs } from "../components/BreadCrumbs"


const ribbonTitles = {
  'unitaryPatent': unitaryPatentCountries.join(', ')
}

export function MemberDetails({family, member, isEditUser}: {family: Family, member: Member, isEditUser: boolean}) {
  const {t}  = useTranslation();
  const navigate = useNavigate()
  const { hasClaimScopes, hasAnnuities } = useRoles()

  const { deleteMember } = usePatents()
  const {claims, agents, agentLinks, claimScopes, images} = useBackend()

  const familyMemberId = member.familyMemberId

  const score = useMemberScores(familyMemberId) ?? '-'

  const [currentInventors, tagDisplays] = extractAgents({ link: 'inventor', agents, agentLinks, familyMemberId })
  const [currentOwners] = extractAgents({ link: 'owner', agents, agentLinks, familyMemberId })
  const [currentApplicants] = extractAgents({ link: 'applicant', agents, agentLinks, familyMemberId })

  const memberClaimScopes = claims
    .filter(c => c.familyMemberId === familyMemberId && c.claimScopeId !== undefined)
    .map(c => {
      const claimScopeId = c.claimScopeId
      const claimNumber = c.claimNumber
      const image = images.find(i => i.entity === "claim-scope" && i.entityId === claimScopeId)
      const summary = claimScopes.find(c => c.claimScopeId === claimScopeId)?.claimScopeSummary ?? ""
      const caption = `${summary}`
      return { version: c.version, versionDate: c.versionDate, number: claimNumber, image, caption }
    })
  const maxClaim = _.maxBy(memberClaimScopes, c => c.versionDate)
  const relevantClaimScopes = memberClaimScopes.filter(c => c.version === maxClaim?.version).sort((a, b) => a.number - b.number)

  const patentDescription = `${t(member.familyMemberStatus, { context: 'neutral' })} ${t(member.ipType)} ${t('in')} ${member.countryCode}`


  return (
    <div className="flex flex-col">
        <div className="border-b-2 border-pcx-200 pb-5">
          <div className="flex flex-row gap-1 w-full">
            <h3 className="mb-4 max-w-prose text-base font-normal text-gray-500 mr-4 grow">{member.title}</h3>
            {isEditUser && <>
              <Link title="Edit Member" to="edit" className="btn-primary ml-auto shrink-0 w-5 h-5 p-px"><IconEdit /></Link>
              <DeleteButton
                className="w-5 h-5 p-px btn-warn shrink-0"
                deleteAction={() => deleteMember(member)
                  .then(() => navigate(familyUrl(family)))
                }
              />
            </>}
          </div>
          <div className="flex flex-col-reverse sm:grid sm:grid-cols-[1fr_12rem] gap-x-8 gap-y-4">
            <div className="self-start grid grid-cols-1 sm:grid-cols-[10rem_1fr] lg:grid-cols-[10rem_1fr] gap-x-4 gap-y-1">
              <div className="sm:col-start-2">
                {typeof member.patentOfficeLink === "string" && member.patentOfficeLink !== ""
                  && <a
                    className="underline inline-flex gap-1"
                    target="_blank"
                    rel="noreferrer"
                    href={asUrl(member.patentOfficeLink)}
                  >
                    <IconExternalLink />{t('external-link')}
                  </a>
                }
              </div>

              <PatentDocumentInfo {...{ title: t("application"), number: member.applicationNumber, date: member.applicationDate }} />
              <PatentDocumentInfo {...{ title: t("publication"), number: member.publicationNumber, date: member.publicationDate }} />
              <PatentDocumentInfo {...{ title: t("patent"), number: member.patentNumber, date: member.patentDate }} />
              <PatentDocumentInfo {...{ title: t("status"), number: patentDescription }} />
              <PatentDocumentInfo {...{ title: t("expiryDate"), number: member.expiryDate }} />
              <PatentDocumentInfo {...{ title: t("ext-reference"), number: member.externalReference }} />
              <PatentDocumentInfo {...{ title: t("num-claims"), number: member.numberClaims?.toString() }} />
              <PatentDocumentInfo {...{ title: t("score"), number: <span className={score !== '-' ? ('px-2 py-1 text-black/75 ' + background(score)) : ''}>{score}</span> }} />

              <div className="sm:col-span-2 flex flex-row flex-wrap items-start gap-1 empty:mt-0 mt-2 ">
                {["validated", "pctRouteFiling", "firstFiling", "unitaryPatent", "optOut"]
                  .filter(v => member[v] === true)
                  .map(v =>
                    <div key={v} title={ribbonTitles[v]}
                      className="text-sm bg-pc-200 border border-pc-300 px-1 rounded-sm whitespace-nowrap text-pcx-700"
                    >{t(v)}</div>)
                }
              </div>
            </div>
            <div className="sm:min-h-[8rem] max-lg:pb-2 lg:pr-2">
              {!hasClaimScopes
                ? <PlainImage {...{
                  entity: patent_family,
                  entityId: family.patentFamilyId,
                  title: `${member.internalReference}: ${member.title}`,
                  text: family.summary,
                  hint: t('change-in-family')
                }} />
                : <ImageCaroussel {...{
                  images: relevantClaimScopes,
                }} >
                  <div className="italic text-center pt-5">
                    {t('add-images')} <br /><a className="underline" href="#claims-manager">{t('claims-manager')}</a>.
                  </div>
                </ImageCaroussel>
              }
            </div>
          </div>
        </div >
      {hasAnnuities &&
        <div className="grid grid-cols-1 sm:grid-cols-[10rem_1fr] lg:grid-cols-[10rem_1fr] gap-x-4 gap-y-1 py-5 border-b-2 border-pcx-200">
          <MemberRenewalBibliographic {...{ familyMemberId }} />
        </div>}
        <div className="pt-5 pb-3 flex flex-col gap-2 justify-between">
          <div className="grid grid-cols-1 sm:grid-cols-[10rem_1fr] gap-x-4 gap-y-1">
            <KeyValues title={t("inventors")}>{currentInventors.map(a => <AgentRibbon key={a} {...{ a, tagDisplays }} />)}</KeyValues>
            <KeyValues title={t("applicants")}>{currentApplicants.map(a => <AgentRibbon key={a} {...{ a, tagDisplays }} />)}</KeyValues>
            <KeyValues title={t("owners")}>{currentOwners.map(a => <AgentRibbon key={a} {...{ a, tagDisplays }} />)}</KeyValues>
          </div>
          {isEditUser &&
            <div className="self-end">
              <Link to='/settings/agents' className="btn-secondary w-fit whitespace-nowrap text-sm">{t('manage-agents')}</Link>
            </div>}
        </div>
    </div>
  )
}

function MemberComments({member}: {member: Member}) {
  const {t} = useTranslation()
  const {commentsLookUp} = useComments()
  const { isEditUser } = useRoles()

  return (
    <div>
      <div className="pb-4 flex flex-row">
        <h4 className="grow">{t('comments')}</h4>
        {isEditUser &&
          <Link 
            to={`comments?entity=${family_member}&entityId=${member.familyMemberId}`} 
            className="btn-secondary py-0.5 text-sm whitespace-nowrap"
            title={t('add-comment')}
          >
            {t('add')}
          </Link>}
      </div>
      <div className="max-h-[20rem] xl:max-h-[36rem] overflow-y-auto flex flex-col gap-2">
        {(commentsLookUp[family_member]?.[member.familyMemberId] ?? [])
          .map(c => <Comment key={`comment-${c.commentId}`} {...c} />)
        }
      </div>
    </div>
  )
}

function TasksOfMember({member}: {member: Member}) {
  const {t} = useTranslation()
  const {isEditUser} = useRoles()
  const {tasksLookUp} = useTasks()

  const tasks = _(tasksLookUp[family_member]?.[member.familyMemberId] ?? [])
    .filter(t => !t.done)
    .map(deriveDate)
    .sortBy(t => t.date)
    .value()

  const basicParams = {entity: family_member, entityId: '' + member.familyMemberId}

  return (
    <div className="flex flex-col gap-2 sm:max-w-lg">
      <div className="flex flex-row gap-2 justify-between mb-2"> 
        <h4>{t('tasks')}</h4>
        {isEditUser && 
          <Link title={t('add-task')} to={`task?${createSearchParams(basicParams).toString()}`} className="text-sm btn-secondary">
            {t('add')}
          </Link>}
      </div>
      {tasks.map(task => <Task key={task.taskId ?? '-1'} {...{task, entity: family_member, entityId: member.familyMemberId}} /> )}
    </div>
  )
}

function AgentRibbon({a, tagDisplays}) {
  return (
    <span className={agentStyle}>
      <KanjiTip kanjiOpt={tagDisplays[a]}>{tagDisplays[a]}</KanjiTip>
    </span>
  )
}

const agentStyle = "pr-2 inline-flex after:content-[';'] last:after:content-[''] last:pr-0"

interface PatentDocumentProps {
  title: string;
  number?: string | ReactElement;
  date?: string;
}
function PatentDocumentInfo({title, number, date}: PatentDocumentProps) {
  if (number || date)
    return (
      <>
        <div className="sm:text-right text-gray-500">{title}</div>
        <div className="pl-4 sm:pl-0">{number ?? '-'} {date && `(${date})`}</div>
      </>
    )
  else
    return null
}


function AddAgentButton({setShowMask}) {
  const {t} = useTranslation()
  return (
    <button type="button" title={t('add-new')} className="h-6 w-6 cursor-pointer btn-secondary p-px" onClick={() => setShowMask(true)}><IconPlus /></button>
  )
}

const labelStyle = "text-pc-600/80 font-medium whitespace-nowrap"

export function EditFamilyMember() {
  const { members, families, postMember, } = usePatents()
  const { claims, entityOperation, agents, agentLinks, linkOperation, isLoading } = useBackend()
  const {t} = useTranslation()

  let { internalReference } = useParams()
  let [searchParams] = useSearchParams()
  let location = useLocation()

  let navigate = useNavigate()

  const member = members.find(m => m.internalReference === internalReference)
  const otherMembers = new Set(members.map(m => m.internalReference).filter(m => m !== internalReference))
  //console.log(member, internalReference)
  const isAdding = member === undefined

  const [showInventorAddMask, setShowInventorAddMask] = useState(false)
  const [showApplicantAddMask, setShowApplicantAddMask] = useState(false)
  const [showOwnerAddMask, setShowOwnerAddMask] = useState(false)

  const splittedAgents = splitAgents({ agents, allowedAgents: ['person', 'company'] })
  //console.log(agents)

  const familyMemberId = member?.familyMemberId

  const agentsById = _.keyBy(agents, a => a.agentId)

  const [currentInventors, tagDisplays] = extractAgents({familyMemberId, agents, agentLinks, link: "inventor"})
  const [currentApplicants] = extractAgents({familyMemberId, agents, agentLinks, link: "applicant"})
  const [currentOwners] = extractAgents({familyMemberId, agents, agentLinks, link: "owner"})
  //console.log(currentInventors)

  const companies = (splittedAgents.company ?? []).map(a => a.agentId).sort((a, b) => tagDisplays[a].localeCompare(tagDisplays[b]))
  const persons = (splittedAgents.person ?? []).map(a => a.agentId).sort((a, b) => tagDisplays[a].localeCompare(tagDisplays[b]))

  const patentFamilyId = member?.patentFamilyId ?? parseInt(searchParams.get('patentFamilyId'))
  const family = families.find(f => f.patentFamilyId === patentFamilyId)
  const copyFromRef = searchParams.get('copyFrom')
  const srcMember = (members.find(m => m.internalReference === copyFromRef) ?? location.state) ?? {}
  const isCopying = srcMember?.familyMemberId !== undefined
  const copyAgentsFrom = (link) =>
    isCopying
      ? extractAgents({ familyMemberId: srcMember?.familyMemberId, agents, agentLinks, link })[0]
      : []
  //console.log(patentFamilyId, family)
  //console.log(families)
  const copyClaims = claims.filter(c => c.familyMemberId === srcMember?.familyMemberId)

  const initialValues: Member & {inventors: any[], owners: any[], applicants: any[]} = !isAdding 
    ? {...member, inventors: currentInventors, owners: currentOwners, applicants: currentApplicants}
    : {
      title: "",
      countryCode: "WO",
      internalReference: "",
      externalReference: undefined,
      familyMemberStatus: 'in-preparation',
      ipType: 'patent',
      number_claims: undefined,
      applicationNumber: undefined,
      applicationDate: undefined,
      publicationNumber: undefined,
      publicationDate: undefined,
      patentNumber: undefined,
      patentDate: undefined,
      validated: undefined,
      pctRouteFiling: undefined,
      firstFiling: undefined,
      unitaryPatent: undefined,
      optOut: undefined,
      expiryDate: undefined,
      inventors: copyAgentsFrom('inventor'),
      applicants: copyAgentsFrom('applicant'),
      owners: copyAgentsFrom('owner'),
      patentFamilyId,
      ...srcMember,
      patentOfficeLink: (srcMember?.pctRouteFiling || srcMember?.countryCode === 'EP' || srcMember?.validated || location?.state)
        ? srcMember.patentOfficeLink
        : undefined
    }
  //console.log(isAdding, initialValues)

  // newLinks is list of strings, not integers
  function updateLinks(currentLinks, newLinks, linkType, familyMemberId) {
    const asLink = (id) => {
      const agentId = +id
      const {agentType} = agentsById[id]
      return {agentId, familyMemberId, agentType, linkType}
    }
    if (newLinks)
      return saveLinks(currentLinks.map(asLink), newLinks.map(asLink), linkOperation, agent_link)
    else
      return Promise.resolve({})
  }

  return (isLoading 
    ? null
    : <Modal escAction={() => (!showApplicantAddMask && !showInventorAddMask && !showOwnerAddMask) && navigate(-1)}>
        <Formik 
          initialValues={initialValues}
          validateOnBlur={false}
          validateOnChange={true}
          validate={({internalReference, patentDate, patentNumber, countryCode, numberClaims, validated, optOut, unitaryPatent}) => {
              const errors: Record<string, string> = {}
              if (internalReference === undefined || internalReference === "")
                errors.internalReference = t('reference-required')
              else if (otherMembers.has(internalReference))
                errors.internalReference = t('reference-used')

              if (countryCode === "WO") {
                if (patentDate)
                  errors.patentDate = t('no-wo-patent-date')
                if (patentNumber)
                  errors.patentNumber = t('no-wo-patent-num')
              }

              if (typeof numberClaims === 'number' && numberClaims < 0)
                errors.numberClaims = t('num-claims-positive')
                  
              //if (optOut && (!validated || countryCode !== "EP") && !unitaryPatent)
              if (optOut && !(validated || (countryCode === "EP" && !unitaryPatent)))
                errors.optOut = t('opt-out-not-validated')

              return errors
          }}
          onSubmit={(values: Member & {numberClaims: string, inventors: any[], applicants: any[], owners: any[]}, {setSubmitting}) => {
            // make sure numberClaims is a number
            const numberClaims = (values.numberClaims === "" || isNaN(values.numberClaims)) ? undefined : parseInt(values.numberClaims)
            // remove validate/opt-out for non-EP countries; EP (non-UP) can also opt-out
            const {optOut, validated} = 
              values.countryCode === "EP" && !values.unitaryPatent
                ? values
                : (epCountries.indexOf(values.countryCode) < 0 ? {optOut: undefined, validated: undefined} : values)
            // remove unitary patent flag non-EP members
            const unitaryPatent = values.countryCode === "EP" ? values.unitaryPatent : undefined
            const familyMemberId = isAdding ? undefined : values.familyMemberId
            const hasBeenStopped = !isAdding && values.familyMemberStatus === 'stopped' && member.familyMemberStatus !== 'stopped'
            postMember({...values, numberClaims, optOut, validated, unitaryPatent, familyMemberId})
              .then((resp: Member) => {
                const fmi = isAdding ? resp.familyMemberId : familyMemberId
                return updateLinks(currentInventors, values.inventors, 'inventor', fmi)
                  .then(() => updateLinks(currentApplicants, values.applicants, 'applicant', fmi))
                  .then(() => updateLinks(currentOwners, values.owners, 'owner', fmi))
                  .then(() => (copyClaims.length > 0 && isAdding)
                    ? Promise.all(copyClaims.map(c => entityOperation('claim', 'add', { ...c, familyMemberId: fmi }))).then(() => entityOperation('claim', 'get'))
                    : Promise.resolve({})
                  )
              })
              .then(() => navigate(memberUrl(values), {state: {hasBeenStopped}})) // the state is read in the member overview from renewals
              .catch(err => {console.warn(err); setSubmitting(false)})
            //console.log(values)
          }}
        >{({values: {inventors, owners, applicants, countryCode, unitaryPatent, firstFiling, pctRouteFiling, validated, optOut}, errors, touched, setFieldValue, isSubmitting}) => {

            const cannotBeValidated = epCountries.indexOf(countryCode) < 0
            const canOptOut = (euCountries.indexOf(countryCode) >= 0 && !cannotBeValidated && validated !== false ) || 
              (countryCode === "EP" && !unitaryPatent)

            if (!canOptOut && optOut) {
              setFieldValue('optOut', false)
            }

            let inventorAddMask = <AddAgentMask {...{
              title: t('create-inventor'),
              setShowMask: setShowInventorAddMask, 
              withCompanies: false,
              update: (v) => setFieldValue('inventors', [...inventors, v])
            }}/>
            let ownerAddMask = <AddAgentMask {...{
              title: t('create-owner'),
              setShowMask: setShowOwnerAddMask, 
              update: (v) => setFieldValue('owners', [...owners, v])
            }}/>
            let applicantAddMask = <AddAgentMask {...{
              title: t('create-applicant'),
              setShowMask: setShowApplicantAddMask, 
              update: (v) => setFieldValue('applicants', [...applicants, v])
            }}/>
            return (
              <div className="max-w-4xl space-y-2">
              <h4 className="px-4 pt-4 pb-0 text-center">{isAdding
                ? <Trans i18nKey="add-to-family-name" values={{ name: `${family?.internalReference} - ${family?.familyName}` }} />
                : <Trans i18nKey="edit-family-member-name" values={{ name: internalReference }} />
              }</h4>
              {isCopying && <div className="text-center text-sm text-gray-500">{t('copy-from-name', {name: `${srcMember.internalReference} (${srcMember.countryCode})`})}</div>}
              <Form className="">
                <div className="flex flex-col items-center px-4 pb-4">
                  <LoadButton {...{patentFamilyId}} />
                </div>
                <div className="p-4 pt-0 grid sm:grid-cols-3 grid-cols-1 gap-2 md:gap-x-4">
                  <RichField name="title" className="sm:col-span-3">
                    <Field className="h-8 px-2 form-input" />
                  </RichField>

                  <RichField name="countryCode">
                    <Field className="h-8 px-2 py-1 form-select" as="select">
                      {countryCodes.map(value => <option key={value} value={value}>{value}</option>)}
                    </Field>
                  </RichField>

                  <RichField name="internalReference">
                    <Field className="h-8 px-2 form-input" required autoFocus />
                  </RichField>

                  <RichField name="externalReference">
                    <Field className="h-8 px-2 form-input" />
                  </RichField>

                  <RichField name="familyMemberStatus">
                    <Field className="h-8 px-2 py-1 form-select" as="select">
                      {familyMemberStates.map(value => <option key={value} value={value}>{t(value) ?? value}</option>)}
                    </Field>
                  </RichField>

                  <RichField name="ipType">
                    <Field className="h-8 px-2 py-1 form-select" as="select">
                      {ipTypes.map(value => <option key={value} value={value}>{t(value) ?? value}</option>)}
                    </Field>
                  </RichField>

                  <RichField name="numberClaims">
                    <Field className="h-8 px-2 form-input" type="number" />
                  </RichField>

                  <div className="flex flex-col gap-2 justify-between">
                    <RichField name="applicationNumber">
                      <Field className="h-8 px-2 form-input" />
                    </RichField>

                    <RichField name="applicationDate">
                      <Field className="h-8 px-2 form-input" as={DatePicker} />
                    </RichField>
                  </div>

                  <div className="flex flex-col gap-2 justify-between">
                    <RichField name="publicationNumber">
                      <Field className="h-8 px-2 form-input" />
                    </RichField>

                    <RichField name="publicationDate">
                      <Field className="h-8 px-2 form-input" as={DatePicker} />
                    </RichField>
                  </div>

                  <div className="flex flex-col gap-2 justify-between">
                    <RichField name="patentNumber">
                      <Field className="h-8 px-2 form-input" />
                    </RichField>

                    <RichField name="patentDate">
                      <Field className="h-8 px-2 form-input" as={DatePicker} />
                    </RichField>
                  </div>

                  <RichField name="patentOfficeLink" label="external-link" className="sm:col-span-3">
                    <Field className="h-8 px-2 form-input" />
                  </RichField>

                  <RichField name="expiryDate">
                    <Field className="h-8 px-2 form-input" as={DatePicker} />
                  </RichField>

                  <div className="flex flex-col gap-2 pt-2">
                    <label className="inline-flex gap-2">
                      <Trinary name="firstFiling" disabled={pctRouteFiling === true}/>
                      <span className={labelStyle}>{t('firstFiling')}</span>
                    </label>

                    <label className="inline-flex gap-2">
                      <Trinary name="pctRouteFiling" disabled={firstFiling === true} />
                      <span className={labelStyle}>{t('pctRouteFiling')}</span>
                    </label>

                    <label className="inline-flex gap-2">
                      <Trinary name="unitaryPatent" disabled={countryCode !== "EP"} />
                      <span className={`${labelStyle} ${countryCode !== "EP" ? "text-slate-200" : ""}`}>{t('unitaryPatent')}</span>
                    </label>
                    {['firstFiling', 'pctRouteFiling', 'unitaryPatent'].map(field => touched[field] && errors[field] && <div className="text-sm text-red-700">{errors[field] as string}</div>)}
                  </div>

                  <div className="flex flex-col gap-2 pt-2 pb-2">
                    <label className="inline-flex gap-2">
                      <Trinary name="validated" disabled={cannotBeValidated} />
                      <span className={`${labelStyle} ${cannotBeValidated ? "text-slate-200" : ""}`}>{t('validated')}</span>
                    </label>

                    <label className="inline-flex gap-2">
                      <Trinary name="optOut" disabled={!canOptOut} />
                      <span className={`${labelStyle} ${!canOptOut ? "text-slate-200" : ""}`}>{t('optOut')}</span>
                    </label>
                    {['valdiated', 'optOut'].map(field => touched[field] && errors[field] && <div className="whitespace-normal text-sm text-red-700">{errors[field] as string}</div>)}
                  </div>

                  <div className="sm:col-span-3">
                    <RichField name="inventors">
                      <TagListField {...{
                        availableTags: persons,
                        tagDisplays,
                        name: 'inventors',
                        placeholder: t("add"),
                        addButton: <AddAgentButton {...{ setShowMask: setShowInventorAddMask }} />
                      }} />
                    </RichField>
                  </div>

                  <div className="sm:col-span-3">
                    <RichField name="applicants">
                      <TagListField {...{
                        availableTags: { Companies: companies, Persons: persons },
                        tagDisplays,
                        name: 'applicants',
                        placeholder: t("add"),
                        addButton: <AddAgentButton {...{ setShowMask: setShowApplicantAddMask }} />
                      }} />
                    </RichField>
                  </div>

                  <div className="sm:col-span-3">
                    <RichField name="owners">
                      <TagListField {...{
                        availableTags: { Companies: companies, Persons: persons },
                        tagDisplays,
                        name: 'owners',
                        placeholder: t("add"),
                        addButton: <AddAgentButton {...{ setShowMask: setShowOwnerAddMask }} />
                      }} />
                    </RichField>
                  </div>

                </div>

                <div className="flex flex-row-reverse gap-4 bg-pc-200 p-4 col-span-3">
                  <input type="submit" className={clsx("btn-primary", isSubmitting && "animate-pulse")} disabled={isSubmitting} value={t("save")} />
                  {/* @ts-ignore */}
                  <Link to={-1} className="btn-secondary">{t('cancel')}</Link>
                </div>
              </Form>
              {showInventorAddMask && inventorAddMask}
              {showOwnerAddMask && ownerAddMask}
              {showApplicantAddMask && applicantAddMask}
            </div>
          )
        }}</Formik>
    </Modal>
  )
}

const checkNames = [
      'title',
      'countryCode',
      'familyMemberStatus',
      'ipType',
      'applicationNumber',
      'applicationDate',
      'publicationNumber',
      'publicationDate',
      'patentNumber',
      'patentDate',
      //'validated', // do not update validated
      'pctRouteFiling',
      'firstFiling',
      'unitaryPatent',
      'inventors',
      'applicants',
      'owners',
      'patentOfficeLink',
]

// function to test is a string is non empty and not undefined
function nonEmptyString(str) {
  return str !== undefined && str !== ''
}

function emptyArray(arr) {
  return Array.isArray(arr) && arr.length === 0
}

function LoadButton({patentFamilyId}) {
  const {t} = useTranslation()
  const { values, setErrors, setTouched, setValues } = useFormikContext<Member>()
  const { entityOperation } = useBackend()
  const [error, setError] = useState(undefined)
  const [lastValues, setLastValues] = useState(undefined)
  const [isLoading, setIsLoading] = useState(false)
  
  if (error && !_.isEqual(values, lastValues)) {
    setError(undefined)
  }

  function handleLoad() {
    if (!(nonEmptyString(values.publicationNumber) || nonEmptyString(values.patentNumber))) {
      setErrors({ publicationNumber: "Publication number is required" })
      setTouched({ publicationNumber: true }, false)
    } else {
      setIsLoading(true)
      let publicationNumber = (nonEmptyString(values.patentNumber) ? values.patentNumber : values.publicationNumber).trim().replaceAll(' ', '')
      // Check if publication number starts with two characters. If not, add the country code
      if (!publicationNumber.match(/^[A-Za-z]{2}/)) {
        publicationNumber = values.countryCode + publicationNumber
      }
      epoLoad({ patentFamilyId, publicationNumber })
        .then(mem =>
          entityOperation('agent', 'get').then(() => {
            const updateValues = {...values}
            // Only ids needed
            mem['inventors'] = mem['inventors']?.map(a => a.agentId)
            mem['applicants'] = mem['applicants']?.map(a => a.agentId)
            for (const name of checkNames) {
              //console.log({name, value: values[name], empty: !nonEmptyString(values[name])})
              if (name in mem && (!nonEmptyString(values[name]) || emptyArray(values[name]))) {
                updateValues[name] = mem[name]
              }
              // Country code is set by default to WO otherwise
              // Only if country code is WO, the value has probably not been set
              if (name === 'countryCode' && mem[name] !== values[name] && values[name] === 'WO' && nonEmptyString(mem[name])) {
                updateValues[name] = mem[name]
              }
            }
            //console.log({mem, updateValues})
            setValues(updateValues)
          }),
          err => {
            console.error(err.message)
            setError(`Could not load data from EPO for ${publicationNumber}`)
            setLastValues(values)
          }
        )
        .finally(() => setIsLoading(false))
    }
  }
  return <>
    <div className="bg-pcx-200 rounded-sm text-center w-full py-0.5">
      {t("fill-the-form-or")}
      <button className="btn-tertiary inline-flex gap-1 items-center" type="button" onClick={handleLoad}>
        {t('load-from-epo')} {isLoading ? <IconSpinner className='h-5 w-5 animate-spin' />  : <ArrowDownTrayIcon className="h-5 w-5" /> }
      </button>
    </div>
    {error && <div className='text-red-600 p-2 pb-0'>{error}</div>}
  </>
}

function RichField({name, label = undefined, className = '', children}) {
  const {t} = useTranslation()
  // eslint-disable-next-line
  const [field, meta] = useField(name)
  return (
    <div className={"flex flex-col " + className}>
      <label htmlFor={name} aria-label={label ?? name}>
        <span className={labelStyle}>{t(label ?? name)}</span>
      </label>
      {React.cloneElement(children, { name, id: name })}
      {meta.touched && meta.error ? <div className="text-red-600">{meta.error}</div> : null}
    </div>

  )
}

export function FamilyMemberIndex() {
  const {t} = useTranslation()
  const {hasDocuments} = useRoles()

  let { internalReference } = useParams()
  let navigate = useNavigate()
  const {getLastMatchingPath} = useLocationHistory()

  const { familyById, memberByReference } = usePatents()
  const member = memberByReference[internalReference]
  const family = familyById[member?.patentFamilyId]

  // redirect to portfolio if member is not found for a too long time
  useEffect(() => {
      const timer = setTimeout(() => {
          if (member === undefined)
              navigate("/patents/portfolio")
      }, 1500)
    return () => clearTimeout(timer)
  }, [member, navigate])

  if (!member || !family) return null

  return <>
    {/* @ts-ignore */}
    <Helmet>
      <title>{t('patent')} {member.internalReference} | Patent Cockpit</title>
    </Helmet>

    <BreadCrumbs parts={[
      {
        className: 'max-sm:hidden',
        label: t('patents'),
        to: (getLastMatchingPath("/patents/portfolio?")?.path ?? "..") + '#' + encodeURIComponent(family.internalReference)
      },
      <FamilyBreadCrumb family={family} />,
      { to: memberUrl(member), label: member.internalReference }
    ]} />
    <div className="overflow-auto grow px-2 sm:px-4">
      <div className="w-full md:w-fit">
        <div className= "lg:min-w-5xl xl:min-w-6xl">
          <MemberProvider {...{member, family}}>
            <LinkedTabCard links={[
              { to: ".", label: t('overview') },
              { to: "claims", label: t('claims') },
              hasDocuments && { to: "documents", label: t('documents') },
            ]} />
          </MemberProvider>
        </div>
      </div>
    </div>
  </>
}

let MemberContext = createContext({
  member: undefined as Member,
  family: undefined as Family,
})

function MemberProvider({member, family, children}) {
  return <MemberContext.Provider value={{family, member}}>{children}</MemberContext.Provider>
}

function useMember() {
  return useContext(MemberContext)
}

export function FamiliyMemberOverview() {
  const {isEditUser, hasTasks} = useRoles()
  const {family, member} = useMember()

  if (!member || !family) return null // TODO: redirect to Portfolio

  return (
        <div className="grid grid-cols-1 lg:grid-cols-[minmax(40rem,48rem)_45ch] max-lg:divide-y-2 lg:divide-x-2 divide-pcx-200">
          <div className="max-lg:pb-4 lg:pr-4 grow">
            <MemberDetails {...{ member, family, isEditUser }} />
          </div>
          <div className="max-lg:pt-4 lg:pl-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-1 gap-6 items-start">
            <MemberComments {...{ member }} />
            {hasTasks && <TasksOfMember {...{ member }} />}
          </div>
          <Outlet />
        </div>
      )
}

export function FamilyMemberClaims() {
  const {member} = useMember()

  if (!member) return null

  return <Claims />
}

export function FamilyMemberDocuments() {
  const {family, member} = useMember()

  if (!member || !family) return null

  return <FocusedBrowser entity={family_member} internalReference={member.internalReference} parent={family.internalReference} parentName={family.familyName} />
}