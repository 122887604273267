import {Button, CalendarCell, CalendarGrid, DateInput, DateRangePicker, DateSegment, DateValue, Group, Heading, RangeCalendar} from 'react-aria-components';
import { Popover } from '@headlessui/react';
import { parseDate } from '@internationalized/date';
import { CalendarDaysIcon, ChevronLeftIcon, ChevronRightIcon  } from '@heroicons/react/20/solid';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

export interface SelectDateRangeProps {
    className?: string;
    name: string;
    minDate: string;
    minValue?: DateValue;
    maxDate: string;
    setMinDate: (date: string) => void;
    setMaxDate: (date: string) => void;
    title?: string;
    disabled?: boolean;
}

const dataSegmentStyle = "focus:ring-0 focus:outline-none tabular-nums"

function saveParseDate(date: string) {
    try {
        return parseDate(date)
    } catch {
        return undefined
    }
}

export default function SelectDateRange({ className = undefined, name, minDate, maxDate, setMinDate, setMaxDate, title, disabled, ...props }: SelectDateRangeProps) {
    const {t} = useTranslation()

    const isInvalid = minDate && maxDate && minDate > maxDate

    return (
        <DateRangePicker
            aria-label={name}
            isDisabled={disabled}
            shouldForceLeadingZeros={true}
            value={{
                start: saveParseDate(minDate),
                end: saveParseDate(maxDate),
            }}
            onChange={(value) => {
                setMinDate(value.start?.toString())
                setMaxDate(value.end?.toString())
            }}
            isInvalid={isInvalid}
            {...props}
        >
            <Group 
                className={clsx('flex flex-row gap-1.5 items-center form-input', className, isInvalid && 'border-red-600 text-red-800')} 
                title={isInvalid ? t("start-date-before-end-date") : title}
            >
                <DateInput slot="start" className="flex flex-row gap-0.5">
                    {(segment) => <DateSegment className={dataSegmentStyle} segment={segment} />}
                </DateInput>
                <span aria-hidden="true">–</span>
                <DateInput slot="end" className="flex flex-row gap-0.5">
                    {(segment) => <DateSegment className={dataSegmentStyle} segment={segment} />}
                </DateInput>
                <Popover className="relative">
                    <Popover.Button disabled={disabled} className="block">
                        <CalendarDaysIcon className='h-5 w-5 text-pcx-700' />
                    </Popover.Button>
                    <Popover.Panel className="absolute mt-2 -right-2 bg-white rounded-md shadow border border-pcx-400 p-2">
                        <RangeCalendar>
                            <header className='flex flex-row gap-2 w-full justify-between mb-3'>
                                <Button slot="previous"><ChevronLeftIcon className='h-5 w-5' /></Button>
                                <Heading />
                                <Button slot="next"><ChevronRightIcon className='h-5 w-5' /></Button>
                            </header>
                            <CalendarGrid>
                                {(date) =>
                                    <CalendarCell
                                        className={({ isSelected, isDisabled }) => clsx(
                                            'tabular-nums m-0.5 p-1 text-right rounded',
                                            isDisabled ? 'text-slate-400' : 'hover:bg-pcx-200',
                                            isSelected &&
                                            (isDisabled
                                                ? 'bg-slate-100 text-slate-400'
                                                : 'bg-pcx-400 text-white hover:text-slate-900')
                                        )}
                                        date={date} />}
                            </CalendarGrid>
                        </RangeCalendar>
                    </Popover.Panel>
                </Popover>
            </Group>
        </DateRangePicker>
    )
}