import _ from 'lodash'

import { familyUrl, memberUrl } from "../patents/utils"
import { plusPeriod } from '../utils/dates'
import { family_member, patent_family } from "../data"
import { Family, Member } from "../patents/patents"
import { Trademark } from '../trademarks/TrademarksProvider'
import { trademarkUrl } from '../trademarks/utils'

//const today = new Date()

export type DueDateDistance = 'too-late' | 'critical' | 'soon' | 'ok'

export function dueDateDistance(date: string, done: boolean, today: Date): DueDateDistance {
    if (date === undefined) return undefined

    const _date = new Date(date)

    //console.log({date, _date, today})

    if (done) return 'ok'

    if (_date < today)
        return 'too-late'
    else if (_date < new Date(plusPeriod(today, {days: 14})))
        return 'critical'
    else if (_date < new Date(plusPeriod(today, {days: 28})))
        return 'soon'
    else
        return 'ok'
}

const dueDateDistanceOrdering: Record<DueDateDistance, number> = {
    'ok': 0,
    'soon': 1,
    'critical': 2,
    'too-late': 3,
}

// TODO remove in tasks provider 
export interface TaskProps {
    taskId?: number;
    title: string;
    comment?: string;
    dueDate: string;
    internalDueDate?: string;
    done: boolean;
    assignedTo: string;
    entity?: string;
    entityId?: number;
}

export function findWorst(tasks: TaskProps[], today: Date): DueDateDistance {
    let worst = 0
    for (let task of tasks) {
        let d1 = dueDateDistanceOrdering[dueDateDistance(task.dueDate, task.done, today)]
        let d2 = dueDateDistanceOrdering[dueDateDistance(task.internalDueDate, task.done, today)]
        const distance = Math.max(...[d1, d2].filter(d => d !== undefined))
        //console.log({d1, d2, distance})
        if (distance === dueDateDistanceOrdering['too-late'])
            return 'too-late'
        if (distance > worst) {
            worst = distance
        }
    }
    if (worst === 1)
        return 'soon'
    if (worst === 2)
        return 'critical'
    else
        return 'ok'
}

export interface AugmentedTaskProps {
    internalReference?: string;
    to?: string;
    displayName: string;
}
export function augment(
    { entity, entityId, assignedTo }: TaskProps,
    familyById: Record<number, Family>,
    memberById: Record<number, Member>,
    trademarkById: Record<number, Trademark>,
    users: Record<string, any>
): AugmentedTaskProps {
        let reference = undefined
        if (entity === patent_family && entityId in familyById) {
            const {internalReference} = familyById[entityId]
            reference = {internalReference, to: familyUrl({ internalReference })}
        } else if (entity === family_member && entityId in memberById) {
            const { internalReference } = memberById[entityId]
            reference = {internalReference, to: memberUrl({ internalReference })}
        } else if (entity === 'trademark' && entityId in trademarkById) {
            const trademark = trademarkById[entityId]
            reference = {internalReference: trademark.reference, to: trademarkUrl(trademark)}
        }
        const displayName = users[assignedTo]?.displayName ?? assignedTo
        //console.log({reference, entity})
        return {...reference, displayName}
    }

export function deriveDate(task: TaskProps) {
    return ({...task, date: _([task.dueDate, task.internalDueDate]).filter(Boolean).sortBy().first()})
}