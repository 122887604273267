import { useField } from 'formik'

export default function ToggleButton({checked, setChecked, disabled = false}) {

    return (
        <div className={`w-fit cursor-pointer h-fit rounded-sm border-2 relative ${disabled ? "border-slate-300" : checked ? "border-pc-300" : "border-pc-300/30"}`} onChange={() => {
            //console.log('clicked'); 
            (!disabled) && setChecked(!checked)
        }}>
            <div className={`w-8 h-4 ${disabled ? "bg-slate-300" : checked ? "bg-pc-300 " : "bg-pc-300/30"}`}>
                <div className={`rounded-sm w-4 h-4 bg-white dark:bg-pc-100 transition-transform ${checked && "translate-x-4"}`}/>
            </div>
            <div className="absolute top-0 left-0 w-8 h-4" onClick={() => (!disabled) && setChecked(!checked)}/>
        </div>
    )
}

export function ToggleButtonField(props) {
    // eslint-disable-next-line
    const [field, meta, helpers] = useField(props);
    return <ToggleButton id={props.name} {...{...props, checked: meta.value, setChecked: helpers.setValue}} />
}