import React from "react";
import _ from 'lodash'

import { useCrud } from "../BackendProvider";
import { findWorst, TaskProps } from "./utils";
import { SideBarBadge } from "../components/badges";


let TasksContext = React.createContext({
    tasks: [] as TaskProps[],
    todos: [] as TaskProps[], // only open tasks
    taskById: {} as Record<number, TaskProps>,
    tasksLookUp: {} as Record<string, Record<number, TaskProps[]>>,

    deleteTask: (arg: TaskProps) => Promise.resolve(arg),
    postTask: (arg: TaskProps) => Promise.resolve({}),

})


export function TasksProvider({children}) {
    const {data: tasks, postMutation: postTask, deleteMutation: deleteTask } = useCrud<TaskProps>('task', t => t.taskId)

    const tasksLookUp = _(tasks)
        .groupBy(c => c.entity)
        .mapValues(cs => _.groupBy(cs, c => c.entityId))
        .value()

    const taskById = _.keyBy(tasks, c => c.taskId)

    const todos = tasks.filter(t => !t.done)

    const value = {
        tasks,
        todos,
        taskById,
        tasksLookUp,
        postTask,
        deleteTask,
    }
    return <TasksContext.Provider value={value}>
        {children}
    </TasksContext.Provider>
}

export function useTasks() {
    return React.useContext(TasksContext)
}

export function TasksBadge({smallMenu}: {smallMenu?: boolean}) {
    const {tasks} = useTasks()
    const worstTaskDistance = findWorst(tasks, new Date())
    const severity = 
        worstTaskDistance === 'too-late' ? 'error' : 
        worstTaskDistance === 'critical' ? 'warning' : 
        worstTaskDistance === 'soon' ? 'info' : 
        'no-show'

    return <SideBarBadge {...{severity, smallMenu}} />
}