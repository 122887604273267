import React, { useContext, useEffect, useState } from "react"
import { useCrud, useLinkedCrud as useLinkCrud} from '../BackendProvider'
import _ from 'lodash'

import { memberModel, scenarioModel, amountModel } from "./forecasting"
import { useFxContext } from "./FxProvider"
import { useSingleTeamMangement } from "../Management"
import { useFilteredPatents } from "../filter/FilteredPatents"
import { IpType } from "../patents/patents"

export type Scenario = {
    scenarioId?: number,
    name: string,
    referenceDate: string,
}

export type ScenarioFamily = {
    scenarioFamilyId?: number,
    scenarioId: number,
    patentFamilyId?: number,
    selected: boolean,
    name?: string,
}

export type ScenarioFamilyInput = {
    scenarioFamilyId?: number,
    patentFamilyId?: number,
    selected: boolean,
    name?: string,
}

export type ScenarioMember = {
    scenarioMemberId?: number,
    scenarioFamilyId: number,
    type: "ep" | "pct" | "national" | "ea",
    countries: string[],
    date: string,
    selected: boolean,
    firstFiling: boolean,
    unitaryPatent: boolean,
    onlyPrioYear: boolean,
    fromPct: boolean,
    divisionalFiling: boolean,
    ipType: IpType,
}

export type ScenarioAmount = {
    scenarioAmountId?: number,
    scenarioFamilyId: number,
    name: string,
    amount: number,
    currency: string,
    date: string,
    selected: boolean,
}

export type SelectedMember = {
    scenarioId: number,
    familyMemberId: number,
    selected: boolean,
}

export type FamilyMemberModel = number[]
export type FamilyModel = {[familyMemberId: number]: FamilyMemberModel}

let ForecastContext = React.createContext({
    scenarios: [] as Scenario[], // {scenarioId, name, referenceDate}
    scenarioFamilies: [] as ScenarioFamily[], // {scenarioId, scenarioFamilyId, selected, (name?) || patentFamilyId}
    scenarioMembers: [] as ScenarioMember[], // {scenarioId, scenarioFamilyId, scenarioMemberId, type: "ep" | "pct" | "national", countries: [""], date, selected, firstFiling, unitaryPatent, ipType}
    scenarioAmounts: [] as ScenarioAmount[], // {scenarioId, scenarioFamilyId, scenarioAmountId, name, amount, date, selected}
    membersByScenarioFamily: {} as {[scenarioFamilyId: number]: ScenarioMember[]},

    scenarioFamilyById: {} as { [scenarioFamilyId: number]: ScenarioFamily },
    scenarioMemberById: {} as { [scenarioMemberId: number]: ScenarioMember },
    scenarioAmountById: {} as { [scenarioAmountId: number]: ScenarioAmount },
    
    addScenarioFamily: (family: ScenarioFamilyInput) => Promise.resolve({} as ScenarioFamily), 
    deleteScenarioFamily: (family: ScenarioFamily) => Promise.resolve({}), 
    updateScenarioFamily: (family: ScenarioFamily) => Promise.resolve({}), 
    //loadScenarioFamilies: () => Promise.resolve({}),

    addScenarioMember: (member: ScenarioMember) => Promise.resolve({} as ScenarioMember),
    deleteScenarioMember: (member: ScenarioMember) => Promise.resolve({}),
    updateScenarioMember: (member: ScenarioMember) => Promise.resolve({}),
    //loadScenarioMembers: () => Promise.resolve({}),

    addScenarioAmount: (amount: ScenarioAmount) => Promise.resolve({} as ScenarioAmount),
    deleteScenarioAmount: (amount: ScenarioAmount) => Promise.resolve({}),
    updateScenarioAmount: (amount: ScenarioAmount) => Promise.resolve({}),

    selectedScenario: undefined as Scenario,
    setSelectedScenario: (scenario: Scenario) => {},
    setNoScenario: () => {},
    hasScenario: false as boolean,
    hasScenarios: false as boolean,

    currency: "EUR", 

    addScenario: (scenario: Scenario) => Promise.resolve({}),
    deleteScenario: (scenario: Scenario) => Promise.resolve({}),
    updateScenario: (scenario: Scenario) => Promise.resolve({}),
    //loadScenarios: () => Promise.resolve({}),

    copyScenario: (newScenarioId: number) => Promise.resolve({}),

    isSelectedFamily: (patentFamilyId: number | string) => false as boolean,
    setFamilySelected: (patentFamilyId: number, selected: boolean) => {},
    isSelectedMember: (familyMemberId: number) => false as boolean,
    setMemberSelected: (familyMemberId: number, selected: boolean) => {},
    setScenarioAmountSelected: (scenarioAmountId: number, selected: boolean) => {},
    isScenarioAmountSelected: (scenarioAmountId: number) => false as boolean,
    isPureFamily: ({scenarioFamilyId}: {scenarioFamilyId?: number}) => false as boolean,
    isSelectedPureScenarioFamily: (scenarioFamilyId: number) => false as boolean,
    setScenarioFamilySelected: (scenarioFamilyId: number, selected: boolean) => {},
    setScenarioMemberSelected: (scenarioMemberId: number, selected: boolean) => {},
    isSelectedScenarioMember: (scenarioMemberId: number) => false as boolean,

    today: new Date(),
    familyModels: {} as {[patentFamilyId: number]: FamilyModel}, 
    scenarioFamilyModels: {},
    scenarioAmountModels: {},
    scenarioFamilyLookup: {}, 
    familyLookup: {},
})

function calculateToday(selectedScenario: Scenario | undefined) {
    // new Date(Date.UTC .. prevents jumping around 1.1.20XX)
    function toYearStart(date_s: string) {
        const date = new Date(date_s)
        return new Date(Date.UTC(date.getFullYear(), 0, 1))
    }
    function yearStart() {
        return new Date(Date.UTC(new Date().getFullYear(), 0, 1))
    }
    return selectedScenario?.referenceDate ? toYearStart(selectedScenario.referenceDate) : yearStart()
}

export default function ForecastProvider({children}) {

    const {fxConverter} = useFxContext()
    const {team} = useSingleTeamMangement()
    const currency = team?.currency ?? 'EUR'

    const {data: _scenarioFamilies, postMutation: _addScenarioFamily, deleteMutation: deleteScenarioFamily, postMutation: updateScenarioFamily} = 
        useCrud<ScenarioFamily>('scenario-family', s => s.scenarioFamilyId,)
    const {data: scenarioMembers,  postMutation: addScenarioMember, deleteMutation: deleteScenarioMember, postMutation: updateScenarioMember} = 
        useCrud<ScenarioMember>('scenario-member', m => m.scenarioMemberId)
    const {data: scenarioAmounts, postMutation: addScenarioAmount, deleteMutation: deleteScenarioAmount, postMutation: updateScenarioAmount} =
        useCrud<ScenarioAmount>('scenario-amount', a => a.scenarioAmountId)

    const {data: scenarios, postMutation: addScenario, deleteMutation: deleteScenario, postMutation: updateScenario} = 
        useCrud<Scenario>('scenario', s => s.scenarioId)

    // id of -1 means no scenario selected
    const [selectedScenarioId, setSelectedScenarioId] = useState(undefined)
    useEffect(() => {
        if (selectedScenarioId === undefined && _.size(scenarios) > 0)
            setSelectedScenarioId(scenarios[0].scenarioId)
    }, [selectedScenarioId, scenarios])
    //console.log({selectedScenarioId, scenarios})

    const selectedScenario = selectedScenarioId === -1 ? undefined : scenarios.find(v => v.scenarioId === selectedScenarioId) ?? (scenarios ?? [])[scenarios?.length - 1]
    function setSelectedScenario(scenario: Scenario) {
        setSelectedScenarioId(scenario?.scenarioId)
    }
    function setNoScenario() {
        setSelectedScenarioId(-1)
    }
    const hasScenario = selectedScenario !== undefined
    const hasScenarios = scenarios?.length > 0

    const today = calculateToday(selectedScenario)
    //console.log({today})

    const scenarioFamilies = _scenarioFamilies.filter(({scenarioId}) => scenarioId === selectedScenario?.scenarioId)
    
    //console.log({scenarios})
    //console.log({selectedScenario})
    //console.log({scenarioFamilies})
    //console.log({scenarioMembers})

    const membersByScenarioFamily = _.groupBy(scenarioMembers, m => m.scenarioFamilyId)
    //console.log({membersByScenarioFamily})
    const pureFamilies = new Set(_scenarioFamilies.filter(f => f.patentFamilyId === undefined).map(f => f.scenarioFamilyId))
    const isPureFamily = ({scenarioFamilyId}) => pureFamilies.has(scenarioFamilyId)

    const scenarioFamilyByFamilyId = _.keyBy(scenarioFamilies, f => f.patentFamilyId)
    const setFamilySelected = (patentFamilyId: number, selected: boolean) =>  {
        const scenarioFamily = scenarioFamilyByFamilyId[patentFamilyId]
        if (scenarioFamily) {
            updateScenarioFamily({...scenarioFamily, selected})
        } else {
            _addScenarioFamily({scenarioId: selectedScenario?.scenarioId, patentFamilyId, selected})
        }
    }
    const isSelectedFamily = (patentFamilyId: number | string) => scenarioFamilyByFamilyId[patentFamilyId]?.selected ?? true

    const {data: selectedMembers, postMutation: addSelectedMember, deleteMutation: updateSelectedMember} = 
        useLinkCrud<SelectedMember>('selected-member', (a, b) => a.familyMemberId === b.familyMemberId && a.scenarioId === b.scenarioId)
    //console.log({selectedMembers})
    const selectedMemberById = _.keyBy(selectedMembers, m => m.familyMemberId)
    const setMemberSelected = (familyMemberId: number, selected: boolean) =>
        familyMemberId in selectedMemberById
            ? updateSelectedMember({ ...selectedMemberById[familyMemberId], selected })
            : addSelectedMember({ familyMemberId, selected, scenarioId: selectedScenario?.scenarioId })
    const isSelectedMember = (familyMemberId: number) => selectedMemberById[familyMemberId]?.selected ?? true

    const scenarioAmountById = _.keyBy(scenarioAmounts, a => a.scenarioAmountId)
    const setScenarioAmountSelected = (scenarioAmountId: number, selected: boolean) => updateScenarioAmount({...scenarioAmountById[scenarioAmountId], selected})
    const isScenarioAmountSelected = (scenarioAmountId: number) => scenarioAmountById[scenarioAmountId]?.selected

    const scenarioMemberById = _.keyBy(scenarioMembers, m => m.scenarioMemberId)
    const setScenarioMemberSelected = (scenarioMemberId: number, selected: boolean) => updateScenarioMember({...scenarioMemberById[scenarioMemberId], selected})
    const isSelectedScenarioMember = (scenarioMemberId: number) => scenarioMemberById[scenarioMemberId]?.selected

    const scenarioFamilyById = _.keyBy(_scenarioFamilies.filter(f => f.scenarioId === selectedScenario?.scenarioId), f => f.scenarioFamilyId)
    const setScenarioFamilySelected = (scenarioFamilyId: number, selected: boolean) => updateScenarioFamily({...scenarioFamilyById[scenarioFamilyId], selected})
    const isSelectedPureScenarioFamily = (scenarioFamilyId: number) => isPureFamily({scenarioFamilyId}) && (scenarioFamilyById[scenarioFamilyId]?.selected ?? true)

    const {families, membersByFamilyId} = useFilteredPatents()
    const priorityDateByFamilyId = _(families).map(f => {
        if (f.priorityDate !== undefined)
            return [f.patentFamilyId, f.priorityDate]
        else {
            const members = membersByFamilyId[f.patentFamilyId] ?? []
            const priorityDate = _(members).filter(m => m.firstFiling).map(m => m.applicationDate).min() ?? _(members).map(m => m.applicationDate).min()
            return [f.patentFamilyId, priorityDate]
        }
    }).fromPairs().value()

    const familyModels: {[patentFamilyId: number]: {[familyMemberId: number]: number[]}} = _(families).map(family => {
        const members = membersByFamilyId[family.patentFamilyId] ?? []
        const models = _(members)
            .filter(({familyMemberId}) => isSelectedMember(familyMemberId))
            .map(member => [member.familyMemberId, memberModel({member, family, today, currency, fxConverter})])
            .fromPairs().value()
        return [family.patentFamilyId, models]
    }).fromPairs().value()

    const scenarioFamilyLookup = _(scenarioFamilies)
        .map(f => [f.patentFamilyId, f.scenarioFamilyId])
        .fromPairs().value()
    const familyLookup = _(scenarioFamilies)
        .filter(f => f.patentFamilyId !== undefined)
        .map(f => [f.scenarioFamilyId, f.patentFamilyId])
        .fromPairs().value()

    const scenarioFamilyModels = _(scenarioMembers)
        .groupBy(m => m.scenarioFamilyId)
        // Add first filing to the first member of a pure family
        .mapValues((ms, sfi) => {
            const priorityDate = priorityDateByFamilyId[familyLookup[sfi]] ??
                _(ms).filter(m => m.firstFiling).map(m => m.date).min() ?? _(ms).map(m => m.date).min()
            return _(ms)
                .filter(m => m.selected && m.scenarioFamilyId in scenarioFamilyById)
                .sortBy(m => m.date)
                .map((scenarioMember, i) => scenarioModel({ scenarioMember, today, currency, priorityDate, fxConverter }))
                .value()
        })
        .value()

    const scenarioAmountModels = _(scenarioAmounts)
        .groupBy(a => a.scenarioFamilyId)
        .mapValues((as) => _(as)
            .filter(a => a.selected && a.scenarioFamilyId in scenarioFamilyById)
            .sortBy(a => a.date)
            .map((scenarioAmount, i) => amountModel({scenarioAmount, today, currency, fxConverter}))
            .value())
        .value()

    //console.log({families, members})
    //console.log({scenarioFamilyModels, familyModels, scenarioMembers, scenarioFamilies, familyLookup})

    async function copyScenario(newScenarioId: number) {
        const newScenarioFamilies = scenarioFamilies.map(f => ({...f, scenarioId: newScenarioId}))
        //console.log({newScenarioFamilies})
        return Promise.all(newScenarioFamilies.map(f => _addScenarioFamily({...f, scenarioFamilyId: undefined }).then(({scenarioFamilyId}) => {
            const members = (membersByScenarioFamily[f.scenarioFamilyId] ?? []).map(m => ({...m, scenarioMemberId: undefined, scenarioFamilyId}))
            const amounts = scenarioAmounts.filter(a => a.scenarioFamilyId === f.scenarioFamilyId).map(a => ({...a, scenarioAmountId: undefined, scenarioFamilyId}))
            //console.log({members, amounts})
            return Promise.all([
                ...(members.map(m => addScenarioMember(m))),
                ...(amounts.map(a => addScenarioAmount(a))),
            ])
        })))
    }
    
    return <ForecastContext.Provider 
        value={{
            scenarioFamilies, scenarioMembers, scenarioAmounts, membersByScenarioFamily,
            scenarioFamilyById, scenarioMemberById, scenarioAmountById,
            addScenarioFamily: family => _addScenarioFamily({...family, scenarioId: selectedScenario?.scenarioId}) as Promise<ScenarioFamily>, 
            deleteScenarioFamily, updateScenarioFamily,
            addScenarioMember,
            deleteScenarioMember, updateScenarioMember, 
            addScenarioAmount,
            deleteScenarioAmount, updateScenarioAmount,
            scenarios, selectedScenario, setSelectedScenario, setNoScenario, hasScenario, hasScenarios,
            currency,
            addScenario, deleteScenario, updateScenario, copyScenario,
            isSelectedFamily, setFamilySelected,
            isSelectedMember, setMemberSelected,
            setScenarioAmountSelected, isScenarioAmountSelected,
            isPureFamily, isSelectedPureScenarioFamily, setScenarioFamilySelected,
            setScenarioMemberSelected, isSelectedScenarioMember,
            today,
            familyModels, scenarioFamilyModels, scenarioAmountModels,
            scenarioFamilyLookup, familyLookup,
        }}>
        {children}
    </ForecastContext.Provider>
}

export function useForecast() {
    return useContext(ForecastContext)
}