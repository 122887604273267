import { createContext, useContext } from "react";
import _ from 'lodash'

import { useCrud } from "./DennemeyerProvider";

export interface ImportState {
    importStateId?: number;
    ipRightId: number;
    statusOld: RenewalStatus;
    comment?: string;
    transferState: TransferStatus;
}

export type RenewalStatus = 'active' | 'inactive' | 'unknown'
export type TransferStatus = 'complete' | 'incomplete' | 'unknown'

let ImportStateContext = createContext({
    imports: [] as ImportState[],
    postImport: (importState: ImportState) => Promise.resolve(importState),
    deleteImport: (importState: ImportState) => Promise.resolve({}),

    importByIpRightId: {} as Record<number, ImportState>,
})

export function ImportProvider({ children }: { children: React.ReactNode }) {
    const {data: imports, postMutation: postImport, deleteMutation: deleteImport} = useCrud<ImportState>('ip-imports', e => e.importStateId)

    const importByIpRightId = _.keyBy(imports, 'ipRightId')

    const value = {
        imports,
        postImport,
        deleteImport,
        importByIpRightId,
    }

    return (
        <ImportStateContext.Provider {...{value}}>
            {children}
        </ImportStateContext.Provider>
    )
}

export function useImportState() {
    return useContext(ImportStateContext)
}