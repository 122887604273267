import { useState, PureComponent } from "react"
import { Bar, BarChart, Cell, ResponsiveContainer, Treemap, Tooltip, XAxis, YAxis, PieChart, Pie, Line, ComposedChart } from 'recharts'
import { Helmet } from 'react-helmet-async'
import { Link } from "react-router-dom"
import { useTranslation } from 'react-i18next'
import { ChartBarIcon, Squares2X2Icon } from '@heroicons/react/24/outline'
import { ChartBarIcon as SolidChartBarIcon } from '@heroicons/react/24/solid'
import _ from 'lodash'

import { useTasks } from '../tasks/TasksProvider'
import { DueDateBadge } from '../tasks/Tasks'
import { useScores } from '../valuations/scores'
import { colors, scoreColors as _scoreColors, patent_family } from '../data'
import { calculateExpiryDates, isActiveMember } from '../patents/utils'
import { nonEmptyString } from '../utils/strings'
import { useBackend } from '../BackendProvider'
import { useRoles } from "../user/Auth"
import { useForecast } from "../forecast/ForecastProvider"
import { CostChart, ScenarioSelect } from "../forecast/Forecast"
import { sumModels } from "../forecast/forecasting"
import { isGrantedMember } from "../patents/utils"
import { EmptyPortfolioState } from "../patents/Portfolio"
import { useProductMapping } from "../products/products"
import { useLocalState } from "../settings/localStorage"
import { augment } from "../tasks/utils"
import { useUserSettings } from "../user/UserSettingsProvider"
import ToggleButton from "../components/ToggleButton"
import { useFilteredPatents } from "../filter/FilteredPatents"
import { useFilteredCommodities } from "../filter/FilteredCommodities"
import { useFilteredTrademarks } from "../filter/FilteredTrademarks"
import { CostsGraph } from "../costs/CostsGraph"
import { useCosts } from "../costs/CostsProvider"
import { accumulateCosts } from "../costs/utils"
import { useSingleTeamMangement } from "../Management"
import { useFxContext } from "../forecast/FxProvider"
import { IconPlus } from "../components/icons"
import { usePatents } from "../patents/PatentsProvider"

export default function Cockpit() {
    const {t} = useTranslation()
    const {hasCosts, hasTasks} = useRoles()
    const {isLoading} = usePatents()
    const {hasLoaded} = useBackend()
    const {families} = useFilteredPatents()
    return (
        <>
            {/* @ts-ignore */}
            <Helmet>
                <title>{t('cockpit')} | Patent Cockpit</title>
            </Helmet>
            <h1 className="sr-only">{t('cockpit')}</h1>
            <div className="main-content bg-pcx-100 pt-4">
                {!isLoading && hasLoaded
                    ? (families.length > 0
                        ? <div className='flex flex-wrap gap-4 h-fit'>
                            <OverviewChart />
                            <UsageChart />
                            <ProtectionChart />
                            <IpStatusChart />
                            <ScoreChart />
                            <PrioExpiriesChart />
                            {hasCosts && <CockpitCostChart />}
                            {hasCosts && <CockpitDriverChart />}
                            <CountryHistogram />
                            <TagsCharts />
                            <ScenarioChart />
                            {hasTasks && <TasksCard />}
                        </div>
                        : <div className='max-w-screen-lg'>
                            <EmptyPortfolioState />
                        </div>)
                    : <ChartContainer>
                        <SolidChartBarIcon className="h-24 w-24 text-pcx-300 mx-auto my-12 animate-pulse" />
                    </ChartContainer>
                }
            </div>
        </>
    )
}

function OverviewChart() {
    const {t} = useTranslation()
    const {hasInnovation} = useRoles()
    const { members, families } = useFilteredPatents()
    const { commodities } = useFilteredCommodities()
    const { membersByCommodityId, claimScopeMembersByCommodityId } = useProductMapping()
    const { inventions } = useBackend()

    const protectedCommodities = _(commodities).filter(c => !c.isThirdParty &&
        (c.commodityId in membersByCommodityId || c.commodityId in claimScopeMembersByCommodityId)
    ).size()

    const values = [
        { count: families.length, text: 'patent-family', to: '/patents/portfolio' },
        { count: members.length, text: 'patent', side: t('granted') + ': ' + members.filter(m => m.familyMemberStatus === 'granted').length, to: '/patents/portfolio'},
        hasInnovation && 
        { count: inventions.length, text: 'invention', to: '/inventions'},
        { count: commodities.filter(c => !c.isThirdParty).length, text: 'product', side: t('protected') + ': ' + protectedCommodities, to: '/products'},
    ].filter(Boolean).map(({side, count, text, to}) => ({side, count, text: t(`cockpit-page.${text}`, {count}), to}))

    return (
        <ChartContainer>
            <h2 className='mb-2'><Link to="/patents/portfolio">{t('cockpit-page.overview')}</Link></h2>
            <table>
                <tbody>
                    {values.map(({count, text, side, to}) => {
                        const display = `${text} ${side ? `(${side})` : ''}`
                        return (
                        <tr key={text} >
                            <td className="pt-4 align-bottom text-right text-3xl font-bold text-pcx-600">{count}</td>
                            <td className="pl-2 align-bottom text-slate-400 font-base text-xl">
                                {to ? <Link to={to}>{display}</Link> : display}
                            </td>
                        </tr>
                    )}
                    )}
                </tbody>
            </table>
        </ChartContainer>
    )
}

function PrioExpiriesChart() {
    const {t} = useTranslation()
    // use members from background as most often the first filings are not active anymore
    const {members} = usePatents()
    const {families} = useFilteredPatents()
    const familyById = _.keyBy(families, 'patentFamilyId')

    const thisYear = new Date().getFullYear().toString()
    const startYear = new Date().getFullYear() - 20
    const endYear = new Date().getFullYear() + 20

    const bucket = (date) => date?.substring(0, 4)

    const familyPriorityDates = _(families)
        .map(({patentFamilyId, priorityDate}) => [patentFamilyId, bucket(priorityDate)])
        .filter(([id, d]) => d !== undefined)
        .value()

    const priorityDates = _(members)
        .filter(({firstFiling, applicationDate, patentFamilyId}) => patentFamilyId in familyById /*&& firstFiling*/ && applicationDate !== undefined)
        .map(({patentFamilyId, applicationDate}) => [patentFamilyId, bucket(applicationDate)])
        .concat(familyPriorityDates)
        .filter(([id, date]) => date >= startYear)
        .groupBy(([id]) => id)
        .toPairs()
        .map(([id, dates]) => _.min(dates.map(([id, date]) => date)))
        .countBy()
        .value()

    if (_.size(priorityDates) > 0) {
        if (!(thisYear in priorityDates))
            priorityDates[thisYear] = 0
        if (!(startYear in priorityDates))
            priorityDates[startYear] = 0
    }

    const expiryDates = calculateExpiryDates(members, familyById, bucket)

    if (_.size(expiryDates) > 0) {
        if (!(thisYear in expiryDates))
            expiryDates[thisYear] = 0
        if (!(endYear in expiryDates))
            expiryDates[endYear] = 0
    }

    if (_.size(priorityDates) === 0 && _.size(expiryDates) === 0) 
        return null

    //console.log({priorityDates, expiryDates})

    return (
        <ChartContainer>
            <h2 className='mb-2'><Link to="/patents/portfolio">{t('cockpit-page.first-filings')}</Link></h2>
            <YearlyHistogram counts={priorityDates} name="Priorities" color={colors['pcx-400']} lineColor={colors['pcx-500']} />
            <h2 className='mb-2 mt-4'><Link to="/patents/portfolio">
                {t('cockpit-page.expiries')} <span className="text-sm font-normal text-slate-700">{t('cockpit-page.last-expiry')}</span>
            </Link></h2>
            <YearlyHistogram counts={expiryDates} name="Expiries" color={colors['pcx-600']} lineColor={colors['pcx-300']} decreasing />
        </ChartContainer>
    )
}

function HistogramTooltip({active, label, payload, lineColor}) {
    //console.log({payload})
    if (active && payload?.length > 1)
        return <div className="bg-white p-2 pb-0 rounded-lg border border-pcx-400">
            <p>{label}: {payload[0].value}</p>
            {lineColor && <p>Total: {payload[1].value}</p>}
        </div>
    else
        return null
}

function YearlyHistogram({counts, name, color, lineColor, decreasing = false}) {
    const years = [...Object.keys(counts)]
    const start_year = parseInt(_.min(years))
    const end_year = parseInt(_.max(years))

    let data = []
    let total = 0
    let maxCount = 0
    for (let year = start_year; year <= end_year; year++) {
        const count = counts[year] ?? 0
        if (count > maxCount)
            maxCount = count
        total += count
        data.push({year, count, total})
    }
    if (data.length === 0)
        return <div className='text-slate-500'>No data</div>

    if (decreasing) {
        const lastTotal = data[data.length - 1].total
        data = data.map(({year, count, total}) => ({year, count, total: lastTotal - total}))
    }

    return (
        <ResponsiveContainer className='text-sm' width="100%" height={100}>
            <ComposedChart
                data={data}
                margin={{ left: -30, top: 5, right: lineColor ? -30 : 30, bottom: 0 }}
            >
                <XAxis dataKey="year" domain={[start_year - 1, end_year + 1]} />
                <YAxis orientation="left" yAxisId="1" allowDecimals={false} domain={[0, maxCount]} />
                {lineColor && <YAxis orientation="right" yAxisId="2" domain={[0, total]} />}

                {/* @ts-ignore */}
                <Tooltip wrapperStyle={{ outline: "none" }} content={<HistogramTooltip {...{ lineColor }} />} />
                <Bar yAxisId="1" dataKey="count" name={name} fill={color} />
                {lineColor && <Line yAxisId="2" dataKey="total" name="Total" type="monotone" stroke={lineColor} />}
            </ComposedChart>
        </ResponsiveContainer>
    )
}

class CountryArea extends PureComponent {
    render() {
      // @ts-ignore
      const { root, value, depth, x, y, width, height, colors, textColors, name } = this.props;

      if (!_.isArray(root.children)) return null

      // Higher rank means more area
      const maxValue: number = _.max(root.children.map((c: {value: number}) => c.value))
      const rank = (value / maxValue) * (root.children.length - 1) 

      const colorIndex = Math.floor((rank / (root.children.length / colors.length)))
      const textIndex = Math.floor((rank / (root.children.length / textColors.length)))

      //console.log({rank, N: root.children.length , B: textColors.length})
      //console.log({name, width, height})
      //console.log(this.props)

      const minTextWidth = Math.max(20, (name?.length ?? 2) * 8)
  
      return (
        <g>
          <rect
            x={x}
            y={y}
            width={width}
            height={height}
            style={{
              fill: depth < 2 ? colors[colorIndex] : '#ffffff00',
              stroke: '#fff',
              strokeWidth: 2 / (depth + 1e-10),
              strokeOpacity: 1 / (depth + 1e-10),
            }}
          />
          {depth === 1 && height > 14 && width > minTextWidth ? ( // To small boxes don't fit the text
            <text 
                x={x + width / 2} y={y + height / 2 + 7} textAnchor="middle" 
                fill={depth < 2 ? textColors[textIndex] : "#fff"} 
                fontSize={14}
            >
              {name}
            </text>
          ) : null}
        </g>
      );
    }
  }

const TreemapTooltip = ({active, label, payload}) => {
    if (active)
        return <div className="bg-white p-2 pb-0 rounded-lg border border-pcx-400">
            <p>{payload[0].payload.name}: {payload[0].value}</p>
        </div>
    else
        return null
}

const sortedColors = _(colors).toPairs().sortBy(([key]) => key).map(([key, color]) => color).value()
const textColors = [colors['pcx-900'], colors['pcx-900'], colors['pcx-100'], colors['pcx-100'], colors['pcx-100'], colors['pcx-100']]

function BarTreemapToggle({showBars, setShowBars}) {
    return (
        <button className="text-pcx-600" onClick={() => setShowBars(!showBars)}>
            {showBars
                ? <Squares2X2Icon className="h-6 w-6" />
                : <ChartBarIcon className="h-6 w-6 rotate-90 -scale-x-100" />}
        </button>
    )
}

function CountryHistogram() {
    const {t} = useTranslation()
    const {members} = useFilteredPatents()

    const [showBars, setShowBars] = useLocalState('cockpit-show-bars', false)

    const countries = _.countBy(members, 'countryCode')
    const data = _(countries).toPairs().map(([name, count]) => ({name, count})).sortBy('count').reverse().value()
    //console.log({data})

    //console.log({sortedColors})

    return <ChartContainer>
        <div className="flex flex-row justify-between">
            <h2 className="mb-2 ">
                <Link to="/patents/country-family">{t('countries')}</Link> {showBars && <TopXMarker count={data.length} max={10} />}
            </h2>
            <div className=" mt-0.5">
                <BarTreemapToggle {...{showBars, setShowBars}} />
            </div>
        </div>
        {data.length > 0 &&
            <ResponsiveContainer className="text-xs" width="100%" height={250}>
                {showBars
                    ? <BarChart
                        layout="vertical"
                        data={data.slice(0, 10)}
                        margin={{ left: 0, top: 5, right: 0, bottom: -10 }}
                    >
                        <YAxis type="category" dataKey="name" hide={true}/>
                        <XAxis type="number" allowDecimals={false} />
                        {/* @ts-ignore */}
                        <Tooltip wrapperStyle={{ outline: "none" }} content={<HistogramTooltip />} />
                        <Bar 
                            dataKey="count" name="Countries" fill={colors['pcx-300']} 
                            label={({x, y, height, value, name, width }) => {
                                if (width < 32)
                                    return null
                                const y_offset = height / 2 - 12 / 2 + 1 // 12 is the font size 
                                return <text x={x + 5} y={y + height - y_offset} fill={colors['pcx-900']} >{name}: {value}</text>
                            }}
                        />
                    </BarChart>
                    : <Treemap 
                        animationDuration={500} data={data} dataKey="count" 
                        // @ts-ignore
                        content={<CountryArea colors={sortedColors} textColors={textColors} />}
                    >
                        {/* @ts-ignore */}
                        <Tooltip wrapperStyle={{ outline: "none" }} content={<TreemapTooltip />} />
                    </Treemap>
                }
            </ResponsiveContainer>}
    </ChartContainer>
}

function TagsChart({data, tall=false}) {
    const height = tall ? 250 : 110
    return (
        <ResponsiveContainer className='text-xs' width="100%" height={height}>
            <BarChart
                layout="vertical"
                data={data}
                margin={{ left: 0, top: 0, right: 0, bottom: -10 }}
            >
                <YAxis type="category" dataKey="name" hide={true} />
                <XAxis type="number" allowDecimals={false} />
                {/* @ts-ignore */}
                <Tooltip wrapperStyle={{ outline: "none" }} content={<HistogramTooltip />} />
                <Bar dataKey="count" name="Tags" fill={colors['pcx-300']}
                    label={({ x, y, offset, height, value, name, width, ...props }) => {
                        const y_offset = height / 2 - 12 / 2 + 1 // 12 is the font size 
                        return <text x={x + 5} y={y + height - y_offset} fill={colors['pcx-900']} >{name}: {value}</text>
                    }}
                />
            </BarChart>
        </ResponsiveContainer>
    )
}

function TagsCharts() {
    const {t} = useTranslation()
    const {tagsLookup} = useBackend()
    const {families } = useFilteredPatents()

    const tags = families.flatMap(({patentFamilyId}) => tagsLookup[patent_family]?.[patentFamilyId] ?? [])

    const familyData = _(tags)
        .countBy()
        .toPairs()
        .map(([name, count]) => ({name, count, others: families.length - count}))
        .sortBy('count')
        .reverse()
        .value()

    /*
    const commodityClasses = _(commodities)
        .filter(({isThirdParty}) => !isThirdParty)
        .map(({commodityClass}) => commodityClass ?? t('unclassified'))
        .countBy().toPairs()
        .map(([name, count]) => ({name, count, others: commodities.length - count}))
        .sortBy('count')
        .reverse()
        .value()*/

    if (familyData.length === 0) return null

    return <ChartContainer>
        <h2 className='mb-2'><Link to="/patents/portfolio">{t('cockpit-page.tagged-families')}</Link> <TopXMarker count={familyData.length} max={10} /></h2>
        <TagsChart data={familyData.slice(0, 10)} tall/>
        {/*<h2 className='mb-1 mt-2'><Link to="/products/portfolio">{t('cockpit-page.product-classes')}</Link> <TopXMarker count={commodityClasses.length} max={5} /></h2>
        <TagsChart data={commodityClasses.slice(0, 5)} />*/}
    </ChartContainer>
}

function TopXMarker({count, max = 5}: {count: number, max?: number}) {
    const {t} = useTranslation()
    if (count <= max) return null
    return <span className="text-slate-500">({t('cockpit-page.top-x-of-total', {count: max, total: count})})</span>
}

function PieChartTooltip({active, label, payload}) {
    //console.log({payload, label, props})
    if (active)
        return <div className="bg-white p-2 pb-0 rounded-lg border border-pcx-400">
            <p className="whitespace-nowrap">{payload[0].payload.displayName}: {payload[0].value}</p>
        </div>
    else
        return null
}

const RADIAN = Math.PI / 180;
const statusChartLabels = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index, name, ...props }) => {
    //console.log({props, index})
    if (percent < 0.10) return null

    const radius = innerRadius + (outerRadius - innerRadius) * 0.6;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    const color = name === 'in-preparation' ? colors['pcx-800'] : colors['pcx-100']

    return (
        <text x={x} y={y} fill={color} textAnchor={'middle'} dominantBaseline="central">
            {`${(percent * 100).toFixed(0)}%`}
        </text>
    );
}

function IpStatusChart() {
    const {t} = useTranslation()
    const {members} = useFilteredPatents()

    const statuses = _.countBy(members, 'familyMemberStatus')
    const data = _(statuses).toPairs().map(([name, count]) => ({name, count, displayName: t(name)})).value()
    //console.log({data})
    
    const statusColors = {
        'in-preparation': colors['pcx-200'],
        'pending': colors['pcx-400'],
        'granted': colors['pcx-600'],
        'stopped': colors['pcx-800'],
    }

    return <ChartContainer>
        <h2 className='mb-4'><Link to="/patents/portfolio">{t('familyMemberStatus')}</Link></h2>
        <div className="flex flex-row items-center gap-6">
            <div className="w-40 h-40">
                <ResponsiveContainer width="100%" height="100%">
                    <PieChart width={100} height={100} >
                        <Pie data={data} dataKey="count" nameKey="name" cx="50%" cy="50%" outerRadius="100%"
                            label={statusChartLabels} labelLine={false}
                        >
                            {data.map((entry, index) => <Cell key={`cell-${index}`} fill={statusColors[entry.name]} />)}
                        </Pie>
                        {/* @ts-ignore */}
                        <Tooltip wrapperStyle={{ outline: "none" }} content={<PieChartTooltip />} />
                    </PieChart>
                </ResponsiveContainer>
            </div>
            <div>
                {_(statusColors).toPairs().map(([name, color]) => <div key={name} className="flex flex-row gap-2 items-center">
                    <div className="w-3 h-3 rounded-sm" style={{backgroundColor: color}}></div>
                    <p className="pt-2 text-xs xl:text-sm  text-slate-700">{t(name)}</p>
                </div>).value()}
            </div>
        </div>
    </ChartContainer>
}

function ChartContainer({children, className = ''}) {
    return <div className={`min-w-full max-w-full sm:min-w-xs sm:max-w-xs xl:min-w-sm xl:max-w-sm p-4 bg-white rounded-xl shadow-sm ${className}`}>
        {children}
    </div>
}

const patentEffectivenessCategories = ['only-active', 'only-granted']
function useConsiderMember(key) {
    const {t} = useTranslation()
    const [patentEffectiveness, setPatentEffectiveness] = useLocalState(`cockpit-${key}`, patentEffectivenessCategories[0])

    const control = 
        <select 
            value={patentEffectiveness} onChange={e => setPatentEffectiveness(e.target.value)} 
            className="form-select text-xs max-w-full text-ellipsis pl-2 py-1 mt-4 text-pcx-400 border-pcx-400 rounded-sm focus:outline-none focus:ring-0"
        >
            {patentEffectivenessCategories.map(c => <option key={c} value={c}>{t(`cockpit-page.${c}`)}</option>)}
        </select>
    const considerMember = patentEffectiveness === 'only-active' ? isActiveMember : isGrantedMember
    return {control, considerMember}
}

function UsageChart() {
    const { t } = useTranslation()
    const { hasClaimScopes } = useRoles()
    const { members  } = useFilteredPatents()
    const { commodities } = useFilteredCommodities()
    const { commoditiesByFamilyId, claimScopeCommoditiesByPatentFamilyId } = useProductMapping()

    const {control, considerMember} = useConsiderMember('usage')

    if (commodities?.length === 0)
        return null

    const _families =  _(members).filter(considerMember).map(m => m.patentFamilyId).uniq().value()

    const maxCount = 3

    const csLookup = hasClaimScopes ? claimScopeCommoditiesByPatentFamilyId : {}
    //console.log({claimScopeCommoditiesByPatentFamilyId})
    //console.log({_families, claimScopeCommoditiesByPatentFamilyId, commoditiesByFamilyId})
    // count, how many commodities are protected by each family
    const protectionCount = _(_families).map(patentFamilyId => {
        const commodities = csLookup[patentFamilyId] ?? commoditiesByFamilyId[patentFamilyId] ?? []
        const [thirdPary, own] = _.partition(commodities, c => c?.isThirdParty)
        if (own.length > 0) 
            return Math.min(own.length, maxCount)
        else if (thirdPary.length > 0)
            return maxCount + 1  // we just count that a t least own third party product is hindered
        else
            return 0
    }).countBy().value()

    //console.log({protectionCount})

    const protectionColors = _.range(maxCount + 1).map(protectionCount => ({
        name: protectionCount === 0 ? "protects-none" : protectionCount >= maxCount ? `protects-${maxCount}-or-more` : `protects-${protectionCount}`,
        color: colors[`pcx-${(protectionCount + 2) * 100}`],
        protectionCount,
        displayName: protectionCount === 0 
            ? t("cockpit-page.protects-none") 
            : protectionCount >= maxCount 
            ? t("cockpit-page.protects-x-or-more", {count: maxCount}) 
            : t("cockpit-page.protects-x", {count: protectionCount}),
    }))
    protectionColors[maxCount + 1] = {
        name: "protects-from-competition",
        color: colors[`pcx-900`],
        protectionCount: maxCount + 1,
        displayName: t("cockpit-page.protects-from-competition") 
    }


    const protectionCounts = _(protectionCount)
        .toPairs()
        .map(([protectionCount, count]) => ({
            name: protectionColors[protectionCount]?.name,
            displayName: protectionColors[protectionCount]?.displayName,
            count,
            protectionCount,
        }))
        .value()

    return <ChartContainer>
        <div className="flex flex-col h-full">
            <h2 className='mb-4'><Link to="/products/patent-mapping">{t('cockpit-page.patent-protection-usage')}</Link></h2>
            <BasicProtectionChart {...{ protectionCounts, protectionColors }} />
            <div className="grow" />
            {control}
        </div>
    </ChartContainer>
}

const protectionChartLabels = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index, protectionCount, labelColor,}) => {

    //console.log({index, percent, cx, cy, protectionCount})
    //console.log({props})
    if (percent < 0.10) return null

    const radius = innerRadius + (outerRadius - innerRadius) * 0.6;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    const color = labelColor ?? (index < 2 && protectionCount < 2 ? colors["pcx-800"] : colors["pcx-100"])

    return (
        <text x={x} y={y} fill={color} textAnchor={'middle'} dominantBaseline="central">
            {`${(percent * 100).toFixed(0)}%`}
        </text>
    );
};

function BasicProtectionChart({protectionCounts, protectionColors}) {
    const existingCounts = new Set(_(protectionCounts).map(c => parseInt(c.protectionCount)).value())
    //console.log({existingCounts, protectionColors})
    return (
        <div className="flex flex-row items-center gap-6">
            <div className="w-40 h-40">
                <ResponsiveContainer width="100%" height="100%">
                    <PieChart width={100} height={100} >
                        <Pie 
                            data={protectionCounts} dataKey="count" nameKey="name" 
                            label={protectionChartLabels} labelLine={false}
                            cx="50%" cy="50%" outerRadius="100%" startAngle={90} endAngle={450}
                        >
                            {protectionCounts.map((entry, index) => <Cell key={`cell-${index}`} fill={protectionColors[entry.protectionCount].color} />)}
                        </Pie>
                        {/* @ts-ignore */}
                        <Tooltip wrapperStyle={{ outline: "none" }} content={<PieChartTooltip />} />
                    </PieChart>
                </ResponsiveContainer>
            </div>
            <div>
                {_(protectionColors)
                    .map(({ protectionCount, name, color, displayName }) =>
                        existingCounts.has(protectionCount) &&
                        <div key={name} className="flex flex-row gap-2 mb-1 items-start">
                            <div className="w-3 h-3 mt-1 shrink-0 rounded-sm" style={{ backgroundColor: color }}></div>
                            <p className="text-xs xl:text-sm text-slate-700">{displayName}</p>
                        </div>)
                    .value()}
            </div>
        </div>
    )
}

function ProtectionChart() {
    const {t} = useTranslation()
    const {hasClaimScopes} = useRoles()
    const {members} = useFilteredPatents()
    const {commodities} = useFilteredCommodities()

    const { familiesByCommodityId, claimScopeMembersByCommodityId } = useProductMapping()

    const {control, considerMember} = useConsiderMember('protection')

    if (commodities?.length === 0)
        return null


    const activeMembers =  new Set(_(members).filter(considerMember).map(m => m.familyMemberId).value())
    const activeFamilies =  new Set(_(members).filter(considerMember).map(m => m.patentFamilyId).value())

    const csLookup = hasClaimScopes ? (id) => {
        const res = _(claimScopeMembersByCommodityId[id] ?? [])
            .filter(m => activeMembers.has(m.familyMemberId))
            .map(m => m.patentFamilyId)
            .uniq()
            .value()
        return res?.length === 0 ? undefined : res
     } : () => undefined

    const maxCount = 5
    const commodityProtection = _(commodities)
        .filter(c => !c?.isThirdParty)
        .map(c => {
            const families = _(csLookup(c?.commodityId) ?? [])
                .concat(familiesByCommodityId[c?.commodityId]?.map(f => f?.patentFamilyId) ?? [])
                .filter(f => activeFamilies.has(f))
                .uniq()
                .size()

            return Math.min(families, maxCount)
        })
        .countBy()
        .value()
    //console.log({ commodityProtection })

    const protectionColors = _.range(maxCount + 1).map(protectionCount => ({
        name: protectionCount === 0 ? "no-protection" : protectionCount >= maxCount ? `protected-by-${maxCount}-or-more` : `protected-by-${protectionCount}`,
        color: colors[`pcx-${(protectionCount + 2) * 100}`],
        protectionCount,
        displayName: protectionCount === 0 
            ? t("cockpit-page.no-protection") 
            : protectionCount >= maxCount 
            ? t("cockpit-page.protected-by-x-or-more", {count: maxCount}) 
            : t("cockpit-page.protected-by-x", {count: protectionCount}),
    }))

    const protectionCounts = _(commodityProtection)
        .toPairs()
        .map(([protectionCount, count]) => ({
            name: protectionColors[protectionCount]?.name,
            displayName: protectionColors[protectionCount]?.displayName,
            count,
            protectionCount,
        }))
        .value()

    return <ChartContainer>
        <div className="flex flex-col h-full">
            <h2 className='mb-4'><Link to="/products/patent-mapping">{t('cockpit-page.product-protection-status')}</Link></h2>
            <BasicProtectionChart {...{ protectionCounts, protectionColors }} />
            <div className="grow" />
            {control}
        </div>
    </ChartContainer>
}

function ScenarioChart() {
    const {t} = useTranslation()
    const {currency, hasScenario, hasScenarios} = useForecast()
    const {today, familyModels, scenarioAmountModels, scenarioFamilyModels, familyLookup, isSelectedFamily, isSelectedPureScenarioFamily} = useForecast()

    const models = _(familyModels).toPairs()
        .flatMap(([key, models]) => isSelectedFamily(key) ? models : {})
        // @ts-ignore
        .reduce((acc, curr) => ({...acc, ...curr}), {})
    //console.log(flatted)
    const costs = sumModels({models, today})

    const isScenarioFamilySelected = (_scenarioFamilyId) => {
            const scenarioFamilyId = parseInt(_scenarioFamilyId)
            const isPureFamily = !(scenarioFamilyId in familyLookup)
            return isPureFamily 
                ? isSelectedPureScenarioFamily(scenarioFamilyId)
                : isSelectedFamily(familyLookup[scenarioFamilyId])
    }

    const scenarioModels =_(scenarioFamilyModels).toPairs()
        .flatMap(([scenarioFamilyId, model]) => isScenarioFamilySelected(scenarioFamilyId) ? model : [])
        .value()
    const amountModels = _(scenarioAmountModels).toPairs()
        .flatMap(([scenarioFamilyId, model]) => isScenarioFamilySelected(scenarioFamilyId) ? model : [])
        .value()
    //console.log({scenarioModels})
    // @ts-ignore
    const scenarioCosts = hasScenario ? sumModels({models: [...scenarioModels, ...amountModels], today}) : undefined

    return <ChartContainer>
        <div className="flex flex-row items-center gap-8 mb-4">
            <h2 className=''>
                <Link to="/patents/forecast">{t('forecast.title')}</Link>
            </h2>
            {hasScenarios &&
                <ScenarioSelect className="form-select border-pcx-200 py-1 grow text-pcx-900 truncate text-ellipsis" />
            }
        </div>
        <CostChart {...{ costs, scenarioCosts }} />

        <div className="flex flex-col gap-2 text-slate-500 text-xs w-full pl-2">
            <div className="inline-flex gap-2 items-center"><div className="h-4 w-4 bg-pcx-500" />{t('forecast.costs-from-existing')} ({currency})</div>
            <div className="inline-flex gap-2 items-center"><div className="h-4 w-4 bg-pcx-300" />{t('forecast.costs-from-scenario')} ({currency})</div>
        </div>

    </ChartContainer>
}

function ScoreChart() {
    const {t} = useTranslation()
    const scoresByFamilyId = useScores()

    //console.log({scoresByFamilyId})

    if (_.size(scoresByFamilyId) === 0) return null

    const names = ['very-high-score', 'high-score', 'medium-score', 'low-score', 'no-score', ]
    
    const scoreColors = names.map((name, protectionCount) => ({
        name,
        protectionCount,
        displayName: t(`cockpit-page.${name}`),
        color: _scoreColors[name], // colors[`pcx-${protectionCount * 200 + 100}`]
    })) 
    const labelColors = [
        'rgb(26 46 5)',
        'rgb(54 83 20)',
        'rgb(133 77 14)',
        'rgb(185 28 28)',
        'rgb(69 10 10)', //'rgb(185 28 28)', //'rgb(254 242 242)',
    ]
    //console.log({scoreColors})

    const scoreCounts = _(scoresByFamilyId).toPairs()
        .map(([familyId, scores]) => {
            const score = scores.familyScore
            if (score === undefined) return 4 - 0
            else if (score < 1.0) return 4 - 1
            else if (score < 2.0) return 4 - 2
            else if (score < 3.0) return 4 - 3
            else return 4 - 4
        })
        .countBy()
        .toPairs()
        .map(([protectionCount, count]) => ({
            name: scoreColors[protectionCount]?.name,
            displayName: scoreColors[protectionCount]?.displayName,
            count,
            protectionCount,
            labelColor: labelColors[protectionCount]
        }))
        .value()
    //console.log({scoreCounts})

    return <ChartContainer>
        <h2 className="mb-4">
            <Link to="/patents/evaluations">{t('cockpit-page.family-scores')}</Link>
        </h2>
        <BasicProtectionChart {...{ protectionCounts: scoreCounts, protectionColors: scoreColors }} />
    </ChartContainer>
}

function TasksCard() {
    const {t} = useTranslation()
    const {tasks} = useTasks()
    const {familyById, memberById} = usePatents()
    const {trademarkById} = useFilteredTrademarks()
    const {users, userSettings: {user}} = useUserSettings()
    const [onlyMine, setOnlyMine] = useState(false)

    const nextTasks = _(tasks)
        .filter(t => !t.done)
        .filter(t => !onlyMine || t.assignedTo === user)
        .map(task => ({...task, date: _([task.dueDate, task.internalDueDate]).filter(Boolean).sortBy().first()}))
        .sortBy('date')
        .take(3)
        .map(task => ({...task, ...augment(task, familyById, memberById, trademarkById, users)}))
        .value()

    const today = new Date()

    return <ChartContainer>
        <div className="mb-4 flex flex-row gap-2 justify-between items-end">
            <h2>
                <Link to="/tasks">{t('next-tasks')}</Link>
            </h2>
            <label className="text-sm text-slate-700 inline-flex gap-2">
                {t('only-mine')} <ToggleButton checked={onlyMine} setChecked={setOnlyMine} />
            </label>
        </div>
        {nextTasks.map(task =>
            <Link 
                key={task.taskId} to={task.to ?? '/tasks'}
                className="block rounded-lg shadow-sm border border-pcx-200 mt-2 "
            >
                <div title={task.title} className="text-sm flex flex-row items-center gap-1 px-2 py-1 bg-pcx-100">
                    <h3 className="min-w-0 overflow-hidden text-ellipsis whitespace-nowrap grow text-base">{task.title}</h3>
                    <DueDateBadge {...{...task, today}} />
                    <div className="tabular-nums whitespace-nowrap">{task.date}</div>
                </div>
                {nonEmptyString(task.comment) && 
                    <div className="p-2 text-sm text-slate-700 line-clamp-1 relative">
                        {task.comment}
                        <div className="absolute bottom-0 bg-white/50 h-2 w-[95%]"/>
                    </div>}
            </Link>)}
    </ChartContainer>
}

function CockpitCostChart() {
    const {t} = useTranslation()

    const {team} = useSingleTeamMangement()
    const currency = team?.currency

    const {members} = useFilteredPatents()
    const {fxConverter} = useFxContext()
    const {costsByMemberId} = useCosts()

    const costs = members.flatMap(m => costsByMemberId[m.familyMemberId] ?? [])

    const byYear = _.mapValues(accumulateCosts(costs, {fxConverter, inclVat: false, currency}).byYear, c => Math.round(c))
    //console.log({byYear})

    return (
        <ChartContainer>
            <h2 className="pb-4">
                <Link to="/patents/costs">{t('costs')}</Link>
            </h2>
            <div className="hidden last:block">
                <p className="text-slate-500 mb-4">
                    {t('no-costs-yet')}
                </p>
                <Link to="/patents/costs" className="btn-secondary">
                    <IconPlus className="h-5 w-5 inline align-middle mb-1" /> {t('add-cost')} 
                </Link>
            </div>
            <CostsGraph {...{byYear}} />
        </ChartContainer>
    )
}

function CockpitDriverChart() {
    const {t} = useTranslation()
    const {isLoading} = useBackend()

    const {team} = useSingleTeamMangement()
    const currency = team?.currency

    const {membersByFamilyId, families} = useFilteredPatents()
    const {fxConverter} = useFxContext()
    const {costsByMemberId} = useCosts()

    const [showBars, setShowBars] = useLocalState('cockpit-costs-show-bars', false)

    if (isLoading) return null

    const data = _(families)
        .map(f => {
            const costs = (membersByFamilyId[f.patentFamilyId] ?? []).flatMap(m => costsByMemberId[m.familyMemberId] ?? [])
            const total = Math.round(accumulateCosts(costs, { fxConverter, inclVat: false, currency }).total)
            return { name: f.internalReference, total }
        })
        .filter(d => d.total > 0)
        .sortBy('total')
        .reverse()
        .value()

    return (
        <ChartContainer>
            <div className="flex flex-row justify-between">
                <h2 className="mb-2 ">
                    <Link to="/patents/costs">{t('costs')}</Link> {showBars && <TopXMarker count={data.length} max={10} />}
                </h2>
                <div className=" mt-0.5">
                    <BarTreemapToggle {...{showBars, setShowBars}} />
                </div>
            </div>
            <ResponsiveContainer className="text-xs" width="100%" height={250}>
                {showBars
                    ? <BarChart
                        layout="vertical"
                        data={data.slice(0, 10)}
                        margin={{ left: 0, top: 5, right: 0, bottom: -10 }}
                    >
                        <YAxis type="category" dataKey="name" hide={true} />
                        <XAxis type="number" allowDecimals={false} />
                        {/* @ts-ignore */}
                        <Tooltip wrapperStyle={{ outline: "none" }} content={<HistogramTooltip />} />
                        <Bar
                            dataKey="total" name="Costs" fill={colors['pcx-300']}
                            label={({ x, y, height, value, name, width }) => {
                                //if (width < 32)
                                //    return null
                                //console.log({name, width})
                                const y_offset = height / 2 - 12 / 2 + 1 // 12 is the font size 
                                return <text x={x + 5} y={y + height - y_offset} fill={colors['pcx-900']} >{name}: {value}</text>
                            }}
                        />
                    </BarChart>
                    : <Treemap
                        animationDuration={500} data={data} dataKey="total"
                        // @ts-ignore
                        content={<CountryArea colors={sortedColors} textColors={textColors} />}
                    >
                        {/* @ts-ignore */}
                        <Tooltip wrapperStyle={{ outline: "none" }} content={<TreemapTooltip />} />
                    </Treemap>}
            </ResponsiveContainer>
        </ChartContainer>
    )
}