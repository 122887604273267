import _ from "lodash"
import clsx from "clsx"
import { Fragment, useMemo, useEffect, useState } from "react"
import { Menu, Transition } from '@headlessui/react'
import { Link } from "react-router-dom"
import { Helmet } from 'react-helmet-async'
import { Formik, Form, Field } from "formik"
import { useTranslation, Trans } from "react-i18next"

import { useCosts } from "../costs/CostsProvider"
import { IconArrowUpDown, IconChevronDown, IconChevronUp } from "../components/icons"
import { useBackend } from "../BackendProvider"
import { Invention } from "../inventions/InventionsProvider"
import { inventionUrl } from "../inventions/utils"
import Modal from "../components/Modal"
import { familyUrl, memberUrl } from "./utils"
import { downloadReport } from "../backend"
import { family_member, patent_family } from "../data"
import { calculatePriorityDate } from "./utils"
import { useMessages } from "../Messages"
import { useAuth, useRoles } from "../user/Auth"
import { PlainImage } from "../components/Image"
import { useComments } from "../comments/CommentsProvider"
import { useValuations } from "../valuations/ValuationsProvider"
import { calcScore } from "../valuations/Valuations"
import TagList from "../components/TagList"
import { useProductMapping } from "../products/products"
import { Family, Member } from "./patents"
import { AgentLink, Agent } from "../agents/utils"
import { Commodity } from '../products/products'
import { useInventions } from "../inventions/InventionsProvider"
import { IpRight, useDennemeyer, useMaintenances } from "../renewal/DennemeyerProvider"
import { useFilteredPatents } from "../filter/FilteredPatents"
import { useFilteredCommodities } from "../filter/FilteredCommodities"
import { extractState, useAugmentMaintenanceAction } from "../renewal/utils"
import { useSingleTeamMangement } from "../Management"
import { useFxContext } from "../forecast/FxProvider"
import { accumulateCosts } from "../costs/utils"
import { usePatents } from "./PatentsProvider"
import { extractStatusDescription } from "../renewal/MaintenanceActions"
import { useProducts } from "../products/ProductsProvider"

const byFamilyMember = "by-family-member"
const byPatentFamily = "by-patent-family"
const byClaimScopes = "by-claim-scopes"
const byProduct = "by-product"
const byInvention = "by-invention"


// agents: {['(p|c)-$agentId]}
function getAgent(agentLinks: AgentLink[], agents: Record<string, Agent>, type: string) {

    const agentsLookup = agentLinks.reduce((acc, l) => {
        if (l.linkType === type) {
            const key = `${l.agentType === "person" ? "p" : "c"}-${l.agentId}`
            const agent = agents[key]
            return agent ? {...acc, [l.familyMemberId]: [...(acc[l.familyMemberId] ?? []), agent]} : acc
        } else {
            return acc
        }
    }, {})

    return (member) => {
        return agentsLookup[member.familyMemberId]
    }
}

interface Row {
    families: Family[];
    members: Member[];
    products: Commodity[];
    claimScopeIds: number[];
    inventions: Invention[];
    key: string | number;
}

// We assume that a claim scope does not span families
// return {[member], [family], [product] key}
function useRows(groupBy: string): Row[] {
    const { hasClaimScopes } = useRoles()
    const { memberById } = usePatents()
    const { claims } = useBackend()
    const { families, familyById, membersByFamilyId } = useFilteredPatents()
    const { commodities } = useFilteredCommodities()
    const { claimScopeCommoditiesByPatentFamilyId, commoditiesByFamilyId, membersByCommodityId, commoditiesByMemberId, claimScopeCommoditiesByFamilyMemberId, claimScopeMembersByCommodityId } = useProductMapping()
    const { inventions, inventionsByMemberId } = useInventions()
    //console.log({claimScopeCommoditiesByFamilyMemberId, commoditiesByMemberId})
    //console.log({claimScopeCommoditiesByPatentFamilyId})

    function inventionsByMembers(members: {familyMemberId?: number}[]) {
        return _(members)
            .flatMap(m => inventionsByMemberId[m.familyMemberId] ?? [])
            .unionBy(i => i.inventionId)
            .value()
    }

    // TODO: sort all families/members by internal reference
    const claimScopesByMemberId = hasClaimScopes
        ? _(claims)
            .groupBy(c => c.familyMemberId)
            .mapValues(cs => _(cs).map(c => c.claimScopeId).filter(Boolean).sortBy().value())
            .value()
        : {}

    switch(groupBy) {
        case byFamilyMember:
        case family_member:
            return _(membersByFamilyId)
                .toPairs()
                .filter(([familyId]) => familyById[familyId] !== undefined)
                .sortBy(([familyId]) => familyById[familyId].internalReference) // TODO: needed?
                .flatMap(([familyId, members]) => {
                    return members.map(m => ({
                        families: [familyById[familyId]],
                        members: [m],
                        products: ((hasClaimScopes ? claimScopeCommoditiesByFamilyMemberId : {})[m.familyMemberId] 
                            ?? commoditiesByMemberId[m.familyMemberId]) ?? [],
                        claimScopeIds: claimScopesByMemberId[m.familyMemberId] ?? [],
                        inventions: inventionsByMemberId[m.familyMemberId] ?? [],
                        key: m.internalReference,
                    }))
                }).value()
        case byPatentFamily:
        case patent_family:
            return _(families)
                .sortBy(f => f.internalReference)
                .map(f => {
                    const members = membersByFamilyId[f.patentFamilyId] ?? []
                    return {
                        families: [f],
                        members,
                        // This can have undefined. Why?
                        products: ((hasClaimScopes ? claimScopeCommoditiesByPatentFamilyId : {})[f.patentFamilyId] 
                            ?? commoditiesByFamilyId[f.patentFamilyId]) ?? [],
                        claimScopeIds: _(members).flatMap(m => claimScopesByMemberId[m.familyMemberId]).uniq().value(),
                        inventions: inventionsByMembers(members),
                        key: f.internalReference,
                    }
                }).value()
        
        case byClaimScopes:
        case 'claimScope':
            return _(membersByFamilyId).toPairs().flatMap(([familyId, members]) => {
                const families = [familyById[familyId]]
                return _(members)
                    .map(({familyMemberId}) => ({familyMemberId, claimScopes: claimScopesByMemberId[familyMemberId] ?? []}))
                    .groupBy(({claimScopes}) => claimScopes.join('-'))
                    .toPairs()
                    .map(([key, value]) => ({
                        families,
                        members: value.map(({familyMemberId}) => memberById[familyMemberId]) ?? [],
                        products: claimScopeCommoditiesByFamilyMemberId[value[0]?.familyMemberId] ?? [],
                        claimScopeIds: _(value).flatMap(({claimScopes}) => claimScopes).uniq().value(),
                        inventions: inventionsByMembers(value),
                        key: `${familyId}-${key}`,
                    }))
                    .value()
            }).value()
        case byProduct:
            return _(commodities)
                .map(c => {
                    const members = ((hasClaimScopes ? claimScopeMembersByCommodityId : {})[c.commodityId] 
                        ?? membersByCommodityId[c.commodityId]) ?? []
                    const families = _(members).map(m => familyById[m.patentFamilyId]).filter(Boolean).uniqBy(f => f.patentFamilyId).value()
                    //console.log({c, families})
                    return {
                        families,
                        members,
                        products: [c],
                        claimScopeIds: _(members).flatMap(m => claimScopesByMemberId[m.familyMemberId]).uniq().value(),
                        inventions: inventionsByMembers(members),
                        key: c.commodityId,
                    }
                })
                .value()
        case byInvention: 
            return _(inventions)
                .map(invention => {
                    const member = memberById[invention.familyMemberId]
                    const members = member ? [member] : []
                    const families = _(members).map(m => familyById[m.patentFamilyId]).filter(Boolean).value()
                    return {
                        families,
                        members,
                        inventions: [invention],
                        products: ((hasClaimScopes ? claimScopeCommoditiesByFamilyMemberId : {})[member?.familyMemberId] 
                        ?? commoditiesByMemberId[member?.familyMemberId]) ?? [],
                        claimScopeIds: _(members).flatMap(m => claimScopesByMemberId[m.familyMemberId]).uniq().value(),
                        key: invention.inventionId,
                    }
                })
                .value()
        default:
            console.warn(`Not handling ${groupBy}`)
            return []
    }
}


function storeGroupBy(groupBy: string) {
    window.localStorage.setItem('analytics-groupBy', groupBy)
}
function loadGroupBy(groupableFields: string[]){
    const gb = window.localStorage.getItem('analytics-groupBy')
    if (groupableFields.indexOf(gb) < 0)
        return undefined
    else
        return gb
}

function storeSearchFields(fields: string[]) {
    window.localStorage.setItem('analytics-fields', fields.join(","))
}
function loadSearchFields(searchableFields: Record<string, any>){
    const fs = window.localStorage.getItem('analytics-fields')
    if (fs && fs.length > 0)
        return fs.split(",").filter(f => f in searchableFields)
    else
        return []
}

function loadSortOrder(): number {
    return +(window.localStorage.getItem('analytics-sort-order') ?? +1)
}
function saveSortOrder(sortOrder: number) {
    window.localStorage.setItem('analytics-sort-order', '' + sortOrder)
}

function loadSortField(): string {
    return window.localStorage.getItem('analytics-sort-field')
}
function saveSortField(sortField: string) {
    window.localStorage.setItem('analytics-sort-field', sortField)
}

function loadSelectedQueryId(): number | undefined {
    const id = +window.localStorage.getItem('analytics-query-id')
    return isNaN(id) ? undefined : id
}

function saveSelectedQueryId(id: number | undefined) {
    if (id)
        window.localStorage.setItem('analytics-query-id', '' + id)
    else
        window.localStorage.removeItem('analytics-query-id')
}

export interface SortButtonProps {
    searchField: string; 
    sortField: string; 
    sortOrder: number; 
    setSortField: (field: string | ((field: string) => string)) => void; 
    setSortOrder: (order: number | ((order: number) => number)) => void
}

export function SortButton({searchField, sortField, sortOrder, setSortField, setSortOrder}: SortButtonProps) {
    const isSorting = searchField === sortField
    const buttonElement =
        (searchField === sortField)
            ? sortOrder === 1 
                ? <IconChevronDown /> 
                : <IconChevronUp />
            : <IconArrowUpDown />
    return (
        <button className={isSorting ? "text-pcx-800" : "text-pcx-800/50"} onClick={() => {
            if (isSorting) {
                setSortOrder(s => s * -1)
            } else {
                setSortField(searchField)
                setSortOrder(+1)
            }
        }}>
            {buttonElement}
        </button>
    )
}

const maxFamilies = 150

export default function DataWarehouse() {
    const { t } = useTranslation()

    const { setErrorMessage } = useMessages()
    const { hasClaimScopes, isEditUser, hasAnnuities, hasCosts, hasInnovation } = useRoles()
    const {team} = useSingleTeamMangement()

    const currency = team?.currency ?? 'EUR'
    const {fxConverter} = useFxContext()

    const { agentLinks: _agentLinks, agents: _agents, tagsLookup, imagesLookup, claims, claimScopes, dataQueries } = useBackend()
    const { commodityById } = useProducts()
    const { families: allFamilies, membersByFamilyId: allMembersByFamilyId } = usePatents()
    const { families: _families, members: _members, familyById } = useFilteredPatents()
    const { commentsLookUp } = useComments()
    const { costsByMemberId } = useCosts()

    const { ipRightByMemberId, costCentersByIpRightId, validationsByIpRightId } = useDennemeyer()
    const { augmentMaintenanceAction } = useAugmentMaintenanceAction()
    //console.log({ipRightByDennemeyerId})
    //console.log({costCentersByIpRightId})

    // const minDate = today(getLocalTimeZone())
    // const maxDate = minDate.add({months: 6})
    const {data: maintenanceActions, fetchNextPage, hasNextPage} = useMaintenances({/*minDate, maxDate,*/ onlyInstructable: false, onlyOpen: false})
    useEffect(() => {
        if (hasNextPage)
            fetchNextPage()
    }, [hasNextPage, fetchNextPage, maintenanceActions])
    const maintenanceActionByMemberId = useMemo(() => 
        _(maintenanceActions?.pages?.flatMap(p => p?.Data?.Page ?? []))
            .map(augmentMaintenanceAction)
            .filter(m => m.member?.familyMemberId !== undefined)
            .groupBy(m => m.member?.familyMemberId)
            .mapValues(ms => _.minBy(ms, m => m.instructionDueDate))
            .value(),
    [maintenanceActions, augmentMaintenanceAction])

    const { scoresLookup } = useValuations()
    const scoresById = _.mapValues(scoresLookup, ss => {
        const score = calcScore(ss)
        return score
    })

    /// Limit the number of families to be processed
    // TODO: limiting the numbers of families in useRow
    const families = _.sortBy(_families.slice(0, maxFamilies), f => f.internalReference)
    const familyIds = new Set(families.map(f => f.patentFamilyId))
    const members = _members.filter(m => familyIds.has(m.patentFamilyId))

    const relevantMembers = new Set(members.map(m => m.familyMemberId))
    const agentLinks = _agentLinks.filter(l => relevantMembers.has(l.familyMemberId))

    const [showSaveAsModal, setShowSaveAsModal] = useState(false)
    const [showOpenModal, setShowOpenModal] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)

    const tagsById = tagsLookup[patent_family] ?? []

    const dataQueryById = _.keyBy(dataQueries, q => q.queryId)

    const agents = Object.fromEntries(_.map(_agents, a => {
        if (a.agentType === 'person')
            return [`p-${a.agentId}`, `${a.lastName}, ${a.firstName}`]
        else if (a.agentType === 'company')
            return [`c-${a.agentId}`, `${a.name}`]
        else {
            console.warn("Cannot handle agent type " + a.agentType)
            return []
        }
    }))
    
    const claimScopesByMember = claims
        .filter(c => relevantMembers.has(c.familyMemberId))
        .reduce((acc, cur) => {
            if (cur.claimScopeId)
                return { ...acc, [cur.familyMemberId]: _.sortBy([...(acc[cur.familyMemberId] ?? []), cur.claimScopeId]) }
            else
                return acc
        }, {})
    const claimScopesById = Object.fromEntries(claimScopes.map(c => [c.claimScopeId, c.claimScopeSummary]))
    // [{familyMemberIds: [INT], claimScopeIds: [INT]}]
    //console.log(claimScopesByMember)
    //console.log(claimScopesById)
    //console.log(agents)
    //console.log(tagsById)
    //console.log(Object.keys(familyById))

    const membersPriorityDates = useMemo(
      () => {
        //console.log("Calculating the priority dates")
        return _.mapValues(allMembersByFamilyId, members => calculatePriorityDate(members))
      },
      [allMembersByFamilyId],
    )
    const yes = t('yes')
    const no = t('no')

    function onlyDefinedBoolean(b?: boolean) {
        return b === undefined ? [] : [b ? yes : no]
    }

    function get_comments(entity: string, entityId: number) {
        return commentsLookUp[entity]?.[entityId]?.map(c => c.comment).filter(c => c && c.trim() !== '') ?? []
    }
    function get_last_comment(entity: string, entityId: number) {
        return commentsLookUp[entity]?.[entityId]?.[0]?.comment ?? ''
    }

    const currencyFormat = new Intl.NumberFormat(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})

    // NOTE: only for members we currently return arrays of values. Does it make sense to do this for all of them?
    const _searchableFields = {
        family: {
            dw_image: (f: Family) => f.patentFamilyId,
            comments: (f: Family) => get_comments(patent_family, f.patentFamilyId),
            lastComment: (f: Family) => get_last_comment(patent_family, f.patentFamilyId),
            patentFamilyReference: (f: Family) => f.internalReference,
            patentFamilyExtReference: (f: Family) => f.externalReference ?? '',
            familyName: (f: Family) => [f.familyName],
            patentFamilySummary: (f: Family) => f.summary ?? '',
            priorityDate: (f: Family) => familyById[f.patentFamilyId]?.priorityDate ?? membersPriorityDates[f.patentFamilyId]?.date ?? '',
            tags: (f: Family) => tagsById[f.patentFamilyId],
        },
        'family-member': {
            applicant: getAgent(agentLinks, agents, "applicant"),
            applicationDate: (m: Member) => [m.applicationDate],
            applicationNumber: (m: Member) => [m.applicationNumber],
            dw_claimScope: hasClaimScopes ? (m: Member) => claimScopesByMember[m.familyMemberId] ?? [] : undefined,
            countryCode: (m: Member) => [m.countryCode],
            familyMemberStatus: (m: Member) => [t([m.familyMemberStatus])],
            firstFiling: (m: Member) => onlyDefinedBoolean(m.firstFiling),
            familyMemberReference: (m: Member) => [m.internalReference],
            familyMemberExtReference: (m: Member) => [m.externalReference],
            inventor: getAgent(agentLinks, agents, "inventor"),
            ipType: (m: Member) => [t([m.ipType])],
            numberClaims: (m: Member) => [m.numberClaims],
            costs: !hasCosts ? undefined : ((m: Member) => accumulateCosts(costsByMemberId[m.familyMemberId] ?? [], {fxConverter, inclVat: false, currency}).total),
            owner: getAgent(agentLinks, agents, "owner"),
            optOut: (m: Member) => optOutMeaningful(m) ? onlyDefinedBoolean(m.optOut) : [],
            patentDate: (m: Member) => [m.patentDate],
            patentNumber: (m: Member) => [m.patentNumber],
            patentOfficeLink: (m: Member) => [m.patentOfficeLink],
            pctRouteFiling: (m: Member) => onlyDefinedBoolean(m.pctRouteFiling),
            expiryDate: (m: Member) => [m.expiryDate],
            publicationDate: (m: Member) => [m.publicationDate],
            publicationNumber: (m: Member) => [m.publicationNumber],
            patentComments: (m: Member) => get_comments(family_member, m.familyMemberId),
            lastPatentComment: (m: Member) => get_last_comment(family_member, m.familyMemberId),
            dw_score: (m: Member) => [scoresById[m.familyMemberId]],
            title: (m: Member) => [m.title],
            unitaryPatent: (m: Member) => m.countryCode === 'EP' ? onlyDefinedBoolean(m.unitaryPatent) : [],
            validated: (m: Member) => onlyDefinedBoolean(m.validated),
        },
        'annuities': hasAnnuities && { 
            'instruction-due': (m: Member) => {
                const nextDueDate = maintenanceActionByMemberId[m.familyMemberId]?.instructionDueDate
                return nextDueDate ?? ''
            },
            dm_status: (m: Member) => {
                // TODO: translate?
                let maintenanceAction = maintenanceActionByMemberId[m.familyMemberId]
                if (maintenanceAction) {
                    const { status, instruction } = maintenanceAction
                    const [phase] = extractStatusDescription({ maintenanceAction, status, instruction, t })
                    return phase
                } else 
                return ''
            },
            'payment-provider-state': (member: Member) => {
                const pcIpRight = ipRightByMemberId[member.familyMemberId]
                //const { data, ref, isLoading } = useLoadIpRightInViewport(pcIpRight.dennemeyerId)
                const ipRight = (maintenanceActionByMemberId[member.familyMemberId]?.IpRightInfo ?? {
                    Status: member.familyMemberStatus === 'pending' ? 'Pending' : member.familyMemberStatus === 'granted' ? 'Granted' : 'Inactive',
                }) as IpRight
                const status = extractState(member, pcIpRight, ipRight, validationsByIpRightId[pcIpRight?.ipRightId] ?? [])
                return t(`renewals-desc.${status}`)
            },
            'cost-centers': (m: Member) => 
                (costCentersByIpRightId[ipRightByMemberId[m.familyMemberId]?.ipRightId] ?? [])
                    .map(cc => cc.percentage === 100 ? cc.name : `${cc.name} (${cc.percentage}%)`)
                    .join(', '),
            dm_validation_errors: (m: Member) => {
                const pcIpRight = ipRightByMemberId[m.familyMemberId]
                const messages = validationsByIpRightId[pcIpRight?.ipRightId] ?? []
                return messages.map(m =>  m.message).join("\n")
            },
            fees: (m: Member) => {
                return maintenanceActionByMemberId[m.familyMemberId]?.fees ?? {}
            },
        },
        'products': {
            commodityReference: (c: Commodity) => c.commodityReference,
            commodityClass: (c: Commodity) => c.commodityClass,
            commodityDescription: (c: Commodity) => c.commodityDescription,
            isThirdParty: (c: Commodity) => c.isThirdParty ? yes : no,
            productImage: (c: Commodity) => c.commodityId,
        },
        'inventions': hasInnovation && {
            inventionReference: (i: Invention) => i.reference,
            inventionReferenceDate: (i: Invention) => i.referenceDate,
            inventionTitle: (i: Invention) => i.title,
            inventionSummary: (i: Invention) => i.summary,
        }
    }

    const searchableFields = _(_searchableFields).toPairs()
        .filter(([group, fields]) => typeof fields === 'object')
        .flatMap(([group, fields]) => _(fields).toPairs().filter(([field, fct]) => fct !== undefined).value())
        .fromPairs()
        .value()

    //console.log({_searchableFields, searchableFields})
    
    const noAggregagation = new Set([
        'comments',
        'patentComments',
        'lastPatentComment',
        'lastComment',
        'dw_claimScope',
        'applicationDate',
        'applicationNumber',
        'expiryDate',
        'publicationDate',
        'publicationNumber',
        'patentDate',
        'patentNumber',
        'patentFamilyReference', 
        'familyMemberReference',
        'patentFamilyExtReference',
        'patentFamilySummary',
        'patentOfficeLink',
        'familyMemberExtReference',
        'title',
        'familyName',
        'dw_image',
        'dw_score',
        'dm_validation_errors',
        'commodityReference',
        'productImage',
        'commodityDescription',
        'inventionReference',
        'inventionReferenceDate',
        'inventionTitle',
        'inventionSummary',
    ])

    // TODO: how to translate the tagDisplays in tagFields? Maybe pre-create the displayFields object?
    const groupableFields = [ 
        byFamilyMember, 
        byPatentFamily,  
        hasClaimScopes && byClaimScopes, 
        byProduct, 
        byInvention,
    ].filter(Boolean)

    const [groupByField, setGroupByField] = useState(loadGroupBy(groupableFields) ?? groupableFields[0])
    useEffect(() => storeGroupBy(groupByField), [groupByField])
    const [searchFields, setSearchFields] = useState(loadSearchFields(searchableFields))
    useEffect(() => storeSearchFields(searchFields), [searchFields])
    //console.log(searchFields)

    
    const displayFields = Object.fromEntries([
        ...groupableFields,
        ..._(searchableFields).toPairs().map(([group, fields]) => [group, ..._.keys(fields)]).value()
    ].map(f => [f, t(f)]))

    //const [onlyCount, setOnlyCount] = useState(false)

    const [sortOrder, setSortOrder] = useState(loadSortOrder())
    const [sortField, setSortField] = useState(loadSortField())
    useEffect(() => saveSortOrder(sortOrder), [sortOrder])
    useEffect(() => saveSortField(sortField), [sortField])

    const sortIndex = searchFields.indexOf(sortField)
    const collator = new Intl.Collator()

    //const groupByDisplay = groupByField === patent_family ? "Patent Families": "Family Members"

    const [selectedQueryId, setSelectedQueryId] = useState(loadSelectedQueryId())
    useEffect(() => saveSelectedQueryId(selectedQueryId), [selectedQueryId])
    const [queryAction, setQueryAction] = useState("")

    const queryName = dataQueryById[selectedQueryId]?.queryName
    const queryActions:  QueryAction[] = [
        {disabled: false, value: "_new", label: t('new')},
        {disabled: dataQueries.length === 0, value: "_open", label: t('open')},
        {disabled: !isEditUser || queryName === undefined, value: "_save", label: t('save')},
        {disabled: !isEditUser || searchFields.length === 0, value: "_save_as", label: t('save-as')},
        {disabled: !isEditUser || queryName === undefined, value: "_rename", label: t('rename')},
        {disabled: !isEditUser || queryName === undefined, value: "_delete", label: t('delete') + " " + (queryName ?? '')},
    ]

    function selectQuery(action) {
        //const action = event?.target?.value
        switch(action) {
            case "_new":
                setSearchFields([])
                setGroupByField(byFamilyMember)
                setSelectedQueryId(undefined)
                break
            case "_open": 
                setShowOpenModal(true)
                break
            case "_save": 
            case "_save_as": 
            case "_rename": 
                setQueryAction(action)
                setShowSaveAsModal(true)
                break
            case "_delete": 
                setShowDeleteModal(true)
                break
            case "": break
            default:
                const queryId = action
                const query = dataQueryById[queryId]
                setSelectedQueryId(queryId)
                //console.log(query)
                setGroupByField(query.groupBy)
                setSearchFields(query.fields)
        }
    }

    const validValue = (v) => v !== undefined && ((typeof v === 'string' && v.trim() !== "") || typeof v === 'object' || typeof v === 'number')

    // TODO: add product filter to bar (own/third party and filter word)
    const rows = useRows(groupByField)
    //console.log({_rows: rows})
    const rowData = rows
        .map(row => ({
            ...row,
            columns: searchFields.map(f => {
                let values = []
                if (f in _searchableFields.family) {
                    values = row.families.map(_searchableFields.family[f]).filter(validValue)
                } else if (f === 'dw_score') {
                    const scores = _(row.members).flatMap(m => searchableFields[f](m)).filter(validValue).value()
                    const score = _.mean(scores)
                    if (isNaN(score))
                        values = []
                    else 
                        values = [score.toFixed(1)]
                } else if (f === 'fees' && 'annuities' in _searchableFields) {
                    return _(row.members ?? [])
                        .flatMap(m => _.toPairs(_searchableFields['annuities'][f](m)))
                        .reduce((acc, [ccy, amount]) => ({...acc, [ccy]: parseFloat((acc[ccy] ?? 0)) + parseFloat(amount)}), {})
                } else if (row.claimScopeIds && f === 'dw_claimScope') {
                    return Object.fromEntries(row.claimScopeIds.map(c => [c, 1]))
                } else if (typeof _searchableFields['family-member'][f] === 'function')
                    values = row.members.flatMap(_searchableFields['family-member'][f]).filter(validValue)
                else if (f in (_searchableFields['annuities'] || {}))
                    values = row.members.flatMap(_searchableFields['annuities'][f]).filter(validValue)
                else if (f in _searchableFields['products'])
                    values = row.products.filter(Boolean).map(_searchableFields['products'][f]).filter(validValue)
                else if (f in (_searchableFields['inventions'] || {}))
                    values = row.inventions.filter(Boolean).map(_searchableFields['inventions'][f]).filter(validValue)
                return _.countBy(values)
            })
        }))
        .sort((rowA, rowB) => {
            if (sortIndex < 0) return 0
            const a = Object.keys(rowA.columns[sortIndex]).sort()?.[0]
            const b = Object.keys(rowB.columns[sortIndex]).sort()?.[0]
            return a === undefined ? 1 : b === undefined ? -1 : (collator.compare(a, b) * sortOrder)
        })
        .filter(({columns}) => columns.find(c => _.size(c) > 0))

    function dotProduct(values: Record<number, number>) {
        return _(values).entries().map(([k, v]) => (+k) * v).sum()
    }

    // Loops over all rows to get the total counts
    const totalCounts = searchFields.map((f, fi) =>
        noAggregagation.has(f)
            ? {Total: _.sum(rowData.map(r => _.size(r.columns[fi])))}
            : f === "numberClaims"
            ? {Total: _(rowData).map(r => dotProduct(r.columns[fi])).sum()} 
            : f === "costs"
            ? {Total: currencyFormat.format(_(rowData).map(r => dotProduct(r.columns[fi])).sum())}
            : _(rowData).flatMap(r => _.toPairs(r.columns[fi])).filter(([key]) => validValue(key)).groupBy(([key]) => key).mapValues(v => _.sum(v.map(([k,v]) => v))).value()
    )
    const tableData = {rows: rowData, columnKeys: searchFields, columns: searchFields.map(f => t([f])), totalCounts}
                        
    //console.log(tableData)
    
    const labelStyle="w-24 after:content-[':'] shrink-0"
    const inputGroupStyle="flex flex-row items-center gap-2 max-w-3xl"

    // values: key of value to count
    function renderCell(values, field) {
        const possibleRef = Object.keys(values)[0]
        const linkStyle = "underline-link py-0 whitespace-nowrap after:content-[';'] mr-1 last:mr-0 last:after:content-['']"
        if (field === "patentFamilyReference" && possibleRef) {
            return _(values)
                .keys().sortBy()
                .map(internalReference =>
                    <Link key={internalReference} className={linkStyle} to={familyUrl({ internalReference })}>
                        {internalReference}
                    </Link>)
                .value()
        } else if (field === "familyMemberReference") {
            return (
                <div className="flex flex-wrap"> {Object.entries(values).map(([ref]) =>
                    <Link key={ref} className={linkStyle + " pr-1"} to={memberUrl({internalReference: ref})}>
                        {ref}
                    </Link>
                )}</div>
            )
        } else if (field === "inventionReference") {
            return (
                <div className="flex flex-wrap"> {Object.entries(values).map(([ref]) =>
                    <Link key={ref} className={linkStyle + " pr-1"} to={inventionUrl({reference: ref})}>
                        {ref}
                    </Link>
                )}</div>
            )
        } else if (field === "dw_image" && possibleRef) {
            // possibleRef is the patent_family_id 
            const url = imagesLookup[patent_family]?.[possibleRef]?.url // NOTE: This only takes the first image instead of all if there is a group
            if (url)
                return (
                    <div className="w-36 max-h-[4rem]">
                        <img className="mx-auto h-[4rem]" src={url} alt={`Family ${familyById[possibleRef]?.internalReference ?? ''}`} />
                    </div>
                )
            else return null
        } else if (field === "productImage" && possibleRef) {
            // possibleRef is the patent_family_id 
            const url = imagesLookup['commodity']?.[possibleRef]?.url // NOTE: This only takes the first image instead of all if there is a group
            if (url)
                return (
                    <div className="w-36 max-h-[4rem]">
                        <img className="mx-auto h-[4rem]" src={url} alt={`Product ${commodityById[possibleRef]?.internalReference ?? ''}`} />
                    </div>
                )
            else return null
        } else if (field === "dw_claimScope") {
            const scopes = _.flatten(Object.keys(values))
            //console.log(values)
            //console.log(scopes)
            // ?.map(c => claimScopesById[c])
            return (
                <div className="flex flex-row gap-2">
                    {scopes.map((c, ci) => 
                        <div key={ci} className="w-64 flex flex-col gap-1">
                            <div className="h-36">
                                <PlainImage {...{
                                    entity: 'claim-scope',
                                    entityId: c,
                                    clickable: false,
                                }} />
                            </div>
                            <div
                                key={ci}
                                className="bg-pcx-100 dark:bg-pcx-200 p-1 rounded-md max-w-prose border border-pcx-200"
                                dangerouslySetInnerHTML={{ __html: claimScopesById[c] }} />
                        </div>
                        )}
                </div>
            )
        } else if (field === "numberClaims") {
            const total = dotProduct(values)
            return <div>{total === 0 ? "" : total}</div> 
        } else if (field === "costs") {
            const total = dotProduct(values)
            return <div className="w-full text-right tabular-nums">{currencyFormat.format(total)}</div> 
        } else if (field === "comments" || field === 'patentComments') {
            return <div>{_.keys(values).map((v, vi) => <p className="last:mb-0 whitespace-pre-line" key={vi}>{v}</p>)}</div>
        } else if (field === 'patentOfficeLink') { 
            return <div className="flex flex-row flex-wrap gap-x-1">{
                _(values)
                    .keys()
                    .filter(v => typeof v === 'string' && v.trim() !== '')
                    .map((v, vi) => 
                        <a 
                            href={v} target="_blank" rel="noreferrer" title={v} key={vi}
                            className="whitespace-nowrap max-w-xs text-ellipsis overflow-hidden text-pcx-600 underline"
                        >{v}</a>)
                        
                    .value()
            }</div>
        } else if (field === "fees") {
            return <div>{_(values).toPairs().map(([ccy, v], vi) =>
                <span className="pr-1 last:pr-0 after:content-[';'] last:after:content-['']" key={vi}>{v} {ccy}</span>
            ).value()}</div>
        } else 
            return (
                <div className="flex flex-wrap"> {Object.entries(values).map(([v]) => 
                    <span key={v} className="last:pr-0 pr-1 after:content-[';'] last:after:content-['']">{v}</span>
                )}</div>
            )
    }

    if (families.length === 0)
        return <>
            {/* @ts-ignore */}
            <Helmet>
                <title>{t('data-wizard')} | Patent Cockpit</title>
            </Helmet>
            <div className="flex flex-row gap-2 header-row">
                <h2 className="modern-h2">{t('data-wizard')}</h2>
            </div>
            {families.length === allFamilies.length
                ? <div className="main-content text-center">
                    {t('extract-data-here')}
                    <br />
                    <Link className="text-pcx-500 underline-link" to="/patents/portfolio">{t('add-patents')}</Link>
                </div>
                : <div className="main-content text-center">
                    <Trans i18nKey="no-patent-results" />
                </div>
            }
        </>
    else {
        //console.log(dataQueries)
        const maxRows = 500
        return (
            <>
                {/* @ts-ignore */}
                <Helmet>
                    <title>{t('data-wizard')} | Patent Cockpit</title>
                </Helmet>
                <div className="header-row">
                    <div className="flex flex-row gap-2">
                        <h2 className="modern-h2 grow">{t('data-wizard')}</h2>
                        <button
                            className="btn-secondary font-normal text-base py-px"
                            onClick={() => downloadReport({ url: "/api/excel", report: "data-report", opts: { tableData } }).catch((err) => setErrorMessage(err.message))}
                        >
                            {t('excel-export')}
                        </button>
                    </div>
                </div>
                <div className="main-content">
                    <div className="flex flex-col lg:flex-row-reverse justify-between py-2 gap-4 w-visible">
                        <div className="flex lg:flex-col sm:flex-row flex-col lg:items-start sm:items-center gap-2">
                            <h3 className="text-slate-600 whitespace-nowrap">{queryName ?? t('unnamed-query')}</h3>
                            <QueryMenu {...{selectQuery, queryActions}} />
                        </div>

                        <div className="flex flex-col gap-2">
                            <div className={inputGroupStyle}>
                                <label className={labelStyle}>{t('grouping')}</label>
                                <select
                                    className="form-select text-sm w-40 h-7 py-px px-2"
                                    value={groupByField}
                                    onChange={(e) => {
                                        const gb = e.target.value
                                        setGroupByField(gb)
                                    }}>
                                    {groupableFields.map(f => <option key={f} value={f}>{t([f])}</option>)}
                                </select>
                            </div>

                            <div className={inputGroupStyle}>
                                <label className={labelStyle}>{t('fields')}</label>
                                <TagList {...{
                                    name: "searchFields",
                                    dragable: true,
                                    availableTags: _(_searchableFields)
                                        .toPairs()
                                        .map(([k, vs]) => [t(k), _(vs).keys().filter(v => vs[v] !== undefined).sortBy(v => displayFields[v] ?? v).value()])
                                        .fromPairs()
                                        .value(),
                                    tags: searchFields,
                                    setTags: setSearchFields,
                                    tagDisplays: displayFields,
                                    placeholder: t('field'),
                                }} />
                            </div>

                            {showSaveAsModal && <QueryEditorModal {...{setShowModal: setShowSaveAsModal, fields: searchFields, groupBy: groupByField, selectedQueryId, setSelectedQueryId, queryAction}} />}
                            {showDeleteModal && <QueryDeleteModal {...{setShowModal: setShowDeleteModal, selectedQueryId, setSelectedQueryId}} />}
                            {showOpenModal && <QueryOpenModal {...{setShowModal: setShowOpenModal, setSelectedQueryId, setSearchFields, setGroupByField}} />}
                        </div>

                    </div>
                    {tableData.rows.length >= maxRows &&
                        <div className="py-2">
                            <div className="warning">
                                <Trans i18nKey="only-maxRows-shown" values={{maxRows}} />
                            </div>
                        </div>
                    }
                    {searchFields.length === 0 
                        ? <div className="text-xl text-gray-700 py-10">{t('data-wizard-empty')}</div>
                        : <div className="py-4">
                            <table className="border-spacing-0 border-collapse">
                                <thead className="">
                                    <tr className="text-left">
                                        {searchFields.map(searchField =>
                                            <th key={searchField} className="sticky -top-4 bg-white dark:bg-pcx-100 px-0">
                                                <h3 className="whitespace-nowrap px-2 border-b-2 border-pcx-500">
                                                    {displayFields[searchField]} <SortButton {...{ searchField, sortField, sortOrder, setSortField, setSortOrder }} />
                                                </h3>
                                            </th>
                                        )}
                                    </tr>
                                </thead>
                                <tbody>
                                    {tableData.rows.slice(0, maxRows).map(row =>
                                        <tr key={row.key} className="last:border-b-2 last:border-pcx-500 even:bg-pcx-100 dark:even:bg-pcx-200">
                                            {row.columns.map((values, colIdx) =>
                                                <td key={`${row.key}-${colIdx}`} className="align-top py-0.5 px-2">
                                                    {renderCell(values, searchFields[colIdx])}
                                                </td>
                                            )}
                                        </tr>
                                    )}
                                    {tableData.rows.length >= maxRows &&
                                        <tr className="warning">
                                            <td className="text-slate-500 px-2 py-1" colSpan={searchFields.length}>
                                                <Trans i18nKey="only-maxRows-shown" values={{maxRows}} />
                                            </td>
                                        </tr>
                                    }
                                </tbody>
                                <tfoot>
                                    <tr className="border-t-2 border-gray-800 align-top">
                                        {_.zip(searchFields, totalCounts).map(([field, counts]) =>
                                            <td key={field}>
                                                <div className="px-2 flex flex-col">{
                                                    Object.entries(counts)
                                                        .sort(([va, ca], [vb, cb]) => cb - ca)
                                                        .map(([value, count]) => <div key={`count-${value}`}>{value}: {count}</div>)
                                                }</div>
                                            </td>
                                        )}
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    }
                </div>
            </>
        )
    }
}

function QueryOpenModal({setSelectedQueryId, setShowModal, setSearchFields, setGroupByField}) {
    const {dataQueries} = useBackend()
    const { t } = useTranslation()
    const doSelect = ({queryId, groupBy, fields}) => {
        setSelectedQueryId(queryId)
        setGroupByField(groupBy)
        setSearchFields(fields)
        setShowModal(false)
    }

    return (
        <Modal escAction={() => setShowModal(false)}>
            <div className="dark:bg-pcx-100">
                <h4 className="px-5 pt-4">{t('open-query')}</h4>
                <div className="p-4">
                    <div className="border border-pcx-300 rounded-md p-1 flex flex-col gap-1">
                        {_(dataQueries)
                            .sortBy(q => q.queryName)
                            .map(query =>
                                <button
                                    key={query.queryId}
                                    className="text-left border border-pcx-200 rounded-md px-2 py-px hover:bg-pcx-100 dark:hover:bg-pcx-200 sm:min-w-xs"
                                    onClick={() => doSelect(query)}>
                                    {query.queryName}
                                </button>)
                            .value()}
                    </div>
                </div>
                <div className="px-5 py-4 bg-pcx-200 flex flex-row-reverse gap-2">
                    <button onClick={() => setShowModal(false)} className="btn-secondary">{t('cancel')}</button>
                </div>
            </div>
        </Modal>
    )
}

function QueryDeleteModal({selectedQueryId, setShowModal, setSelectedQueryId}) {
    const {entityOperation, dataQueries} = useBackend()
    const { t } = useTranslation()
    const query = dataQueries.find(q => q.queryId === +selectedQueryId)
    const doDelete = () => entityOperation('data-query', 'delete', +selectedQueryId)
        .then(() => {
            setSelectedQueryId(undefined)
            setShowModal(false)
        })

    return (
        <Modal escAction={() => setShowModal(false)}>
            <div>
                <h4 className="p-4"><Trans i18nKey="delete-query-question" values={{queryName: query?.queryName}} /></h4>
                <div className="p-4 bg-pcx-200 flex flex-row-reverse gap-2">
                    <button onClick={() => doDelete()} className="btn-warn">{t('delete')}</button>
                    <button onClick={() => setShowModal(false)} className="btn-secondary">{t('cancel')}</button>
                </div>
            </div>
        </Modal>
    )
}

export interface Query {
    queryId?: number
    queryName: string
    username: string
    groupBy: string
    fields: string[]
}

export interface QueryInput {
    queryName?: string
}

function QueryEditorModal({fields, groupBy, setShowModal, selectedQueryId: _selectedQueryId, setSelectedQueryId, queryAction}) {
    const {user: {name: username}} = useAuth()
    const {entityOperation, dataQueries} = useBackend()
    const { t } = useTranslation()

    const selectedQueryId = +_selectedQueryId
    const selectedQuery = dataQueries.find(q => q.queryId === selectedQueryId)

    const ops = (queryAction === "_save_as" || selectedQuery === undefined) ? "add" : "update"
    const isSave = queryAction === "_save" && selectedQuery?.queryName
    const isRename = queryAction === "_rename"

    const otherQueries = new Set(dataQueries.map(q => q.queryName))

    function operation(queryName) {
        const dataQuery = { queryName, username, groupBy, fields, queryId: selectedQueryId }
        return entityOperation('data-query', ops, dataQuery)
            .then((resp: Query) => resp?.queryId && setSelectedQueryId(resp?.queryId))
            .finally(() => setShowModal(false))
    }

    return (
        <Modal escAction={() => setShowModal(false)}>
            {isSave
                ? <div>
                    <h3 className="p-4">{t('save-query-question')}</h3>
                    <div className="p-4 bg-pcx-200 flex flex-row-reverse gap-2">
                        <button className="btn-primary" onClick={() => operation(selectedQuery.queryName)}>{t('save')}</button>
                        <button className="btn-secondary" onClick={() => setShowModal(false)}>{t('cancel')}</button>
                    </div>
                </div>
                : <Formik
                    initialValues={{ queryName: selectedQuery?.queryName ?? '' } as QueryInput}
                    onSubmit={({ queryName }) => operation(queryName)}
                    validate={({ queryName }) => {
                        const errors = {} as QueryInput
                        if (queryName === "")
                            errors.queryName = t('empty-query-name-error')
                        else if (queryName.startsWith("_"))
                            errors.queryName = t('underscore-query-error')
                        else if (otherQueries.has(queryName))
                            errors.queryName = t('query-name-used-error')
                        return errors
                    }}
                >{({ errors, values }) =>
                    <Form>
                        <div className="p-4">
                                <h2 className="pb-2">{
                                    ops === "add" ? t('save-as-new-query')
                                    : isRename ? <Trans i18nKey="rename-query" values={{ queryName: selectedQuery.queryName }} />
                                    : <Trans i18nKey="save-query" values={{ queryName: selectedQuery.queryName }} />
                                }
                            </h2>
                            <label>
                                <div className="pb-1 text-slate-700">{t('query-name')}</div>
                                <Field autoFocus className="form-input w-full" name="queryName" required />
                                {errors.queryName && <div className="text-red-700 pt-2">{errors.queryName}</div>}
                            </label>
                        </div>
                        <div className="p-4 bg-pcx-200 flex flex-row-reverse gap-2">
                            <input 
                                disabled={isRename && values.queryName === selectedQuery?.queryName} 
                                className="btn-primary disabled:btn-disabled"
                                type="submit"  value={t('save')}
                            />
                            <button className="btn-secondary" onClick={() => setShowModal(false)}>{t('cancel')}</button>
                        </div>
                    </Form>
                    }</Formik>
            }
        </Modal>
    )
}

interface QueryAction {
    value: string
    label: string
    disabled?: boolean
}


function QueryMenu({selectQuery, queryActions}: {selectQuery: (query: string) => void, queryActions: QueryAction[]}) {
    const {t} = useTranslation()
    return (
        <Menu as="div" className="relative inline-block text-left">
            <div>
                <Menu.Button className="btn-secondary rounded-sm inline-flex justify-center font-normal focus:outline-0 focus:ring-0">
                    {t('query-actions')}
                    <IconChevronDown aria-hidden="true" />
                </Menu.Button>
            </div>

            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-sm bg-white border border-pcx-500 shadow-lg ring-1 ring-pcx-600 ring-opacity-5 focus:outline-none overflow-hidden">
                    {queryActions.map(({ value, label, disabled }) =>
                            <Menu.Item disabled={disabled} key={label}>
                                {({ active }) => 
                                    <button 
                                        onClick={() => selectQuery(value)} 
                                        className={clsx(
                                            disabled ? 'text-gray-400' 
                                            : active ? 'bg-pcx-200 text-pcx-800' : 'text-pcx-700', 
                                            'w-full text-left block px-4 py-2 text-sm')}
                                    >{label}</button>}
                            </Menu.Item>
                    )}
                </Menu.Items>
            </Transition>
        </Menu>
    )
}

function optOutMeaningful(member: Member) {
    return (member.countryCode === 'EP' && member.unitaryPatent !== true) || member.validated
}