import { useEffect, useRef, useState } from "react";
import { ChevronDownIcon, FolderIcon, PlusIcon, EllipsisHorizontalIcon } from "@heroicons/react/20/solid";
import { DocumentIcon, DocumentPlusIcon, FolderPlusIcon } from "@heroicons/react/24/outline";
import { Link, useLocation } from "react-router-dom";
import { Menu } from '@headlessui/react'
import { useTranslation } from "react-i18next";
import { Field, Form, Formik } from "formik";
import { useDrag, useDrop } from "react-dnd";
import { NativeTypes } from 'react-dnd-html5-backend'
import clsx from "clsx";
import _ from "lodash";

import { IconEdit, IconSpinner, IconTrash } from "../components/icons";
import { useMessages } from "../Messages";
import { useDms } from "./DocumentsSession";
import Modal, { LoadingModal } from "../components/Modal";
import { addFolder, moveObject, useAgorumFolder, useFileReload, useFolderReload, uploadFile } from "./backend";
import { useRoles } from "../user/Auth";
import { AgorumObject, AgorumObjectProvider, useBrowser, useObject } from "./DocumentsProvider";

function FilelistItem({isMoving}: {isMoving?: boolean}) {
    const {t} = useTranslation()
    const {object, error, fallback, isLoading} = useObject()

    if (fallback && error) return <ErrorFileItem {...{error: error.message, filename: fallback}} />

    if (isLoading || object === undefined)
        return <div className="text-slate-800">{fallback ?? t('loading')}...</div>

    return object.isFolder ? <FolderView /> : <FileView {...{isMoving}} />
}

function ErrorFileItem({error, filename}: {error: string, filename: string}) {
    return (
        <div className="text-slate-800">
            <div className="flex flex-row items-center gap-3 hover:bg-pcx-100 py-px px-0.5 rounded-sm">
                <button className="grow min-w-0 sm:w-96 truncate text-left whitespace-nowrap" title={error + '\nClick to copy'} onClick={async () => await navigator.clipboard.writeText(error)}>
                    <IconSpinner className="h-5 w-5 inline text-pcx-400 mr-1 mb-0.5 animate-spin" /><span className="grow">{filename}</span>
                </button>
            </div>
        </div>
    )
}

function MovingFile({name}: {name: string}) {
    return <div className="flex flex-row gap-2 items-center text-pcx-400">
        <IconSpinner className="h-4 w-4 animate-spin " /> <span className="text-gray-500">{name}</span>
    </div>
}

interface DraggedFile {
    uuid: string;
    parent: string;
    name: string;
}

const fileType = 'FILE'

function FileView({isMoving}: {isMoving?: boolean}) {
    const {isEditUser} = useRoles()

    const location = useLocation()
    const {object, parent} = useObject()
    const {previewLink} = useBrowser()
    const [{isDragging}, drag] = useDrag({
        type: fileType, 
        item: {uuid: object.uuid, parent, name: object.name} as DraggedFile,
        collect: monitor => ({
            isDragging: monitor.isDragging(),
        })
    })

    if (isMoving) return <MovingFile {...{name: object.name}} />

    const thePreviewLink = previewLink(object)
    const isInPreview = (location.pathname + location.search) === thePreviewLink

    return (
        <div className={clsx("text-slate-800", isDragging && "opacity-50")}>
            <div className={clsx("flex flex-row items-center gap-3 hover:bg-pcx-100 py-px px-0.5 rounded-sm", isInPreview && "bg-pcx-200")}>
                <Link ref={drag} to={thePreviewLink} className="hover:text-black grow min-w-0 sm:w-96 truncate" title={object.description ? object.name + '\n' + object.description :  object.name}>
                    <DocumentIcon className="h-5 w-5 inline text-pcx-400 mr-1 mb-0.5" /><span className="grow">{object.name}</span>
                </Link>
                {isEditUser && <FileEditMenu />}
            </div>
        </div>
    )
}

const fileEnums = {
    edit: 'edit',
    delete: 'delete',
}

export function FileEditMenu() {
    const {t} = useTranslation()

    const [showDialog, setShowDialog] = useState(undefined)

    const showEdit = showDialog === fileEnums.edit
    const showDelete = showDialog === fileEnums.delete

    const cancel = () => setShowDialog(undefined)
   
    // TODO: edit description
    // edit, delete, fullpage?
    return <>
        <Menu as="div" className="relative inline-block">
            <Menu.Button className="block">
                <EllipsisHorizontalIcon className="h-4 w-4 inline text-pcx-500 hover:text-pcx-600 cursor-pointer" /> 
            </Menu.Button>
            <Menu.Items as="div" className="z-10 absolute right-0 bg-white overflow-hidden border border-pcx-300 rounded-md shadow-lg text-pcx-800">
                <Menu.Item as="div" className={itemStyle}>
                    <IconEdit  className="h-5 w-5 inline mb-0.5 mr-2" /><button onClick={() => setShowDialog(fileEnums.edit)}>{t('edit')}</button>
                </Menu.Item>
                <Menu.Item as="div" className={itemStyle}>
                    <IconTrash className="h-5 w-5 inline mb-0.5 mr-2" /><button onClick={() => setShowDialog(fileEnums.delete)}>{t('delete')}</button>
                </Menu.Item>
                {/* TODO 
                <Menu.Item as="div" className={itemStyle}>
                    <button onClick={() => findParent(object.uuid).then(res => console.log({res}))}>FIND PARENT</button>
                </Menu.Item>*/}
            </Menu.Items>
        </Menu>
        {showEdit && <RenameModal {...{cancel}} />}
        {showDelete && <DeleteFileButton {...{cancel}} />}
    </>
}

export function FolderView(
    {defaultOpen, name}:
    {defaultOpen?: boolean, name?: string}
    ) {
    const {t} = useTranslation()
    const {isEditUser} = useRoles()

    const {object, parent} = useObject()

    const {sessionId, baseurl} = useDms()
    const [isUploading, setIsUploading] = useState(false)
    const [isMoving, setIsMoving] = useState(undefined as undefined | DraggedFile)
    const {reload} = useFolderReload(object)
    const {reload: fileReload} = useFileReload()

    // For moving
    const [{isOver, canDrop}, dropRef] = useDrop({
        accept: fileType, 
        drop: async (item: DraggedFile, monitor) => {
            //console.log(`Move ${item.uuid} to ${object.uuid} from ${item.parent}`)
            if (isEditUser) {
                setIsMoving(item)
                await moveObject(item.uuid, object.uuid, item.parent)
                //console.log('reloading')
                fileReload(item.uuid)
                //console.log('reloading parent')
                reload(item.parent)
                reload()
                setIsMoving(undefined)
                //console.log('done')
            }
        },
        canDrop: (item: DraggedFile) => isEditUser && item.parent !== object.uuid,
        collect: monitor => ({
            isOver: monitor.isOver(),
            canDrop: !!monitor.canDrop(),
        })
    })
    //console.log({isOver, canDrop, object, isDragging})

    // On drop for uploading
    async function onDrop(files: File[]) {
        //console.log(files)
        if (isEditUser) {
            setIsUploading(true)
            await Promise.all(files.map((f: File) => uploadFile(f, object.uuid, sessionId, baseurl)))
                .catch(err => console.error(err))
                .finally(() => {
                    setIsUploading(false);
                    reload();
                })
        }
    }

    // For uploading
    const [{isOver: fileIsOver, canDrop: fileCanDrop}, fileDropRef] = useDrop({
        accept: NativeTypes.FILE,
        drop: async (item: {files: File[]}) => {
            return onDrop(item.files)
        },
        collect: monitor => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(), // only true if dragging process on-going
        })
    })

    const {isOpen: _isOpen, setOpen} = useBrowser()
    const isOpen = defaultOpen === true || _isOpen(object.uuid)
    function setIsOpen(open: boolean) {
        setOpen(object.uuid, open)
    }
    
    const [showAddFolder, setShowAddFolder] = useState(false)

    const {folder} = useAgorumFolder(object)

    const nodes = _((isOpen ? (folder ?? []) : []) as (AgorumObject | DraggedFile & {isMoving: boolean})[])
        .concat(isMoving ? [{isMoving: true, ...isMoving}] : [])
        .filter(n => !n.name.startsWith('.'))
        .sortBy(f => f.name?.toLocaleLowerCase())
        .value()

    // showInlineDropzone == is empty
    const showInlineDropzone = isOpen && nodes.length === 0 && isEditUser

    const folderName = name ?? object.name

    // if (fileIsOver)
    //     console.log({folderName, fileIsOver, fileCanDrop, })

    if (!object) return null

    return (
        <div key={`${object.uuid}-${isOpen}`} className="text-slate-800 relative">
            <div ref={dropRef} className={clsx(
                "flex flex-row items-center gap-2  py-px px-0.5 rounded",
                ((isOver && canDrop) || (fileCanDrop && fileIsOver)) ? "bg-pcx-400 text-white" : "hover:bg-pcx-100",
            )}>
                <button className="hover:text-black grow text-left truncate min-w-0" onClick={() => setIsOpen(!isOpen)}>
                    <ChevronDownIcon className={clsx("h-5 w-5 inline mr-1", !isOpen && "rotate-[270deg]", defaultOpen && 'hidden')} />
                    <FolderIcon className="h-5 w-5 inline text-pcx-300 mr-1 mb-0.5" />
                    {folderName}
                    {object?.description && <span className="text-gray-500"> - {object.description}</span>}
                </button>
                {isEditUser &&
                    <div className="inline"><FolderMenu {...{ object, parent, setShowAddFolder }} /></div>}
            </div>
            {showInlineDropzone &&
                <label className="rounded text-gray-500 hover:text-gray-600 hover:bg-pcx-100 cursor-pointer pl-7 block">
                    {t('empty-drop-files-here')}
                    <input type="file" multiple className="hidden" onChange={e => onDrop(Array.from(e.target.files))} />
                </label>}
            {fileCanDrop &&
                <div ref={fileDropRef} className={clsx(
                    "absolute z-20 top-0 left-0 right-0 bottom-0 border-2  text-pcx-700 rounded",
                    "px-2 font-medium text-center ",
                    fileIsOver ? "border-pcx-400" : "border-transparent",
                )} />}
            {showAddFolder && isEditUser && <div className="z-10 pl-6"><AddFolderField {...{ uuid: object.uuid, setShowAddFolder }} /></div>}
            {nodes.map(c =>
                    <div key={c.uuid} className="z-10 pl-6">
                        <AgorumObjectProvider {...{ uuid: c.uuid, parent: object.uuid, fallback: c.name, properties: ['inTrash'] }}>
                            <FilelistItem {...{isMoving: 'isMoving' in c}} />
                        </AgorumObjectProvider>
                    </div>)}
            {isUploading && <LoadingModal />}
        </div>
    )
}

//function FileEditButton({object}: {object: AgorumObject}) {
//    const [show, setShow] = useState(false)
//
//    return <>
//        <button onClick={() => setShow(true)} title="Rename" >
//            <IconEdit className="h-4 w-4 text-pcx-500 hover:text-pcx-600" />
//        </button>
//        {show && <RenameModal {...{/*object,*/ cancel: () => setShow(false)}} />}
//    </>
//}

function DeleteFolderModal({cancel}: {cancel: () => void}) {
    const {t} = useTranslation()
    const {object, parent, deleteObject} = useObject()
    const {reload: parentReload} = useFolderReload({uuid: parent})

    async function onDelete() {
        await deleteObject()
        parentReload()
        cancel()
    }
    return (
        <Modal>
            <div className="p-4">
                <h3 className="pb-2 text-lg">
                    {t('really-delete-name', {name: object.name})}
                </h3>
                <p>
                    The folder and all its content will be moved to the trash and deleted within 30 days.
                </p>
            </div>
            <div className="flex flex-row-reverse gap-4 text-base font-medium bg-pcx-200 p-4">
                <button onClick={onDelete} className="btn-warn text-red-900">{t('delete')}</button>
                <button onClick={cancel} className="btn-secondary">{t('cancel')}</button>
            </div>
        </Modal>
    )
}

function RenameModal({cancel}: {cancel: () => void}) {
    const {t} = useTranslation()
    const {object, update} = useObject()
    const {reload} = useFileReload()

    const initialValues = {
        name: object.name,
        description: object.description ?? '',
    }
    return <Modal>
            <Formik
                initialValues={initialValues} 
                onSubmit={async ({ name, description }) => {
                    await update({name, description})
                    await reload(object.uuid)
                    cancel()
                }}>
            <Form>
                <div className="p-4 space-y-4">
                    <h3>{t('rename-name', {name: object.name})}</h3>
                    <label className="block w-full">
                        <div className="label mb-2">{t('name')}</div>
                        <Field type="text" className="form-input w-full" name="name" autoFocus />
                    </label>
                    <label className="block w-full">
                        <div className="label mb-2">{t('description')}</div>
                        <Field type="text" className="form-input w-full" name="description" autoFocus />
                    </label>
                </div>
                <div className="flex flex-row-reverse gap-4 p-4 bg-pcx-200">
                    <input type="submit" className='btn-primary' value={t('save')} />
                    <button type="button" onClick={() => cancel()} className='btn-secondary'>{t('cancel')}</button>
                </div>
            </Form>

        </Formik>
    </Modal>

}

const showEnums = {
    add: 'add',
    rename: 'rename',
    delete: 'delete',
}
// also for file menu items
const itemStyle = "px-3 py-1 first:pt-2 last:pb-2 whitespace-nowrap hover:bg-pcx-100 cursor-pointer w-full text-left"

function FolderMenu({object, setShowAddFolder}: {object: AgorumObject, setShowAddFolder: (show: boolean) => void}) {
    const {t} = useTranslation()

    const [showDialogEnum, setShowDialogEnum] = useState(undefined)

    const showDialog = showDialogEnum === showEnums.add
    const showRenameModal = showDialogEnum === showEnums.rename
    const showDeleteModal = showDialogEnum === showEnums.delete

    const cancel = () => setShowDialogEnum(undefined)

    const {uuid} = object

    return <>
        <Menu as="div" className="relative inline">
            <Menu.Button>
                <EllipsisHorizontalIcon className="h-4 w-4 inline text-pcx-500 hover:text-pcx-600 cursor-pointer" /> 
            </Menu.Button>
            <Menu.Items as="div" className="z-10 absolute right-0 bg-white overflow-hidden border border-pcx-300 rounded-md shadow-lg text-pcx-800">
                <Menu.Item as="button" className={itemStyle} onClick={() => setShowDialogEnum(showEnums.add)}>
                    <DocumentPlusIcon className="h-5 w-5 inline mb-0.5 mr-2" />{t('add-file')}
                </Menu.Item>
                <Menu.Item as="button" className={itemStyle} onClick={() => setShowAddFolder(true)}>
                    <FolderPlusIcon className="h-5 w-5 inline mb-0.5 mr-2" />{t('add-folder')}
                </Menu.Item>
                <Menu.Item as="button" className={itemStyle} onClick={() => setShowDialogEnum(showEnums.rename)}>
                    <IconEdit className="h-5 w-5 inline mb-0.5 mr-2" />{t('rename')}
                </Menu.Item>
                <Menu.Item as="button" className={itemStyle} onClick={() => setShowDialogEnum(showEnums.delete)}>
                    <IconTrash className="h-5 w-5 inline mb-0.5 mr-2" />{t('delete')}
                </Menu.Item>
            </Menu.Items>
        </Menu>
        <AddFileDialog {...{uuid, showDialog, cancel}} />
        {showRenameModal && <RenameModal {...{cancel}} />}
        {showDeleteModal && <DeleteFolderModal {...{cancel}} />}
    </>
}

function AddFolderField({uuid, setShowAddFolder}: {uuid: string, setShowAddFolder: (show: boolean) => void}) {

    const {reload} = useFolderReload({uuid})

    // console.log({uuid})

    async function handleSubmit(e) {
        // console.log({e})
        e.preventDefault()
        const foldername = e.target.elements.foldername.value

        // console.log('Adding folder ' + foldername + ' to ' + uuid)
        return addFolder({uuid, foldername})
            .then(() => {
                reload()
                setShowAddFolder(false)
            })
    }

    return (
        <form className="flex flex-row gap-2 items-center" onSubmit={handleSubmit} >
            <FolderIcon className="h-5 w-5 inline text-pcx-400" />
            <input type="text" autoFocus 
                id="foldername"
                name="foldername"
                onBlur={() => setShowAddFolder(false)}
                className="grow text-sm border-b-2 border-pcx-200 focus:border-pcx-300 focus:ring-0 focus:outline-none" />
            <button type="submit" className="btn-secondary p-px"><PlusIcon className="h-4 w-4" /></button>
        </form>
    )
}

function AddFileDialog({uuid, showDialog, cancel}: {uuid: string, showDialog: boolean, cancel: () => void}) {
    const {setErrorMessage} = useMessages()
    const ref = useRef<HTMLInputElement>()

    const [isUploading, setIsUploading] = useState(false)

    const {reload} = useFolderReload({uuid})

    const { baseurl, sessionId} = useDms()

    useEffect(() => {
        if (ref.current && showDialog) {
            ref.current.click()
            cancel()
        }
    })

    async function handleUpload(e) {
        e.preventDefault()

        setIsUploading(true)
        await Promise.all(Array.from(e.target.files).map((f: File) => uploadFile(f, uuid, sessionId, baseurl)))
            .catch(err => setErrorMessage(err.message))
            .finally(() => {
                reload()
                cancel()
                setIsUploading(false)
            })
    }
    return <>
            <input
                type="file"
                //id={createUploadId(uuid)}
                ref={ref}
                onChange={handleUpload}
                multiple
                style={{
                    clip: "rect(0 0 0 0)",
                    clipPath: "inset(50%)",
                    height: "1px",
                    overflow: "hidden",
                    position: "absolute",
                    whiteSpace: "nowrap",
                    width: "1px",
                }} />
        {isUploading && <LoadingModal />}
    </>
}

function DeleteFileButton({cancel}: {cancel: () => void}) {
    const {t} = useTranslation()

    const {object, parent, deleteObject} = useObject()
    const {reload} = useFolderReload()

    function deleteAction() {
        return deleteObject()
            .then(() => reload(parent))
            .catch(err => console.error(err))
            .finally(() => cancel())
    }
    
    return (
        <Modal>
            <div className="p-4 max-w-lg">
                <h3 className="pb-2 text-lg">
                    {t('really-delete-name', {name: object.name})}
                </h3>
                <p>
                    {t('trash-explanation')}
                </p>
            </div>
            <div className="flex flex-row-reverse gap-4 text-base font-medium bg-pcx-200 p-4">
                <button onClick={deleteAction} className="btn-warn text-red-900">{t('delete')}</button>
                <button onClick={cancel} className="btn-secondary">{t('cancel')}</button>
            </div>
        </Modal>
    )
}