import React, {useContext, useEffect, useState} from "react";
import { Navigate, useLocation } from "react-router";

let LocationContext = React.createContext({
    getLastMatchingPath: (path: string) => ({path, index: 0}),
    portfolioLink: (prefix: string, fallback: string) => fallback,
})

export function LocationProvider({children}) {
    const [history, setHistory] = useState(["/"])
    let location = useLocation()

    useEffect(() => {
        const fullPath = location.pathname + (location.search ? location.search : '')
        // Don't add path if it looks like it is in a modal
        if (!fullPath.endsWith('/edit') && !fullPath.endsWith('/add'))
            setHistory(ls => [fullPath, ...ls].slice(0, 30)) // forget the history after a while
    }, [location])
    //console.log(history)

    const getLastMatchingPath = (path: string) => {
        const index = history.findIndex(p => p.startsWith(path))
        return {path: history[index], index}
    }

    function portfolioLink(prefix: string, fallback: string) {
        if (location.pathname.startsWith(prefix))
            return getLastMatchingPath(prefix + "?")?.path ?? fallback // '?' is for pagination
        else
            return getLastMatchingPath(prefix)?.path ?? fallback
    }

    return (
        <LocationContext.Provider value={{getLastMatchingPath, portfolioLink}}>
            {children}
        </LocationContext.Provider>
    )
}

export function useLocationHistory() {
    return useContext(LocationContext)
}

export function HistoryNavigate({prefix, fallback}) {
    const {getLastMatchingPath} = useLocationHistory()
    let {path: to, index} = getLastMatchingPath(prefix) ?? fallback
    if (to === prefix || index === 0)
        to = fallback
    //console.log(to)
    return <Navigate to={to} replace />
}