import { groupBy } from 'lodash';
import { Helmet } from 'react-helmet-async'
import { useTranslation, Trans } from "react-i18next"
import { CloudArrowUpIcon, PencilSquareIcon } from "@heroicons/react/24/outline";
import { Link, Outlet, useNavigate, useParams } from 'react-router-dom';
import _ from 'lodash'

import Modal from '../components/Modal';
import { Formik, Form, Field, useField } from 'formik';
import { useMessages } from '../Messages';
import { downloadReport } from '../backend';
import { countryCodes } from "../data";
import { ToggleButtonField } from '../components/ToggleButton';
import Image from '../components/Image'
import { saveCrudLinks, saveLinks, useBackend } from '../BackendProvider';
import DeleteButton from '../components/DeleteButton';
import { useRoles } from '../user/Auth';
import { t } from 'i18next';
import { IconChevronDown, IconChevronUp, IconEdit } from '../components/icons';
import { TagListField } from '../components/TagList';
import EmptyState from '../components/EmptyState';
import { useLocalState } from "../settings/localStorage";
import { useFilteredCommodities } from '../filter/FilteredCommodities';
import clsx from 'clsx';
import { useProducts } from './ProductsProvider';
import { Commodity } from './products';

const asLink = (countryCode, commodityId) => ({ countryCode, commodityId });

function CommodityRow({
  commodity,
  images,
  tags,
  commodityCountryLinks,
}) {
  const { isDev, isEditUser, hasProductCountries } = useRoles();
  const { deleteCommodity, reload } = useProducts()

  const countryTags = commodityCountryLinks.map((l) => l.countryCode);
  const tagsOfProduct = tags.filter(
    (t) => t.entity === "commodity" && t.entityId === commodity.commodityId
  );

  const ribbonStyle = clsx('ribbon', commodity.status === "active" && "bg-pcx-200")

  return (
    <div
      key={commodity.commodityId}
      className="grid sm:grid-cols-8 grid-cols-4 gap-3 border-t-2 border-pc-200 pt-2 p-1  max-w-screen-lg"
    >
      <div className="col-span-2 max-w-full">
        <Image
          {...{
            entity: "commodity",
            entityId: commodity.commodityId,
            images,
            isEditable: isEditUser,
          }}
        />
      </div>

      <div className="col-span-2 sm:col-span-4">
        <div className="flex flex-col">
          <h4>{commodity.commodityReference}</h4>
          <div className="flex flex-wrap gap-2 text-sm text-gray-500">
            {isDev &&
              _(tagsOfProduct)
                .map((t) => t.tag)
                .uniq()
                .sortBy((t) => t)
                .map((t) => <div key={t}>{t}</div>)
                .value()}
          </div>
          <div>{commodity.commodityDescription}</div>
        </div>
      </div>

      <div className="sm:col-span-2 col-span-4">
        <div className="flex flex-row justify-between">
            <div className="flex flex-wrap gap-1 text-sm">
              <div className={ribbonStyle}>{t(commodity.status)}</div>
              {commodity.isThirdParty && (
                <div className={ribbonStyle}>{t("isThirdParty")}</div>
              )}
              {(hasProductCountries ? countryTags : []).map((cc) => (
                <div key={cc} className={ribbonStyle}>
                  {cc}
                </div>
              ))}
            </div>
          {isEditUser && (
            <div className="shrink-0 w-fit flex flex-row gap-1 sm:pl-4 pl-2">
              <Link
                to={`product/${commodity.commodityId}/edit`}
                className="btn-primary h-5 w-5 p-px"
                title={t("edit")}
              >
                <IconEdit className="" />
              </Link>
              <DeleteButton
                className="btn-warn h-5 w-5 p-px"
                deleteAction={() => deleteCommodity(commodity).then(() => reload())}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default function ProductPortfolio() {
  const { t } = useTranslation();

  const { setErrorMessage } = useMessages();
  const { isEditUser, hasExcelExport, hasExcelImport } = useRoles();

  const { images, tags, } = useBackend();

  const { commodityCountryLinks } = useProducts();
  const { commodities: allCommodities } = useFilteredCommodities();
  const [sortAscending, setSortAscending] = useLocalState('product-portfolio-sort', true)
  const [filterValue, setFilterValue] = useLocalState('product-portfolio-filter', '')

  let commodities = allCommodities;
  if (typeof filterValue === "string" && filterValue !== "") {
    const lowerCaseValue = filterValue.toLowerCase();
    commodities = commodities.filter(
      (c) =>
        c.commodityReference.toLowerCase().includes(lowerCaseValue) ||
        c.commodityClass?.toLowerCase()?.includes(lowerCaseValue) ||
        c.commodityDescription?.toLowerCase()?.includes(lowerCaseValue)
    );
  }
  //if (checkedProducts !== "all") {
  //  const isThirdParty = checkedProducts === "only-third-party";
  //  commodities = commodities.filter((c) => c.isThirdParty === isThirdParty);
  //}

  const productTags = tags.filter((t) => t.entity === "commodity");

  const commoditiesByClass = groupBy(commodities, (c) => c.commodityClass);
  const sortedClasses = sortAscending
    ? Object.keys(commoditiesByClass).sort()
    : Object.keys(commoditiesByClass).sort().reverse();

  function handleExport() {
    downloadReport({
      url: "/api/excel",
      report: "product-portfolio-report",
    }).catch((e) => setErrorMessage(e.message));
  }

  const isEmpty = allCommodities.length === 0;

  return (
    <>
      {/* @ts-ignore */}
      <Helmet>
        <title>{t("product-portfolio")} | Patent Cockpit</title>
      </Helmet>
      <div className="modern-header-row">
        <div className="flex flex-row gap-2 max-w-screen-lg">
          <h2 className='modern-h2'>{t("products")}</h2>
          <div className="grow" />
          {isEditUser && hasExcelImport && <Link to="/products/extract" className="py-0.5 btn-secondary">
            {t("excel-import")}
          </Link>}
          {!isEmpty && hasExcelExport && (
            <button className="py-0.5 btn-secondary" onClick={handleExport}>
              {t("excel-export")}
            </button>
          )}
        </div>
      </div>
      <div className="main-content">
        <div className="flex flex-col gap-1 max-w-screen-lg">
          {!isEmpty && (
            <div className="flex justify-between">
              <div className="flex gap-4 items-center">
                <button
                  className=" text-pcx-800"
                  onClick={() => setSortAscending(!sortAscending)}
                >
                  {sortAscending ? <IconChevronDown /> : <IconChevronUp />}
                </button>
                <input
                  value={filterValue}
                  onChange={(e) => setFilterValue(e.target.value)}
                  type="search"
                  placeholder="Filter..."
                  className="form-input border-pcx-200  focus:border-pcx-300 py-1  focus:bg-white rounded-md focus:ring-0 placeholder:text-slate-400 text-slate-800"
                />
              </div>
              {isEditUser && (
                <Link to="product/add" className="btn-secondary">
                  {t("add-product")}
                </Link>
              )}
            </div>
          )}
          {(allCommodities ?? []).length === 0 && (
            <EmptyState items={noProductsActions(t)} />
          )}
          {sortedClasses.flatMap((cl) => {
            const cs = commoditiesByClass[cl];
            return [
              <h3
                className="pt-4 border-t-2 border-pc-300 max-w-screen-lg text-pcx-500 font-medium text-base"
                key={cl}
              >
                {cl !== "undefined" ? cl : t("unclassified")}
              </h3>,
              ...cs
                .sort((a, b) =>
                  a.commodityReference.localeCompare(b.commodityReference)
                )
                .map((commodity) => {
                  return (
                    <CommodityRow
                      key={commodity.commodityId}
                      {...{
                        commodity,
                        images,
                        tags: productTags,
                        commodityCountryLinks: commodityCountryLinks.filter(
                          (l) => l.commodityId === commodity.commodityId
                        ),
                      }}
                    />
                  );
                }),
            ];
          })}
        </div>
      </div>
      <Outlet />
    </>
  );
}

const noProductsActions = (t) => [
  {
    title: t("upload-product-portfolio"),
    description: t("upload-product-portfolio-desc"),
    icon: CloudArrowUpIcon,
    background: "bg-pcx-600",
    to: "/products/extract",
  },
  {
    title: t("add-product"),
    description: t("manually-add-product-desc"),
    icon: PencilSquareIcon,
    background: "bg-pcx-300",
    to: "product/add",
  },
];

function asTag(tag, entityId) {
  return { tag, entity: "commodity", entityId };
}

export function AddEditProduct({ isAdding = false }) {
  const { isDev, isEditUser, hasProductCountries } = useRoles();

  const { t } = useTranslation();
  const {
    tags,
    tagsLookup,
    linkOperation,
  } = useBackend();
  const { commodities, commodityByReference, postCommodity, commodityCountryLinks: _commodityCountryLinks, postCommodityCountryLink, deleteCommodityCountryLink } = useProducts();
  const { setErrorMessage } = useMessages();

  let { id: _id } = useParams();
  const id = parseInt(_id);
  const navigate = useNavigate();

  const commodityCountryLinks =
    !isAdding && hasProductCountries
      ? _commodityCountryLinks.filter((l) => l.commodityId === id)
      : [];

  const countryTags = commodityCountryLinks.map((l) => l.countryCode);
  const tagsOfProduct = tagsLookup["commodity"]?.[id] ?? [];
  const allTags = _.uniq(tags.map((t) => t.tag));

  const commodity = commodities.find((c) => c.commodityId === id);

  const existingClasses = _.uniq(
    commodities
      .map((c) => c.commodityClass)
      .filter((c) => c !== "" && c !== undefined)
  );

  const initialValues: Commodity & {tags?: string[], countryCodes?: string[]} = isAdding
    ? {
        commodityId: undefined,
        commodityClass: undefined,
        commodityReference: "",
        commodityDescription: "",
        status: "active",
        isThirdParty: false,
        tags: [],
        countryCodes: [],
      }
    : commodity;

  const handleSubmit = isAdding
    ? (c) =>
      postCommodity(c)
        .then((withId) =>
          postCommodityCountryLink((c.countryCodes ?? []).map((cc) => asLink(cc, withId.commodityId)))
            .then(() =>
              linkOperation(
                "tag",
                "bulk-add",
                (c.tags ?? []).map((tag) => asTag(tag, withId.commodityId))
              )
            )
        )
        .then(() => linkOperation("tag", "get"))
    : (newCommodity) =>
      postCommodity(newCommodity)
        .then(() =>
          saveLinks(
            tagsOfProduct.map((tag) => asTag(tag, newCommodity.commodityId)),
            (newCommodity.tags ?? []).map((tag) =>
              asTag(tag, newCommodity.commodityId)
            ),
            linkOperation,
            "tag"
          )
        )
        .then(() =>
          saveCrudLinks(
            countryTags.map((cc) => asLink(cc, commodity.commodityId)),
            (newCommodity.countryCodes ?? []).map((cc) =>
              asLink(cc, commodity.commodityId)
            ),
            postCommodityCountryLink,
            deleteCommodityCountryLink,
          )
        );

  return (
    <Modal>
      <Formik
        initialValues={initialValues}
        onSubmit={(values) =>
          handleSubmit(values)
            .then(() => navigate("/products/portfolio"))
            .catch((err) => setErrorMessage(err.message))
        }
        enableReinitialize
        validate={(values) => {
          const errors = {};
          if (values.commodityReference in commodityByReference) {
            const c = commodityByReference[values.commodityReference]
            if (c.commodityId !== values.commodityId) {
              errors['commodityReference'] = c.status === 'stopped'
                ? t("is-already-used-what", { what: t("stopped-product") })
                : t("is-already-used");
            }
          }
          return errors;
        }}
      >
        <Form>
          <div className="p-4 grid grid-cols-2 gap-3">
            <h2 className="col-span-2">
              {isAdding ? (
                t("add-product")
              ) : (
                <Trans
                  i18nKey="edit-reference"
                  values={{ reference: commodity?.commodityReference }}
                />
              )}
            </h2>
            {/* TODO: add existing classes as proposal */}
            <RichField
              className="form-input"
              labelClassName="col-span-2 sm:col-span-1"
              name="commodityClass"
              proposals={existingClasses}
            />
            <RichField
              className="form-input"
              labelClassName="col-span-2 sm:col-span-1"
              name="commodityReference"
              autoFocus
            />
            <RichField
              className="form-input h-48 w-full"
              labelClassName="col-span-2"
              name="commodityDescription"
              as="textarea"
            />

            <RichField className="form-select" name="status" as="select">
              {["active", "stopped", "planned"].map((s) => (
                <option key={s} value={s}>
                  {t(s)}
                </option>
              ))}
            </RichField>

            <label className="pl-0.5">
              <div className="label -ml-0.5 mb-1">{t("isThirdParty")}</div>
              <ToggleButtonField name="isThirdParty" />
            </label>

            {isDev && (
              <label className="col-span-2 sm:col-span-1">
                <div className="label mb-1">{t("tags")}</div>
                <TagListField name="tags" availableTags={allTags} />
              </label>
            )}
            {hasProductCountries && (
              <label className="col-span-2 sm:col-span-1">
                <div className="label mb-1">{t("countryCode")}</div>
                <TagListField
                  name="countryCodes"
                  placeholder={t("countryCode")}
                  availableTags={countryCodes}
                />
              </label>
            )}
          </div>
          <div className="p-4 flex flex-row-reverse gap-4 bg-pcx-200">
            {isEditUser && (
              <button id="save" className="btn-primary" type="submit">
                {t("save")}
              </button>
            )}
            {/* @ts-ignore */}
            <Link className="btn-secondary" to={-1 }>
              {t("cancel")}
            </Link>
          </div>
        </Form>
      </Formik>
    </Modal>
  );
}

function RichField({children = null, labelClassName = '', ...props }) {
    // eslint-disable-next-line
    const [field, meta] = useField(props as {name: string})
    const { name } = props
    return (
        <label className={labelClassName}>
            <div className='label mb-1'>{t(name)}</div>
            <Field id={name} {...{...props, list: props?.proposals && `${name}s`}} >
                {children}
            </Field>
            {meta.touched && meta.error && <div className='text-red-500 mt-1 ml-1'>{meta.error}</div>}
            {props?.proposals &&
                <datalist id={`${name}s`}>
                    {_(props.proposals).sortBy(p => p.toLowerCase()).map(p => <option key={p} value={p} />).value()}
                </datalist>}
        </label>
    )
}