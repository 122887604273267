import React, { ReactNode, createContext, useContext, useEffect, useRef, useState } from "react"
import { Disclosure, Menu } from '@headlessui/react'
import { Outlet, useParams } from "react-router"
import { Link, useNavigate } from "react-router-dom"
import { Helmet } from 'react-helmet-async'
import { useTranslation, Trans } from "react-i18next"
import clsx from "clsx"
import _ from 'lodash'

import { family_member, patent_family } from '../data'
import { background } from "../valuations/Valuations"
import { commodityUrl, Commodity, useProductMapping } from "../products/products"
import { DatePicker } from "../components/DatePicker"
import { IconChevronDown, IconEdit } from "../components/icons"
import Image from '../components/Image'
import Modal from "../components/Modal"
import { saveLinks, useBackend } from "../BackendProvider"
import { epoLoad } from "../backend"
import { useMessages } from "../Messages"
import { calculatePriorityDate, countryLabel, familyUrl, memberUrl } from "./utils"
import { emptyStringAsUndefined, truncate } from "../utils/strings"
import { Formik, Form, Field } from "formik"
import { inventionUrl } from "../inventions/utils"
import { useComments } from "../comments/CommentsProvider"
import { useRoles } from "../user/Auth"
import { Comment } from "../comments/Comments"
import { ClaimScape2 } from "../claims/ClaimScape"
import { useLocationHistory } from "../cockpit/Location"
import { TagListField } from "../components/TagList"
import KanjiTip from "../components/KanjiTip"
import { useFamilyScores } from "../valuations/scores"
import { Family } from "./patents"
import { nameOf } from "../agents/utils"
import { FamilyCosts as FamilyCostsElement } from "../costs/FamilyCosts"
import { useInventions } from "../inventions/InventionsProvider"
import { usePatents } from "./PatentsProvider"
import { LinkedTabCard } from "../components/TabCard"
import { FocusedBrowser } from "../documents/FocusedBrowser"
import { BreadCrumbs } from "../components/BreadCrumbs"
import ProtectionIcon from "../components/ProtectionIcon"
import DeleteButton from "../components/DeleteButton"
import { useLocalState } from "../settings/localStorage"

export type FamilyBreadCrumbProps = {
    family: Family;
}

export function EpoLoader() {
    const {families} = usePatents()
    const {t} = useTranslation()
    const navigate = useNavigate()
    const {setErrorMessage} = useMessages()
    const {entityOperation} = useBackend()

    let { internalReference } = useParams()

    return (
        <Modal id="load-modal" escAction={() => navigate("..")}>
            <Formik
                initialValues={{ publicationNumber: '' }}
                onSubmit={({ publicationNumber }) => {
                    const { patentFamilyId } = families.find(f => f.internalReference === internalReference)
                    epoLoad({ patentFamilyId, publicationNumber: publicationNumber.trim() })
                        .then(mem =>
                            entityOperation('agent', 'get').then(() => {
                                navigate({
                                    pathname: "/patents/portfolio/member/add",
                                    search: `?patentFamilyId=${encodeURIComponent(patentFamilyId)}`
                                }, {
                                    state: {
                                        ...mem,
                                        inventors: (mem.inventors ?? []).map(a => a.agentId),
                                        applicants: (mem.applicants ?? []).map(a => a.agentId),
                                    },
                                    replace: true
                                })
                            }),
                            err => { setErrorMessage(err.message); navigate("..") })
                }}
            >
                <Form>
                    <div className="p-4 pb-8">
                        <h3 className="mb-4">{t("load-from-epo")}</h3>
                        <label>
                            <div className="label mb-2">{t('publicationNumber')}</div>
                            <Field className='form-input' name="publicationNumber" type="text" autoFocus required />
                        </label>
                    </div>
                    <div className="p-4 bg-pcx-200 flex flex-row-reverse gap-4">
                        <input type="submit" value={t('load')} className="btn-primary" />
                        <Link to=".." className="btn-secondary">{t('cancel')}</Link>
                    </div>
                </Form>
            </Formik>
        </Modal>
    )
}

function FamilyMemberLinks({members, predicate, countryCount}) {
    const {t} = useTranslation()

    const [showMore, setShowMore] = useState(false)
    const [showButton, setShowButton] = useState(false)
    const childrenList = useRef<HTMLDivElement>(null)

    useEffect(() => {
        const isOverflowing = childrenList.current !== undefined ? (childrenList.current?.scrollHeight > childrenList.current?.offsetHeight) : false
        //console.log({isOverflowing, showMore, scrollHeight: childrenList.current?.scrollHeight, offsetHeight: childrenList.current?.offsetHeight})
        setShowButton(isOverflowing)
    }, [members, childrenList])

    const inState = members.filter(predicate).sort((a, b) => a.internalReference.localeCompare(b.internalReference))
    const doExpandedLabel = _(countryCount).values().max() > 1

    return <div>
        <div ref={childrenList} className={clsx("flex flex-wrap gap-1", showMore ? "" : "max-h-[3.25rem] overflow-hidden")}>
            {inState.length > 0
                ? _(inState)
                    .sortBy(m => m.countryCode)
                    .map(m => {
                        const { label, title } = countryLabel(m, !doExpandedLabel)
                        return <Link
                            className="ribbon ribbon-clickable rounded-sm text-sm py-0.5"
                            key={m.internalReference}
                            to={memberUrl(m)}
                            title={title}
                        >
                            {label}
                        </Link>
                    }).value()
                : null}
        </div>
        {showButton && 
            <button className="ml-1 text-sm text-slate-500 hover:underline" onClick={() => setShowMore(s => !s)}>
                {showMore ? t('show-less') : t('show-more')}
            </button>}
    </div>
}

export function KeyValues({title, children, noRenderEmpty = false, showMore = false}) {
    if (noRenderEmpty && React.Children.count(children) === 0)
        return null
    else
        return <>
                <div className="sm:text-right text-gray-500">{title}</div>
                {showMore
                    ? <ShowMoreValue>{children}</ShowMoreValue> 
                    : <div className="pl-4 sm:pl-0 flex flex-wrap gap-1">{children === null || React.Children.count(children) === 0 ? '-' : children}</div>
                }
            </>
}

function ShowMoreValue({children}) {
    const {t} = useTranslation()

    const [showMore, setShowMore] = useState(false)
    const [showButton, setShowButton] = useState(false)
    const childrenList = useRef<HTMLDivElement>(null)

    useEffect(() => {
        const isOverflowing = childrenList.current !== undefined ? (childrenList.current?.scrollHeight > childrenList.current?.offsetHeight) : false
        //console.log({isOverflowing, showMore, scrollHeight: childrenList.current?.scrollHeight, offsetHeight: childrenList.current?.offsetHeight})
        setShowButton(isOverflowing)
    }, [children, childrenList])

    return (
        <div className="pl-4 sm:pl-0">
            <div ref={childrenList} className={showMore ? "line-clamp-none" : "line-clamp-2"}>
                {children}
            </div>
            {showButton && 
                <button className="text-sm text-slate-500 hover:underline" onClick={() => setShowMore(s => !s)}>
                    {showMore ? t('show-less') : t('show-more')}
                </button>}
        </div>
    )
}


export const FamilyBreadCrumb = ({family}: FamilyBreadCrumbProps) => {
    const familyName = emptyStringAsUndefined(family?.familyName)
    return (
        <Link to={familyUrl(family)}>
            <span>{family.internalReference}</span>  {familyName && <span className="hidden lg:inline ml-3 text-pcx-500">{truncate(familyName)}</span>}
        </Link>
    )
}


function AddMember({family, members}) {
    const {t} = useTranslation()

    const itemStyle = "px-2 pr-6 py-2 hover:bg-pcx-400 font-medium text-pcx-500 hover:text-white -indent-4 pl-6 whitespace-nowrap"

    return (
        <Menu as="div"
            className='relative h-fit text-sm'
        >
            <Menu.Button className={clsx(
                'flex flex-row items-center gap-2 rounded-sm border border-l border-pcx-500 p-1 pl-2',
                members?.length > 0 ? 'text-pcx-500 hover:text-white hover:bg-pcx-400' : 'text-white bg-pcx-500 hover:bg-pcx-600'
            )}>
                <span className="whitespace-nowrap">{t("add-new-family-member")}</span>
                <IconChevronDown />
            </Menu.Button>
            <Menu.Items className='absolute z-10 flex flex-col min-w-full w-fit top-9 right-0 rounded-sm shadow-md border border-pcx-500 bg-white max-h-[13em] overflow-y-auto'>
                <Menu.Item>
                    <Link
                        className={itemStyle}
                        to={{
                            pathname: "/patents/portfolio/member/add",
                            search: `?patentFamilyId=${encodeURIComponent(family.patentFamilyId)}`
                        }}>
                        {t("add-new-family-member")}
                    </Link>
                </Menu.Item>

                <Menu.Item>
                    <Link className={itemStyle} to="epo">{t("load-from-epo")}</Link>
                </Menu.Item>

                {_(members)
                    .sortBy(m => m.internalReference)
                    .map((m, i) =>
                        <Menu.Item key={`fam-${i}`}>
                            <Link
                                className={itemStyle}
                                to={{ 
                                    pathname: "/patents/portfolio/member/add", 
                                    search: `?patentFamilyId=${encodeURIComponent(family.patentFamilyId)}&copyFrom=${encodeURIComponent(m.internalReference)}`
                            }}>
                                <Trans i18nKey="copy-from-name" values={{name:  `${m.internalReference} (${m.countryCode})`}} />
                            </Link>
                        </Menu.Item>
                    )
                    .value()}

            </Menu.Items>
        </Menu>
    )
}


function FamilyComments({family}: {family: Family}) {
    const {t} = useTranslation()

    const {isEditUser} = useRoles()

    const { membersByFamilyId } = usePatents()
    const {commentsLookUp} = useComments()

    const specificMembers = membersByFamilyId[family.patentFamilyId] ?? []

    const memberComments = _(specificMembers)
        .map(member => ({ ...member, comments: commentsLookUp[family_member]?.[member.familyMemberId] ?? [] }))
        .filter(({ comments }) => comments.length > 0)
        .sortBy(m => m.internalReference)
        .value()

    return (
        <div>
            <div className="pb-4 flex flex-row min-w-[20rem]">
                <h4 className="grow">{t('comments')}</h4>
                {isEditUser &&
                    <Link
                        to={`comments?entity=${patent_family}&entityId=${family.patentFamilyId}`} 
                        className="btn-secondary py-0.5 text-sm whitespace-nowrap"
                        title={t('add-comment')}
                    >
                        {t('add')}
                    </Link>}
            </div>
            <div className="text-sm flex flex-col gap-2">
                {(commentsLookUp[patent_family]?.[family?.patentFamilyId] ?? [])
                    .map(c => <Comment key={`comment-${c.commentId}`} {...c} />)
                }
                {memberComments.map(({ internalReference, comments }) =>
                    <Disclosure key={internalReference} as='div' className="mt-2 flex flex-col gap-2">{({ open }) => <>
                        <Disclosure.Button className='flex flex-row justify-between items-center px-2 py-1 bg-pcx-100 hover:bg-pcx-200 text-pcx-600 rounded'>
                            <Link className="hover:underline" to={memberUrl({ internalReference})} >
                                <h5 className="text-pcx-600">{internalReference}</h5>
                            </Link>
                            <IconChevronDown className={clsx('h-5 w-5', open && 'transform rotate-180')} />
                        </Disclosure.Button>
                        <Disclosure.Panel>
                            {comments.map(c => <Comment key={`comment-${c.commentId}`} {...c} />)}
                        </Disclosure.Panel>
                    </>}</Disclosure>
                )}
            </div>
        </div>
    )
}


export function PatentFamilyDetails({family, isEditUser}: {family: Family, isEditUser: boolean}) {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const {hasInnovation} = useRoles()

    const {membersByFamilyId, memberById, deleteFamily, reload } = usePatents()
    const {tagsLookup, agentById, agentsByMemberId} = useBackend()
    //const {commoditiesByFamilyId, claimScopeCommoditiesByPatentFamilyId} = useProductMapping()
    const {inventionsByMemberId} = useInventions()
    const {familyScore, memberScores} = useFamilyScores(family.patentFamilyId)

    const relevantTags = (tagsLookup[patent_family]?.[family.patentFamilyId] ?? [])

    const specificMembers = membersByFamilyId[family.patentFamilyId] ?? []

    const countryCount = _.countBy(specificMembers, m => m.countryCode)

    function getAgents(linkType: string) {
        return _(specificMembers)
            .flatMap(m => agentsByMemberId[linkType]?.[m.familyMemberId] ?? [])
            .uniq()
            .map(a => agentById[a])
            .filter(Boolean)
            .map(a => nameOf(a))
            .uniqWith(_.isEqual)
            .sortBy()
            .value()
    }

    const applicants = getAgents('applicant')
    const owners = getAgents('owner')
    const inventors = getAgents('inventor')

    const membersPriorityDate = calculatePriorityDate(specificMembers)
    const priorityDate = (family.priorityDate ?? membersPriorityDate) ?? '-'

    const memsWithExpiry = specificMembers.map(m => [m.expiryDate, m]).filter(([d]) => (typeof d === 'string') && /^\d+/.test(d[0]))
    const lastExpiry = _.maxBy(memsWithExpiry, ([d]) => d)

    const existingPublicationNumbers = _.uniq(_.sortBy(_.flatMap(specificMembers, m => [m.publicationNumber, m.patentNumber].filter(n => n))))

    // for the inventions
    const relatedInventions = _(specificMembers).flatMap(m => inventionsByMemberId[m.familyMemberId] ?? []).sortBy(i => i.reference).value()

    // TODO: make sure use product mapping contains no undefined products after deletion
    // const products = _([
    //     ...(commoditiesByFamilyId[family.patentFamilyId] ?? []),
    //     ...(claimScopeCommoditiesByPatentFamilyId[family.patentFamilyId] ?? [])
    // ]).filter(p => p !== undefined).uniqBy('commodityId').value()

    const overallScores = _(memberScores).toPairs().map(([familyMemberId, score]) => [score, memberById[familyMemberId]]).value()
    const meanScore = familyScore !== undefined ? parseFloat(familyScore) : undefined

    return <>
        <div className="flex flex-wrap md:flex-nowrap grow items-top gap-2 sm:gap-4 mb-1">
            <div className="grow flex flex-col">
                <h2 className="mb-2">
                    {family.familyName}
                </h2>
            </div>
            {isEditUser &&
                <div className="flex flex-col gap-2 items-end ml-auto">
                    <div className="flex flex-row gap-1">
                        <Link title={t('edit')} to="edit" className="btn-primary p-px w-5 h-5"><IconEdit /></Link>

                        <DeleteButton
                            className="btn-warn p-px w-5 h-5"
                            question={t("delete-family-name", { name: family.internalReference })}
                            deleteAction={async () => {
                                await deleteFamily(family)
                                reload()
                                navigate("../../")
                            }}
                        />
                    </div>
                </div>}
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-[12rem_1fr] gap-4 border-b-2 border-pcx-200 pb-2">
            <div className="h-36">
                <Image {...{
                    entity: "patent-family",
                    entityId: family.patentFamilyId,
                    title: `${family.internalReference}: ${family.familyName}`,
                    isEditable: isEditUser,
                    text: family.summary,
                    epoLoad: existingPublicationNumbers,
                }} />
            </div>

            <div>
                <p>{family.summary}</p>
                <div className="flex flex-row flex-wrap gap-1 text-sm text-gray-600">
                    {relevantTags.map(t => <div className="ribbon w-fit py-0.5" key={t}>{t}</div>)}
                </div>
            </div>
        </div>

        {/* Other Info */}
        <div className="grid grid-cols-1 sm:grid-cols-[12rem_1fr] gap-x-4 gap-y-2 py-3">
            <h4 className="whitespace-nowrap">{t('bibliographic-data')}</h4>
            <div className="justify-self-end">
                {isEditUser &&
                    <AddMember {...{
                        family,
                        members: specificMembers,
                    }} />}
            </div>

            <KeyValues title={t("patents-in-preparation")} noRenderEmpty>{FamilyMemberLinks({ members: specificMembers, predicate: m => m.familyMemberStatus === "in-preparation", countryCount })}</KeyValues>
            <KeyValues title={t("pending-patents")}                     >{FamilyMemberLinks({ members: specificMembers, predicate: m => m.familyMemberStatus === "pending", countryCount })}</KeyValues>
            <KeyValues title={t("granted-patents")}                     >{FamilyMemberLinks({ members: specificMembers, predicate: m => m.familyMemberStatus === "granted", countryCount })}</KeyValues>
            <KeyValues title={t("stopped-patents")}                     >{FamilyMemberLinks({ members: specificMembers, predicate: m => m.familyMemberStatus === "stopped", countryCount })}</KeyValues>

            <KeyValues title={t("applicants")} showMore>{applicants.map(i => <AgentItem key={i} agent={i} />)}</KeyValues>
            <KeyValues title={t("inventors")} showMore>{inventors.map(i => <AgentItem key={i} agent={i} />)}</KeyValues>
            <KeyValues title={t("owners")}>{owners.map(i => <AgentItem key={i} agent={i} />)}</KeyValues>
            <KeyValues title={t("priority-date")}>{
                typeof priorityDate === 'string'
                    ? priorityDate
                    : priorityDate.date
            }</KeyValues>
            {lastExpiry &&
                <KeyValues title={t("last-expiry-date")}>
                    {lastExpiry[0]}
                </KeyValues>
            }
            {hasInnovation && relatedInventions.length > 0 &&
                <KeyValues title={t('inventions')}>
                    {relatedInventions.map(i => <Link key={i.reference} className="ribbon ribbon-clickable mr-1 last:mr-0" to={inventionUrl(i)}>{i.reference}</Link>)}
                    <div className="pt-1 text-slate-500">{t('inform-inventors-3-months')}</div>
                </KeyValues>}
            {/*products.length > 0 &&
                <KeyValues title={t('products')}>
                    {products.map(p =>
                        <Link key={p.commodityReference} className="ribbon ribbon-clickable mr-1 last:mr-0" to={commodityUrl(p)}>
                            {p.commodityReference}
                        </Link>)}
                    </KeyValues>*/}
            {meanScore && <KeyValues title={t('score')}>
                <div
                    title={_(overallScores)
                        .sortBy(([s, m]) => `${9.0 - s} ${m.countryCode}`)
                        .map(([s, m]) => `${m.countryCode}: ${s}`)
                        .join(', ')}
                    className={clsx(background(meanScore), 'py-1 px-2')}
                >{meanScore}</div>
            </KeyValues>}
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-[12rem_1fr] gap-x-4 gap-y-2 py-3 mt-3 border-t-2 border-pcx-200">
            <PatentFamilyProducts {...{family}} />
        </div>
    </>
}

function PatentFamilyProducts({family}) {
    const {t} = useTranslation()
    const {isEditUser} = useRoles()

    const {commoditiesByFamilyId, claimScopeCommoditiesByPatentFamilyId} = useProductMapping()

    function commodityName({commodityClass, commodityReference}: Pick<Commodity, 'commodityClass' | 'commodityReference'>) {
        return `${commodityClass ?? t('unclassified')}: ${commodityReference}`
    }

    // Own products vs. thirt party products
    const products = _([
        ...(commoditiesByFamilyId[family.patentFamilyId] ?? []),
        ...(claimScopeCommoditiesByPatentFamilyId[family.patentFamilyId] ?? [])
    ])
    .filter(p => p !== undefined)
    .uniqBy('commodityId')
    .map(c => ({...c, commodityName: commodityName(c)}))
    .sortBy(c => c.commodityName.toLowerCase())
    .value()

    /**
     * TODO
     *      * Create a Edit modal like the one for trademarks families
     *      * Use parts from there, by extracting it into the products module
     */

    return <>
        <div className="flex flex-row gap-1 justify-between">
            <h4>{t('products')}</h4>
            {isEditUser && <Link to="products" ><IconEdit className="sm:hidden btn-secondary p-px w-5 h-5" /></Link>}
        </div>
        <div className="flex flex-row gap-2">
            <ul className="list-none text-slate-700 pl-0 grow">
                <li className="hidden last:block text-slate-500">{t('no-affected-products')}</li>
                {products.map(({ commodityId, isThirdParty, commodityName }, i) =>
                    <li key={commodityId} className="flex flex-row items-center gap-2">
                        <ProtectionIcon {...{ isThirdParty }} /> <Link to={commodityUrl(products[i])}>{commodityName}</Link>
                    </li>)}
            </ul>
            {isEditUser && <Link to="products" ><IconEdit className="max-sm:hidden btn-secondary p-px w-5 h-5" /></Link>}
        </div>
    </>
}

function AgentItem({agent}) {
    return (
        <span className="inline-flex after:content-[';'] last:after:content-[''] mr-2 last:mr-0">
            <KanjiTip kanjiOpt={agent}>{agent}</KanjiTip>
        </span>
    )
}

type ErrorEditFamily = {
    internalReference?: string
}

export function AddEditFamily() {
    const {t} = useTranslation()

    const { postFamily, families  } = usePatents()
    const {tags, tagsLookup, linkOperation, hasLoaded} = useBackend()
    let navigate = useNavigate()
    const {internalReference} = useParams()

    const isAdding = internalReference === undefined

    const availableTags = _(tags.map(t => t.tag)).uniq().sortBy(t => t.toLowerCase()).value()

    const family = isAdding
        ? {
            familyName: '',
            internalReference: '',
            externalReference: '',
            summary: '',
            priorityDate: undefined,
        }
        : {...families.find(f => f.internalReference === internalReference)}
        
    const otherFamilyReferences = new Set(families.filter(f => f.patentFamilyId !== family.patentFamilyId).map(f => f.internalReference))

    const relevantTags = isAdding 
        ? [] 
        : tagsLookup[patent_family]?.[family.patentFamilyId] ?? []

    async function handleTagUpdate(newTags, patentFamilyId) {
        const asTagEntity = (tag) => ({ entityId: patentFamilyId, entity: "patent-family", tag })
        return saveLinks(relevantTags.map(asTagEntity), newTags.map(asTagEntity), linkOperation, "tag")
    }
    return (hasLoaded
        ? <Modal>
            <Formik
                initialValues={{ ...family, tags: relevantTags }}
                onSubmit={(fam) =>
                    postFamily({...fam, internalReference: fam.internalReference.trim()})
                        .then((famWithId: Family) => handleTagUpdate(fam.tags ?? [], famWithId?.patentFamilyId ?? family.patentFamilyId))
                        .then(() => (family.internalReference !== fam.internalReference) ? navigate(familyUrl(fam)) : navigate(".."))
                }
                validate={(values) => {
                    const errors = {} as ErrorEditFamily
                    if (!(typeof values.internalReference === 'string' && values.internalReference.trim() !== ""))
                        errors.internalReference = t('reference-required')
                    else if (otherFamilyReferences.has(values.internalReference))
                        errors.internalReference = t('reference-used')
                    return errors
                }}
            >{({ touched, errors, isValid }) =>
                <Form className="max-w-4xl">
                    <h3 className="text-center p-4">{
                        isAdding
                            ? <Trans i18nKey="add-family-name" values={{ name: '' }} />
                            : <Trans i18nKey="edit-family-name" values={{ name: `${internalReference} - ${family.familyName}` }} />
                    }</h3>
                    <div className="p-4 pt-0 bg-white w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2 sm:gap-4">

                        <label className="md:col-span-2 lg:col-span-3">
                            <span className="label">{t('familyName')}</span>
                            <Field name="familyName" className="form-input w-full" autoFocus />
                        </label>

                        <label className="">
                            <span className="label">{t('familyReference')}</span>
                            <Field name="internalReference" className="form-input" />
                            {touched.internalReference && errors.internalReference && <span className="text-red-700"><br />{errors.internalReference as string}</span>}
                        </label>

                        <label className="">
                            <span className="label">{t('ext-reference')}</span>
                            <Field name="externalReference" className="form-input" />
                        </label>

                        <label className="w-fit">
                            <span className="label">{t('priority-date')}</span>
                            <Field name="priorityDate" className="form-input py-1.5" as={DatePicker} />
                        </label>

                        <label className="md:col-span-2 lg:col-span-3">
                            <span className="label">{t('summary')}</span>
                            <Field name="summary" as="textarea" className="form-textarea h-48 w-full" />
                        </label>

                        <label className="sm:max-w-full md:col-span-2 lg:col-span-3">
                            <span className="label mb-1">{t('tags')}</span>
                            <TagListField {...{
                                availableTags,
                                name: "tags",
                                primary: true,
                                allowNew: true,
                            }} />
                        </label>
                    </div>
                    <div className="p-4 bg-pc-200 flex flex-row-reverse gap-4">
                        <input type="submit" disabled={!isValid} className="btn-primary disabled:btn-disabled" value={t("save")} />
                        <Link to=".." className="btn-secondary">{t('cancel')}</Link>
                    </div>
                </Form>
                }</Formik>
        </Modal>
        : null
    )
}

export function FamilyIndex() {
    const { t } = useTranslation()
    const { hasClaimScopes, hasDocuments, hasCosts } = useRoles()

    let { internalReference } = useParams()
    let navigate = useNavigate()
    const {getLastMatchingPath} = useLocationHistory()

    const { familyByReference, } = usePatents()

    const family = familyByReference[internalReference]

    useEffect(() => {
        const timer = setTimeout(() => {
            if (family === undefined)
                navigate("/patents/portfolio")
        }, 1500)
    
      return () => clearTimeout(timer)
    }, [family, navigate])

    if (!family) return null

    return <>
        {/* @ts-ignore */}
        <Helmet>
            <title>{t('patent-family')} {family.internalReference} | Patent Cockpit</title>
        </Helmet>
        <BreadCrumbs parts={[
            { to: (getLastMatchingPath("/patents/portfolio?")?.path ?? "..") + '#' + encodeURIComponent(family.internalReference), label: t('patents') },
            <FamilyBreadCrumb family={family} />,
        ]} />
        <FamilyProvider family={family}>
            <div className="overflow-auto grow px-2 sm:px-4">
                <div className="w-fit lg:min-w-4xl xl:min-w-6xl" >
                    <LinkedTabCard links={[
                        { to: '.', label: t('overview') },
                        hasClaimScopes && { to: 'subfamilies', label: t('subfamilies') },
                        hasDocuments && { to: 'documents', label: t('documents') },
                        hasCosts && { to: 'costs', label: t('costs') },
                    ]} />
                </div>
            </div>
        </FamilyProvider>
    </>
}

export function FamilyOverview() {
    const { isEditUser } = useRoles()
    const family = useFamily()

    if (!family) return null

    return (
        <div className="grid grid-cols-1 lg:grid-cols-[minmax(40rem,48rem)_45ch] divide-pcx-200 max-lg:divide-y-2 lg:divide-x-2">
            <div className="lg:pr-4 max-lg:pb-4">
                <PatentFamilyDetails {...{ family, isEditUser }} />
            </div>
            <div className="max-lg:pt-4 lg:pl-4 max-xl:text-sm">
                <FamilyComments {...{ family }} />
            </div>
            <Outlet />
        </div>
    )
}

export function FamilySubfamilies() {
    const {t} = useTranslation()

    const [applyGlobalFilter, setApplyGlobalFilter] = useLocalState('claimscape-apply-global-fitler', true)
    const [onlyIndependentClaims, setOnlyIndependent] = useLocalState('family-subfamilies-only-independent', false)
    const family = useFamily()
    
    const patentFamilyId = family?.patentFamilyId
    if (!patentFamilyId) return null

    return <>
        <div className="flex flex-row gap-4 max-w-3xl">
            <h4 className="pb-2 mr-auto">{t('subfamilies')}</h4>
            <label>
                <input type="checkbox" checked={onlyIndependentClaims} onChange={() => setOnlyIndependent(!onlyIndependentClaims)} className="form-checkbox" />
                <span className="ml-2 text-sm text-slate-500">{t('show-only-independent')}</span>
            </label>
            <label>
                <input type="checkbox" checked={applyGlobalFilter} onChange={() => setApplyGlobalFilter(!applyGlobalFilter)} className="form-checkbox" />
                <span className="ml-2 text-sm text-slate-500">{t('apply-global-filter')}</span>
            </label>
        </div>
        <ClaimScape2 {...{ patentFamilyId, onlyIndependentClaims, applyGlobalFilter }} />
    </>
}

export function FamilyCosts() {
    const family = useFamily()
    
    const patentFamilyId = family?.patentFamilyId
    if (!patentFamilyId) return null

    return <>
        <FamilyCostsElement {...{ patentFamilyId }} />
        <Outlet />
    </>
}

export function FamilyDocuments() {
    const family = useFamily()

    if (!family) return null

    return <FocusedBrowser entity={patent_family} internalReference={family.internalReference} parentName={family.familyName} />
}

let FamilyContext = createContext<Family | null>(null)

function FamilyProvider({ family, children }: { family: Family, children: ReactNode }) {
    return <FamilyContext.Provider value={family}>{children}</FamilyContext.Provider>
}

function useFamily() {
    return useContext(FamilyContext)
}