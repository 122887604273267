import React, {useContext} from "react";
import _ from 'lodash'

import { useBackend } from "../BackendProvider";
import { evaluation_link, evaluation_meta_link } from "../data";
import { DateOrString } from "../utils/dates";

// score = {familyMemberId, type, score}

export type Score = {
    familyMemberId: number;
    type: string;
    score: number;
}

export type ValuationMeta = {
    familyMemberId: number;
    evaluationDate: DateOrString;
}

export type ValuationsContextProps = {
    scores: Score[];
    scoresLookup: Record<number, Score[]>;
    addScores: (scores: Score[]) => Promise<void>;
    deleteScores: (scores: Score[]) => Promise<void>;

    valuationMeta: ValuationMeta[];
    addValuationMetas: (valuationMetas: ValuationMeta[]) => Promise<void>;
    deleteValuationMetas: (valuationMetas: ValuationMeta[]) => Promise<void>;

    loadAll: () => Promise<void>;
}


const ValuationsContext = React.createContext({
    scores: [],
    scoresLookup: {},
    addScores: (ss) => Promise.resolve({}),
    deleteScores: (ss) => Promise.resolve({}),
    
    valuationMeta: [],
    addValuationMetas: (ms) => Promise.resolve({}),
    deleteValuationMetas: (ms) => Promise.resolve({}),

    loadAll: () => Promise.resolve({})
})

export default function ValuationsProvider({children}) {
    const { evaluations, evaluationMetas, linkOperation } = useBackend()

    const scoresLookup = _.groupBy(evaluations, e => e.familyMemberId)

    //console.log(evaluations)
    //console.log(evaluationMetas)

    let value = {
        scores: evaluations,
        scoresLookup,
        addScores: (scores) => linkOperation(evaluation_link, "bulk-add", scores),
        deleteScores: (scores) => linkOperation(evaluation_link, "bulk-delete", scores),

        valuationMeta: evaluationMetas,
        addValuationMetas: (metas) => linkOperation(evaluation_meta_link, "bulk-add", metas),
        deleteValuationMetas: (metas) => linkOperation(evaluation_meta_link, "bulk-delete", metas),

        loadAll: () => linkOperation(evaluation_link, "get").then(() => linkOperation(evaluation_meta_link, "get")) 
    }

    return (
        <ValuationsContext.Provider value={value}>
            {children}
        </ValuationsContext.Provider>
    )
}

export function useValuations() {
    return useContext(ValuationsContext)
}