import { useState } from 'react'
import { useNavigate } from 'react-router'
import { createSearchParams, Link, useSearchParams } from 'react-router-dom'
import { Field, Form, Formik } from 'formik'
import { Trans, useTranslation } from 'react-i18next'

import { IconEdit, IconTrash } from '../components/icons'
import Modal from '../components/Modal'
import { Comment as CommentProps, useComments } from './CommentsProvider'
import { useAuth, useRoles } from '../user/Auth'
import { useUserSettings } from '../user/UserSettingsProvider'
import { DateTimePicker } from '../components/DatePicker'
import { family_member, patent_family } from '../data'
import { EntityLink } from '../components/input/ReferenceLinker'
import { usePatents } from '../patents/PatentsProvider'
import { prettyDate } from '../utils/dates'


export function Comment({comment, user, commentId, entityId, entity, created, isEditable = true}: CommentProps & {isEditable?: boolean}) {
    const {deleteComment} = useComments()
    const {t} = useTranslation()
    const {users} = useUserSettings()
    const {isEditUser} = useRoles()

    const [isDeleting, setIsDeleting] = useState(false)

    const search = createSearchParams({ 
        commentId: commentId?.toString() ?? '',
        entity,
        entityId: entityId?.toString() ?? '',
    }).toString()

    return (
        <div className="border border-pcx-200 rounded-md overflow-hidden shrink-0 shadow-sm">
            <div className="px-2 py-1 flex flex-row gap-1 items-center border-b border-pcx-200 bg-pcx-200">
                <div className="whitespace-nowrap font-medium text-pc-600">{users[user]?.displayName ?? user}</div>
                <div className="grow" />
                <div className="max-sm:hidden text-pcx-600 text-sm tabular-nums pr-2 pt-1 whitespace-nowrap">
                    {prettyDate(created)}
                </div>
                {isEditUser && isEditable && <>
                    <Link className="w-5 h-5 p-px btn-primary shrink-0" to={{ pathname: "comments", search }} ><IconEdit /></Link>
                    <button title="Delete comment" className="shrink-0 w-5 h-5 p-px btn-warn disabled:btn-disabled disabled:p-px" onClick={() => setIsDeleting(true)}><IconTrash /></button>
                </>}
            </div>
            <div className="p-2 whitespace-pre-line">
                {comment}
            </div>
            {isDeleting &&
                <Modal>
                <div className="p-4">
                    <h3>{t('delete-comment-question')}</h3>
                    <p className="pt-2 text-slate-600 max-w-prose">
                        {comment}
                    </p>
                </div>
                    <div className="flex flex-row justify-end gap-2 items-center text-sm p-4 bg-pc-200">
                        <button className="btn-secondary" onClick={() => setIsDeleting(false)}>{t('cancel')}</button>
                        <button className="btn-warn" onClick={() => deleteComment({ commentId } as CommentProps)}>{t('delete')}</button>
                    </div>
                </Modal>
            }
        </div>
    )
}

// TODO: not needed?
export function SmallComment({user, created, comment, entity, entityId, commentId, to=undefined}: CommentProps & {to?: string}) {
    const {users} = useUserSettings()
    const commentStyle = "pl-1 pb-2 whitespace-pre-line block"
    const fullComment = <>{comment} <span className="ml-auto text-slate-400 float-right pb-2">{prettyDate(created)}</span></>

    return <>
        <p className="px-2 bg-pcx-200 flex flex-row gap-2 justify-between clear-both">
            <span className="font-medium text-pcx-800">{users[user]?.displayName ?? user}</span> 
            <EntityLink entity={entity} entityId={entityId} className='text-pcx-600 hover:underline'/>
        </p>
        {to === undefined 
            ? <p className={commentStyle}>{fullComment}</p> 
            : <Link to={`${to}?commentId=${commentId}&entity=${entity}&entityId=${entityId}`} className={commentStyle}>{fullComment}</Link>}
    </>
}

export function AddCommentModal() {
    const {t} = useTranslation()
    let navigate = useNavigate()
    let [searchParams] = useSearchParams()
    const commentId = parseInt(searchParams.get('commentId'))
    const entityId = parseInt(searchParams.get('entityId'))
    const entity = searchParams.get('entity')

    if (isNaN(entityId) || entity === null)
        console.error(`Cannot create comment for unknown entity (${entity} / ${entityId})`)

    const { familyById, memberById } = usePatents()
    const name = ( // TODO: as search param
        entity === patent_family ? familyById[entityId]?.internalReference :
        entity === family_member ? memberById[entityId]?.internalReference :
        searchParams.get('name')
    ) ?? ''

    const {users} = useUserSettings()
    const {user: {name: user}} = useAuth()

    const {addComment, updateComment, comments} = useComments()

    const initialComment = comments.find(c => c.commentId === commentId)
    const isAdding = initialComment === undefined

    const initialValues = initialComment ?? {
        comment: '',
        created: new Date().toISOString()
    }

    const submit = !isNaN(commentId) // == isUpdating?
        ? ({ comment, created }) => updateComment({user, created, commentId, comment, entity, entityId}).then(() => navigate(".."))
        : ({ comment, created }) => addComment({ user, created, comment, entityId, entity }).then(() => navigate(".."))

    return (
        <Modal escAction={() => navigate("..")}>
            <h3 className="p-4 pb-0"><Trans i18nKey="comment-for" values={{name}} /></h3>
            <div className="min-w-[90vw] sm:min-w-[60ch]">
                <Formik
                    initialValues={initialValues}
                    onSubmit={submit}
                >
                    <Form>
                        <div className="p-4">
                            <div className="whitespace-nowrap font-medium text-pc-600">{users[user]?.displayName ?? user}</div>
                            {isAdding
                                ? <div className="text-slate-400 tabular-nums">
                                    {prettyDate(initialValues.created)}
                                </div>
                                : <Field name="created" className="mt-2 form-input border-slate-300 text-slate-600 p-1 pr-2" as={DateTimePicker} />
                            }
                            <Field name="comment" autoFocus as="textarea" className="mt-2 form-textarea sm:w-[60ch] p-1 border border-slate-300 min-h-[8rem]" />
                        </div>
                        <div className="flex flex-row-reverse gap-4 bg-pc-200 p-4">
                            <input type="submit" value={t("save")} className="btn-primary" />
                            <button className="btn-secondary" onClick={() => navigate("..")}>{t('cancel')}</button>
                        </div>
                    </Form>
                </Formik>
            </div>
        </Modal>
    )
}