import _ from "lodash";
import React, {useContext} from "react";

import { useCrud } from "../BackendProvider";

export interface Comment {
    commentId?: number;
    user: string;
    created: string;
    //updated: string;
    comment: string;
    entity: string;
    entityId: number
}


let CommentsContext = React.createContext({
    comments: [] as Comment[],
    commentsLookUp: {} as Record<string, Record<number, Comment[]>>,
    addComment: (arg: Comment) => Promise.resolve(arg),
    deleteComment: (arg: Comment) => Promise.resolve(arg),
    updateComment: (arg: Comment) => Promise.resolve({}),
    //loadComments: () => Promise.resolve({}),
})

export default function CommentsProvider({children}) {
    const {data: comments, postMutation, deleteMutation: deleteComment} = useCrud<Comment>('comment', c => c.commentId)

    const commentsLookUp = _(comments)
        .groupBy(c => c.entity)
        .mapValues(cs => 
            _(cs)
                .groupBy(c => c.entityId)
                .mapValues(_cs => _(_cs).sortBy(c => c.created).reverse().value())
                .value())
        .value()
    
    return (
        <CommentsContext.Provider value={{comments, commentsLookUp, addComment: postMutation, updateComment: postMutation, deleteComment}}>
            {children}
        </CommentsContext.Provider>
    )
}

export function useComments() {
    return useContext(CommentsContext)
}