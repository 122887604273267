import React, { useContext } from "react";
import { Commodity, CommodityClaimScopeLink, CommodityCountryLink, CommodityFamilyLink } from "./products";
import { useCrud, useLinkedCrud } from "../BackendProvider";
import { commodity_claim_scope_link, commodity_country_link, commodity_family_link } from "../data";
import _ from "lodash";
import { useQueryClient } from "@tanstack/react-query";

let ProductsContext = React.createContext({
    commodities: [] as Commodity[],
    postCommodity: (commodity: Commodity) => Promise.resolve(commodity),
    deleteCommodity: (commodity: Commodity) => Promise.resolve({}),
    commodityById: {} as Record<number, Commodity>,
    commodityByReference: {} as Record<string, Commodity>,

    commodityFamilyLinks: [] as CommodityFamilyLink[],
    postCommodityFamilyLink: (links: CommodityFamilyLink | CommodityFamilyLink[]) => Promise.resolve(links) as Promise<CommodityFamilyLink | CommodityFamilyLink[]>,
    deleteCommodityFamilyLink: (links: CommodityFamilyLink | CommodityFamilyLink[]) => Promise.resolve({}),
    commodityClaimScopeLinks: [] as CommodityClaimScopeLink[],
    postCommodityClaimScopeLink: (links: CommodityClaimScopeLink | CommodityClaimScopeLink[]) => Promise.resolve(links) as Promise<CommodityClaimScopeLink | CommodityClaimScopeLink[]>,
    deleteCommodityClaimScopeLink: (links: CommodityClaimScopeLink | CommodityClaimScopeLink[]) => Promise.resolve({}),
    commodityCountryLinks: [], // TODO
    postCommodityCountryLink: (links: CommodityCountryLink | CommodityCountryLink[]) => Promise.resolve(links) as Promise<CommodityCountryLink | CommodityCountryLink[]>,
    deleteCommodityCountryLink: (links: CommodityCountryLink | CommodityCountryLink[]) => Promise.resolve({}),

    isLoading: false as boolean,
    reload: () => {},
})

export function ProductsProvider({children}) {
    const queryClient = useQueryClient()

    const {data: commodities, postMutation: postCommodity, deleteMutation: deleteCommodity, isLoading: isCommoditiesLoading} =
        useCrud<Commodity>('commodity', c => c.commodityId)

    const {data: commodityFamilyLinks, postMutation: postCommodityFamilyLink, deleteMutation: deleteCommodityFamilyLink, isLoading: isFamilyLinksLoading} =
        useLinkedCrud<CommodityFamilyLink>(commodity_family_link, (a, b) => a.commodityId === b.commodityId && a.patentFamilyId === b.patentFamilyId)
    const {data: commodityClaimScopeLinks, postMutation: postCommodityClaimScopeLink, deleteMutation: deleteCommodityClaimScopeLink, isLoading: isClaimScopesLoading} =
        useLinkedCrud<CommodityClaimScopeLink>(commodity_claim_scope_link, (a, b) => a.commodityId === b.commodityId && a.claimScopeId === b.claimScopeId)
    const {data: commodityCountryLinks, postMutation: postCommodityCountryLink, deleteMutation: deleteCommodityCountryLink, isLoading: isCountriesLoading} =
        useLinkedCrud<CommodityCountryLink>(commodity_country_link, (a, b) => a.commodityId === b.commodityId && a.countryCode === b.countryCode)

    const commodityById = _.keyBy(commodities, c => c.commodityId)
    const commodityByReference = _.keyBy(commodities, 'commodityReference')

    const isLoading = isCommoditiesLoading || isFamilyLinksLoading || isClaimScopesLoading || isCountriesLoading

    function reload() {
        queryClient.invalidateQueries(['commodity'])
        queryClient.invalidateQueries([commodity_family_link])
        queryClient.invalidateQueries([commodity_claim_scope_link])
        queryClient.invalidateQueries([commodity_country_link])
    }

    // TODO deleteing commodity should do this:
    /*

                  .then(() =>
                    Promise.all(
                      entityOperation(commodity_family_link, "get"),
                      entityOperation(commodity_claim_scope_link, "get")
                    )
                  )
                  */

    const value = {
        commodities,
        postCommodity, deleteCommodity,
        commodityById, 
        commodityByReference,
        commodityFamilyLinks,
        postCommodityFamilyLink, deleteCommodityFamilyLink,
        commodityClaimScopeLinks,
        postCommodityClaimScopeLink, deleteCommodityClaimScopeLink,
        commodityCountryLinks,
        postCommodityCountryLink, deleteCommodityCountryLink,
        isLoading, reload,
    }

    return (
        <ProductsContext.Provider value={value}>
            {children}
        </ProductsContext.Provider>
    )
}

export function useProducts() {
    return useContext(ProductsContext)
}