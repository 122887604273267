import React, { useContext, useEffect, useState } from "react";

import { useLocalState } from "../settings/localStorage";

export interface FilterConfig {
  name: string;
  config?: any;
  display?: string;
  isActive: boolean;
}

let FilterContext = React.createContext({
  filterText: "",
  setFilterText: (arg: string | ((s: string) => {})) => {},
  showActiveOnly: true,
  setShowActiveOnly: (arg: boolean | ((s: boolean) => {})) => {},

  activeFilters: [] as FilterConfig[],
  setActiveFilters: (
    arg: FilterConfig[] | ((a: FilterConfig[]) => FilterConfig[])
  ) => {}, // Dispatch<SetState<FilterConfig[]>>
  addFilter: (arg: FilterConfig) => {},

  updateActiveFilter: (arg: FilterConfig, index: number) => {},
  removeActiveFilter: (index: number) => {},
});


function loadActiveFilters() {
  const filterString = localStorage.getItem("active-filters");

  if (filterString === null) {
    return [ ];
  }
  const filters = JSON.parse(filterString);
  return filters
}
function saveActiveFilters(filters: FilterConfig[]) {
  localStorage.setItem("active-filters", JSON.stringify(filters));
}

export function FilterProvider({ children }) {

  // TODO: remove
  const [filterText, setFilterText] = useState("");
  const [showActiveOnly, setShowActiveOnly] = useLocalState('filter-active-only', true)

  const [activeFilters, setActiveFilters] = useState(loadActiveFilters());
  useEffect(() => saveActiveFilters(activeFilters), [activeFilters]);

  function updateActiveFilter(filter: FilterConfig, index: number) {
    setActiveFilters((fs: FilterConfig[]) =>
      fs.map((f, i) => (i === index ? filter : f))
    );
  }

  function removeActiveFilter(index: number) {
    setActiveFilters((fs: FilterConfig[]) => fs.filter((f, i) => i !== index));
  }

  let value = {
    filterText,
    setFilterText,
    showActiveOnly,
    setShowActiveOnly,
    activeFilters,
    setActiveFilters,
    addFilter: (filter: FilterConfig) => setActiveFilters(fs => [...fs, filter]),
    updateActiveFilter,
    removeActiveFilter,
  };
  
  return (
    <FilterContext.Provider value={value}>{children}</FilterContext.Provider>
  );
}

export function useFilter() {
  return useContext(FilterContext);
}
